import React from "react";
import OpportunityRadarGetDataAndFilter from "./OpportunityRadarComponents/OpportunityRadarGetDataAndFilter";

export default function OpportunityRadar() {
  return (
    <div>
      <OpportunityRadarGetDataAndFilter />
    </div>
  );
}

import React from "react";
import GenericReportDateScope from "./GlobalBodyComponents/GenericReportDateScope";
import GlobalChannelOverView from "./GlobalBodyComponents/GlobalChannelOverView";

export default function GlobalBody() {
  return (
    <div className="container">
      <h5 style={{ color: "#4c1d95" }}>Overall Reports</h5>
      <GlobalChannelOverView />
    </div>
  );
}

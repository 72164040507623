import React from "react";
import "../MyCss/Footer.css";
import o2runbox from "../Images/o2runbox.png";
import { useState, useEffect, Dimensions } from "react";

export default function Footer() {
  function useWindowSize() {
    const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
    }, []);
    return size;
  }
  const [height, width] = useWindowSize();
  return (
    <div className="footer">
      <img
        style={{
          maxWidth:
            width > 1201
              ? "8%"
              : width > 1025
              ? "10%"
              : width > 769
              ? "12%"
              : width > 481
              ? "15%"
              : width > 320
              ? "25%"
              : "25%",
          // maxHeight: "auto",
          marginLeft: 10,
          marginBottom: 10,
        }}
        src={o2runbox}
        class="rounded float-start"
        alt="image"
      ></img>
    </div>
  );
}

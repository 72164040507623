import axios from "axios";
import React, { useState, useEffect, useCallback } from "react";
import { withRouter, useHistory, useLocation } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
//import { useChart } from "../components/chart";
import { useChart } from "../chart";
import PropTypes from "prop-types";
import { Select } from "antd";
import { Card, CardHeader, Box, Grid } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { MyUrl } from "../../App";
import { Avatar, List, Skeleton, Switch } from "antd";
import DemandTable1 from "./DemandTable1";
import DemandTable2 from "./DemandTable2";
import DemandTable3 from "./DemandTable3";
import DemandTable4 from "./DemandTable4";
import DemandTable5 from "./DemandTable5";
import DemandTable6 from "./DemandTable6";

import { ColorLensOutlined } from "@mui/icons-material";
var MyArray0 = [];
var MyArray1a = [];
var MyArray2 = [];
var MyArray3 = [];
var MyArray4 = [];
var MyArray5 = [];
var MyArray6 = [];
var ForSelectArray = [];
var ForSelectArray2 = [];
var Obj11 = {};
var Obj12 = {};
var Obj13 = {};
var Obj14 = {};

var newArray9 = [];
var newArray = [];
var newArray1 = [];
var newArray2 = [];
var newArray3 = [];
var newArray4 = [];
var newArray5 = [];
var newArray6 = [];
var newArray7 = [];

var Obj1 = {};
var Obj2 = {};
var Obj3 = {};
var Obj4 = {};
var Obj5 = {};
var Obj6 = {};
var Obj7 = {};

export default function PickCompanies({ MyDateFrom, MyDateTo, ...other }) {
  const [isLoading1, setIsLoading1] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);
  const [isLoading3, setIsLoading3] = useState(true);
  const [isLoading4, setIsLoading4] = useState(true);
  const [isLoading5, setIsLoading5] = useState(true);
  const [isLoading6, setIsLoading6] = useState(true);
  const [isLoading7, setIsLoading7] = useState(true);
  const [isLoading8, setIsLoading8] = useState(true);
  const [isLoading9, setIsLoading9] = useState(true);

  const { pathname } = useLocation();
  const [Obj, setObj] = useState([]);
  const [data1, setData1] = useState("");
  const [data2, setData2] = useState("");
  const [data3, setData3] = useState("");
  const [data4, setData4] = useState("");
  const [data5, setData5] = useState("");
  const [data6, setData6] = useState("");
  const [data7, setData7] = useState("");
  const [data8, setData8] = useState("");
  const [data9, setData9] = useState([]);

  const [MyArray1, setMyArray1] = useState([]);
  const [AppsDate, setAppsDate] = useState("&fillApps=true");

  //${MyUrl}/api/DemandReport/getDemandData?${a}${MyDateFrom}${MyDateTo}&daily=true // For Graph with true

  //${MyUrl}/api/DemandReport/getAppsData?ids=4&startDate=2022-12-01&endDate=2022-12-01&fillApps=true // from left side with true
  //${MyUrl}/api/DemandReport/getRegionsData?ids=4&startDate=2022-12-01&endDate=2022-12-02 //in middle
  //${MyUrl}/api/DemandReport/getOsData?startDate=2022-12-01&endDate=2022-12-02 //from rigth

  //${MyUrl}/api/DemandReport/fillsBest?startDate=2022-12-22&endDate=2022-12-22 //For first table after 3 behind each
  //${MyUrl}/api/DemandReport/campOptimization?startDate=2022-12-01&endDate=2022-12-20 //for second table after 3 behind each
  //${MyUrl}/api/DemandReport/getOpportunityData?&startDate=2022-12-22&endDate=2022-12-22 // for third table anfter 3 behid each
  const [isLoading10, setIsLoading10] = useState(true);
  const [isLoading11, setIsLoading11] = useState(true);
  const [isLoading12, setIsLoading12] = useState(true);
  const now = new Date();
  const MyToken = JSON.parse(localStorage.getItem("Token"));
  console.log("MyToken");
  console.log(MyToken);
  // const [DaysPerCurrentMonth, setDaysPerCurrentMonth] = useState([]);
  // Tota number of days in current month
  const totalDays = new Date(
    now.getFullYear(),
    now.getMonth() + 1,
    0
  ).getDate();

  // Today's day
  const today = now.getDate();
  const month = now.getMonth() + 1;
  const year = now.getFullYear();

  // const FirstOfMonth = Date(now.getFullYear(), now.getMonth() + 1, 1);

  // Remaining days of the month
  const remainingDays = totalDays - today;
  const DaysPerCurrentMonth = [];
  for (var i = 1; i < totalDays + 1; i++) {
    DaysPerCurrentMonth.push(String(i) + "/" + month);
  }

  const chartLabels2 = [
    "00:00",
    "01:00",
    "02:00",
    "03:00",
    "04:00",
    "05:00",
    "06:00",
    "07:00",
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
    "23:00",
    "23:00",
    "24:00",
    "25:00",
  ];
  PickCompanies.propTypes = {
    title: PropTypes.string,
    subheader: PropTypes.string,
    chartData: PropTypes.array.isRequired,
    chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  };

  useEffect(() => {
    CallDataDemandDataForGrph();
  }, [MyArray0, MyArray6]);
  function MakeE() {
    setTimeout(() => {
      setIsLoading11(false);
    }, 0);
  }
  const CallDataGetOpportunityData = (a) => {
    setIsLoading12(true);
    newArray7 = [];
    try {
      axios
        .get(
          `${MyUrl}/api/DemandReport/getOpportunityData?${a}${MyDateFrom}${MyDateTo}
                `,
          {
            headers: {
              Authorization: `${MyToken}`,
            },
          }
        )
        .then((res) => {
          const data100 = res.data;
          console.log("newArray7");
          console.log(data100);
          for (let i = 0; i < data100.responseData.data.length; i++) {
            if (
              data100.responseData.data[i].impressions_good > 1000 &&
              data100.responseData.data[i].fill > 0.1 &&
              Number.parseFloat(
                data100.responseData.data[i].qs.toString().replace("e-", "")
              ).toFixed(2) > 5
            ) {
              Obj14 = {
                BillingName:
                  data100.responseData.data[i].advertiser.billingName,
                SEAT: data100.responseData.data[i].seatName,
                campaign: data100.responseData.data[i].campaign.name,
                ENV: data100.responseData.data[i].environment.name,
                OS: data100.responseData.data[i].os.name,
                Bundle: data100.responseData.data[i].app_bundle.name,
                Region: data100.responseData.data[i].region.name,
                Req: data100.responseData.data[i].ad_requests,
                Imp: data100.responseData.data[i].impressions_good,
                Fill: data100.responseData.data[i].fill,
                Revenue: data100.responseData.data[i].revenue_total,
                QS: Number.parseFloat(
                  data100.responseData.data[i].qs.toString().replace("e-", "")
                ).toFixed(2),
              };
              newArray7.push(Obj14);
            }
          }
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading12(false);
        });
    } catch (error) {}
  };
  const CallDataDemandDataForGrph = (a) => {
    data9.length = 0;
    setIsLoading10(true);
    try {
      axios
        .get(
          `${MyUrl}/api/DemandReport/getDemandData?${a}&startDate=${
            year + "-" + month + "-01"
          }&endDate=${year + "-" + month + "-" + today}&daily=true
                `,
          {
            headers: {
              Authorization: `${MyToken}`,
            },
          }
        )
        .then((res) => {
          const data66 = res.data;
          for (let i = 0; i < data66.responseData.data.length; i++) {
            MyArray3 = [];
            ForSelectArray2.push(
              data66.responseData.data[i].advertiser.billingName
            );
            for (let j = 0; j < data66.responseData.data[i].dates.length; j++) {
              MyArray3.push(
                data66.responseData.data[i].dates[j].revenue.toFixed(2)
              );
            }
            Obj12 = {
              type: "line",
              fill: "solid",
              data: MyArray3,
              name: data66.responseData.data[i].advertiser.billingName,
            };
            MyArray2.push(Obj12);
          }
          for (let i = 0; i < 10; i++) {
            MyArray1a = [];
            ForSelectArray.push(
              data66.responseData.data[i].advertiser.billingName
            );
            for (let j = 0; j < data66.responseData.data[i].dates.length; j++) {
              MyArray1a.push(
                data66.responseData.data[i].dates[j].revenue.toFixed(2)
              );
            }
            Obj11 = {
              type: "line",
              fill: "solid",
              data: MyArray1a,
              name: data66.responseData.data[i].advertiser.billingName,
            };
            MyArray0.push(data66.responseData.data[i].advertiser.billingName);
            MyArray6.push(Obj11);
          }
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading10(false);
        });
    } catch (error) {}
  };
  const chartOptions = useChart({
    plotOptions: { bar: { columnWidth: "30%" } },
    fill: { type: MyArray0.map((i) => i.fill) },
    labels: DaysPerCurrentMonth,
    xaxis: { type: "time" },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== "undefined") {
            return `${y
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} $`;
          }
          return y;
        },
      },
    },
  });

  const options = [];
  for (let i = 0; i < ForSelectArray2.length; i++) {
    options.push({
      label: ForSelectArray2[i],
      value: ForSelectArray2[i],
    });
  }
  const handleChange = (value) => {
    setIsLoading11(true);

    MyArray4 = value;
    MyArray6.length = 0;
    for (let i = 0; i < MyArray4.length; i++) {
      for (let j = 0; j < ForSelectArray2.length; j++) {
        if (MyArray4[i] === MyArray2[j].name) {
          MyArray5 = [];
          for (let k = 0; k < MyArray2[j].data.length; k++) {
            MyArray5.push(MyArray2[j].data[k]);
          }
          Obj13 = {
            name: MyArray2[j].name,
            data: MyArray5,
            type: "line",
            fill: "solid",
          };
          MyArray6.push(Obj13);
        }
      }
    }
    MakeE();
    setTimeout(() => {
      setIsLoading11(false);
    }, 0);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  function useWindowSize() {
    const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
    }, []);
    return size;
  }
  const [height, width] = useWindowSize();
  const CallDatafillsBest = (a) => {
    //the last

    setIsLoading8(true);
    newArray6 = [];
    try {
      axios
        .get(
          `${MyUrl}/api/DemandReport/fillsBest?${a}${MyDateFrom}${MyDateTo}
              `,
          {
            headers: {
              Authorization: `${MyToken}`,
            },
          }
        )
        .then((res) => {
          const data88 = res.data;
          setData8(data88);

          for (let i = 0; i < data88.responseData.data.length; i++) {
            Obj6 = {
              BillingName: data88.responseData.data[i].advertiser.billingName,
              SEAT: data88.responseData.data[i].seatName,
              ENV: data88.responseData.data[i].environment.name,
              OS: data88.responseData.data[i].os.name,
              Bundle: data88.responseData.data[i].app_bundle.name,
              Region: data88.responseData.data[i].region.name,
              Req: data88.responseData.data[i].ad_requests,
              Imp: data88.responseData.data[i].impressions_good,
              Fill: data88.responseData.data[i].fill,
              Revenue: data88.responseData.data[i].revenue_total,
              QS: Number.parseFloat(
                data88.responseData.data[i].qs.toString().replace("e-", "")
              ).toFixed(2),
            };
            newArray6.push(Obj6);
          }
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading8(false);
        });
    } catch (error) {}
  };
  const CallDataCampOptimization = (a) => {
    setIsLoading7(true);
    newArray5 = [];
    try {
      axios
        .get(
          `${MyUrl}/api/DemandReport/campOptimization?${a}${MyDateFrom}${MyDateTo}
              `,
          {
            headers: {
              Authorization: `${MyToken}`,
            },
          }
        )
        .then((res) => {
          const data77 = res.data;
          setData7(data77);
          for (let i = 0; i < data77.responseData.data.length; i++) {
            Obj5 = {
              Id: data77.responseData.data[i].campaign.id,
              CampaignName: data77.responseData.data[i].campaign.name,
              SEAT: data77.responseData.data[i].seatName,
              ENV: data77.responseData.data[i].environment.name,
              Req: data77.responseData.data[i].ad_requests,
              Imp: data77.responseData.data[i].impressions_good,
              Fill: data77.responseData.data[i].fill,
              Revenue: data77.responseData.data[i].revenue_total,
              QS: Number.parseFloat(
                data77.responseData.data[i].qs.toString().replace("e-", "")
              ).toFixed(2),
            };
            newArray5.push(Obj5);
          }
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading7(false);
        });
    } catch (error) {}
  };
  //   const CallDataDemandDataForGrph = (a) => {
  //     setIsLoading6(true);
  //     try {
  //       axios
  //         .get(
  //           `${MyUrl}/api/DemandReport/getDemandData?${a}${MyDateFrom}${MyDateTo}&daily=true
  //               `
  //         )
  //         .then((res) => {
  //           const data66 = res.data;
  //           setData6(data66);
  //         })
  //         .catch((error) => {})
  //         .finally(() => {
  //           setIsLoading6(false);
  //         });
  //     } catch (error) {}
  //   };
  const CallData1 = () => {
    setIsLoading1(true);
    MyArray1.length = 0;
    try {
      axios
        .get(
          `${MyUrl}/api/Dashboard/getDailyIncome?$${MyDateFrom}${MyDateTo}
              `,
          {
            headers: {
              Authorization: `${MyToken}`,
            },
          }
        )
        .then((res) => {
          const data2 = res.data;
          setData2(data2);
          for (let i = 0; i < data2.responseData.length; i++) {
            MyArray1.push(data2.responseData[i]);
          }
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading1(false);
        });
    } catch (error) {}
  };

  const CallDataTopOsData = (a) => {
    setIsLoading5(true);
    newArray3 = [];
    try {
      axios
        .get(
          `${MyUrl}/api/DemandReport/getOsData?${a}${MyDateFrom}${MyDateTo}
              `,
          {
            headers: {
              Authorization: `${MyToken}`,
            },
          }
        )
        .then((res) => {
          const data55 = res.data;
          setData5(data55);
          for (let i = 0; i < data55.responseData.data.length; i++) {
            Obj3 = {
              id: i,
              Env: data55.responseData.data[i].environment.name,
              OS: data55.responseData.data[i].os.name,
              REQ: data55.responseData.data[i].ad_requests,
              IMP: data55.responseData.data[i].impressions_good,
              Fill: data55.responseData.data[i].fill,
              Rev: data55.responseData.data[i].revenue_total,
              QS: Number.parseFloat(
                data55.responseData.data[i].qs.toString().replace("e-", "")
              ).toFixed(2),
            };
            newArray3.push(Obj3);
          }
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading5(false);
        });
    } catch (error) {}
  };

  const CallDataTopFillingRegions = (a) => {
    setIsLoading3(true);
    newArray1 = [];
    try {
      axios
        .get(
          `${MyUrl}/api/DemandReport/getRegionsData?${a}${MyDateFrom}${MyDateTo}
              `,
          {
            headers: {
              Authorization: `${MyToken}`,
            },
          }
        )
        .then((res) => {
          const data33 = res.data;
          setData3(data33);
          for (let i = 0; i < data33.responseData.data.length; i++) {
            Obj1 = {
              id: i,
              Region: data33.responseData.data[i].region.name,
              REQ: data33.responseData.data[i].ad_requests,
              IMP: data33.responseData.data[i].impressions_good,
              Fill: data33.responseData.data[i].fill,
              Rev: data33.responseData.data[i].revenue_total,
              QS: Number.parseFloat(
                data33.responseData.data[i].qs.toString().replace("e-", "")
              ).toFixed(2),
            };
            newArray1.push(Obj1);
          }
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading3(false);
        });
    } catch (error) {}
  };

  const CallDataAppsDate = (a) => {
    setIsLoading4(true);
    newArray2 = [];
    try {
      axios
        .get(
          `${MyUrl}/api/DemandReport/getAppsData?${a}${MyDateFrom}${MyDateTo}${AppsDate}
              `,
          {
            headers: {
              Authorization: `${MyToken}`,
            },
          }
        )
        .then((res) => {
          const data44 = res.data;
          setData4(data44);
          for (let i = 0; i < data44.responseData.data.length; i++) {
            Obj2 = {
              id: i,
              Bundle: data44.responseData.data[i].app_bundle.name,
              Name: data44.responseData.data[i].app_name.name,
              REQ: data44.responseData.data[i].ad_requests,
              IMP: data44.responseData.data[i].impressions_good,
              Fill: data44.responseData.data[i].fill,
              Rev: data44.responseData.data[i].revenue_total,
              QS: Number.parseFloat(
                data44.responseData.data[i].qs.toString().replace("e-", "")
              ).toFixed(2),
            };
            newArray2.push(Obj2);
          }
          //newArray2
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading4(false);
        });
    } catch (error) {}
  };

  useEffect(() => {
    CallDataGetOpportunityData();
    CallData1();
    CallDataTopFillingRegions();
    CallDataAppsDate();
    CallDataTopOsData();
    CallDataCampOptimization();
    CallDatafillsBest();
  }, [newArray9]); // shoud add newArray10

  return (
    <div>
      {isLoading1 ? (
        <>
          <Skeleton active avatar>
            <List.Item.Meta
              avatar={<Avatar src={""} />}
              title={<a href={""}>{""}</a>}
              description={""}
            />
            {""}
          </Skeleton>{" "}
        </>
      ) : (
        <>
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <div>
              {MyArray1.map(function (item, i) {
                return (
                  <>
                    <Checkbox
                      onClick={() => {
                        const index1 = newArray9.indexOf(
                          data2.responseData[i].id
                        );

                        if (index1 > -1) {
                          newArray9.splice(index1, 1);
                        } else {
                          newArray9.push(data2.responseData[i].id);
                        }

                        const index = newArray.indexOf(
                          `&ids=${data2.responseData[i].id}`
                        );

                        if (index > -1) {
                          newArray.splice(index, 1);
                        } else {
                          newArray.push(`&ids=${data2.responseData[i].id}`);
                        }
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <a style={{ color: "#7e22ce", fontSize: 25 }}>
                      {MyArray1[i].name}
                    </a>
                  </>
                );
              })}
              <div
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <br></br>
                <Button
                  style={{ margin: 5, backgroundColor: "#8b5cf6" }}
                  variant="contained"
                  onClick={() => {
                    CallDataGetOpportunityData(
                      newArray.toString().replaceAll(",", "")
                    );
                    //   CallData1(newArray.toString().replaceAll(",", ""));
                    CallDataTopFillingRegions(
                      newArray.toString().replaceAll(",", "")
                    );
                    CallDataAppsDate(newArray.toString().replaceAll(",", ""));
                    CallDataTopOsData(newArray.toString().replaceAll(",", ""));
                    CallDataCampOptimization(
                      newArray.toString().replaceAll(",", "")
                    );
                    CallDatafillsBest(newArray.toString().replaceAll(",", ""));
                    // CallDataDemandDataForGrph(
                    //   newArray.toString().replaceAll(",", "")
                    // );
                  }}
                >
                  Filter
                </Button>
                <br></br>
              </div>
            </div>
            &nbsp;
          </div>
        </>
      )}
      <>
        <div
          style={{
            flex: 500 > 769 ? (500 > 1023 ? "0 1 33%" : "0 1 50%") : "0 1 100%",
            width: "100%",
          }}
        ></div>
        {isLoading10 ? (
          <>
            <Skeleton active avatar>
              <List.Item.Meta
                avatar={<Avatar src={""} />}
                title={<a href={""}>{""}</a>}
                description={""}
              />
              {""}
            </Skeleton>
          </>
        ) : (
          <>
            <Card {...other}>
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder="Please select"
                defaultValue={MyArray0}
                onChange={handleChange}
                options={options}
              />
              <Box sx={{ p: 3, pb: 1 }} dir="ltr">
                <h5>Impressions in month</h5>
                <ReactApexChart
                  type="line"
                  series={MyArray6}
                  options={chartOptions}
                  height={750}
                />
              </Box>
              <h5
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  color: "black",
                }}
              >
                Days
              </h5>
            </Card>
          </>
        )}
      </>
      <div
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            flex:
              width > 769 ? (width > 1023 ? "0 1 33%" : "0 1 50%") : "0 1 100%",
            width: "100%",
          }}
        >
          {isLoading4 ? (
            <>
              <Skeleton active avatar>
                <List.Item.Meta
                  avatar={<Avatar src={""} />}
                  title={<a href={""}>{""}</a>}
                  description={""}
                />
                {""}
              </Skeleton>{" "}
            </>
          ) : (
            <>
              <DemandTable1 newArray2={newArray2} />
            </>
          )}
        </div>
        <div>&nbsp;</div>

        <div
          style={{
            flex:
              width > 769 ? (width > 1023 ? "0 1 33%" : "0 1 50%") : "0 1 100%",
            width: "100%",
          }}
        >
          {isLoading3 ? (
            <>
              <Skeleton active avatar>
                <List.Item.Meta
                  avatar={<Avatar src={""} />}
                  title={<a href={""}>{""}</a>}
                  description={""}
                />
                {""}
              </Skeleton>{" "}
            </>
          ) : (
            <>
              <DemandTable2 newArray1={newArray1} />
            </>
          )}
        </div>
        <div>&nbsp;</div>
        <div
          style={{
            flex:
              width > 769 ? (width > 1023 ? "0 1 33%" : "0 1 50%") : "0 1 100%",
            width: "100%",
          }}
        >
          {isLoading5 ? (
            <>
              <Skeleton active avatar>
                <List.Item.Meta
                  avatar={<Avatar src={""} />}
                  title={<a href={""}>{""}</a>}
                  description={""}
                />
                {""}
              </Skeleton>{" "}
            </>
          ) : (
            <>
              <DemandTable3 newArray3={newArray3} />
            </>
          )}
        </div>
        <div>&nbsp;</div>
      </div>
      {isLoading8 ? (
        <>
          <Skeleton active avatar>
            <List.Item.Meta
              avatar={<Avatar src={""} />}
              title={<a href={""}>{""}</a>}
              description={""}
            />
            {""}
          </Skeleton>{" "}
        </>
      ) : (
        <>
          <DemandTable5 newArray6={newArray6} />{" "}
        </>
      )}
      {isLoading7 ? (
        <>
          <Skeleton active avatar>
            <List.Item.Meta
              avatar={<Avatar src={""} />}
              title={<a href={""}>{""}</a>}
              description={""}
            />
            {""}
          </Skeleton>{" "}
        </>
      ) : (
        <>
          <DemandTable4 newArray5={newArray5} />{" "}
        </>
      )}

      {isLoading12 ? (
        <>
          <Skeleton active avatar>
            <List.Item.Meta
              avatar={<Avatar src={""} />}
              title={<a href={""}>{""}</a>}
              description={""}
            />
            {""}
          </Skeleton>{" "}
        </>
      ) : (
        <>
          <DemandTable6 newArray7={newArray7} />{" "}
        </>
      )}
    </div>
  );
}

import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Pie, measureTextWidth } from "@ant-design/plots";
import { withRouter, useHistory, useLocation } from "react-router-dom";

export default function GlobalDonuts({
  SendDataToGlobalDonuts,
  SendDataToGlobalDonuts1,
  SendDataToGlobalDonuts2,
}) {
  const { pathname } = useLocation();

  // console.log(SendDataToGlobalDonuts);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  function useWindowSize() {
    const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
    }, []);
    return size;
  }
  const [height, width] = useWindowSize();

  function renderStatistic(containerWidth, text, style) {
    const { width: textWidth, height: textHeight } = measureTextWidth(
      text,
      style
    );
    const R = containerWidth / 2; // r^2 = (w / 2)^2 + (h - offsetY)^2

    let scale = 1;

    if (containerWidth < textWidth) {
      scale = Math.min(
        Math.sqrt(
          Math.abs(
            Math.pow(R, 2) /
              (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2))
          )
        ),
        1
      );
    }

    const textStyleStr = `width:${containerWidth}px;`;
    return `<div style="${textStyleStr};font-size:${scale}em;line-height:${
      scale < 1 ? 1 : "inherit"
    };">${text}</div>`;
  }

  const data = [
    {
      name: "a",
      value: 1000,
    },
    {
      name: "b",
      value: 200,
    },
    {
      name: "c",
      value: 180,
    },
    {
      name: "d",
      value: 150,
    },
    {
      name: "e",
      value: 1200,
    },
    {
      name: "f",
      value: 500,
    },
  ];

  const formatter = (value, item) => {
    const percent = (
      (item.value / data.reduce((acc, curr) => acc + curr.value, 0)) *
      100
    ).toFixed(2);
    return `${item.name} (${percent}%)`;
  };

  const config2 = {
    appendPadding: 10,
    data: SendDataToGlobalDonuts2,
    angleField: "value",
    colorField: "name",
    radius: 1,
    innerRadius: 0.64,
    meta: {
      value: {
        formatter: (v) =>
          `${(
            (v /
              SendDataToGlobalDonuts2.reduce(
                (acc, curr) => acc + curr.value,
                0
              )) *
            100
          ).toFixed(2)}%`,
      },
    },
    legend: {
      position: "bottom",
    },
    radius: 0.6,
    appendPadding: 10,
    label: {
      type: "outer",
      offset: "35%",
      content: ({ percent }) => `${(percent * 100).toFixed(2)}%`,
      style: {
        fontSize: 14,
        textAlign: "center",
      },
    },
    statistic: {
      title: {
        offsetY: -4,
        customHtml: (container, view, datum) => {
          const { width, height } = container.getBoundingClientRect();
          const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
          const text = datum ? datum.name : "Revenue";
          return renderStatistic(d, text, {
            fontSize: 20,
          });
        },
      },
      content: {
        offsetY: 4,
        style: {
          fontSize: "15px",
        },
        customHtml: (container, view, datum, SendDataToGlobalDonuts2) => {
          const { width } = container.getBoundingClientRect();
          const text = datum
            ? `$ ${datum.value
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
            : `$ ${SendDataToGlobalDonuts2.reduce((r, d) => r + d.value, 0)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
          return renderStatistic(width, text, {
            fontSize: 15,
          });
        },
      },
    },
    // 添加 中心统计文本 交互
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
      {
        type: "pie-statistic-active",
      },
    ],
  };

  const config1 = {
    appendPadding: 10,
    data: SendDataToGlobalDonuts1,
    angleField: "value",
    colorField: "name",
    radius: 1,
    innerRadius: 0.64,
    // interactions: [
    //   {
    //     type: "element-selected",
    //   },
    //   {
    //     type: "element-active",
    //   },
    // ],
    meta: {
      value: {
        formatter: (v) =>
          `${(
            (v /
              SendDataToGlobalDonuts1.reduce(
                (acc, curr) => acc + curr.value,
                0
              )) *
            100
          ).toFixed(2)}%`,
      },
    },
    // label: {
    //   content: ({ percent }) => `${(percent * 100).toFixed(2)}%`,
    //   type: "inner",
    //   offset: "-50%",
    //   style: {
    //     textAlign: "center",
    //   },
    //   autoRotate: false,
    // },
    legend: {
      position: "bottom",
    },
    radius: 0.6,
    appendPadding: 10,

    label: {
      type: "outer",
      offset: "35%",
      content: ({ percent }) => `${(percent * 100).toFixed(2)}%`,
      style: {
        fontSize: 14,
        textAlign: "center",
      },
    },
    statistic: {
      title: {
        offsetY: -4,
        customHtml: (container, view, datum) => {
          const { width, height } = container.getBoundingClientRect();
          const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
          const text = datum ? datum.name : "Revenue";
          return renderStatistic(d, text, {
            fontSize: 20,
          });
        },
      },
      content: {
        offsetY: 4,
        style: {
          fontSize: "15px",
        },
        customHtml: (container, view, datum, SendDataToGlobalDonuts1) => {
          const { width } = container.getBoundingClientRect();
          const text = datum
            ? `$ ${datum.value
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
            : `$ ${SendDataToGlobalDonuts1.reduce((r, d) => r + d.value, 0)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
          return renderStatistic(width, text, {
            fontSize: 15,
          });
        },
      },
    },
    // 添加 中心统计文本 交互
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
      {
        type: "pie-statistic-active",
      },
    ],
  };
  const config = {
    appendPadding: 10,
    data: SendDataToGlobalDonuts,
    angleField: "value",
    colorField: "name",
    radius: 1,
    innerRadius: 0.64,
    // interactions: [
    //   {
    //     type: "element-selected",
    //   },
    //   {
    //     type: "element-active",
    //   },
    // ],
    meta: {
      value: {
        formatter: (v) =>
          `${(
            (v /
              SendDataToGlobalDonuts.reduce(
                (acc, curr) => acc + curr.value,
                0
              )) *
            100
          ).toFixed(2)}%`,
      },
    },
    // label: {
    //   content: ({ percent }) => `${(percent * 100).toFixed(2)}%`,
    //   type: "inner",
    //   offset: "-50%",
    //   style: {
    //     textAlign: "center",
    //   },
    //   autoRotate: false,
    // },
    legend: {
      position: "bottom",
    },
    radius: 0.6,
    appendPadding: 10,

    label: {
      type: "outer",
      offset: "35%",
      content: ({ percent }) => `${(percent * 100).toFixed(2)}%`,
      style: {
        fontSize: 14,
        textAlign: "center",
      },
    },
    statistic: {
      title: {
        offsetY: -4,
        customHtml: (container, view, datum) => {
          const { width, height } = container.getBoundingClientRect();
          const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
          const text = datum ? datum.name : "Revenue";
          return renderStatistic(d, text, {
            fontSize: 20,
          });
        },
      },
      content: {
        offsetY: 4,
        style: {
          fontSize: "15px",
        },
        customHtml: (container, view, datum, SendDataToGlobalDonuts) => {
          const { width } = container.getBoundingClientRect();
          const text = datum
            ? `$ ${datum.value
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
            : `$ ${SendDataToGlobalDonuts.reduce((r, d) => r + d.value, 0)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
          return renderStatistic(width, text, {
            fontSize: 15,
          });
        },
      },
    },
    // 添加 中心统计文本 交互
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
      {
        type: "pie-statistic-active",
      },
    ],
  };
  return (
    <div>
      <div
        style={{
          // alignItems: "center",
          // justifyContent: "center",
          // display: "flex",
          // flexWrap: "wrap",
          // paddingTop: 30,

          display: "flex",
          flexDirection: "row",
          overflowX: width > 766 ? "auto" : "scroll",
        }}
      >
        <div
          style={{
            margin: 10,
            backgroundColor: "white",
            flex: width > 766 ? "0 1 31%" : "0 1 100vh",
            borderRadius: 15,
            background: "#f9f8f9",
          }}
        >
          <div
            style={{
              width: width > 766 ? "" : 300,
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 15,
              justifyContent: "space-between",
              alignItems: "center",
              display: "flex",
            }}
          >
            <span style={{ color: "#1a1523", fontSize: 16 }}>
              Revenue by Seat
            </span>
            {/* <span style={{ color: "#1a1523", fontSize: 16, color: "#6f6e77" }}>
              5/03
            </span> */}
          </div>
          <Pie {...config} />
        </div>
        <div
          style={{
            margin: 10,
            backgroundColor: "white",
            flex: width > 766 ? "0 1 31%" : "0 1 100vh",
            borderRadius: 15,
            background: "#f9f8f9",
          }}
        >
          <div
            style={{
              width: width > 766 ? "" : 300,
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 15,
              justifyContent: "space-between",
              alignItems: "center",
              display: "flex",
            }}
          >
            <span style={{ color: "#1a1523", fontSize: 16 }}>
              Revenue by Env
            </span>
            {/* <span style={{ color: "#1a1523", fontSize: 16, color: "#6f6e77" }}>
              5/03
            </span> */}
          </div>

          <Pie {...config1} />
        </div>
        <div
          style={{
            margin: 10,
            backgroundColor: "white",
            flex: width > 766 ? "0 1 31%" : "0 1 100vh",
            borderRadius: 15,
            background: "#f9f8f9",
          }}
        >
          <div
            style={{
              width: width > 766 ? "" : 300,
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 15,
              justifyContent: "space-between",
              alignItems: "center",
              display: "flex",
            }}
          >
            <span style={{ color: "#1a1523", fontSize: 16 }}>
              Revenue by Integration
            </span>
            {/* <span style={{ color: "#1a1523", fontSize: 16, color: "#6f6e77" }}>
              5/03
            </span> */}
          </div>
          <Pie {...config2} />
        </div>
        &nbsp; &nbsp;
      </div>
    </div>
  );
}

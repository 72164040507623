import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
// @mui
import { Card, CardHeader, Box, Grid } from "@mui/material";
// components
import { useChart } from "./chart";
import { useEffect, useState, useCallback } from "react";

import ClipLoader from "react-spinners/ClipLoader";
import AppWidgetSummary from "./AppWidgetSummary";
import { Avatar, List, Skeleton, Switch } from "antd";

var sum1 = 0;

// ----------------------------------------------------------------------

SetIncomByMonth.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
  chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default function SetIncomByMonth({
  title,
  subheader,
  chartLabels,
  chartData,
  ...other
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);

  const now = new Date();
  const totalDays = new Date(
    now.getFullYear(),
    now.getMonth() + 1,
    0
  ).getDate();

  const today = now.getDate();

  const month = now.getMonth() + 1;
  const remainingDays = totalDays - today;
  const DaysPerCurrentMonth = [];
  for (var i = 1; i < totalDays + 1; i++) {
    DaysPerCurrentMonth.push(i);
  }
  const chartOptions = useChart({
    plotOptions: { bar: { columnWidth: "16%" } },
    fill: { type: chartData.map((i) => i.fill) },
    labels: DaysPerCurrentMonth,
    xaxis: { type: "time" },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== "undefined") {
            return `${y
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} $`;
          }
          return y;
        },
      },
    },
  });

  return (
    <>
      {!isLoading ? (
        <>
          <Skeleton active avatar>
            <List.Item.Meta
              avatar={<Avatar src={""} />}
              title={<a href={""}>{""}</a>}
              description={""}
            />
            {""}
          </Skeleton>
        </>
      ) : (
        <>
          <Card {...other}>
            <CardHeader title={title} subheader={subheader} />
            <Box sx={{ p: 3, pb: 1 }} dir="ltr">
              <h5>NetIncome by Dollar $</h5>

              <ReactApexChart
                type="line"
                series={chartData}
                options={chartOptions}
                height={650}
              />
            </Box>
            <h5
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                color: "black",
              }}
            >
              Days
            </h5>
          </Card>
        </>
      )}
    </>
  );
}
export const SetIncomByMonth7 = ({ chartData }) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div>
      {" "}
      {!isLoading ? (
        <>
          <Skeleton active avatar>
            <List.Item.Meta
              avatar={<Avatar src={""} />}
              title={<a href={""}>{""}</a>}
              description={""}
            />
            {""}
          </Skeleton>
        </>
      ) : (
        <>
          <Grid>
            <Grid item>
              <AppWidgetSummary
                title="Monthly Net Income Projection"
                total={`${chartData
                  .toFixed(3)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} $`}
                icon={"ant-design:android-filled"}
              />
            </Grid>{" "}
          </Grid>{" "}
          <br></br>
        </>
      )}
    </div>
  );
};

import React, { useState, useEffect } from "react";
import axios from "axios";
import { MyUrl } from "../../../App";
import moment from "moment";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Skeleton from "@mui/material/Skeleton";
import Lottie from "lottie-react";
import reloadpage from "../../../reloadpage.json";

import { Divider } from "antd";
import Calendar from "../../../Images/Calendar.png";
import DemandTopFillingApps from "./DemandTopFillingApps";
import DemandTopFillingRegions from "./DemandTopFillingRegions";
import DemandTopFillingOS from "./DemandTopFillingOS";
import DemandWhatFillsBests from "./DemandWhatFillsBests";
import DemandCampaignOptimizationOverview from "./DemandCampaignOptimizationOverview";
import DemandOpportunityRadar from "./DemandOpportunityRadar";
import DemandGraph from "./DemandGraph";
import jwt_decode from "jwt-decode";
var jwtToken = "";

var SendDataToFirstTable = [];
var ObjSendDataToFirstTable = {};

var SendDataToIncomeGraph = [];
var ObjSendDataToIncomeGraph = {};

var SendDataToSecondTable = [];
var ObjSendDataToSecondTable = {};

var SendDataToTopFillingApps = [];
var SendDataToTopFillingAppsLast = [];
var SendDataToTopFillingAppsLast2 = [];
var ObjSendDataToTopFillingApps = {};

var SendDataToFillingRegions = [];
var ObjSendDataToFillingRegions = {};

var SendDataToTopOS = [];
var ObjSendDataToTopOS = {};

var SendDataToFillsBest = [];
var ObjSendDataToFillsBest = {};

var SendDataToCampOptimization = [];
var ObjSendDataToCampOptimization = {};

var SendDataToOpportunityRadar = [];
var ObjSendDataToOpportunityRadar = {};

var SendDataToGrph = [];
var ObjSendDataToGrph = {};

var SendDataToGrph1 = [];
var ObjSendDataToGrph1 = {};

var SendDataToGrph2 = [];
var ObjSendDataToGrph2 = {};
var options = [];
var options2 = [];
var compName = "Apps";
var PickedDate = 0;
const styles = {
  root: {
    "&$checked": {
      color: "#3f51b5",
    },
  },
  checked: {},
  circle: {
    borderRadius: "50%",
    width: 20,
    height: 20,
    backgroundColor: "#fff",
    border: "2px solid #fff", // we can add color after solid
  },
  checkedIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    width: 20,
    height: 20,
    backgroundColor: "white",
  },
};

const CircleCheckbox = withStyles(styles)(({ classes, ...props }) => (
  <Checkbox
    {...props}
    classes={{
      root: classes.root,
      checked: classes.checked,
    }}
    icon={<span className={classes.circle} />}
    checkedIcon={
      <span className={classes.checkedIcon}>
        <CheckIcon style={{ fontSize: 16, color: "black" }} />
      </span>
    }
  />
));

export default function DemandGetDataAndFilter() {
  const [isClicked, setIsClicked] = useState(false);

  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();
  //const MyToken = JSON.parse(localStorage.getItem("Token"));
  const MyToken = JSON.parse(sessionStorage.getItem("Token"));

  const base64Url = MyToken.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const payload = JSON.parse(atob(base64));
  const expirationDate = new Date(payload.exp * 1000);
  const currentTime = new Date().getTime(); // Get the current time in milliseconds
  const [CheckTypeOfUser, setCheckTypeOfUser] = useState("");
  const RefreshFunc1 = async () => {
    const token = sessionStorage.getItem("Token");
    var parsed = JSON.parse(token);

    const decodedToken = jwt_decode(token);
    const role =
      decodedToken[
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
      ];
    setCheckTypeOfUser(role);
    jwtToken = JSON.parse(token); //parsed;
    const base64Url = JSON.parse(token).split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const payload = JSON.parse(atob(base64));
    const expirationDate = new Date(payload.exp * 1000);
    const currentTime = new Date().getTime(); // Get the current time in milliseconds
    if (expirationDate < currentTime) {
      try {
        axios
          .post(
            `${MyUrl}/api/RefreshToken/refresh`,
            { accessToken: JSON.parse(token).replace("Bearer ", "") },
            { withCredentials: true }
          )
          .then((response) => {
            parsed = response.data.accessToken;
            const updatedObjString = JSON.stringify(response.data.accessToken);
            sessionStorage.setItem(
              "Token",
              JSON.stringify(response.data.accessToken)
            );
            GetDailyIncomeFunc();
            GetDemandDataForGrphFunc(startDate, endDate);
            GetOpportunityRadarDataFunc(startDate, endDate);
            GetCampOptimizationDataFunc(startDate, endDate);
            GetFillsBestFunc(startDate, endDate);
            GetTopTopOSDataFunc(startDate, endDate);
            GetTopFillingRegionsDataFunc(startDate, endDate);
            GetTopFillingAppsFunc(startDate, endDate);
            GetMonthlyIncomeFunc(startDate, endDate);
            //RefreshFunc(Today, Today);

            return () => {
              source.cancel("Component unmounted");
            };
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      const items = sessionStorage.getItem("Token");
      var parsed = JSON.parse(items);
      jwtToken = JSON.parse(token); // parsed;
      GetDailyIncomeFunc();
      GetDemandDataForGrphFunc(startDate, endDate);
      GetOpportunityRadarDataFunc(startDate, endDate);
      GetCampOptimizationDataFunc(startDate, endDate);
      GetFillsBestFunc(startDate, endDate);
      GetTopTopOSDataFunc(startDate, endDate);
      GetTopFillingRegionsDataFunc(startDate, endDate);
      GetTopFillingAppsFunc(startDate, endDate);
      GetMonthlyIncomeFunc(startDate, endDate);
      //RefreshFunc(Today, Today);

      return () => {
        source.cancel("Component unmounted");
      };
    }
  };
  const RefreshFunc = async (startDate, endDate) => {
    const token = sessionStorage.getItem("Token");
    var parsed = JSON.parse(token);

    const decodedToken = jwt_decode(token);
    const role =
      decodedToken[
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
      ];
    setCheckTypeOfUser(role);
    jwtToken = JSON.parse(token); // parsed;
    const base64Url = JSON.parse(token).split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const payload = JSON.parse(atob(base64));
    const expirationDate = new Date(payload.exp * 1000);
    const currentTime = new Date().getTime(); // Get the current time in milliseconds
    if (expirationDate < currentTime) {
      try {
        axios
          .post(
            `${MyUrl}/api/RefreshToken/refresh`,
            { accessToken: JSON.parse(token).replace("Bearer ", "") },
            { withCredentials: true }
          )
          .then((response) => {
            parsed = response.data.accessToken;
            const updatedObjString = JSON.stringify(response.data.accessToken);
            sessionStorage.setItem(
              "Token",
              JSON.stringify(response.data.accessToken)
            );
            GetDemandDataForGrphFunc(startDate, endDate);
            GetOpportunityRadarDataFunc(startDate, endDate);
            GetCampOptimizationDataFunc(startDate, endDate);
            GetFillsBestFunc(startDate, endDate);
            GetTopTopOSDataFunc(startDate, endDate);
            GetTopFillingRegionsDataFunc(startDate, endDate);
            GetTopFillingAppsFunc(startDate, endDate);
            GetMonthlyIncomeFunc(startDate, endDate);
            return () => {
              source.cancel("Component unmounted");
            };
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      const items = sessionStorage.getItem("Token");
      var parsed = JSON.parse(items);
      jwtToken = JSON.parse(token);

      GetDemandDataForGrphFunc(startDate, endDate);
      GetOpportunityRadarDataFunc(startDate, endDate);
      GetCampOptimizationDataFunc(startDate, endDate);
      GetFillsBestFunc(startDate, endDate);
      GetTopTopOSDataFunc(startDate, endDate);
      GetTopFillingRegionsDataFunc(startDate, endDate);
      GetTopFillingAppsFunc(startDate, endDate);
      GetMonthlyIncomeFunc(startDate, endDate);

      return () => {
        source.cancel("Component unmounted");
      };
    }
  };

  useEffect(() => {
    setChooceCompanies([]);
    RefreshFunc1();
    // RefreshFunc(Today, Today);
  }, []);
  const [chooceCompanies, setChooceCompanies] = useState([]);
  const [ChooseBNamesForGraph, setChooseBNamesForGraph] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState("");
  const [isLoading1, setIsLoading1] = useState(true);
  const [data1, setData1] = useState("");
  const [isLoading2, setIsLoading2] = useState(true);
  const [data2, setData2] = useState("");

  const [isLoading3, setIsLoading3] = useState(true);
  const [data3, setData3] = useState("");

  const [isLoading4, setIsLoading4] = useState(true);
  const [data4, setData4] = useState("");

  const [isLoading5, setIsLoading5] = useState(true);
  const [data5, setData5] = useState("");

  const [isLoading6, setIsLoading6] = useState(true);
  const [data6, setData6] = useState("");

  const [isLoading7, setIsLoading7] = useState(true);
  const [data7, setData7] = useState("");

  const [isLoading8, setIsLoading8] = useState(true);
  const [data8, setData8] = useState("");

  const [isLoading9, setIsLoading9] = useState(true);
  const [data9, setData9] = useState("");

  const ChooceTable = ["Apps", "Regions", "OS"];

  const [CompColor, setCompColor] = useState(0);
  const [AppsDate, setAppsDate] = useState("&fillApps=true");

  //when pick today
  const Today = moment().add("days").format("YYYY-MM-DD");
  //when pick yesterday
  const Yesterday = moment().subtract(1, "day").format("YYYY-MM-DD");
  //when pick 7 days
  const SevenDays = moment().subtract(7, "day").format("YYYY-MM-DD");

  var LastDayInMonth = moment(LastDayInMonth)
    .endOf("month")
    .format("YYYY-MM-DD");

  var FirstDayInMonth = moment(FirstDayInMonth)
    .startOf("month")
    .format("YYYY-MM-DD");

  const currentDate = moment();
  const daysInCurrentMonth = currentDate.daysInMonth();

  const handleCheckboxChange = (event, productName) => {
    if (event.target.checked) {
      setChooceCompanies([...chooceCompanies, productName]); // add to array
    } else {
      setChooceCompanies(
        chooceCompanies.filter((name) => name !== productName)
      ); // remove from array
    }
  };

  const products = ["Product A", "Product B", "Product C"];
  // console.log(chooceCompanies);

  const idsArray = [1, 2, 3, 4];

  const IdsToGetData = chooceCompanies
    .map((id) => "&ids=" + String(id))
    .join("");

  //  console.log(IdsToGetData); // "&ids=1&ids=2&ids=3&ids=4"
  // const GetDailyIncomeFunc = () => {
  //   const items = sessionStorage.getItem("Token");
  //   //console.log(items);
  //   var parsed = JSON.parse(items);
  //   //console.log(parsed);

  //   console.log("GetDailyIncomeFunc");
  //   setIsLoading(true);
  //   try {
  //     let base64 = require("base-64"); // install it before use from npm i base-64
  //     axios
  //       .get(
  //         `${MyUrl}/api/Dashboard/getDailyIncome?&startDate=${startDate}&endDate=${endDate}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${parsed.replace("Bearer ", "")}`,
  //           },
  //           cancelToken: source.token,
  //         }
  //       )
  //       .then((res) => {
  //         const data = res.data;
  //         setData(data);
  //         //       console.log(data);
  //         for (let i = 0; i < data.responseData.length; i++) {
  //           //console.log(data.responseData[i].id);

  //           setChooceCompanies((prevCompanies) => [
  //             ...prevCompanies,
  //             data.responseData[i].id,
  //           ]);
  //         }
  //       })
  //       .catch((error) => {})
  //       .finally(() => {
  //         setIsLoading(false);
  //       });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const GetDailyIncomeFunc = () => {
    const items = sessionStorage.getItem("Token");
    var parsed = JSON.parse(items);
    setIsLoading(true);
    try {
      let base64 = require("base-64"); // install it before use from npm i base-64
      axios
        .get(`${MyUrl}/api/Account/userSeats`, {
          headers: {
            Authorization: `Bearer ${JSON.parse(items).replace("Bearer ", "")}`,
          },
          cancelToken: source.token,
        })
        .then((res) => {
          const data = res.data;
          setData(data);
          console.log(data);
          for (let i = 0; i < data.length; i++) {
            setChooceCompanies((prevCompanies) => [
              ...prevCompanies,
              data[i].id,
            ]);
          }
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading(false);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const GetTopFillingAppsFunc = (startDate, endDate) => {
    const items = sessionStorage.getItem("Token");
    var parsed = JSON.parse(items);

    setIsLoading3(true);
    try {
      axios
        .get(
          `${MyUrl}/api/DemandReport/getAppsData?${IdsToGetData}&startDate=${startDate}&endDate=${endDate}${AppsDate}
              `,
          {
            headers: {
              Authorization: `Bearer ${parsed.replace("Bearer ", "")}`,
            },
            cancelToken: source.token,
          }
        )
        .then((res) => {
          const data3 = res.data;
          setData3(data3);
          SendDataToTopFillingApps = [];
          ObjSendDataToTopFillingApps = {};
          for (let i = 0; i < data3.responseData.data.length; i++) {
            ObjSendDataToTopFillingApps = {
              id: i,
              Bundle: data3.responseData.data[i].app_bundle.name,
              Name: data3.responseData.data[i].app_name.name,
              REQ: data3.responseData.data[i].ad_requests,
              IMP: data3.responseData.data[i].impressions_good,
              Fill: data3.responseData.data[i].fill,
              Rev: data3.responseData.data[i].revenue_total,
              QS: Number.parseFloat(
                data3.responseData.data[i].qs.toString().replace("e-", "")
              ).toFixed(2),
            };
            SendDataToTopFillingApps.push(ObjSendDataToTopFillingApps);
          }

          // SendDataToTopFillingAppsLast = SendDataToTopFillingApps.sort(
          //   (a, b) => b - a
          // );
          // SendDataToTopFillingAppsLast2 = SendDataToTopFillingApps.slice(0, 10);
          //console.log(SendDataToTopFillingApps);
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading3(false);
        });
    } catch (error) {}
  };

  const GetTopFillingRegionsDataFunc = (startDate, endDate) => {
    const items = sessionStorage.getItem("Token");
    var parsed = JSON.parse(items);

    setIsLoading4(true);
    try {
      axios
        .get(
          `${MyUrl}/api/DemandReport/getRegionsData?${IdsToGetData}&startDate=${startDate}&endDate=${endDate}
              `,
          {
            headers: {
              Authorization: `Bearer ${parsed.replace("Bearer ", "")}`,
            },
            cancelToken: source.token,
          }
        )
        .then((res) => {
          const data4 = res.data;
          setData4(data4);
          SendDataToFillingRegions = [];
          ObjSendDataToFillingRegions = {};
          for (let i = 0; i < data4.responseData.data.length; i++) {
            ObjSendDataToFillingRegions = {
              id: i,
              Region: data4.responseData.data[i].region.name,
              REQ: data4.responseData.data[i].ad_requests,
              IMP: data4.responseData.data[i].impressions_good,
              Fill: data4.responseData.data[i].fill,
              Rev: data4.responseData.data[i].revenue_total,
              QS: Number.parseFloat(
                data4.responseData.data[i].qs.toString().replace("e-", "")
              ).toFixed(2),
            };
            SendDataToFillingRegions.push(ObjSendDataToFillingRegions);
          }
          //console.log(SendDataToFillingRegions);
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading4(false);
        });
    } catch (error) {}
  };

  const GetTopTopOSDataFunc = (startDate, endDate) => {
    const items = sessionStorage.getItem("Token");
    var parsed = JSON.parse(items);
    setIsLoading5(true);
    try {
      axios
        .get(
          `${MyUrl}/api/DemandReport/getOsData?${IdsToGetData}&startDate=${startDate}&endDate=${endDate}
              `,
          {
            headers: {
              Authorization: `Bearer ${parsed.replace("Bearer ", "")}`,
            },
            cancelToken: source.token,
          }
        )
        .then((res) => {
          const data5 = res.data;
          setData5(data5);
          SendDataToTopOS = [];
          ObjSendDataToTopOS = {};

          for (let i = 0; i < data5.responseData.data.length; i++) {
            ObjSendDataToTopOS = {
              id: i,
              Env: data5.responseData.data[i].environment.name,
              OS: data5.responseData.data[i].os.name,
              REQ: data5.responseData.data[i].ad_requests,
              IMP: data5.responseData.data[i].impressions_good,
              Fill: data5.responseData.data[i].fill,
              Rev: data5.responseData.data[i].revenue_total,
              QS: Number.parseFloat(
                data5.responseData.data[i].qs.toString().replace("e-", "")
              ).toFixed(2),
            };
            SendDataToTopOS.push(ObjSendDataToTopOS);
          }
          //console.log(SendDataToTopOS);
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading5(false);
        });
    } catch (error) {}
  };

  const GetFillsBestFunc = (startDate, endDate) => {
    const items = sessionStorage.getItem("Token");
    var parsed = JSON.parse(items);

    setIsLoading6(true);
    try {
      axios
        .get(
          `${MyUrl}/api/DemandReport/fillsBest?${IdsToGetData}&startDate=${startDate}&endDate=${endDate}
              `,
          {
            headers: {
              Authorization: `Bearer ${parsed.replace("Bearer ", "")}`,
            },
            cancelToken: source.token,
          }
        )
        .then((res) => {
          const data6 = res.data;
          console.log(data6);
          setData6(data6);
          SendDataToFillsBest = [];
          ObjSendDataToFillsBest = {};
          //console.log(data6);

          for (let i = 0; i < data6.responseData.data.length; i++) {
            ObjSendDataToFillsBest = {
              BillingName: data6.responseData.data[i].advertiser.billingName,
              SEAT: data6.responseData.data[i].fillSeat.name,
              ENV: data6.responseData.data[i].environment.name,
              OS: data6.responseData.data[i].os.name,
              Bundle: data6.responseData.data[i].app_bundle.name,
              //Region: data6.responseData.data[i].region.name,
              Req: data6.responseData.data[i].ad_requests,
              Imp: data6.responseData.data[i].impressions_good,
              Fill: data6.responseData.data[i].fill,
              Revenue: data6.responseData.data[i].revenue_total,
              QS: Number.parseFloat(
                data6.responseData.data[i].qs.toString().replace("e-", "")
              ).toFixed(2),
            };
            SendDataToFillsBest.push(ObjSendDataToFillsBest);
          }
          //console.log(SendDataToFillsBest);
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading6(false);
        });
    } catch (error) {}
  };

  const GetCampOptimizationDataFunc = (startDate, endDate) => {
    const items = sessionStorage.getItem("Token");
    var parsed = JSON.parse(items);

    setIsLoading7(true);
    try {
      axios
        .get(
          `${MyUrl}/api/DemandReport/campOptimization?${IdsToGetData}&startDate=${startDate}&endDate=${endDate}
              `,
          {
            headers: {
              Authorization: `Bearer ${parsed.replace("Bearer ", "")}`,
            },
            cancelToken: source.token,
          }
        )
        .then((res) => {
          const data7 = res.data;
          setData7(data7);
          SendDataToCampOptimization = [];
          ObjSendDataToCampOptimization = {};
          for (let i = 0; i < data7.responseData.data.length; i++) {
            ObjSendDataToCampOptimization = {
              Id: data7.responseData.data[i].campaign.id,
              CampaignName: data7.responseData.data[i].campaign.name,
              SEAT: data7.responseData.data[i].seatName,
              ENV: data7.responseData.data[i].environment.name,
              Req: data7.responseData.data[i].ad_requests,
              Imp: data7.responseData.data[i].impressions_good,
              Fill: data7.responseData.data[i].fill,
              Revenue: data7.responseData.data[i].revenue_total,
              QS: Number.parseFloat(
                data7.responseData.data[i].qs.toString().replace("e-", "")
              ).toFixed(2),
            };
            SendDataToCampOptimization.push(ObjSendDataToCampOptimization);
          }
          //console.log(SendDataToCampOptimization);
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading7(false);
        });
    } catch (error) {}
  };
  const GetOpportunityRadarDataFunc = (startDate, endDate) => {
    const items = sessionStorage.getItem("Token");
    var parsed = JSON.parse(items);
    setIsLoading8(true);
    try {
      axios
        .get(
          `${MyUrl}/api/DemandReport/getOpportunityData?${IdsToGetData}&startDate=${startDate}&endDate=${endDate}
                `,
          {
            headers: {
              Authorization: `Bearer ${parsed.replace("Bearer ", "")}`,
            },
            cancelToken: source.token,
          }
        )
        .then((res) => {
          const data8 = res.data;
          setData8(data8);
          //console.log(data8);
          SendDataToOpportunityRadar = [];
          ObjSendDataToOpportunityRadar = {};

          for (let i = 0; i < data8.responseData.data.length; i++) {
            if (
              data8.responseData.data[i].impressions_good > 1000 &&
              data8.responseData.data[i].fill > 0.1 &&
              Number.parseFloat(
                data8.responseData.data[i].qs.toString().replace("e-", "")
              ).toFixed(2) > 5
            ) {
              ObjSendDataToOpportunityRadar = {
                BillingName: data8.responseData.data[i].advertiser.billingName,
                SEAT: data8.responseData.data[i].seatName,
                campaign: data8.responseData.data[i].campaign.name,
                ENV: data8.responseData.data[i].environment.name,
                OS: data8.responseData.data[i].os.name,
                Bundle: data8.responseData.data[i].app_bundle.name,
                Region: data8.responseData.data[i].region.name,
                Req: data8.responseData.data[i].ad_requests,
                Imp: data8.responseData.data[i].impressions_good,
                Fill: data8.responseData.data[i].fill,
                Revenue: data8.responseData.data[i].revenue_total,
                QS: Number.parseFloat(
                  data8.responseData.data[i].qs.toString().replace("e-", "")
                ).toFixed(2),
              };
              SendDataToOpportunityRadar.push(ObjSendDataToOpportunityRadar);
            }
          }
          //console.log(SendDataToOpportunityRadar);
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading8(false);
        });
    } catch (error) {}
  };

  const yesterday = moment().subtract(1, "days").format("YYYY-MM-DD");
  const DaysOfMonth = moment().daysInMonth();
  const TheDay = moment().add(1, "days").format("YYYY-MM-DD");
  const GetDemandDataForGrphFunc = (startDate, endDate) => {
    const items = sessionStorage.getItem("Token");
    var parsed = JSON.parse(items);

    // const [isLoading9, setIsLoading9] = useState(true);
    // const [data9, setData9] = useState("");
    //data9.length = 0;
    setIsLoading9(true);
    try {
      axios
        .get(
          `${MyUrl}/api/DemandReport/getDemandData?${IdsToGetData}&startDate=${FirstDayInMonth}&endDate=${Today}&daily=true
                `,
          {
            headers: {
              Authorization: `Bearer ${parsed.replace("Bearer ", "")}`,
            },
            cancelToken: source.token,
          }
        )
        .then((res) => {
          const data9 = res.data;
          setData9(data9);
          console.log(data9);
          SendDataToGrph = [];
          ObjSendDataToGrph = {};
          options = [];
          options2 = [];
          // for (
          //   let i = 0;
          //   i <= DaysOfMonth - data9.responseData.data[0].dates.length;
          //   i++
          // ) {
          //   console.log(i);
          //   console.log(data9.responseData.data[0].dates.length + i);
          //   ObjSendDataToGrph = {
          //     name: "",
          //     date: moment().add(i, "days").format("DD/MM/YYYY"),
          //     value: "",
          //   };
          //   SendDataToGrph.push(ObjSendDataToGrph);
          //   ObjSendDataToGrph = {};
          // }
          for (let i = 0; i < data9.responseData.data.length; i++) {
            for (let j = 0; j < data9.responseData.data[i].dates.length; j++) {
              //10 the length

              ObjSendDataToGrph = {
                name: data9.responseData.data[i].advertiser.billingName,
                date: data9.responseData.data[i].dates[j].name,
                value: data9.responseData.data[i].dates[j].revenue,
              };
              //console.log(ObjSendDataToGrph);
              SendDataToGrph.push(ObjSendDataToGrph);
            }
          }
          console.log(SendDataToGrph);
          for (let i = 0; i < data9.responseData.data.length; i++) {
            //for (let j = 0; j < data9.responseData.data[i].dates.length; j++) {
            // ObjSendDataToGrph = {
            //   name: data9.responseData.data[i].advertiser.billingName,
            //   date: data9.responseData.data[i].dates[j].name,
            //   value: data9.responseData.data[i].dates[j].revenue,
            // };
            options.push({
              value: data9.responseData.data[i].advertiser.billingName,
              label: data9.responseData.data[i].advertiser.billingName,
            });
            //}
          }
          console.log(options);

          SendDataToGrph1 = [];
          ObjSendDataToGrph1 = {};
          options2 = [];
          for (let i = 0; i < 10; i++) {
            for (let j = 0; j < data9.responseData.data[i].dates.length; j++) {
              ObjSendDataToGrph = {
                name: data9.responseData.data[i].advertiser.billingName,
                date: data9.responseData.data[i].dates[j].name,
                value: data9.responseData.data[i].dates[j].revenue,
              };
              SendDataToGrph1.push(ObjSendDataToGrph);
              setChooseBNamesForGraph(SendDataToGrph1);
            }
            options2.push(data9.responseData.data[i].advertiser.billingName);
            //console.log(options2);

            // SendDataToGrph1 = SendDataToGrph.sort((a, b) => b.value - a.value);
            // SendDataToGrph2 = SendDataToGrph1.slice(0, 10)
          }
          console.log(SendDataToGrph);
          console.log(SendDataToGrph1);

          //console.log(SendDataToGrph2);
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading9(false);
        });
    } catch (error) {}
  };

  const handleChange2 = (value) => {
    console.log(`selected ${value}`);
  };
  // useEffect(() => {
  //   GetDemandDataForGrphFunc();
  // }, [SendDataToGrph, SendDataToGrph1]);
  // function MakeE() {
  //   setTimeout(() => {
  //     setIsLoading9(false);
  //   }, 0);
  // }

  // const handleChange1 = (value) => {
  //   setIsLoading9(true);
  //   MakeE();
  //   setTimeout(() => {
  //     setIsLoading9(false);
  //   }, 0);
  // };

  const GetDailyIncomeFunc1 = (startDate, endDate) => {
    const items = sessionStorage.getItem("Token");
    var parsed = JSON.parse(items);

    setIsLoading2(true);
    try {
      let base64 = require("base-64"); // install it before use from npm i base-64
      axios
        .get(
          `${MyUrl}/api/Dashboard/getDailyIncome?${IdsToGetData}&startDate=${startDate}&endDate=${endDate}`,
          {
            headers: {
              Authorization: `Bearer ${parsed.replace("Bearer ", "")}`,
            },
            cancelToken: source.token,
          }
        )
        .then((res) => {
          const data2 = res.data;
          setData2(data2);
          //         console.log(data2);
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading2(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setChooceCompanies([]);
    //PickDatesToGetData(0);
    // GetDemandDataForGrphFunc();
    // GetOpportunityRadarDataFunc();
    // GetCampOptimizationDataFunc();
    // GetFillsBestFunc();
    // GetTopTopOSDataFunc();
    // GetTopFillingRegionsDataFunc();
    // GetTopFillingAppsFunc();
    // GetDailyIncomeFunc();
    // GetMonthlyIncomeFunc();
    // return () => {
    //   source.cancel("Component unmounted");
    // };
  }, []);

  const GetMonthlyIncomeFunc = (startDate, endDate) => {
    const items = sessionStorage.getItem("Token");
    var parsed = JSON.parse(items);
    console.log("GetMonthlyIncomeFunc");
    setIsLoading1(true);
    try {
      axios
        .get(
          `${MyUrl}/api/Dashboard/getMonthlyIncome?${IdsToGetData}&startDate=${startDate}&endDate=${endDate}`,
          {
            headers: {
              Authorization: `Bearer ${parsed.replace("Bearer ", "")}`,
            },
            cancelToken: source.token,
          }
        )
        .then((res) => {
          //console.log("data1");
          const data1 = res.data;
          setData1(data1);
          //console.log(data1);

          // Assume responseData is the nested list of objects
          //const result = [];
          // for (let i = 0; i < companies[0].dates.length; i++) {
          //   let sum = 0;
          //   for (let j = 0; j < companies.length; j++) {
          //     sum += companies[j].dates[i].value;
          //   }
          //   result.push({ date: companies[0].dates[i].date, value: sum });
          // }
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading1(false);
        });
    } catch (error) {}
  };

  //For CheckBox
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  //End for CheckBox

  //Select Days
  const [days, setDays] = React.useState(0);
  const handleChangeDays = (event) => {
    setDays(event.target.value);
    // PickDatesToGetData(event.target.value);
  };
  //End select Days

  const [OpenRange, SetOpenRange] = useState(false);
  var startDate = Today;
  var endDate = Today;

  async function PickDatesToGetData(event) {
    setDays(event);
    if (event === 0) {
      SetOpenRange(false);
      startDate = Today;
      endDate = Today;
      await RefreshFunc(startDate, endDate);
      // GetDailyIncomeFunc1(Today, Today);
      // GetMonthlyIncomeFunc(Today, Today);
    } else if (event === 1) {
      SetOpenRange(false);
      startDate = Yesterday;
      endDate = Yesterday;
      await RefreshFunc(startDate, endDate);
    } else if (event === 2) {
      SetOpenRange(false);
      startDate = SevenDays;
      endDate = Yesterday;
      await RefreshFunc(startDate, endDate);
    } else if (event === 3) {
      SetOpenRange(false);
      startDate = FirstDayInMonth;
      endDate = Yesterday;
      await RefreshFunc(startDate, endDate);
    } else if (event === 4) {
      if (value.$D === undefined) {
        startDate = Today;
      } else {
        startDate = `${value.$y}-${value.$M + 1}-${value.$D}`;
      }
      if (value1.$D === undefined) {
        endDate = Today;
      } else {
        endDate = `${value1.$y}-${value1.$M + 1}-${value1.$D}`;
      }
      SetOpenRange(true);
      await RefreshFunc(startDate, endDate);
    }
  }
  // const handleClick = () => {
  //   SetOpenRange(!OpenRange);
  // };

  useEffect(() => {
    // This code will run only when myVariable changes
    //   console.log("myVariable changed to", OpenRange);
  }, [OpenRange]);
  //console.log("Number of days in current month:", daysInCurrentMonth);

  //For Date Pick

  var [value, setValue] = React.useState(startDate);
  var [value1, setValue1] = React.useState(endDate);
  const handleChange3 = (newValue) => {
    setValue(newValue);
    startDate = `${newValue.$y}-${newValue.$M + 1}-${newValue.$D}`;
    console.log(`startDate = ${newValue.$y}-${newValue.$M + 1}-${newValue.$D}`);
  };

  const handleChange4 = (newValue1) => {
    setValue1(newValue1);
    endDate = `${newValue1.$y}-${newValue1.$M + 1}-${newValue1.$D}`;
    console.log(
      `endDate = ${newValue1.$y}-${newValue1.$M + 1}-${newValue1.$D}`
    );
  };

  function useWindowSize() {
    const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
    }, []);
    return size;
  }
  const [height, width] = useWindowSize();
  return (
    <div style={{ paddingLeft: "2.5%", paddingRight: "1%", paddingTop: 30 }}>
      <h2>Select Products:</h2>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            overflowX: width > 766 ? "" : "scroll",
          }}
        >
          {" "}
          <Skeleton
            animation="wave"
            //sx={{ bgcolor: "grey.900" }}
            // variant="rectangular"
            width={750}
            height={80}
            borderRadius={100}
          />
        </div>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                // display: "flex",
                // flexDirection: "row",
                display: "flex",
                flexDirection: "row",
                overflowX: width > 766 ? "" : "scroll",
              }}
            >
              {Array.isArray(data) ? (
                data &&
                data.map((responseData) => (
                  <div>
                    <div
                      key={responseData.id}
                      style={{
                        display: "flex",
                        background: responseData.color,
                        borderRadius: 100,
                        justifyContent: "center",
                        alignItems: "center",
                        margin: 3,
                        paddingRight: 5,
                        height: 40,
                      }}
                    >
                      <CircleCheckbox
                        checked={chooceCompanies.includes(responseData.id)}
                        onChange={(event) =>
                          handleCheckboxChange(event, responseData.id)
                        }
                      />
                      <label>{responseData.name}</label>
                    </div>
                  </div>
                ))
              ) : (
                <>
                  <h5>There is no companies!</h5>
                </>
              )}
              <button
                style={{
                  border: "none",
                  background: "white",
                  cursor: "pointer",
                  minWidth: 60,
                }}
                onClick={() => {
                  PickDatesToGetData(PickedDate);
                  setIsClicked(true);
                  setTimeout(() => setIsClicked(false), 1000);
                }}
              >
                <Lottie
                  loop={isClicked ? true : false}
                  autoplay={isClicked ? true : false}
                  animationData={reloadpage}
                  rendererSettings={{
                    preserveAspectRatio: "xMidYMid slice",
                    display: "flex",
                    background: "white",
                    borderRadius: 100,
                    justifyContent: "center",
                    alignItems: "center",
                    margin: 3,
                    paddingRight: 5,
                    height: 40,
                    width: 60,
                    minWidth: 60,
                  }}
                  isPaused={isClicked}
                />
              </button>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingRight: "1%",
              }}
            >
              <Box sx={{ minWidth: 150 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">days</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={days}
                    label="days"
                    onChange={handleChangeDays}
                  >
                    <MenuItem
                      sx={{ width: 200 }}
                      value={0}
                      onClick={() => {
                        PickedDate = 0;
                        PickDatesToGetData(0);
                      }}
                    >
                      Today
                    </MenuItem>
                    <MenuItem
                      value={1}
                      onClick={() => {
                        PickedDate = 1;
                        PickDatesToGetData(1);
                      }}
                    >
                      Yesterday
                    </MenuItem>
                    <MenuItem
                      value={2}
                      onClick={() => {
                        PickedDate = 2;
                        PickDatesToGetData(2);
                      }}
                    >
                      Last 7 Days
                    </MenuItem>
                    <MenuItem
                      value={3}
                      onClick={() => {
                        PickedDate = 3;
                        PickDatesToGetData(3);
                      }}
                    >
                      This Months
                    </MenuItem>
                    <div style={{ height: 1 }}>
                      <Divider />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                          label="From Date"
                          inputFormat="DD-MM-YYYY"
                          value={value}
                          onChange={handleChange3}
                          renderInput={(params) => <TextField {...params} />}
                        />
                        &nbsp;&nbsp;
                        <DesktopDatePicker
                          label="To Date"
                          inputFormat="DD-MM-YYYY"
                          value={value1}
                          onChange={handleChange4}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </div>

                    <br></br>

                    <MenuItem
                      value={4}
                      onClick={() => {
                        SetOpenRange(true);
                        // PickDatesToGetData(4);
                      }}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          variant="contained"
                          style={{
                            backgroundColor: "black",
                            textTransform: "none",
                            fontSize: "100%",
                            borderRadius: "12px",
                          }}
                          onClick={() => {
                            if (value === "" || value1 === "") {
                              console.log("Wrong");
                            } else {
                              console.log("Call Data");
                              SetOpenRange(false);
                              PickedDate = 4;
                              PickDatesToGetData(4);
                            }
                          }}
                        >
                          {/* Filter */}
                          Custom Range
                        </Button>
                      </div>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
          </div>
        </>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      ></div>

      {isLoading9 ? (
        <>
          {" "}
          <Skeleton
            animation="wave"
            //sx={{ bgcolor: "grey.900" }}
            // variant="rectangular"
            //width={750}
            height={500}
            borderRadius={100}
          />
        </>
      ) : (
        <>
          <DemandGraph
            options2={options2}
            options={options}
            SendDataToGrph={SendDataToGrph}
            ChooseBNamesForGraph={ChooseBNamesForGraph}
            SendDataToGrph1={SendDataToGrph1}
          />
        </>
      )}
      <span style={{ color: "#1a1523", fontSize: 18 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          Top Filling &nbsp;&nbsp;
          {ChooceTable.map((responseData, i) => (
            <div
              style={{
                background: "#f4f2f4",
                borderTopLeftRadius: i === 0 ? 50 : 0,
                borderBottomLeftRadius: i === 0 ? 50 : 0,
                borderTopRightRadius: i === ChooceTable.length - 1 ? 50 : 0,
                borderBottomRightRadius: i === ChooceTable.length - 1 ? 50 : 0,
                height: 40,
                alignItems: "center",
                paddingLeft: i === 0 ? 3 : 0,
                paddingRight: i === ChooceTable.length - 1 ? 3 : 0,
                //justifyContent: "center",
                display: "flex",
              }}
            >
              <button
                value={responseData}
                style={{
                  borderWidth: 0,
                  borderRadius: 50,
                  background: CompColor === i ? "white" : "#f4f2f4",
                  height: 35,
                  fontWeight: CompColor === i ? "bold" : "",
                  fontSize: 14,
                  minWidth: 80,
                }}
                onClick={() => {
                  setCompColor(i);
                  compName = responseData;
                  // setComponentName(responseData.component.name);
                  console.log(responseData);
                }}
              >
                {responseData}
              </button>
              {i !== ChooceTable.length - 1 ? (
                <div
                  style={{
                    width: 1,
                    height: 16,
                    flexGrow: 0,
                    background: "#c8c7cb",
                  }}
                ></div>
              ) : (
                <></>
              )}
            </div>
          ))}
        </div>
      </span>

      <div style={{ paddingBottom: 30 }}>
        {compName === "Apps" ? (
          <>
            {" "}
            {isLoading3 ? (
              <>
                {" "}
                <Skeleton
                  animation="wave"
                  //sx={{ bgcolor: "grey.900" }}
                  // variant="rectangular"
                  //width={750}
                  height={500}
                  borderRadius={100}
                />
              </>
            ) : (
              <>
                <DemandTopFillingApps
                  SendDataToTopFillingApps={SendDataToTopFillingApps}
                  startDate={startDate}
                  endDate={endDate}
                />
              </>
            )}
          </>
        ) : compName === "Regions" ? (
          <>
            {" "}
            {isLoading4 ? (
              <>
                {" "}
                <Skeleton
                  animation="wave"
                  //sx={{ bgcolor: "grey.900" }}
                  // variant="rectangular"
                  //width={750}
                  height={500}
                  borderRadius={100}
                />
              </>
            ) : (
              <>
                <DemandTopFillingRegions
                  SendDataToFillingRegions={SendDataToFillingRegions}
                  startDate={startDate}
                  endDate={endDate}
                />
              </>
            )}
          </>
        ) : (
          <>
            {" "}
            {isLoading5 ? (
              <>
                {" "}
                <Skeleton
                  animation="wave"
                  //sx={{ bgcolor: "grey.900" }}
                  // variant="rectangular"
                  //width={750}
                  height={500}
                  borderRadius={100}
                />
              </>
            ) : (
              <>
                <DemandTopFillingOS
                  SendDataToTopOS={SendDataToTopOS}
                  startDate={startDate}
                  endDate={endDate}
                />
              </>
            )}
          </>
        )}
      </div>
      {isLoading6 ? (
        <>
          {" "}
          <Skeleton
            animation="wave"
            //sx={{ bgcolor: "grey.900" }}
            // variant="rectangular"
            //width={750}
            height={500}
            borderRadius={100}
          />
        </>
      ) : (
        <>
          <DemandWhatFillsBests
            SendDataToFillsBest={SendDataToFillsBest}
            startDate={startDate}
            endDate={endDate}
          />
        </>
      )}
      {/* {isLoading7 ? (
        <>Loading7...</>
      ) : (
        <>
          <DemandCampaignOptimizationOverview
            SendDataToCampOptimization={SendDataToCampOptimization}
            startDate={startDate}
            endDate={endDate}
          />
        </>
      )} */}
      {/* {isLoading8 ? (
        <>Loading8...</>
      ) : (
        <>
          <DemandOpportunityRadar
            SendDataToOpportunityRadar={SendDataToOpportunityRadar}
            startDate={startDate}
            endDate={endDate}
          />
        </>
      )} */}
    </div>
  );
}

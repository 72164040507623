import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "../../MyCss/Income.css";
import IncomeChannelOverViewTable from "./IncomeChannelOverViewTable";
import moment from "moment";
var myNum3 = "ss";
export default function GeneralReportChannelDateScope() {
  //For yesterday Date
  const [Color, setColor] = React.useState(1);
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }
  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }
  //EndFor yesterday Date
  const [OpenRange, SetOpenRange] = useState(false);
  //For Date Pick
  const [value, setValue] = React.useState(dayjs(new Date()));
  const [value1, setValue1] = React.useState(dayjs(new Date()));
  const [value2, setValue2] = React.useState(true);
  const [CustomValue, setCustomValue] = React.useState("");

  const handleChange = (newValue) => {
    setValue(newValue);
    setdateFrom(`&startDate=${newValue.$y}-${newValue.$M + 1}-${newValue.$D}`);
    console.log(`${newValue.$y}-${newValue.$M + 1}-${newValue.$D}`);
  };

  const handleChange1 = (newValue1) => {
    setValue1(newValue1);
    setdateTo(`&endDate=${newValue1.$y}-${newValue1.$M + 1}-${newValue1.$D}`);
    console.log(`${newValue1.$y}-${newValue1.$M + 1}-${newValue1.$D}`);
  };
  const handleChange2 = (newValue2) => {
    setValue1(newValue2);
  };
  //End For Date Pick
  const current = new Date();
  const dateNow = `${current.getFullYear()}-${
    current.getMonth() + 1
  }-${current.getDate()}`;

  const Week1 = moment();
  const Month1 = moment();
  const Week = Week1.subtract(7, "d").format("YYYY-MM-DD");
  const Month = `${current.getFullYear()}-${current.getMonth() + 1}-01`;
  const [dateFrom, setdateFrom] = React.useState(`&startDate=${dateNow}`);
  const [dateTo, setdateTo] = React.useState(`&endDate=${dateNow}`);

  useEffect(() => {}, [OpenRange, dateFrom, dateTo]);
  console.log(dateFrom);
  console.log(dateTo);

  return (
    <div>
      <div className="SetRow">
        <div className="inFirst">
          <h6 style={{ color: "#4c1d95" }}>Generic report date scope:</h6>
        </div>
        <div className="inLast">
          <Button
            style={{
              margin: 5,
              backgroundColor: Color === 1 ? "#5b21b6" : "#8b5cf6",
            }}
            variant="contained"
            onClick={() => {
              setColor(1);
              setdateFrom(`&startDate=${dateNow}`);
              setdateTo(`&endDate=${dateNow}`);
              SetOpenRange(false);
            }}
          >
            Today
          </Button>
          <Button
            style={{
              margin: 5,
              backgroundColor: Color === 2 ? "#5b21b6" : "#8b5cf6",
            }}
            variant="contained"
            onClick={() => {
              setColor(2);
              setdateFrom(`&startDate=${formatDate(yesterday)}`);
              setdateTo(`&endDate=${formatDate(yesterday)}`);
              SetOpenRange(false);
            }}
          >
            Yesterday
          </Button>
          <Button
            style={{
              margin: 5,
              backgroundColor: Color === 3 ? "#5b21b6" : "#8b5cf6",
            }}
            variant="contained"
            onClick={() => {
              setColor(3);
              setdateFrom(`&startDate=${Week}`);
              setdateTo(`&endDate=${formatDate(yesterday)}`);
              SetOpenRange(false);
            }}
          >
            Last 7 Days
          </Button>
          <Button
            style={{
              margin: 5,
              backgroundColor: Color === 4 ? "#5b21b6" : "#8b5cf6",
            }}
            variant="contained"
            onClick={() => {
              setColor(4);
              SetOpenRange(false);
              setdateFrom(`&startDate=${Month}`);
              setdateTo(`&endDate=${dateNow}`);
            }}
          >
            This Month
          </Button>
          <Button
            style={{
              margin: 5,
              backgroundColor: Color === 5 ? "#5b21b6" : "#8b5cf6",
            }}
            onClick={() => {
              setColor(5);
              setdateFrom(`&startDate=${value.$y}-${value.$M + 1}-${value.$D}`);
              setdateTo(`&endDate=${value1.$y}-${value1.$M + 1}-${value1.$D}`);
              SetOpenRange(!OpenRange);
            }}
            variant="contained"
            endIcon={<ArrowDropDownIcon />}
          >
            Custom Range
          </Button>
        </div>
      </div>
      {OpenRange ? (
        <div>
          <div className="centerIt">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="From Date"
                inputFormat="YYYY-MM-DD"
                value={value}
                onChange={handleChange}
                renderInput={(params) => <TextField {...params} />}
              />
              &nbsp;&nbsp;
              <h6>-</h6>
              &nbsp;&nbsp;
              <DesktopDatePicker
                label="To Date"
                inputFormat="YYYY-MM-DD"
                value={value1}
                onChange={handleChange1}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>{" "}
            <h6></h6>
          </div>
          <div className="centerIt">
            <br></br>
          </div>
        </div>
      ) : (
        <></>
      )}
      <IncomeChannelOverViewTable MyDateFrom={dateFrom} MyDateTo={dateTo} />
    </div>
  );
}

import React from "react";
import { Outlet, Link, withRouter, useHistory } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import DemandBody from "../components/DemandBody";
function Demand() {
  return (
    <div>
      <Header />
      <DemandBody />
      <Footer />
    </div>
  );
}
export default withRouter(Demand);

import { styled, useTheme } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { MyUrl } from "./../App";

import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import NewHeader from "./NewHeader";
import Dashboard from "./Pages/Dashboard";
import Income from "./Pages/Income";
import Global from "./Pages/Global";

import { useState } from "react";
import DashboardColoredImg from "../Images/DashboardColoredImg.png";
import IncomeColoredImg from "../Images/IncomeColoredImg.png";
import GlobalColoredImg from "../Images/GlobalColoredImg.png";
import SupplyColoredImg from "../Images/SupplyColoredImg.png";
import DemandColoredImg from "../Images/DemandColoredImg.png";
import CampaignManagementColor from "../Images/CampaignManagementColor.png";
import OpportunityRadarColor from "../Images/OpportunityRadarColor.png";
import DashboardImg from "../Images/DashboardImg.png";
import IncomeImg from "../Images/IncomeImg.png";
import GlobalImg from "../Images/GlobalImg.png";
import SupplyImg from "../Images/SupplyImg.png";
import DemandImg from "../Images/DemandImg.png";
import O2RLogo from "../Images/O2RLogo.png";
import CampaignManagementImage from "../Images/CampaignManagementImage.png";
import OpportunityRadarImage from "../Images/OpportunityRadarImage.png";

import Mediasource1 from "../Images/Mediasource1.png";
import MediasourceColor1 from "../Images/MediasourceColor1.png";

import Supply from "./Pages/Supply";
import Demand from "./Pages/Demand";
import CampaignManagement from "./Pages/CampaignManagement";
import OpportunityRadar from "./Pages/OpportunityRadar";
import MediaSourceGetDataAndFilter from "./Pages/MediaSourceComponents/MediaSourceGetDataAndFilter";
import { width } from "@mui/system";

var pageWidth = window.innerWidth;

const drawerWidth = 280;
const useStyles = makeStyles({
  drawerPaper: {
    backgroundColor: "#f9f8f9", // set your desired color here
  },
});

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});
const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(pageWidth < 766 ? 0 : 7)} + 0px)`,
  // [theme.breakpoints.up("sm")]: {
  //   width: `calc(${theme.spacing(pageWidth < 766 ? 0 : 8)} + 0px)`,
  // },
});
console.log("pageWidth" + pageWidth);
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
var jwtToken = "";
var HidePages = "";

export default function MainPageLeftDrawer({ WidthFromMainPage }) {
  const [CheckTypeOfUser, setCheckTypeOfUser] = useState("");
  //const [HidePages, setHidePages] = useState("");

  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();

  const RefreshFunc = () => {
    const token = sessionStorage.getItem("Token");
    var parsed = JSON.parse(token);

    console.log(parsed);
    const decodedToken = jwt_decode(token);
    const role =
      decodedToken[
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
      ];
    console.log("MainPageLeftDrawer");
    console.log(role);
    console.log("Hide");

    HidePages = role;
    console.log(HidePages);

    setCheckTypeOfUser(role);
    jwtToken = JSON.parse(token); //parsed
    const base64Url = jwtToken.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const payload = JSON.parse(atob(base64));
    const expirationDate = new Date(payload.exp * 1000);
    const currentTime = new Date().getTime(); // Get the current time in milliseconds
    //  const now =new Date(Date.UTC())
    if (expirationDate < currentTime) {
      try {
        axios
          .post(
            `${MyUrl}/api/RefreshToken/refresh`,
            { accessToken: JSON.parse(token).replace("Bearer ", "") },
            { withCredentials: true }
          )
          .then((response) => {
            parsed = response.data.accessToken;
            const updatedObjString = JSON.stringify(response.data.accessToken);
            sessionStorage.setItem("Token", updatedObjString);
            // GetDailyIncomeFunc(response.data.accessToken);
            // GetMonthlyIncomeFunc(response.data.accessToken);
            return () => {
              source.cancel("Component unmounted");
            };
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("Token is still valid");
      const items = sessionStorage.getItem("Token");
      var parsed = JSON.parse(items);
      jwtToken = JSON.parse(items); //parsed
      // GetDailyIncomeFunc(jwtToken);
      // GetMonthlyIncomeFunc(jwtToken);
      return () => {
        source.cancel("Component unmounted");
      };
    }
  };

  const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
  function useWindowSize() {
    const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
    }, []);
    return size;
  }
  const [value, setValue] = useState(false);
  console.log(WidthFromMainPage);
  pageWidth = WidthFromMainPage;
  const handleChildUpdate = (childValue) => {
    setValue(childValue);
    setOpen(childValue);
  };

  const [height, width] = useWindowSize();
  const classes = useStyles();
  const [openPage, setOpenPage] = useState(0);
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [myText, setMyText] = React.useState("");
  const [goToPage, setGoToPage] = React.useState();

  useEffect(() => {
    RefreshFunc();
    if (HidePages !== "accountManager") {
      setMyText("Dashboard");
      setGoToPage(0);
    } else {
      setMyText("Global Reports");
      setGoToPage(2);
    }
  }, []);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  var LeftImages1 = [
    DashboardColoredImg,
    IncomeColoredImg,
    GlobalColoredImg,
    SupplyColoredImg,
    DemandColoredImg,
    CampaignManagementColor,
    OpportunityRadarColor,
    MediasourceColor1,
  ];
  var LeftImages2 = [
    DashboardImg,
    IncomeImg,
    GlobalImg,
    SupplyImg,
    DemandImg,
    CampaignManagementImage,
    OpportunityRadarImage,
    Mediasource1,
  ];

  return (
    <div
      style={{
        //flex: 1,
        //  display: "flex", // For desktop should flex it  put for mobile when unable it (no flex)
        // paddingRight: "1%",
        display: "flex", // width > 766 ? "flex" : "",
        flexGrow: 1,
      }}
    >
      {/* <CssBaseline />
      <AppBar position="fixed" open={open}></AppBar> */}
      {/* {width < 766 ? <h1>yes</h1> : <h1>no</h1>} */}
      <Drawer
        variant="permanent"
        open={open}
        classes={{ paper: classes.drawerPaper }}
      >
        <div
          style={{
            display: open === true ? "flex" : "",
            flexDirection: "row",
            alignItems: open === true ? "center" : "",
            justifyContent: open === true ? "space-between" : "",
          }}
        >
          {open === true ? (
            <div style={{ paddingLeft: "10%" }} onClick={() => {}}>
              <img src={O2RLogo} width={40}></img>{" "}
            </div>
          ) : (
            <></>
          )}

          <DrawerHeader>
            <IconButton
              style={{}}
              onClick={() => {
                setOpen(!open);
              }}
            >
              {open === true ? (
                <ChevronLeftIcon />
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                      position: "relative",
                      left: "25%",
                      alignItems: "center",
                    }}
                  >
                    <img src={O2RLogo} width={40}></img>
                    <ChevronRightIcon />
                  </div>
                </>
              )}
            </IconButton>
          </DrawerHeader>
        </div>
        {/* <Divider /> */}
        <List>
          {[
            "Dashboard",
            "Income Reports",
            "Global Reports",
            "Supply Reports",
            "Demand Reports",
            "Campaign Management",
            "Opportunity Radar",
            "Media Source",
          ].map((text, index) => (
            <ListItem key={text} disablePadding sx={{ display: "block" }}>
              <div
                onClick={() => {
                  setGoToPage(index);
                  setMyText(text);
                  // console.log(myText);
                  // console.log(text);
                  // console.log(index);
                }}
                style={{
                  color: myText === text ? "#5746af" : "",
                  background: text === myText ? "#e4defc" : "",
                  borderRadius: 10,
                  margin: 5,
                }}
              >
                {(HidePages === "accountManager" && text === "Media Source") ||
                (HidePages === "accountManager" && text === "Dashboard") ||
                (HidePages === "accountManager" &&
                  text === "Income Reports") ? (
                  <></>
                ) : (
                  <ListItemButton sx={{ pl: open ? 3 : 2 }}>
                    <ListItemIcon>
                      <img
                        src={
                          text === myText
                            ? LeftImages1[index]
                            : LeftImages2[index]
                        }
                        width={20}
                      ></img>{" "}
                    </ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItemButton>
                )}
                {/* <ListItemButton sx={{ pl: open ? 3 : 2 }}>
                  <ListItemIcon>
                    <img
                      src={
                        text === myText
                          ? LeftImages1[index]
                          : LeftImages2[index]
                      }
                      width={20}
                    ></img>{" "}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton> */}
              </div>
            </ListItem>
          ))}
        </List>
      </Drawer>

      <div style={{ width: "100%" }}>
        <NewHeader PageName={myText} onUpdate={handleChildUpdate} />

        {HidePages === "masterAccountManager" ? (
          <></>
        ) : (
          <>
            {goToPage === 0 ? (
              <>
                <Dashboard />
              </>
            ) : (
              <></>
            )}
          </>
        )}
        {HidePages === "masterAccountManager" ? (
          <></>
        ) : (
          <>
            {goToPage === 1 ? (
              <>
                <Income />
              </>
            ) : (
              <></>
            )}
          </>
        )}

        {goToPage === 2 ? (
          <>
            <Global />
          </>
        ) : (
          <></>
        )}
        {goToPage === 3 ? (
          <>
            <Supply />
          </>
        ) : (
          <></>
        )}
        {goToPage === 4 ? (
          <>
            <Demand />
          </>
        ) : (
          <></>
        )}
        {goToPage === 5 ? (
          <>
            <CampaignManagement />
          </>
        ) : (
          <></>
        )}
        {goToPage === 6 ? (
          <>
            <OpportunityRadar />
          </>
        ) : (
          <></>
        )}
        {HidePages === "masterAccountManager" ? (
          <></>
        ) : (
          <>
            {goToPage === 7 ? (
              <>
                <MediaSourceGetDataAndFilter />
              </>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
    </div>
  );
}

{
  /* <ListItemButton
sx={{
	minHeight: 48,
	justifyContent: open ? "initial" : "center",
	px: 2.5,
}}
>
<ListItemIcon
	sx={{
		minWidth: 0,
		mr: open ? 3 : "auto",
		justifyContent: "center",
	}}
>
	{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
</ListItemIcon>
<ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
</ListItemButton> */
}

import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
// @mui
import { Card, CardHeader, Box, Grid } from "@mui/material";
// components
import { useChart } from "./chart";
import { useEffect, useState, useCallback } from "react";

import ClipLoader from "react-spinners/ClipLoader";
import AppWidgetSummary from "./AppWidgetSummary";
import { Avatar, List, Skeleton, Switch } from "antd";

// ----------------------------------------------------------------------
var sum1 = 0;

SetIncomByDayOverView.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
  chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const chartLabels1 = [
  "01/01/2004",
  "02/01/2004",
  "03/01/2004",
  "04/01/2004",
  "05/01/2004",
  "06/01/2004",
  "07/01/2004",
  "08/01/2004",
  "09/01/2004",
  "10/01/2004",
  "11/01/2004",
  "12/01/2004",
  //  '01/01/2004',
  //  '01/01/2004',
];

export default function SetIncomByDayOverView({
  title,
  subheader,
  chartLabels,
  chartData,
  ...other
}) {
  const [data, setData] = useState(null);
  const [array, setArray] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [O2rrevenue, setO2rRevenue] = useState([]);
  const [SumDaily, setSumDaily] = useState([]);
  const [MySum, setMySum] = useState([]);
  const [revenue, setrevenue] = useState([]);

  const fff = [
    {
      name: "Team D",
      type: "line",
      fill: "solid",
      data: [40, 35, 46, 40, 55, 45, 74, 62, 69, 46, 49, 45],
    },
  ];
  const chartLabels2 = [
    "00:00",
    "01:00",
    "02:00",
    "03:00",
    "04:00",
    "05:00",
    "06:00",
    "07:00",
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
    "23:00",
    //       '01/01/2004',
    //        '01/01/2004',
  ];
  const chartOptions = useChart({
    plotOptions: { bar: { columnWidth: "16%" } },
    fill: { type: chartData.map((i) => i.fill) },
    labels: chartLabels2,
    xaxis: { type: "time" },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== "undefined") {
            return `${y
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} $`;
          }
          return y;
        },
      },
    },
  });

  return (
    <>
      {!isLoading ? (
        <>
          {" "}
          <Skeleton active avatar>
            <List.Item.Meta
              avatar={<Avatar src={""} />}
              title={<a href={""}>{""}</a>}
              description={""}
            />
            {""}
          </Skeleton>
        </>
      ) : (
        <>
          {" "}
          <Card {...other}>
            <CardHeader title={title} subheader={subheader} />
            <Box sx={{ p: 3, pb: 1 }} dir="ltr">
              <h5>NetIncome by Dollar $</h5>
              <ReactApexChart
                type="line"
                series={chartData}
                options={chartOptions}
                height={650}
              />
            </Box>
            <h5
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                color: "black",
              }}
            >
              Hours
            </h5>
          </Card>
        </>
      )}
    </>
  );
}

import React, { useState, useEffect, useCallback } from "react";
import { withRouter, useHistory, useLocation } from "react-router-dom";
import Pattern from "../../../Images/Pattern.png";
import Up from "../../../Images/Up.png";
import Pattern2 from "../../../Images/Pattern2.png";
import Down from "../../../Images/Down.png";
import DailyNet2 from "../../../Images/DailyNet2.png";

export default function SupplyReportsTop3({ SendDataToTop3 }) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  function useWindowSize() {
    const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
    }, []);
    return size;
  }
  const [height, width] = useWindowSize();

  return (
    <div
      style={{
        paddingTop: 30,
      }}
    >
      {" "}
      <div
        style={{
          flex: 1,
          // flexDirection: "row",
          //display: "flex",
          //alignItems: "center",
          justifyContent: width < 766 ? "" : "space-evenly",
          //flexWrap: "wrap",
          display: "flex",
          flexDirection: "row",
          overflowX: width > 766 ? "" : "scroll",
        }}
      >
        <div
          style={{
            marginRight: width > 766 ? "" : 10,
            //            paddingRight: 100,
            flex:
              width < 759
                ? "0 1 100%"
                : width >= 760 && width < 1024
                ? "0 1 45%"
                : "0 1 30%",
            borderRadius: 20,
            //height: "40%",
            //marginBottom: 10,
            //padding: 10,
            paddingLeft: 10,
            paddingBottom: 15,
            borderWidth: 10,
            border: "1px solid #e4defc",
          }}
        >
          <div
            style={{
              width: width > 766 ? "" : 300,
              flex: 1,
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <span style={{ fontSize: 20, color: "#20134b", paddingTop: 20 }}>
              Ad Requests
            </span>
            <img
              src={Pattern}
              style={{ width: width / 3, maxWidth: width > 770 ? 150 : 100 }}
            ></img>
          </div>

          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
              paddingTop: 10,
            }}
          >
            <div
              style={{
                width: "30%",
              }}
            >
              <span style={{ fontSize: 14 }}>MTD</span>
            </div>
            <div
              style={{
                width: "70%",
              }}
            >
              <span style={{ fontSize: 16 }}>
                Bil. {SendDataToTop3[0].addRequests.monthlyData.toFixed(3)}
              </span>
            </div>
          </div>

          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: "30%",
              }}
            >
              <span style={{ fontSize: 14 }}>Yesterday</span>
            </div>
            <div
              style={{
                width: "70%",
              }}
            >
              <span style={{ fontSize: 16 }}>
                Bil. {SendDataToTop3[0].addRequests.yesterdayData.toFixed(3)}
              </span>
            </div>
          </div>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
              //              width: "50%",
            }}
          >
            <div
              style={{
                width: "30%",
              }}
            >
              <span style={{ fontSize: 14 }}>Today</span>
            </div>
            <div
              style={{
                display: "flex",
                width: "70%",
                alignItems: "center",
              }}
            >
              <span style={{ fontSize: 16 }}>
                Bil. {SendDataToTop3[0].addRequests.todaysData.toFixed(3)}
              </span>
              &nbsp;&nbsp;
              {SendDataToTop3[0].addRequests.ratio > 0 ? (
                <img
                  style={{
                    width: 15,
                    height: 15,
                  }}
                  src={Up}
                ></img>
              ) : (
                <img
                  style={{
                    width: 15,
                    height: 15,
                  }}
                  src={Down}
                ></img>
              )}
              &nbsp;&nbsp;
              <span style={{ fontSize: 14 }}>
                {SendDataToTop3[0].addRequests.ratio.toFixed(3)}%
              </span>
            </div>
          </div>
        </div>
        <div
          style={{
            marginRight: width > 766 ? "" : 10,

            flex:
              width < 759
                ? "0 1 100%"
                : width >= 760 && width < 1024
                ? "0 1 45%"
                : "0 1 30%",
            borderRadius: 20,
            paddingLeft: 10,
            paddingBottom: 15,
            borderWidth: 10,
            border: "1px solid #e4defc",
          }}
        >
          <div
            style={{
              width: width > 766 ? "" : 300,

              flex: 1,
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <span style={{ fontSize: 20, color: "#20134b", paddingTop: 20 }}>
              Impression
            </span>
            <img
              src={Pattern2}
              style={{ width: width / 3, maxWidth: width > 770 ? 150 : 100 }}
            ></img>
          </div>

          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
              paddingTop: 10,
            }}
          >
            <div
              style={{
                width: "30%",
              }}
            >
              <span style={{ fontSize: 14 }}>MTD</span>
            </div>
            <div
              style={{
                width: "70%",
              }}
            >
              <span style={{ fontSize: 16 }}>
                Mil. {SendDataToTop3[0].impression.monthlyData.toFixed(3)}
              </span>
            </div>
          </div>

          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: "30%",
              }}
            >
              <span style={{ fontSize: 14 }}>Yesterday</span>
            </div>
            <div
              style={{
                width: "70%",
              }}
            >
              <span style={{ fontSize: 16 }}>
                Mil. {SendDataToTop3[0].impression.yesterdayData.toFixed(3)}
              </span>
            </div>
          </div>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
              //              width: "50%",
            }}
          >
            <div
              style={{
                width: "30%",
              }}
            >
              <span style={{ fontSize: 14 }}>Today</span>
            </div>
            <div
              style={{
                display: "flex",
                width: "70%",
                alignItems: "center",
              }}
            >
              <span style={{ fontSize: 16 }}>
                Mil. {SendDataToTop3[0].impression.todaysData.toFixed(3)}
              </span>
              &nbsp;&nbsp;
              {SendDataToTop3[0].impression.ratio > 0 ? (
                <img
                  style={{
                    width: 15,
                    height: 15,
                  }}
                  src={Up}
                ></img>
              ) : (
                <img
                  style={{
                    width: 15,
                    height: 15,
                  }}
                  src={Down}
                ></img>
              )}
              &nbsp;&nbsp;
              <span style={{ fontSize: 14 }}>
                {SendDataToTop3[0].impression.ratio.toFixed(3)}%
              </span>
            </div>
          </div>
        </div>
        <div
          style={{
            flex:
              width < 759
                ? "0 1 100%"
                : width >= 760 && width < 1024
                ? "0 1 45%"
                : "0 1 30%",
            borderRadius: 20,
            paddingLeft: 10,
            paddingBottom: 15,
            borderWidth: 10,
            border: "1px solid #e4defc",
          }}
        >
          <div
            style={{
              width: width > 766 ? "" : 300,

              flex: 1,
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <span style={{ fontSize: 20, color: "#20134b", paddingTop: 20 }}>
              Fill Rate
            </span>
            <img
              src={DailyNet2}
              style={{ width: width / 3, maxWidth: width > 770 ? 150 : 100 }}
            ></img>
          </div>

          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
              paddingTop: 10,
            }}
          >
            <div
              style={{
                width: "30%",
              }}
            >
              <span style={{ fontSize: 14 }}>MTD</span>
            </div>
            <div
              style={{
                width: "70%",
              }}
            >
              <span style={{ fontSize: 16 }}>
                {SendDataToTop3[0].fillRate.monthlyData.toFixed(3)} %
              </span>
            </div>
          </div>

          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: "30%",
              }}
            >
              <span style={{ fontSize: 14 }}>Yesterday</span>
            </div>
            <div
              style={{
                width: "70%",
              }}
            >
              <span style={{ fontSize: 16 }}>
                {SendDataToTop3[0].fillRate.yesterdayData.toFixed(3)} %
              </span>
            </div>
          </div>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
              //              width: "50%",
            }}
          >
            <div
              style={{
                width: "30%",
              }}
            >
              <span style={{ fontSize: 14 }}>Today</span>
            </div>
            <div
              style={{
                display: "flex",
                width: "70%",
                alignItems: "center",
              }}
            >
              <span style={{ fontSize: 16 }}>
                {SendDataToTop3[0].fillRate.todaysData.toFixed(3)} %
              </span>
              &nbsp;&nbsp;
              {SendDataToTop3[0].fillRate.ratio > 0 ? (
                <img
                  style={{
                    width: 15,
                    height: 15,
                  }}
                  src={Up}
                ></img>
              ) : (
                <img
                  style={{
                    width: 15,
                    height: 15,
                  }}
                  src={Down}
                ></img>
              )}
              &nbsp;&nbsp;
              <span style={{ fontSize: 14 }}>
                {SendDataToTop3[0].fillRate.ratio.toFixed(3)}%
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

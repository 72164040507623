import React, {
  useState,
  useEffect,
  useCallback,
  createContext,
  useContext,
} from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { render } from "@testing-library/react";
import CustomReportingSelects from "./CustomReportingSelects";
import CustomReportingDateRange from "./CustomReportingDateRange";
import CustomReportingMetricFilter from "./CustomReportingMetricFilter";

export var test = "aa";

export default function CustomReportingButtons(test) {
  const [Color, setColor] = React.useState(false);
  const [Color1, setColor1] = React.useState(false);
  const [Color2, setColor2] = React.useState(false);
  const [Color3, setColor3] = React.useState(false);
  const [Color4, setColor4] = React.useState(false);
  const [Color5, setColor5] = React.useState(false);
  const [Color6, setColor6] = React.useState(false);
  const [Color7, setColor7] = React.useState(false);
  const [Color8, setColor8] = React.useState(false);
  const [Color9, setColor9] = React.useState(false);
  const [Color10, setColor10] = React.useState(false);
  const [Color11, setColor11] = React.useState(false);
  const [Color12, setColor12] = React.useState(false);
  const [Color13, setColor13] = React.useState(false);
  const [Color14, setColor14] = React.useState(false);
  const [Color15, setColor15] = React.useState(false);
  const [Color16, setColor16] = React.useState(false);
  const [Color17, setColor17] = React.useState(false);
  const [Color18, setColor18] = React.useState(false);
  const [Color19, setColor19] = React.useState(false);
  const [Color20, setColor20] = React.useState(false);
  const [Color21, setColor21] = React.useState(false);
  const [Color22, setColor22] = React.useState(false);
  const [Color23, setColor23] = React.useState(false);
  const [Color24, setColor24] = React.useState(false);
  const [Color25, setColor25] = React.useState(false);

  return (
    <div>
      <div
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <Stack spacing={2} direction="row">
          <div
            style={{
              flex:
                500 > 769 ? (500 > 1023 ? "0 1 33%" : "0 1 50%") : "0 1 100%",
              width: "100%",
            }}
          >
            {" "}
            <Button
              style={{
                margin: 5,
                backgroundColor: Color === true ? "#5b21b6" : "#8b5cf6",
              }}
              onClick={() => {
                setColor(!Color);
                test = "bb";
                console.log("1");
                console.log(test);
              }}
              variant="contained"
            >
              Date
            </Button>
            <Button
              style={{
                margin: 5,
                backgroundColor: Color1 === true ? "#5b21b6" : "#8b5cf6",
              }}
              onClick={() => {
                setColor1(!Color1);
                test = "ss";
                console.log("1");
                console.log(test);
              }}
              variant="contained"
            >
              Hours
            </Button>
            <Button
              style={{
                margin: 5,
                backgroundColor: Color2 === true ? "#5b21b6" : "#8b5cf6",
              }}
              onClick={() => {
                setColor2(!Color2);
              }}
              variant="contained"
            >
              Channel
            </Button>
            <Button
              style={{
                margin: 5,
                backgroundColor: Color3 === true ? "#5b21b6" : "#8b5cf6",
              }}
              onClick={() => {
                setColor3(!Color3);
              }}
              variant="contained"
            >
              Source
            </Button>
            <Button
              style={{
                margin: 5,
                backgroundColor: Color4 === true ? "#5b21b6" : "#8b5cf6",
              }}
              onClick={() => {
                setColor4(!Color4);
              }}
              variant="contained"
            >
              Sid
            </Button>
            <Button
              style={{
                margin: 5,
                backgroundColor: Color5 === true ? "#5b21b6" : "#8b5cf6",
              }}
              onClick={() => {
                setColor5(!Color5);
              }}
              variant="contained"
            >
              Advertiser
            </Button>
            <Button
              style={{
                margin: 5,
                backgroundColor: Color6 === true ? "#5b21b6" : "#8b5cf6",
              }}
              onClick={() => {
                setColor6(!Color6);
              }}
              variant="contained"
            >
              Campaign
            </Button>
            <Button
              style={{
                margin: 5,
                backgroundColor: Color7 === true ? "#5b21b6" : "#8b5cf6",
              }}
              onClick={() => {
                setColor7(!Color7);
              }}
              variant="contained"
            >
              Campaign Integration Type
            </Button>
            <Button
              style={{
                margin: 5,
                backgroundColor: Color8 === true ? "#5b21b6" : "#8b5cf6",
              }}
              onClick={() => {
                setColor8(!Color8);
              }}
              variant="contained"
            >
              Campaign Type
            </Button>
            <Button
              style={{
                margin: 5,
                backgroundColor: Color9 === true ? "#5b21b6" : "#8b5cf6",
              }}
              onClick={() => {
                setColor9(!Color9);
              }}
              variant="contained"
            >
              Ad Server
            </Button>
            <Button
              style={{
                margin: 5,
                backgroundColor: Color10 === true ? "#5b21b6" : "#8b5cf6",
              }}
              onClick={() => {
                setColor10(!Color10);
              }}
              variant="contained"
            >
              Domain
            </Button>
            <Button
              style={{
                margin: 5,
                backgroundColor: Color11 === true ? "#5b21b6" : "#8b5cf6",
              }}
              onClick={() => {
                setColor11(!Color11);
              }}
              variant="contained"
            >
              Top Domain
            </Button>
            <Button
              style={{
                margin: 5,
                backgroundColor: Color12 === true ? "#5b21b6" : "#8b5cf6",
              }}
              onClick={() => {
                setColor12(!Color12);
              }}
              variant="contained"
            >
              Player Size
            </Button>
            <Button
              style={{
                margin: 5,
                backgroundColor: Color13 === true ? "#5b21b6" : "#8b5cf6",
              }}
              onClick={() => {
                setColor13(!Color13);
              }}
              variant="contained"
            >
              Country
            </Button>
            <Button
              style={{
                margin: 5,
                backgroundColor: Color14 === true ? "#5b21b6" : "#8b5cf6",
              }}
              onClick={() => {
                setColor14(!Color14);
              }}
              variant="contained"
            >
              Region
            </Button>
            <Button
              style={{
                margin: 5,
                backgroundColor: Color15 === true ? "#5b21b6" : "#8b5cf6",
              }}
              onClick={() => {
                setColor15(!Color15);
              }}
              variant="contained"
            >
              City
            </Button>
            <Button
              style={{
                margin: 5,
                backgroundColor: Color16 === true ? "#5b21b6" : "#8b5cf6",
              }}
              onClick={() => {
                setColor16(!Color16);
              }}
              variant="contained"
            >
              Operating System
            </Button>
            <Button
              style={{
                margin: 5,
                backgroundColor: Color17 === true ? "#5b21b6" : "#8b5cf6",
              }}
              onClick={() => {
                setColor17(!Color17);
              }}
              variant="contained"
            >
              User Agent
            </Button>
            <Button
              style={{
                margin: 5,
                backgroundColor: Color18 === true ? "#5b21b6" : "#8b5cf6",
              }}
              onClick={() => {
                setColor18(!Color18);
              }}
              variant="contained"
            >
              User Agent Version
            </Button>
            <Button
              style={{
                margin: 5,
                backgroundColor: Color19 === true ? "#5b21b6" : "#8b5cf6",
              }}
              onClick={() => {
                setColor19(!Color19);
              }}
              variant="contained"
            >
              Environment
            </Button>
            <Button
              style={{
                margin: 5,
                backgroundColor: Color20 === true ? "#5b21b6" : "#8b5cf6",
              }}
              onClick={() => {
                setColor20(!Color20);
              }}
              variant="contained"
            >
              App Name
            </Button>
            <Button
              style={{
                margin: 5,
                backgroundColor: Color21 === true ? "#5b21b6" : "#8b5cf6",
              }}
              onClick={() => {
                setColor21(!Color21);
              }}
              variant="contained"
            >
              Bundle ID
            </Button>
            <Button
              style={{
                margin: 5,
                backgroundColor: Color22 === true ? "#5b21b6" : "#8b5cf6",
              }}
              onClick={() => {
                setColor22(!Color22);
              }}
              variant="contained"
            >
              Ad Type
            </Button>
            <Button
              style={{
                margin: 5,
                backgroundColor: Color23 === true ? "#5b21b6" : "#8b5cf6",
              }}
              onClick={() => {
                setColor23(!Color23);
              }}
              variant="contained"
            >
              Month
            </Button>
            <Button
              style={{
                margin: 5,
                backgroundColor: Color24 === true ? "#5b21b6" : "#8b5cf6",
              }}
              onClick={() => {
                setColor24(!Color24);
              }}
              variant="contained"
            >
              Device Type
            </Button>
            <Button
              style={{
                margin: 5,
                backgroundColor: Color25 === true ? "#5b21b6" : "#8b5cf6",
              }}
              onClick={() => {
                setColor25(!Color25);
              }}
              variant="contained"
            >
              Source Type
            </Button>
          </div>
        </Stack>
      </div>
      <CustomReportingSelects
        Color={Color}
        Color1={Color1}
        Color2={Color2}
        Color3={Color3}
        Color4={Color4}
        Color5={Color5}
        Color6={Color6}
        Color7={Color7}
        Color8={Color8}
        Color9={Color9}
      />
      <CustomReportingMetricFilter />
    </div>
  );
}
//CustomReportingFilters

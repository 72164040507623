import React from "react";
import DemandGetDataAndFilter from "./DemandComponents/DemandGetDataAndFilter";

export default function Demand() {
  return (
    <div>
      <DemandGetDataAndFilter />
    </div>
  );
}

import React, { useState, useEffect, useRef, useMemo } from "react";
import ReactDOM from "react-dom";
import { Line } from "@ant-design/plots";
import moment from "moment";
import { uniq, findIndex } from "@antv/util";
import { debounce } from "lodash";
import { Select } from "antd";
export default function GlobalFirstGraph({
  options2,
  options,
  SendDataToGrph,
  SendDataToGrph1, // af7s alasmaa almogode b SendDataToGrph = SendDataToGrph1
  ChooseBNamesForGraph,
  MyChooce2,
}) {
  const currentDate = moment().format("MMMM DD, YYYY");
  const [DataToShow, setDataToShow] = useState(SendDataToGrph1);
  const [IsLoading9, setIsLoading9] = useState(false);

  // console.log(SendDataToGrph);
  // console.log(SendDataToGrph1);
  // console.log(currentDate);
  //console.log(MyChooce2);
  //  console.log("ChooseBNamesForGraph");

  //  console.log(ChooseBNamesForGraph);

  // console.log(aa);
  // console.log(aa.length);

  const COLOR_PLATE_10 = [
    "#5B8FF9",
    "#5AD8A6",
    "#5D7092",
    "#F6BD16",
    "#E8684A",
    "#6DC8EC",
    "#9270CA",
    "#FF9D4D",
    "#269A99",
    "#FF99C3",
  ];
  const container = document.getElementById("container");
  // const customTooltipFormatter = (datum) => {
  //   return { name: datum.name, value: `$ ${datum.value.toFixed(2)}` };
  // };
  const customTooltipFormatter = (datum) => {
    return {
      name: datum.name,
      value: `$ ${datum.value
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
    };
  };
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const handleResize = debounce(() => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }, 250);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ width: "100%" }), []);
  const handleChange2 = (value) => {
    setTimeout(() => {
      setIsLoading9(true);
    }, 0);
    //console.log(`selected ${value}`);
    options2.push(value);
    // console.log(options2);
    SendDataToGrph1 = [];
    setDataToShow([]);
    for (let i = 0; i < SendDataToGrph.length; i++) {
      for (let j = 0; j < options2.length; j++) {
        if (SendDataToGrph[i].name === value[j]) {
          SendDataToGrph1.push(SendDataToGrph[i].name);
          setDataToShow((prevData) => [...prevData, SendDataToGrph[i]]);
        }
      }
    }
    setTimeout(() => {
      setIsLoading9(false);
    }, 0);
    //console.log(DataToShow);
  };
  //console.log(DataToShow);

  //useEffect(() => {}, [options, DataToShow]);
  const config = {
    data: DataToShow,
    xField: "date",
    yField: "value",
    seriesField: "name",
    yAxis: {
      label: {
        // 数值格式化为千分位
        formatter: (v) => `$ ${v}`,
      },
    },
    color: COLOR_PLATE_10,
    point: {
      shape: ({ name }) => {
        return "circle";
      },
      style: ({ hour }) => {
        return {
          r: hour, // 4 个数据示一个点标记
        };
      },
    },
    tooltip: { formatter: customTooltipFormatter },
  };

  return (
    <div
      style={{
        paddingRight: "1%",
        paddingTop: 30,
        paddingBottom: 30,
      }}
    >
      <Select
        mode="multiple"
        allowClear
        style={{
          width: "100%",
        }}
        placeholder="Please select"
        defaultValue={options2}
        onChange={handleChange2}
        options={options}
      />
      <>
        {IsLoading9 ? (
          <>a</>
        ) : (
          <>
            <div style={{ paddingTop: "0.5%", paddingBottom: "0.5%" }}>
              <span style={{ color: "#1a1523", fontSize: 18 }}>
                Revenue by Dates
                {/* <span style={{ color: "#e4e2e4" }}> • </span>{" "}
                <span style={{ color: "#6f6e77" }}>{currentDate}</span> */}
              </span>
            </div>
            <div style={containerStyle}>
              <div style={gridStyle} className="ag-theme-alpine">
                <div>
                  <Line {...config} />
                </div>
              </div>
            </div>
          </>
        )}
      </>
    </div>
  );
}

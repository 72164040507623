import { Pie } from "@ant-design/plots";
import React, { useState, useEffect, useCallback, useRef } from "react";
import { withRouter, useHistory, useLocation } from "react-router-dom";
export default function SupplyTeamReport3Donuts({ Array4, Array5, Array13 }) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  function useWindowSize() {
    const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
    }, []);
    return size;
  }
  const [height, width] = useWindowSize();

  const config2 = {
    legend: {
      position: "left",
    },

    appendPadding: 10,
    data: Array13,
    angleField: "value",
    colorField: "type",
    radius: 0.6,
    label: {
      type: "outer",
      offset: "35%",
      content: ({ percent }) => `${(percent * 100).toFixed(2)}%`,
      style: {
        fontSize: 14,
        textAlign: "center",
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
  };

  const config1 = {
    legend: {
      position: "left",
    },

    appendPadding: 10,
    data: Array4,
    angleField: "value",
    colorField: "type",
    radius: 0.6,
    label: {
      type: "outer",
      offset: "35%",
      content: ({ percent }) => `${(percent * 100).toFixed(2)}%`,
      style: {
        fontSize: 14,
        textAlign: "center",
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
  };

  const config = {
    legend: {
      position: "left",
    },

    appendPadding: 10,
    data: Array5,
    angleField: "value",
    colorField: "type",
    radius: 0.6,
    label: {
      type: "outer",
      offset: "35%",
      content: ({ percent }) => `${(percent * 100).toFixed(2)}%`,
      style: {
        fontSize: 14,
        textAlign: "center",
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
  };

  return (
    <div>
      <div
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        {" "}
        <div
          style={{
            margin: 10,

            backgroundColor: "white",
            flex: width > 481 ? "0 1 32%" : "0 1 100vh",
            width: "100%",
            borderRadius: 15,
          }}
        >
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <h5>Traffic Source</h5>
          </div>
          <Pie {...config2} />
        </div>
        <div
          style={{
            margin: 10,
            backgroundColor: "white",
            flex: width > 481 ? "0 1 32%" : "0 1 100vh",
            width: "100%",
            borderRadius: 15,
          }}
        >
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <h5>Environment</h5>
          </div>
          <Pie {...config1} />
        </div>
        <div
          style={{
            margin: 10,

            backgroundColor: "white",
            flex: width > 481 ? "0 1 32%" : "0 1 100vh",
            width: "100%",
            borderRadius: 15,
          }}
        >
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <h5>Operation System</h5>
          </div>

          <Pie {...config} />
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect, useCallback } from "react";
import { Pie, measureTextWidth } from "@ant-design/plots";
import { withRouter, useHistory, useLocation } from "react-router-dom";

export default function MediaSourceDunots(DataFromFather) {
  //console.log(DataFromFather.SendDataToDunots1);
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  function useWindowSize() {
    const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
    }, []);
    return size;
  }
  const [height, width] = useWindowSize();

  const data = [
    {
      type: "a",
      value: 27,
    },
    {
      type: "b",
      value: 25,
    },
    {
      type: "c",
      value: 18,
    },
    {
      type: "d",
      value: 15,
    },
    {
      type: "e",
      value: 10,
    },
    {
      type: "f",
      value: 5,
    },
  ];
  function renderStatistic(containerWidth, text, style) {
    const { width: textWidth, height: textHeight } = measureTextWidth(
      text,
      style
    );
    const R = containerWidth / 2; // r^2 = (w / 2)^2 + (h - offsetY)^2

    let scale = 1;

    if (containerWidth < textWidth) {
      scale = Math.min(
        Math.sqrt(
          Math.abs(
            Math.pow(R, 2) /
              (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2))
          )
        ),
        1
      );
    }

    const textStyleStr = `width:${containerWidth}px;`;
    return `<div style="${textStyleStr};font-size:${scale}em;line-height:${
      scale < 1 ? 1 : "inherit"
    };">${text}</div>`;
  }
  const config = {
    appendPadding: 10,
    data: DataFromFather.SendDataToDunots1,
    angleField: "value",
    colorField: "name",
    radius: 1,
    innerRadius: 0.64,
    meta: {
      value: {
        formatter: (v) =>
          `${(
            (v /
              DataFromFather.SendDataToDunots1.reduce(
                (acc, curr) => acc + curr.value,
                0
              )) *
            100
          ).toFixed(2)}%`,
      },
    },
    legend: {
      position: "bottom",
    },
    radius: 0.6,
    appendPadding: 10,
    label: {
      type: "outer",
      offset: "35%",
      content: ({ percent }) => `${(percent * 100).toFixed(2)}%`,
      style: {
        fontSize: 14,
        textAlign: "center",
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    statistic: {
      title: {
        offsetY: -4,
        customHtml: (container, view, datum) => {
          const { width, height } = container.getBoundingClientRect();
          const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
          const text = datum ? datum.name : "Revenue";
          return renderStatistic(d, text, {
            fontSize: 20,
          });
        },
      },
      content: {
        offsetY: 4,
        style: {
          fontSize: "15px",
        },
        customHtml: (container, view, datum, SendDataToDunots1) => {
          const { width } = container.getBoundingClientRect();
          const text = datum
            ? `$ ${datum.value
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
            : `$ ${SendDataToDunots1.reduce((r, d) => r + d.value, 0)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
          return renderStatistic(width, text, {
            fontSize: 15,
          });
        },
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
      {
        type: "pie-statistic-active",
      },
    ],
  };

  const config1 = {
    appendPadding: 10,
    data: DataFromFather.SendDataToDunots2,
    angleField: "value",
    colorField: "name",
    radius: 1,
    innerRadius: 0.64,
    meta: {
      value: {
        formatter: (v) =>
          `${(
            (v /
              DataFromFather.SendDataToDunots2.reduce(
                (acc, curr) => acc + curr.value,
                0
              )) *
            100
          ).toFixed(2)}%`,
      },
    },
    legend: {
      position: "bottom",
    },
    radius: 0.6,
    appendPadding: 10,
    label: {
      type: "outer",
      offset: "35%",
      content: ({ percent }) => `${(percent * 100).toFixed(2)}%`,
      style: {
        fontSize: 14,
        textAlign: "center",
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    statistic: {
      title: {
        offsetY: -4,
        customHtml: (container, view, datum) => {
          const { width, height } = container.getBoundingClientRect();
          const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
          const text = datum ? datum.name : "Revenue";
          return renderStatistic(d, text, {
            fontSize: 20,
          });
        },
      },
      content: {
        offsetY: 4,
        style: {
          fontSize: "15px",
        },
        customHtml: (container, view, datum, SendDataToDunots2) => {
          const { width } = container.getBoundingClientRect();
          const text = datum
            ? `$ ${datum.value
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
            : `$ ${SendDataToDunots2.reduce((r, d) => r + d.value, 0)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
          return renderStatistic(width, text, {
            fontSize: 15,
          });
        },
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
      {
        type: "pie-statistic-active",
      },
    ],
  };

  const config2 = {
    appendPadding: 10,
    data: DataFromFather.SendDataToDunots3,
    angleField: "value",
    colorField: "name",
    radius: 1,
    innerRadius: 0.64,
    meta: {
      value: {
        formatter: (v) =>
          `${(
            (v /
              DataFromFather.SendDataToDunots3.reduce(
                (acc, curr) => acc + curr.value,
                0
              )) *
            100
          ).toFixed(2)}%`,
      },
    },
    legend: {
      position: "bottom",
    },
    radius: 0.6,
    appendPadding: 10,
    label: {
      type: "outer",
      offset: "35%",
      content: ({ percent }) => `${(percent * 100).toFixed(2)}%`,
      style: {
        fontSize: 14,
        textAlign: "center",
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    statistic: {
      title: {
        offsetY: -4,
        customHtml: (container, view, datum) => {
          const { width, height } = container.getBoundingClientRect();
          const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
          const text = datum ? datum.name : "Revenue";
          return renderStatistic(d, text, {
            fontSize: 20,
          });
        },
      },
      content: {
        offsetY: 4,
        style: {
          fontSize: "15px",
        },
        customHtml: (container, view, datum, SendDataToDunots3) => {
          const { width } = container.getBoundingClientRect();
          const text = datum
            ? `$ ${datum.value
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
            : `$ ${SendDataToDunots3.reduce((r, d) => r + d.value, 0)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
          return renderStatistic(width, text, {
            fontSize: 15,
          });
        },
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
      {
        type: "pie-statistic-active",
      },
    ],
  };

  const config3 = {
    appendPadding: 10,
    data: DataFromFather.SendDataToDunots4,
    angleField: "value",
    colorField: "name",
    radius: 1,
    innerRadius: 0.64,
    meta: {
      value: {
        formatter: (v) =>
          `${(
            (v /
              DataFromFather.SendDataToDunots4.reduce(
                (acc, curr) => acc + curr.value,
                0
              )) *
            100
          ).toFixed(2)}%`,
      },
    },
    legend: {
      position: "bottom",
    },
    radius: 0.6,
    appendPadding: 10,
    label: {
      type: "outer",
      offset: "35%",
      content: ({ percent }) => `${(percent * 100).toFixed(2)}%`,
      style: {
        fontSize: 14,
        textAlign: "center",
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    statistic: {
      title: {
        offsetY: -4,
        customHtml: (container, view, datum) => {
          const { width, height } = container.getBoundingClientRect();
          const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
          const text = datum ? datum.name : "Revenue";
          return renderStatistic(d, text, {
            fontSize: 20,
          });
        },
      },
      content: {
        offsetY: 4,
        style: {
          fontSize: "15px",
        },
        customHtml: (container, view, datum, SendDataToDunots4) => {
          const { width } = container.getBoundingClientRect();
          const text = datum
            ? `$ ${datum.value
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
            : `$ ${SendDataToDunots4.reduce((r, d) => r + d.value, 0)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
          return renderStatistic(width, text, {
            fontSize: 15,
          });
        },
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
      {
        type: "pie-statistic-active",
      },
    ],
  };
  return (
    <div style={{ paddingTop: 30, paddingBottom: 30 }}>
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          justifyContent: width > 766 ? "space-evenly" : "",
          overflowX: width > 766 ? "auto" : "scroll",
        }}
      >
        <div
          style={{
            flex: 1,
            borderWidth: 1,
            border: "1px solid #f5f5f5",
            borderRadius: 10,
            minWidth: 200,
            maxWidth: "22%",
            marginRight: width > 766 ? 0 : 20,
            background: "#f9f8f9",
          }}
        >
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <span style={{ fontSize: 20, color: "#20134b", paddingBottom: 10 }}>
              Revenue By Source
            </span>
          </div>
          <Pie {...config} />
        </div>
        <div
          style={{
            flex: 1,
            borderWidth: 1,
            border: "1px solid #f5f5f5",
            borderRadius: 10,
            minWidth: 200,
            maxWidth: "22%",
            marginRight: width > 766 ? 0 : 20,
            background: "#f9f8f9",
          }}
        >
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <span style={{ fontSize: 20, color: "#20134b", paddingBottom: 10 }}>
              CTV Revenue By Source
            </span>
          </div>
          <Pie {...config1} />
        </div>
        <div
          style={{
            flex: 1,
            borderWidth: 1,
            border: "1px solid #f5f5f5",
            borderRadius: 10,
            minWidth: 200,
            maxWidth: "22%",
            marginRight: width > 766 ? 0 : 20,
            background: "#f9f8f9",
          }}
        >
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <span style={{ fontSize: 20, color: "#20134b", paddingBottom: 10 }}>
              InApp Revenue By Source
            </span>
          </div>
          <Pie {...config2} />
        </div>
        <div
          style={{
            flex: 1,
            borderWidth: 1,
            border: "1px solid #f5f5f5",
            borderRadius: 10,
            minWidth: 200,
            maxWidth: "22%",
            marginRight: width > 766 ? 0 : 20,
            background: "#f9f8f9",
          }}
        >
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <span style={{ fontSize: 20, color: "#20134b", paddingBottom: 10 }}>
              Integration Type Revenue By Source
            </span>
          </div>
          <Pie {...config3} />
        </div>
      </div>
    </div>
  );
}

import React from "react";
import CampaignManagementGetDataAndFilter from "./CampaignManagementComponents/CampaignManagementGetDataAndFilter";

export default function CampaignManagement() {
  return (
    <div>
      <CampaignManagementGetDataAndFilter />
    </div>
  );
}

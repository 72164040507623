import ReactApexChart from "react-apexcharts";
import React, { useState } from "react";
import ResizeDetector from "react-resize-detector";

export default function SupplyReportsFirstGraph({
  SendDataToGraphData,
  SendDataToGraphData1,
  SendDataToGraphData2,
  SendDataToGraphData3,
}) {
  const [chartWidth, setChartWidth] = useState(0);

  const series = [
    {
      name: "Ad Requests",
      type: "line",
      data: SendDataToGraphData1,
    },
    {
      name: "Impressions",
      type: "line", //line
      data: SendDataToGraphData2,
    },
    {
      name: "Fill Rate",
      type: "column",
      data: SendDataToGraphData,
    },
  ];
  const options = {
    chart: {
      type: "bar",
      width: "100%",
      height: 400,
    },
    plotOptions: {
      bar: {
        columnWidth: "10%",
      },
    },
    chart: {
      height: 350,
      type: "line",
      stacked: false,
    },
    dataLabels: {
      //enabled: false,
    },
    stroke: {
      width: [1, 1],
    },
    xaxis: {
      categories: SendDataToGraphData3, //[2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016],
    },
    yaxis: [
      {
        seriesName: "Income",
        //opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          //show: true,
          color: "#008FFB",
        },
        labels: {
          style: {
            colors: "#008FFB",
          },
          formatter: function (value) {
            return value + "B";
          },
        },
        title: {
          //text: "Ad Requests By Billions",
          style: {
            color: "#008FFB",
          },
        },
      },
      {
        axisTicks: {
          show: true,
        },
        axisBorder: {
          //show: true,
          color: "#00E396",
        },
        labels: {
          formatter: function (value) {
            return value + "M";
          },
          style: {
            colors: "#00E396",
          },
        },
        title: {
          //text: "Impressions By Millions",
          style: {
            color: "#00E396",
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      {
        seriesName: "Revenue",
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          //show: true,
          color: "#FEB019",
        },
        labels: {
          style: {
            colors: "#FEB019",
          },
        },
        title: {
          //text: "Fill Rate By Percent",
          style: {
            color: "#FEB019",
          },
        },
      },
    ],
    tooltip: {
      fixed: {
        enabled: false,
        position: "topRight", // topRight, topLeft, bottomRight, bottomLeft
        offsetY: 30,
        offsetX: 60,
      },
    },
    legend: {
      horizontalAlign: "left",
      offsetX: 40,
    },
  };
  const handleResize = (width) => {
    setChartWidth(width);
  };
  //      <div style={{ flex: "1 1 auto", overflow: "auto" }}>

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        paddingTop: 30,
      }}
    >
      <div style={{ flex: "1 1 auto" }}>
        <div style={{ background: "white" }}>
          <span style={{ color: "#1a1523", fontSize: 18 }}>
            Ad Requests, Impression, and Fill Rate
          </span>

          <ResizeDetector handleWidth onResize={handleResize} />
          <ReactApexChart
            options={options}
            series={series}
            type="line"
            height={500}
          />
        </div>
      </div>
    </div>
  );
}

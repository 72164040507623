import { Grid, Container, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
// sections
import { AppWebsiteVisits } from "../sections/@dashboard/app";

var newArray1 = [];
var newArray2 = [];
var newArray3 = [];
var newArray4 = [];
var newArray5 = [];
var newArray6 = [];

// ----------------------------------------------------------------------

export default function IncomeGraph({ myArr, myArr1, myArr2, MyTrue }) {
  const [data1, setData1] = useState("");
  newArray1 = [];
  newArray2 = [];
  newArray3 = [];
  newArray4 = [];
  newArray5 = [];
  newArray6 = [];
  for (let i = 0; i < myArr2.length; i++) {
    newArray4.push(myArr2[i].seat);
    newArray5.push(myArr2[i].revenue.toFixed(2));
    newArray6.push(myArr2[i].netincome.toFixed(2));
  }
  for (let i = 0; i < myArr.length; i++) {
    console.log(myArr[i].revenue);
    newArray1.push(myArr[i].seat);
    newArray2.push(myArr[i].revenue.toFixed(2));
    newArray3.push(myArr[i].netincome.toFixed(2));
  }
  const users = Array.isArray(data1.responseData)
    ? data1 &&
      data1.responseData.map((_, responseData) => ({
        // id: faker.datatype.uuid(),
        seat: data1.responseData[responseData].name,
        revenue:
          data1.responseData[
            responseData
          ].dataNetto.sumResp.revenueTotal.toFixed(2),
        mediacost:
          data1.responseData[responseData].dataNetto.sumResp.mediaCost.toFixed(
            2
          ),
        operationfee:
          data1.responseData[
            responseData
          ].dataNetto.sumResp.operationCost.toFixed(2),
        grossprofit:
          data1.responseData[
            responseData
          ].dataNetto.sumResp.grossProfit.toFixed(2),
        partnerfee:
          data1.responseData[responseData].dataNetto.sumResp.partnerFee.toFixed(
            2
          ),
        profitmargin: "number%",
        netincome:
          data1.responseData[responseData].dataNetto.sumResp.netIncome.toFixed(
            2
          ),
        netinc: "number%",
      }))
    : [];

  return (
    <>
      {myArr1 === [] ? (
        <>
          <Grid item xs={5} md={2} lg={2}>
            <AppWebsiteVisits
              title="Graph Revenue & Net Income"
              subheader=""
              chartLabels={newArray1}
              chartData={[
                {
                  name: "revenue",
                  type: "column",
                  fill: "solid",
                  data: newArray2,
                },
                {
                  name: "Net Income",
                  type: "column",
                  fill: "solid",
                  data: newArray3,
                },
              ]}
            />
          </Grid>
        </>
      ) : (
        <>
          <AppWebsiteVisits
            title="Graph Revenue & Net Income"
            subheader=""
            chartLabels={newArray4}
            chartData={[
              {
                name: "revenue",
                type: "column",
                fill: "solid",
                data: newArray5,
              },
              {
                name: "Net Income",
                type: "column",
                fill: "solid",
                data: newArray6,
              },
            ]}
          />
        </>
      )}
    </>
  );
}

import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import { display } from "@mui/system";
import { Input, Space, Table, Typography, Button, Checkbox } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import "antd/dist/antd.css";
import "../../Pages/styles.css";

var obj = {};
var newArray11 = [];

var TheSum = [];

// const columnGroupingModel = [
//   {
//     groupId: "naming",
//     headerName: "CTV",
//     headerClassName: "my-super-theme--naming-group",
//     children: [{ field: "revenue" }, { field: "precent" }],
//   },
//   {
//     groupId: "naming1",
//     headerName: "InApp",
//     headerClassName: "my-super-theme--naming-group1",
//     children: [{ field: "revenue1" }, { field: "precent1" }],
//   },
// ];

export default function GlobalTable3({ newArray13 }) {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    // { field: "id", headerName: "ID", width: 90 },
    {
      dataIndex: "AppBundle",
      title: "AppBundle",
      flex: 1,
    },
    {
      dataIndex: "AppName",
      title: "AppName",
    },
    {
      dataIndex: "Fill",
      title: "Fill",
      render: (value) => {
        return Number(value * 1).toLocaleString("en") + " %";
      },
    },
    {
      dataIndex: "Impressions",
      title: "Imp",
      ...getColumnSearchProps("Impressions"),
      sorter: (a, b) => a.Impressions - b.Impressions,

      render: (value) => {
        return Number(value * 1).toLocaleString("en");
      },
    },
    {
      dataIndex: "OS",
      title: "OS",
    },

    {
      dataIndex: "QS",
      title: "QS",

      render: (value) => {
        return (
          //  "$ " +
          Number.parseFloat(value.toString().replace("e-", "")).toFixed(2)
        );
      },
    },
    {
      dataIndex: "Revenue",
      title: "Revenue",
      flex: 1,
      editable: true,
      ...getColumnSearchProps("Revenue"),
      sorter: (a, b) => a.Revenue - b.Revenue,

      render: (value) => {
        return "$ " + Number(value * 1).toLocaleString("en");
      },
    },
  ];
  const [pageSize, setPageSize] = React.useState(15);
  console.log("t3");

  console.log(newArray13);
  // rowSelection objects indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    onSelect: (record, selected, selectedRows) => {
      console.log(record, selected, selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      console.log(selected, selectedRows, changeRows);
    },
  };
  return (
    <div>
      <br></br>
      <div style={{ backgroundColor: "white" }}>
        <div
          style={{
            backgroundColor: "white",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <div style={{ width: "100%" }}>
            <h5>Top 10 apps</h5>

            <div style={{ width: "100%" }}></div>
            <Table
              //      rowSelection={rowSelection}
              dataSource={newArray13}
              columns={columns}
              pagination={{
                pageSize: 15,
                position: ["none", "none"],
              }}
              size="small"
              scroll={{ x: "max-content", y: 280 }}
              bordered
            ></Table>
          </div>
        </div>
      </div>{" "}
    </div>
  );
}

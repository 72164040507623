import React, { useState, useEffect } from "react";
import { withRouter, useHistory, useLocation } from "react-router-dom";

import { Pie } from "@ant-design/plots";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function GlobalDonut({ pushArray, pushArray1, pushArray2 }) {
  const { pathname } = useLocation();

  // console.log(pushArray1)
  // console.log(pushArray2)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  function useWindowSize() {
    const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
    }, []);
    return size;
  }
  const [height, width] = useWindowSize();

  //  console.log(width);

  const config = {
    legend: {
      position: "left",
    },

    appendPadding: 10,
    data: pushArray,
    angleField: "value",
    colorField: "type",
    radius: 0.6,
    label: {
      offset: "35%",

      type: "outer",
      content: ({ percent }) => `${(percent * 100).toFixed(2)}%`,
      style: {
        fontSize: 14,
        textAlign: "center",
      },
    },
  };

  const config1 = {
    legend: {
      position: "left",
    },

    appendPadding: 10,
    data: pushArray1,
    angleField: "value",
    colorField: "type",
    radius: 0.6,
    label: {
      type: "outer",
      offset: "35%",
      content: ({ percent }) => `${(percent * 100).toFixed(2)}%`,
      style: {
        fontSize: 14,
        textAlign: "center",
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
  };

  const config2 = {
    legend: {
      position: "left",
    },

    appendPadding: 10,
    data: pushArray2,
    angleField: "value",
    colorField: "type",
    radius: 0.6,
    label: {
      type: "outer",
      offset: "35%",
      content: ({ percent }) => `${(percent * 100).toFixed(2)}%`,
      style: {
        fontSize: width > 400 ? 14 : 10,
        textAlign: "center",
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
  };
  return (
    <div>
      <div
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            margin: 10,
            backgroundColor: "white",
            flex: width > 481 ? "0 1 32%" : "0 1 100vh",
            width: "100%",
            borderRadius: 15,
          }}
        >
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <h5>Revenue by Seat</h5>
          </div>
          <Pie {...config1} />
        </div>
        <div
          style={{
            margin: 10,

            backgroundColor: "white",
            flex: width > 481 ? "0 1 32%" : "0 1 100vh",
            width: "100%",
            borderRadius: 15,
          }}
        >
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <h5>Revenue by Env</h5>
          </div>

          <Pie {...config} />
        </div>
        <div
          style={{
            margin: 10,

            backgroundColor: "white",
            flex: width > 481 ? "0 1 32%" : "0 1 100vh",
            width: "100%",
            borderRadius: 15,
          }}
        >
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <h5>Revenue by Integration</h5>
          </div>
          <Pie {...config2} />
        </div>
        &nbsp; &nbsp;
      </div>
    </div>
  );
}
//   <div>&nbsp;</div>

import React from "react";
import SupplyReportsGetDataAndFilter from "./SupplyReportsComponents/SupplyReportsGetDataAndFilter";

export default function Supply() {
  return (
    <div>
      <SupplyReportsGetDataAndFilter />
    </div>
  );
}

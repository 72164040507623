import React, { useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";

function CustomReportingMetricFilter() {
  const [formValues, setFormValues] = useState([
    { choose: "", sign: "", name: "", email: "" },
  ]);
  const [age, setAge] = React.useState("");
  const handleChange1 = (event) => {
    setAge(event.target.value);
  };

  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    setFormValues([
      ...formValues,
      { choose: "", sign: "", name: "", email: "" },
    ]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  let handleSubmit = (event) => {
    event.preventDefault();
    alert(JSON.stringify(formValues));
  };

  return (
    <form onSubmit={handleSubmit}>
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <h5>Metric Filter:-</h5>
        &nbsp;&nbsp;
        <Button
          onClick={() => addFormFields()}
          variant="contained"
          style={{
            backgroundColor: "#8b5cf6",
          }}
        >
          Add
        </Button>
        {/* <button className="button submit" type="submit">
          Submit
        </button> */}
      </div>
      {formValues.map((element, index) => (
        <div
          key={index}
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {index ? (
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Box sx={{ minWidth: 150, width: 100, margin: 1 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Age</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    type="text"
                    name="choose"
                    value={element.choose || ""}
                    label="Age"
                    onChange={(e) => handleChange(index, e)}
                  >
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              &nbsp;&nbsp;&nbsp;
              <Box sx={{ minWidth: 150, width: 100 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Sign</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    type="text"
                    name="sign"
                    value={element.sign || ""}
                    label="Sign"
                    onChange={(e) => {
                      handleChange(index, e);
                      console.log(element.sign);
                    }}
                  >
                    <MenuItem value={1}> {"="} </MenuItem>
                    <MenuItem value={2}> {"!="} </MenuItem>
                    <MenuItem value={3}> {">"} </MenuItem>
                    <MenuItem value={4}> {">="} </MenuItem>
                    <MenuItem value={5}> {"<</option>"} </MenuItem>
                    <MenuItem value={6}> {"<=</option>"} </MenuItem>
                  </Select>
                </FormControl>
              </Box>
              &nbsp;&nbsp;&nbsp;
              <Box component="form" noValidate autoComplete="off">
                <TextField
                  type="text"
                  name="name"
                  value={element.name || ""}
                  onChange={(e) => handleChange(index, e)}
                  id="outlined-basic"
                  label="Outlined"
                  variant="outlined"
                />
              </Box>
              &nbsp;&nbsp;&nbsp;
              <Button
                style={{
                  backgroundColor: "#8b5cf6",
                }}
                onClick={() => removeFormFields(index)}
                variant="contained"
                startIcon={<DeleteIcon />}
              >
                Delete
              </Button>
              <br></br>
            </div>
          ) : null}
        </div>
      ))}
    </form>
  );
}
export default CustomReportingMetricFilter;

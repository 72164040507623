import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Outlet, Link, withRouter, useHistory } from "react-router-dom";
import { MyUrl } from "../App";
// @mui
import { useTheme } from "@mui/material/styles";
import { Grid, Container, Typography } from "@mui/material";
import SetIncomByMonth from "./SetIncomByMonth";
import SetIncomByDay from "./SetIncomByDay";
import AppWidgetSummary from "./AppWidgetSummary";
import { useState, useEffect, Dimensions, useCallback } from "react";
import Button from "@mui/material/Button";
//getAllUrlsByTypeId
import SetIncomByDayOverView from "./SetIncomByDayOverView";
import SetIncomByMonthOverView from "./SetIncomByMonthOverView";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { SetIncomByDay7 } from "./SetIncomByDay";
import { SetIncomByMonth7 } from "./SetIncomByMonth";
import axios from "axios";
import { Avatar, List, Skeleton, Switch } from "antd";
import { Redirect } from "../App";
// components
// sections
import "../MyCss/Body.css";
import { esES } from "@mui/x-date-pickers-pro";
export var token1 = "";
var sum1 = 0;
var sum2 = 0;
var sum33 = 0;
var sum333 = 0;
var sum4 = 0;
var sum44 = 0;

//var myDate = "&endDate=2022-11-09";
function Body() {
  const urlParams1 = new URLSearchParams(window.location.search);
  const encodedObject = urlParams1.get("myObject");
  const decodedString = atob(encodedObject);
  const decodedObject = JSON.parse(decodedString);
  console.log(decodedObject);
  // Switch button General or Seats
  const [SeatsOrGeneral, setSeatsOrGeneral] = useState(true);

  const [alignment, setAlignment] = React.useState("web");
  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  // End switch button General or Seats

  let history = useHistory();
  const now = new Date();
  const today = now.getDate();
  const month = now.getMonth() + 1;
  const GoToUsersPanel = async () => {
    history.push("/ForCheck");
  };
  const [data, setData] = useState(null);
  const [data1, setData1] = useState(null);
  const [data2, setData2] = useState(null);
  const [data3, setData3] = useState(null);
  const [data4, setData4] = useState(null);
  const [data5, setData5] = useState(null);

  const [array, setArray] = useState([]);
  const [array1, setArray1] = useState([]);
  const [array2, setArray2] = useState([]);
  const [array3, setArray3] = useState([]);
  const [array4, setArray4] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [O2rrevenue, setO2rRevenue] = useState([]);
  const [O2rrevenue1, setO2rRevenue1] = useState([]);
  const [O2rrevenue2, setO2rRevenue2] = useState([]);
  const [O2rrevenue3, setO2rRevenue3] = useState([]);
  const [O2rrevenue4, setO2rRevenue4] = useState([]);

  const [SumDaily, setSumDaily] = useState([]);
  const [SumDaily1, setSumDaily1] = useState([]);
  const [SumDaily2, setSumDaily2] = useState([]);

  const [MySum, setMySum] = useState([]);
  const [MySum1, setMySum1] = useState([]);
  const [MySum2, setMySum2] = useState([]);

  const [revenue, setrevenue] = useState([]);

  const [isLoading1, setIsLoading1] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);
  const [isLoading3, setIsLoading3] = useState(true);
  const [isLoading4, setIsLoading4] = useState(true);
  const [isLoading5, setIsLoading5] = useState(true);
  const [isLoading6, setIsLoading6] = useState(true);

  function getDaysInMonth(year, month) {
    return new Date(year, month, 0).getDate();
  }

  const date = new Date();
  const currentYear = date.getFullYear();
  const currentMonth = date.getMonth() + 1; // 👈️ months are 0-based

  // 👇️ Current Month
  const daysInCurrentMonth = getDaysInMonth(currentYear, currentMonth);

  const current = new Date();
  const dateNow = `${current.getFullYear()}-${
    current.getMonth() + 1
  }-${current.getDate()}`;

  const [dateFrom, setdateFrom] = React.useState(`&startDate=${dateNow}`);
  const [dateTo, setdateTo] = React.useState(`&endDate=${dateNow}`);

  const now1 = new Date();

  const firstDayOfMonth = `startDate=${now1.getFullYear()}-${
    now1.getMonth() + 1
  }-01`; //new Date(now1.getFullYear(), now1.getMonth(), 1);
  const urlParams = new URLSearchParams(window.location.search);
  const tokenFromUrl = urlParams.get("Authorization");
  if (tokenFromUrl !== null) {
    var token = tokenFromUrl;
    localStorage.setItem("Token", JSON.stringify(token));
    const MyToken = JSON.parse(localStorage.getItem("Token"));
  }
  const MyToken = JSON.parse(localStorage.getItem("Token"));
  console.log("MyToken");
  console.log(MyToken);
  useEffect(() => {
    getAllUrlsByTypeIds();
    getAllUrlsByTypeId3();
    getMonthlyIncome();
    getAllUrlsByTypeId2();
    getAllUrlsByTypeId();
    getAllUrlsByTypeId1();
  }, []);

  const getAllUrlsByTypeId = useCallback(() => {
    setIsLoading1(true);
    try {
      let base64 = require("base-64"); // install it before use from npm i base-64

      sum1 = 0;

      axios
        .get(`${MyUrl}/api/Dashboard/getDailyIncome?${dateFrom}${dateTo}`, {
          headers: {
            Authorization: `${MyToken}`,
          },
        })
        .then((res) => {
          const data = res.data;
          setData(data);

          for (let j = 0; j < data.responseData[0].dataNetto.data.length; j++) {
            const revenue = [];
            sum1 = 0;
            for (let i = 0; i < data.responseData.length; i++) {
              sum1 += data.responseData[i].dataNetto.data[j].netIncome;
              var result1 = sum1;
            }

            MySum.push(result1.toFixed(2));
          }
        })
        .catch((error) => {})
        .finally(() => {
          const obj11 = {
            name: "Seats overview",
            type: "line",
            fill: "solid",
            data: MySum,
          };
          O2rrevenue.push(obj11);
          setIsLoading1(false);
        });
    } catch (error) {
      console.log(error);
    }
  });

  const getAllUrlsByTypeId1 = () => {
    setIsLoading2(true);
    try {
      console.log("ggg");
      sum2 = 0;
      axios
        .get(
          `${MyUrl}/api/Dashboard/getMonthlyIncome?${firstDayOfMonth}${dateTo}`,
          {
            headers: {
              Authorization: `${MyToken}`,
            },
          }
        )
        .then((res) => {
          if (firstDayOfMonth !== dateTo) {
            const data = res.data;
            setData1(data);
            for (
              let j = 0;
              j < data.responseData[0].dataNetto.data.length - 1;
              j++
            ) {
              const revenue = [];
              sum2 = 0;
              for (let i = 0; i < data.responseData.length; i++) {
                sum2 += data.responseData[i].dataNetto.data[j].netIncome;
                var result1 = sum2;
              }
              MySum1.push(result1.toFixed(2));
            }
          }
        })
        .catch((error) => {})
        .finally(() => {
          const obj11 = {
            name: "Seats overview",
            type: "line",
            fill: "solid",
            data: MySum1,
          };
          O2rrevenue1.push(obj11);
          setIsLoading2(false);
        });
    } catch (error) {}
  };

  const getAllUrlsByTypeId2 = useCallback(() => {
    setIsLoading3(true);
    try {
      axios
        .get(`${MyUrl}/api/Dashboard/getDailyIncome?${dateFrom}${dateTo}`, {
          headers: {
            Authorization: `${MyToken}`,
          },
        })
        .then((res) => {
          const data = res.data;
          setData2(data);
          for (let i = 0; i < data.responseData.length; i++) {
            array1.push(data.responseData[i].dataNetto.data);
          }
          for (let j = 0; j < data.responseData.length; j++) {
            const revenue = [];
            for (
              let i = 0;
              i < data.responseData[j].dataNetto.data.length;
              i++
            ) {
              revenue.push(array1[j][i].netIncome.toFixed(2));
              SumDaily.push(array1[j][i].netIncome.toFixed(2));
            }
            const obj11 = {
              name: data.responseData[j].name,
              type: "line",
              fill: "solid",
              data: revenue,
            };
            O2rrevenue2.push(obj11);
          }
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading3(false);
        });
    } catch (error) {}
  });

  const getMonthlyIncome = () => {
    setIsLoading4(true);
    console.log("ffff");
    try {
      axios
        .get(
          `${MyUrl}/api/Dashboard/getMonthlyIncome?${firstDayOfMonth}${dateTo}`,
          {
            headers: {
              Authorization: `${MyToken}`,
            },
          }
        )
        .then((res) => {
          if (firstDayOfMonth !== dateTo) {
            const data = res.data;
            setData3(data);
            for (let i = 0; i < data.responseData.length; i++) {
              array2.push(data.responseData[i].dataNetto.data);
            }
            for (let j = 0; j < data.responseData.length; j++) {
              const revenue = [];
              for (
                let i = 0;
                i < data.responseData[j].dataNetto.data.length - 1;
                i++
              ) {
                revenue.push(array2[j][i].netIncome.toFixed(2));
              }
              const obj11 = {
                name: data.responseData[j].name,
                type: "line",
                fill: "solid",
                data: revenue,
              };
              O2rrevenue3.push(obj11);
            }
          }
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading4(false);
        });
    } catch (error) {}
  };

  const getAllUrlsByTypeId3 = useCallback(() => {
    setIsLoading5(true);
    try {
      sum33 = 0;
      axios
        .get(`${MyUrl}/api/Dashboard/getDailyIncome?${dateFrom}${dateTo}`, {
          headers: {
            Authorization: `${MyToken}`,
          },
        })
        .then((res) => {
          const data = res.data;
          setData4(data);
          for (let i = 0; i < data.responseData.length; i++) {
            array4.push(data.responseData[i].dataNetto.data);
          }
          for (let j = 0; j < data.responseData.length; j++) {
            const revenue = [];
            for (
              let i = 0;
              i < data.responseData[j].dataNetto.data.length;
              i++
            ) {
              revenue.push(array4[j][i].netIncome);
              SumDaily1.push(array4[j][i].netIncome);
              sum33 += array4[j][i].netIncome;
              sum333 = data.responseData[j].dataNetto.data.length;
            }
          }
        })
        .catch((error) => {})
        .finally(() => {
          sum33 /= sum333;
          sum33 *= 24;
          setIsLoading5(false);
        });
    } catch (error) {}
  });

  const getAllUrlsByTypeIds = () => {
    setIsLoading6(true);
    try {
      console.log("mmmmm");
      sum4 = 0;
      axios
        .get(
          `${MyUrl}/api/Dashboard/getMonthlyIncome?${firstDayOfMonth}${dateTo}`,
          {
            headers: {
              Authorization: `${MyToken}`,
            },
          }
        )
        .then((res) => {
          if (firstDayOfMonth !== dateTo) {
            const data = res.data;
            setData5(data);
            for (let i = 0; i < data.responseData.length; i++) {
              array3.push(data.responseData[i].dataNetto.data);
            }
            for (let j = 0; j < data.responseData.length; j++) {
              const aa = [];
              for (
                let i = 0;
                i < data.responseData[j].dataNetto.data.length - 1;
                i++
              ) {
                aa.push(array3[j][i].netIncome);
                SumDaily2.push(array3[j][i].netIncome);

                sum4 += array3[j][i].netIncome;
                sum44 = data.responseData[j].dataNetto.data.length - 1;
              }
            }
          }
        })
        .catch((error) => {
          console.log("res");
          // if (error.status !== 200) {
          //   console.log("Logout");
          //   localStorage.removeItem("Token");
          //   const redirectUrl = Redirect;
          //   window.location = redirectUrl;
          // }
        })
        .finally(() => {
          sum4 /= sum44;
          sum4 *= daysInCurrentMonth;
          setIsLoading6(false);
        });
    } catch (error) {}
  };

  return (
    <div className="container">
      <br></br>

      <ToggleButtonGroup
        color="error"
        value={alignment}
        exclusive
        onChange={handleChange}
        aria-label="Platform"
      >
        <ToggleButton
          style={{
            color: "white",
            backgroundColor: SeatsOrGeneral ? "#6b21a8" : "#a855f7",
          }}
          onClick={() => {
            setSeatsOrGeneral(true);
          }}
          value="web"
        >
          General
        </ToggleButton>
        <ToggleButton
          style={{
            color: "white",
            backgroundColor: !SeatsOrGeneral ? "#6b21a8" : "#a855f7",
          }}
          onClick={() => {
            setSeatsOrGeneral(false);
          }}
          value="ios"
        >
          Seats
        </ToggleButton>
      </ToggleButtonGroup>
      <br></br>
      <Grid
        container
        spacing={3}
        style={{ justifyContent: "center", alignItems: "center" }}
      >
        <Grid item xs={12} sm={6} md={3}>
          {isLoading5 ? (
            <>
              <Skeleton active avatar>
                <List.Item.Meta
                  avatar={<Avatar src={""} />}
                  title={<a href={""}>{""}</a>}
                  description={""}
                />
                {""}
              </Skeleton>{" "}
            </>
          ) : (
            <>
              {" "}
              <SetIncomByDay7 chartData={sum33} />
            </>
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          {isLoading6 ? (
            <>
              <Skeleton active avatar>
                <List.Item.Meta
                  avatar={<Avatar src={""} />}
                  title={<a href={""}>{""}</a>}
                  description={""}
                />
                {""}
              </Skeleton>{" "}
            </>
          ) : (
            <>
              {" "}
              <SetIncomByMonth7 chartData={sum4} />
            </>
          )}
        </Grid>

        {/* <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Monthly Net Income Projection" total={1352831} color="info" icon={'ant-design:apple-filled'} />
          </Grid> */}
      </Grid>
      {SeatsOrGeneral ? (
        <>
          {" "}
          General{" "}
          <Grid item xs={12} md={6} lg={8}>
            {isLoading1 ? (
              <>
                <Skeleton active avatar>
                  <List.Item.Meta
                    avatar={<Avatar src={""} />}
                    title={<a href={""}>{""}</a>}
                    description={""}
                  />
                  {""}
                </Skeleton>{" "}
              </>
            ) : (
              <>
                {" "}
                <SetIncomByDayOverView
                  title={`Daily Income OverView ${month}/${today}`}
                  //   subheader="(+43%) than last year"
                  chartLabels={["01/01/2003", "02/01/2003"]}
                  chartData={O2rrevenue}
                />
              </>
            )}
          </Grid>
          <br></br>
          <Grid item xs={12} md={6} lg={8}>
            {isLoading2 ? (
              <>
                <Skeleton active avatar>
                  <List.Item.Meta
                    avatar={<Avatar src={""} />}
                    title={<a href={""}>{""}</a>}
                    description={""}
                  />
                  {""}
                </Skeleton>{" "}
              </>
            ) : (
              <>
                <SetIncomByMonthOverView
                  title={`Monthly Income OverView`}
                  //subheader="(+43%) than last year"
                  chartLabels={[]}
                  chartData={O2rrevenue1}
                />
              </>
            )}
          </Grid>
          <br></br>
        </>
      ) : (
        <>
          Seats
          <Grid item xs={12} md={6} lg={8}>
            {isLoading3 ? (
              <>
                <Skeleton active avatar>
                  <List.Item.Meta
                    avatar={<Avatar src={""} />}
                    title={<a href={""}>{""}</a>}
                    description={""}
                  />
                  {""}
                </Skeleton>{" "}
              </>
            ) : (
              <>
                <SetIncomByDay
                  title={`Daily Income ${month}/${today} `}
                  //  subheader="(+43%) than last year"
                  chartData={O2rrevenue2}
                />
              </>
            )}
          </Grid>
          <br></br>
          <Grid item xs={12} md={6} lg={8}>
            {isLoading4 ? (
              <>
                <Skeleton active avatar>
                  <List.Item.Meta
                    avatar={<Avatar src={""} />}
                    title={<a href={""}>{""}</a>}
                    description={""}
                  />
                  {""}
                </Skeleton>{" "}
              </>
            ) : (
              <>
                <SetIncomByMonth
                  title={`Monthly Income OverView`}
                  //  subheader="(+43%) than last year"
                  chartData={O2rrevenue3}
                />
              </>
            )}
          </Grid>
          <br></br>
        </>
      )}
    </div>
  );
}
export default Body;

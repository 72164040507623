import React, { useState, useEffect, useRef, useCallback } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import CustomReportingButtons from "./CustomReportingButtons";
import CustomReportingSelects from "./CustomReportingSelects";
import { test } from "./CustomReportingButtons";
import CustomReportingDateRange from "./CustomReportingDateRange";

function CustomReportingFilters() {
  useEffect(() => {}, [test]);

  console.log(test);
  return (
    <div style={{ backgroundColor: "white" }}>
      <div style={{ margin: 10 }}>
        <h5>Filters</h5>
      </div>
      <div style={{ margin: 10 }}>
        <h5>Select Reports:-</h5>

        <CustomReportingDateRange />
      </div>
    </div>
  );
}
export default CustomReportingFilters;

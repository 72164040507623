import React, { useState, useEffect, useRef, useCallback } from "react";
import { Select, Checkbox } from "antd";

const options = [];
for (let i = 10; i < 36; i++) {
  options.push({
    label: i.toString(36) + i,
    value: i.toString(36) + i,
  });
}
const handleChange = (value) => {
  console.log(`selected ${value}`);
};

//For checkBox
const onChange = (e) => {
  console.log(`checked = ${e.target.checked}`);
};

//End For checkBox
export default function CustomReportingSelects({
  Color,
  Color1,
  Color2,
  Color3,
  Color4,
  Color5,
  Color6,
  Color7,
  Color8,
  Color9,
}) {
  const [Checked1, setChecked1] = useState(false);
  const [Checked2, setChecked2] = useState(false);

  useEffect(() => {}, [Color]);

  return (
    <div
      style={{
        //     justifyContent: "space-between",
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      <h5>
        {Color1 ? (
          <>
            <div
              style={{
                flex:
                  500 > 769 ? (500 > 1023 ? "0 1 33%" : "0 1 50%") : "0 1 100%",
                width: "100%",
              }}
            >
              <h5>Select Hours:-</h5>
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder="Please select"
                defaultValue={["a10", "c12"]}
                onChange={handleChange}
                options={options}
              />

              <Checkbox
                checked={Checked1}
                onChange={() => {
                  setChecked1(!Checked1);
                }}
              >
                Checkbox
              </Checkbox>
              <Checkbox
                checked={Checked2}
                onChange={() => {
                  setChecked2(!Checked2);
                }}
              >
                Checkbox
              </Checkbox>
            </div>
          </>
        ) : (
          <></>
        )}
      </h5>
      {Color1 ? <>&nbsp;&nbsp;&nbsp;</> : <></>}
      <h5>
        {Color2 ? (
          <>
            <div
              style={{
                flex:
                  500 > 769 ? (500 > 1023 ? "0 1 33%" : "0 1 50%") : "0 1 100%",
                width: "100%",
              }}
            >
              <h5>Select Channel:-</h5>
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder="Please select"
                defaultValue={["a10", "c12"]}
                onChange={handleChange}
                options={options}
              />
              <Checkbox
                checked={Checked1}
                onChange={() => {
                  setChecked1(!Checked1);
                }}
              >
                Checkbox
              </Checkbox>
              <Checkbox
                checked={Checked2}
                onChange={() => {
                  setChecked2(!Checked2);
                }}
              >
                Checkbox
              </Checkbox>
            </div>
          </>
        ) : (
          <></>
        )}
      </h5>
      {Color2 ? <>&nbsp;&nbsp;&nbsp;</> : <></>}

      <h5>
        {Color3 ? (
          <>
            <div
              style={{
                flex:
                  500 > 769 ? (500 > 1023 ? "0 1 33%" : "0 1 50%") : "0 1 100%",
                width: "100%",
              }}
            >
              <h5>Source:-</h5>
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder="Please select"
                defaultValue={["a10", "c12"]}
                onChange={handleChange}
                options={options}
              />{" "}
              <Checkbox
                checked={Checked1}
                onChange={() => {
                  setChecked1(!Checked1);
                }}
              >
                Checkbox
              </Checkbox>
              <Checkbox
                checked={Checked2}
                onChange={() => {
                  setChecked2(!Checked2);
                }}
              >
                Checkbox
              </Checkbox>
            </div>
          </>
        ) : (
          <></>
        )}
      </h5>
      {Color3 ? <>&nbsp;&nbsp;&nbsp;</> : <></>}

      <h5>
        {Color4 ? (
          <>
            <div
              style={{
                flex:
                  500 > 769 ? (500 > 1023 ? "0 1 33%" : "0 1 50%") : "0 1 100%",
                width: "100%",
              }}
            >
              <h5>Sid:-</h5>
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder="Please select"
                defaultValue={["a10", "c12"]}
                onChange={handleChange}
                options={options}
              />{" "}
              <Checkbox
                checked={Checked1}
                onChange={() => {
                  setChecked1(!Checked1);
                }}
              >
                Checkbox
              </Checkbox>
              <Checkbox
                checked={Checked2}
                onChange={() => {
                  setChecked2(!Checked2);
                }}
              >
                Checkbox
              </Checkbox>
            </div>
          </>
        ) : (
          <></>
        )}
      </h5>
      {Color4 ? <>&nbsp;&nbsp;&nbsp;</> : <></>}

      <h5>
        {Color5 ? (
          <>
            <div
              style={{
                flex:
                  500 > 769 ? (500 > 1023 ? "0 1 33%" : "0 1 50%") : "0 1 100%",
                width: "100%",
              }}
            >
              <h5>Advertiser:-</h5>
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder="Please select"
                defaultValue={["a10", "c12"]}
                onChange={handleChange}
                options={options}
              />
              <Checkbox
                checked={Checked1}
                onChange={() => {
                  setChecked1(!Checked1);
                }}
              >
                Checkbox
              </Checkbox>
              <Checkbox
                checked={Checked2}
                onChange={() => {
                  setChecked2(!Checked2);
                }}
              >
                Checkbox
              </Checkbox>
            </div>
          </>
        ) : (
          <></>
        )}
      </h5>
    </div>
  );
}

import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
// @mui
import { Card, CardHeader, Box, Grid } from "@mui/material";
// components
import { useChart } from "./chart";
import { useEffect, useState, useCallback } from "react";

import ClipLoader from "react-spinners/ClipLoader";
import AppWidgetSummary from "./AppWidgetSummary";
import { Avatar, List, Skeleton, Switch } from "antd";

// ----------------------------------------------------------------------
var sum1 = 0;

SetIncomByMonthOverView.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
  chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const chartLabels1 = [
  "01/01/2004",
  "02/01/2004",
  "03/01/2004",
  "04/01/2004",
  "05/01/2004",
  "06/01/2004",
  "07/01/2004",
  "08/01/2004",
  "09/01/2004",
  "10/01/2004",
  "11/01/2004",
  "12/01/2004",
  //       '01/01/2004',
  //        '01/01/2004',
];

export default function SetIncomByMonthOverView({
  title,
  subheader,
  chartLabels,
  chartData,
  ...other
}) {
  const [array, setArray] = useState([]);

  const now = new Date();
  // const [DaysPerCurrentMonth, setDaysPerCurrentMonth] = useState([]);
  // Tota number of days in current month
  const totalDays = new Date(
    now.getFullYear(),
    now.getMonth() + 1,
    0
  ).getDate();

  // Today's day
  const today = now.getDate();

  const month = now.getMonth() + 1;
  // Remaining days of the month
  const remainingDays = totalDays - today;
  const DaysPerCurrentMonth = [];
  for (var i = 1; i < totalDays + 1; i++) {
    DaysPerCurrentMonth.push(String(i) + "/" + month);
  }

  useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [O2rrevenue, setO2rRevenue] = useState([]);
  const [SumDaily, setSumDaily] = useState([]);
  const [MySum, setMySum] = useState([]);

  const [revenue, setrevenue] = useState([]);

  const chartOptions = useChart({
    plotOptions: { bar: { columnWidth: "30%" } },
    fill: { type: chartData.map((i) => i.fill) },
    labels: DaysPerCurrentMonth,
    xaxis: { type: "date" },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== "undefined") {
            return `${y
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} $`;
          }
          return y;
        },
      },
    },
  });

  return (
    <>
      {!isLoading ? (
        <>
          <Skeleton active avatar>
            <List.Item.Meta
              avatar={<Avatar src={""} />}
              title={<a href={""}>{""}</a>}
              description={""}
            />
            {""}
          </Skeleton>
        </>
      ) : (
        <>
          <Card {...other}>
            <CardHeader title={title} subheader={subheader} />

            <Box sx={{ p: 3, pb: 1 }} dir="ltr">
              <h5>NetIncome by Dollar $</h5>

              <ReactApexChart
                type="line"
                series={chartData}
                options={chartOptions}
                height={650}
              />
            </Box>
            <h5
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                color: "black",
              }}
            >
              Days
            </h5>
          </Card>
        </>
      )}
    </>
  );
}

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { MyUrl } from "../../../App";
import moment from "moment";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";
import { Divider } from "antd";
// import IncomeFirstTable from "./IncomeFirstTable";
// import IncomeGraph from "./IncomeGraph";
// import IncomeSecondTable from "./IncomeSecondTable";
import jwt_decode from "jwt-decode";
import Lottie from "lottie-react";
import UnboxtheBlackbox from "../../../UnboxtheBlackbox.json";
import loading33refresh from "../../../loading33refresh.json";
import loadingrefresh from "../../../loadingrefresh.json";
import refreshicon from "../../../refreshicon.json";
import reloadpage from "../../../reloadpage.json";
import MediaSourceTopSix from "./MediaSourceTopSix";
import MediaSourceDunots from "./MediaSourceDunots";
import MediaSourceGraphs from "./MediaSourceGraphs";
import MediaSourceTablePerformanceReport from "./MediaSourceTablePerformanceReport";
import MediaSourceTableSourceCompare from "./MediaSourceTableSourceCompare";

var PickedDate = 0;
var jwtToken = "";
var compName = "general";

var SendDataToDunots1 = [];
var SendDataToDunots2 = [];
var SendDataToDunots3 = [];

var SendDataToDunots4 = [];
var SendDataToDunots5 = [];
var SendDataToDunots6 = [];
var SendDataToDunots7 = [];
var SendDataToDunots8 = [];
var SendDataToDunots9 = [];
var SendDataToDunots10 = [];

var SendDataToDunots11 = [];
var SendDataToDunots12 = [];
var SendDataToDunots13 = [];
var SendDataToDunots14 = [];
var SendDataToDunots15 = [];

var combinedArray = [];
const styles = {
  root: {
    "&$checked": {
      color: "#3f51b5",
    },
  },
  checked: {},
  circle: {
    borderRadius: "50%",
    width: 20,
    height: 20,
    backgroundColor: "#fff",
    border: "2px solid #fff", // we can add color after solid
  },
  checkedIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    width: 20,
    height: 20,
    backgroundColor: "white",
  },
};
const CircleCheckbox = withStyles(styles)(({ classes, ...props }) => (
  <Checkbox
    {...props}
    classes={{
      root: classes.root,
      checked: classes.checked,
    }}
    icon={<span className={classes.circle} />}
    checkedIcon={
      <span className={classes.checkedIcon}>
        <CheckIcon style={{ fontSize: 16, color: "black" }} />
      </span>
    }
  />
));
export default function MediaSourceGetDataAndFilter() {
  console.log("compName");

  console.log(compName);
  const ChooceTable = ["Demand", "Supply"];
  const [CompColor, setCompColor] = useState(0);
  const [data7, setData7] = useState(["General", "CTV", "Mobile App"]);

  useEffect(() => {
    setChooceCompanies([]);
    RefreshFunc1();
    //RefreshFunc(Today, Today);
  }, []);
  const gridRef = useRef(); // Optional - for accessing Grid's API
  const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row
  function useWindowSize() {
    const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
    }, []);
    return size;
  }

  const [ColorOfButtonsMSTE, setColorOfButtonsMSTE] = useState(0);

  const [height, width] = useWindowSize();
  const source = axios.CancelToken.source();
  const items1 = sessionStorage.getItem("Token");
  const [CheckTypeOfUser, setCheckTypeOfUser] = useState("");
  const MyToken = JSON.parse(sessionStorage.getItem("Token"));
  const base64Url = MyToken.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const payload = JSON.parse(atob(base64));
  const expirationDate = new Date(payload.exp * 1000);
  const currentTime = new Date().getTime(); // Get the current time in milliseconds

  const RefreshFunc1 = async () => {
    // const items = sessionStorage.getItem("Token");
    const token = sessionStorage.getItem("Token");
    var parsed = JSON.parse(token);
    const decodedToken = jwt_decode(token);
    const role =
      decodedToken[
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
      ];
    setCheckTypeOfUser(role);
    jwtToken = JSON.parse(token);
    const base64Url = JSON.parse(token).split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const payload = JSON.parse(atob(base64));
    const expirationDate = new Date(payload.exp * 1000);
    const currentTime = new Date().getTime(); // Get the current time in milliseconds
    if (expirationDate < currentTime) {
      try {
        axios
          .post(
            `${MyUrl}/api/RefreshToken/refresh`,
            { accessToken: JSON.parse(token).replace("Bearer ", "") },
            { withCredentials: true }
          )
          .then((response) => {
            parsed = response.data.accessToken;
            const updatedObjString = JSON.stringify(response.data.accessToken);
            sessionStorage.setItem(
              "Token",
              JSON.stringify(response.data.accessToken)
            );
            GetDailyIncomeFunc();
            // GetMonthlyIncomeFunc(startDate, endDate);
            // GetDailyIncomeFunc1(startDate, endDate);
            //RefreshFunc(Today, Today);

            return () => {
              source.cancel("Component unmounted");
            };
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      const token = sessionStorage.getItem("Token");
      //var parsed = JSON.parse(items);
      jwtToken = JSON.parse(token);
      GetDailyIncomeFunc();
      //   GetMonthlyIncomeFunc(startDate, endDate);
      //   GetDailyIncomeFunc1(startDate, endDate);
      //RefreshFunc(Today, Today);
      return () => {
        source.cancel("Component unmounted");
      };
    }
  };
  const RefreshFunc = async (startDate, endDate) => {
    const token = sessionStorage.getItem("Token");
    //var parsed = JSON.parse(token);
    const decodedToken = jwt_decode(token);
    const role =
      decodedToken[
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
      ];
    setCheckTypeOfUser(role);
    jwtToken = JSON.parse(token);
    const base64Url = JSON.parse(token).split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const payload = JSON.parse(atob(base64));
    const expirationDate = new Date(payload.exp * 1000);
    const currentTime = new Date().getTime(); // Get the current time in milliseconds
    if (expirationDate < currentTime) {
      try {
        axios
          .post(
            `${MyUrl}/api/RefreshToken/refresh`,
            { accessToken: JSON.parse(token).replace("Bearer ", "") },
            { withCredentials: true }
          )
          .then((response) => {
            parsed = response.data.accessToken;
            const updatedObjString = JSON.stringify(response.data.accessToken);
            sessionStorage.setItem(
              "Token",
              JSON.stringify(response.data.accessToken)
            );
            // GetMonthlyIncomeFunc(startDate, endDate);
            // GetDailyIncomeFunc1(startDate, endDate);
            GetDailyTrafficSourceRevenue();
            GetMonthlyTrafficSourceRevenue();
            DunotRevenueBySource();
            DunotRevenueBySourceCTVandInApp();
            DunotRevenueBySourceIntegrationType();
            GraphgetTrafficSourceMediaCostImpAndRevByDate();
            GetOverallPerformanceReport();
            getSourceCompareReport(compName);

            return () => {
              source.cancel("Component unmounted");
            };
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      const items = sessionStorage.getItem("Token");
      var parsed = JSON.parse(items);
      jwtToken = JSON.parse(items); //parsed
      GetDailyTrafficSourceRevenue();
      GetMonthlyTrafficSourceRevenue();
      DunotRevenueBySource();
      DunotRevenueBySourceCTVandInApp();
      DunotRevenueBySourceIntegrationType();
      GraphgetTrafficSourceMediaCostImpAndRevByDate();
      GetOverallPerformanceReport();
      getSourceCompareReport(compName);

      //   GetMonthlyIncomeFunc(startDate, endDate);
      //   GetDailyIncomeFunc1(startDate, endDate);
      return () => {
        source.cancel("Component unmounted");
      };
    }
  };

  const RefreshFunc2 = async (startDate, endDate) => {
    const token = sessionStorage.getItem("Token");
    //var parsed = JSON.parse(token);
    const decodedToken = jwt_decode(token);
    const role =
      decodedToken[
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
      ];
    setCheckTypeOfUser(role);
    jwtToken = JSON.parse(token);
    const base64Url = JSON.parse(token).split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const payload = JSON.parse(atob(base64));
    const expirationDate = new Date(payload.exp * 1000);
    const currentTime = new Date().getTime(); // Get the current time in milliseconds
    if (expirationDate < currentTime) {
      try {
        axios
          .post(
            `${MyUrl}/api/RefreshToken/refresh`,
            { accessToken: JSON.parse(token).replace("Bearer ", "") },
            { withCredentials: true }
          )
          .then((response) => {
            parsed = response.data.accessToken;
            const updatedObjString = JSON.stringify(response.data.accessToken);
            sessionStorage.setItem(
              "Token",
              JSON.stringify(response.data.accessToken)
            );
            // GetMonthlyIncomeFunc(startDate, endDate);
            // GetDailyIncomeFunc1(startDate, endDate);

            getSourceCompareReport(compName);

            return () => {
              source.cancel("Component unmounted");
            };
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      const items = sessionStorage.getItem("Token");
      var parsed = JSON.parse(items);
      jwtToken = JSON.parse(items); //parsed

      getSourceCompareReport(compName);

      //   GetMonthlyIncomeFunc(startDate, endDate);
      //   GetDailyIncomeFunc1(startDate, endDate);
      return () => {
        source.cancel("Component unmounted");
      };
    }
  };
  const [chooceCompanies, setChooceCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState("");
  const [isLoading1, setIsLoading1] = useState(true);
  const [data1, setData1] = useState("");
  const [isLoading2, setIsLoading2] = useState(true);
  const [data2, setData2] = useState("");

  var [value, setValue] = React.useState(startDate);
  var [value1, setValue1] = React.useState(endDate);
  const handleChange3 = (newValue) => {
    setValue(newValue);
    startDate = `${newValue.$y}-${newValue.$M + 1}-${newValue.$D}`;
  };

  const handleChange4 = (newValue1) => {
    setValue1(newValue1);
    endDate = `${newValue1.$y}-${newValue1.$M + 1}-${newValue1.$D}`;
  };
  const [isClicked, setIsClicked] = useState(false);
  //when pick today
  const Today = moment().add("days").format("YYYY-MM-DD");
  //when pick yesterday
  const Yesterday = moment().subtract(1, "day").format("YYYY-MM-DD");
  //when pick 7 days
  const SevenDays = moment().subtract(7, "day").format("YYYY-MM-DD");

  var LastDayInMonth = moment(LastDayInMonth)
    .endOf("month")
    .format("YYYY-MM-DD");

  var FirstDayInMonth = moment(FirstDayInMonth)
    .startOf("month")
    .format("YYYY-MM-DD");

  const currentDate = moment();
  const daysInCurrentMonth = currentDate.daysInMonth();
  const [selectedObject, setSelectedObject] = useState(true);
  const [count, setCount] = useState(0);

  const handleCheckboxChange = (event, productName) => {
    if (event.target.checked) {
      // setIsLoading2(true);
      // setTimeout(() => {
      //   // setCount(count + 1);
      //   setIsLoading2(false);
      // }, 0);

      setChooceCompanies([...chooceCompanies, productName]); // add to array
      // for (let i = 0; i < SendDataToFirstTable1.length; i++) {
      //   if (SendDataToFirstTable1[i].id === productName) {
      //     console.log(SendDataToFirstTable1[i]);
      //     SendDataToFirstTable.push(SendDataToFirstTable1[i]);
      //     //console.table(SendDataToFirstTable); // see table in console
      //   }
      // }
      // for (let i = 0; i < SendDataToIncomeGraph1.length; i++) {
      //   if (SendDataToIncomeGraph1[i].id === productName) {
      //     console.log(SendDataToIncomeGraph1[i]);
      //     SendDataToIncomeGraph.push(SendDataToIncomeGraph1[i]);
      //   }
      // }
      //setIsLoading2(false);
    } else {
      setChooceCompanies(
        chooceCompanies.filter((name) => name !== productName)
      ); // remove from array

      // SendDataToFirstTable = SendDataToFirstTable.filter(
      //   (item) => item.id !== productName
      // );
      // SendDataToIncomeGraph = SendDataToIncomeGraph.filter(
      //   (item) => item.id !== productName
      // );
      // SendDataToSecondTable = SendDataToSecondTable.filter(
      //   (item) => item.id !== productName
      // );
      //   console.log(SendDataToSecondTable);
      //   console.log("SendDataToIncomeGraph1");
      //   console.log(SendDataToSecondTable1);
    }
  };

  //For CheckBox
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  //End for CheckBox

  //Select Days
  const [days, setDays] = React.useState(0);
  const handleChangeDays = (event) => {
    setDays(event.target.value);
    // PickDatesToGetData(event.target.value);
  };
  //End select Days

  const [OpenRange, SetOpenRange] = useState(false);
  var startDate = Today;
  var endDate = Today;
  async function PickDatesToGetData(event) {
    setDays(event);
    if (event === 0) {
      SetOpenRange(false);
      startDate = Today;
      endDate = Today;
      await RefreshFunc(startDate, endDate);
      // GetDailyIncomeFunc1(Today, Today);
      // GetMonthlyIncomeFunc(Today, Today);
    } else if (event === 1) {
      SetOpenRange(false);
      startDate = Yesterday;
      endDate = Yesterday;
      await RefreshFunc(startDate, endDate);
    } else if (event === 2) {
      SetOpenRange(false);
      startDate = SevenDays;
      endDate = Yesterday;
      await RefreshFunc(startDate, endDate);
    } else if (event === 3) {
      SetOpenRange(false);
      startDate = FirstDayInMonth;
      endDate = Yesterday;
      await RefreshFunc(startDate, endDate);
    } else if (event === 4) {
      if (value.$D === undefined) {
        startDate = Today;
      } else {
        startDate = `${value.$y}-${value.$M + 1}-${value.$D}`;
      }
      if (value1.$D === undefined) {
        endDate = Today;
      } else {
        endDate = `${value1.$y}-${value1.$M + 1}-${value1.$D}`;
      }
      SetOpenRange(true);
      await RefreshFunc(startDate, endDate);
    }
  }

  async function PickDatesToGetData1(event) {
    setDays(event);
    if (event === 0) {
      SetOpenRange(false);
      startDate = Today;
      endDate = Today;
      await RefreshFunc2(startDate, endDate);
      // GetDailyIncomeFunc1(Today, Today);
      // GetMonthlyIncomeFunc(Today, Today);
    } else if (event === 1) {
      SetOpenRange(false);
      startDate = Yesterday;
      endDate = Yesterday;
      await RefreshFunc(startDate, endDate);
    } else if (event === 2) {
      SetOpenRange(false);
      startDate = SevenDays;
      endDate = Yesterday;
      await RefreshFunc2(startDate, endDate);
    } else if (event === 3) {
      SetOpenRange(false);
      startDate = FirstDayInMonth;
      endDate = Yesterday;
      await RefreshFunc2(startDate, endDate);
    } else if (event === 4) {
      if (value.$D === undefined) {
        startDate = Today;
      } else {
        startDate = `${value.$y}-${value.$M + 1}-${value.$D}`;
      }
      if (value1.$D === undefined) {
        endDate = Today;
      } else {
        endDate = `${value1.$y}-${value1.$M + 1}-${value1.$D}`;
      }
      SetOpenRange(true);
      await RefreshFunc2(startDate, endDate);
    }
  }
  const GetDailyIncomeFunc = () => {
    const items = sessionStorage.getItem("Token");
    var parsed = JSON.parse(items);
    setIsLoading(true);
    try {
      let base64 = require("base-64"); // install it before use from npm i base-64
      axios
        .get(`${MyUrl}/api/Account/userSeats`, {
          headers: {
            Authorization: `Bearer ${JSON.parse(items).replace("Bearer ", "")}`,
          },
          cancelToken: source.token,
        })
        .then((res) => {
          const data = res.data;
          setData(data);
          //console.log(data);
          for (let i = 0; i < data.length; i++) {
            setChooceCompanies((prevCompanies) => [
              ...prevCompanies,
              data[i].id,
            ]);
          }
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const [IsLoading3, setIsLoading3] = useState(true);
  const [IsLoading4, setIsLoading4] = useState(true);
  const [IsLoading5, setIsLoading5] = useState(true);
  const [IsLoading6, setIsLoading6] = useState(true);
  const [IsLoading7, setIsLoading7] = useState(true);
  const [IsLoading8, setIsLoading8] = useState(true);
  const [IsLoading9, setIsLoading9] = useState(true);
  const [IsLoading10, setIsLoading10] = useState(true);

  const [
    GetDailyTrafficSourceRevenueData,
    setGetDailyTrafficSourceRevenueData,
  ] = useState("");
  const [
    GetMonthlyTrafficSourceRevenueData,
    setGetMonthlyTrafficSourceRevenueData,
  ] = useState("");

  const [DailyME, setDailyME] = useState("");
  const [DailyMS, setDailyMS] = useState("");
  const [DailyMT, setDailyMT] = useState("");
  const [DailyEmpty, setDailyEmpty] = useState("");

  const [MonthlyME, setMonthlyME] = useState("");
  const [MonthlyMS, setMonthlyMS] = useState("");
  const [MonthlyMT, setMonthlyMT] = useState("");
  const [MonthlyEmpty, setMonthlyEmpty] = useState("");

  const IdsToGetData = chooceCompanies
    .map((id) => "&ids=" + String(id))
    .join("");
  const GetDailyTrafficSourceRevenue = () => {
    setDailyME(0);
    setDailyMS(0);
    setDailyMT(0);
    setDailyEmpty(0);
    const items = sessionStorage.getItem("Token");
    var parsed = JSON.parse(items);

    setIsLoading3(true);
    try {
      let base64 = require("base-64"); // install it before use from npm i base-64
      axios
        .get(
          `${MyUrl}/api/TrafficSource/getDailyTrafficSourceRevenue?${IdsToGetData}`,
          {
            headers: {
              Authorization: `Bearer ${JSON.parse(items).replace(
                "Bearer ",
                ""
              )}`,
            },
            cancelToken: source.token,
          }
        )
        .then((res) => {
          const data = res.data;
          //  console.log("data");
          //   console.log(data);

          setGetDailyTrafficSourceRevenueData(data);
          //console.log(data.responseData);

          //console.log(data.responseData[1].revenue);

          // .toFixed(2)
          //       .toString()
          //       .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          for (let i = 0; i < data.responseData.length; i++) {
            if (data.responseData[i].trafficSourceType === "ME") {
              setDailyME(
                data.responseData[i].revenue
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              );
            }
            if (data.responseData[i].trafficSourceType === "MS") {
              setDailyMS(
                data.responseData[i].revenue
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              );
            }
            if (data.responseData[i].trafficSourceType === "MT") {
              setDailyMT(
                data.responseData[i].revenue
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              );
            }

            if (data.responseData[i].trafficSourceType === "") {
              setDailyEmpty(
                data.responseData[i].revenue
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              );
            }
          }
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading3(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const GetMonthlyTrafficSourceRevenue = () => {
    setMonthlyME(0);
    setMonthlyMS(0);
    setMonthlyMT(0);
    setMonthlyEmpty(0);
    const items = sessionStorage.getItem("Token");
    var parsed = JSON.parse(items);
    setIsLoading4(true);
    try {
      let base64 = require("base-64"); // install it before use from npm i base-64
      axios
        .get(
          `${MyUrl}/api/TrafficSource/getMonthlyTrafficSourceRevenue?${IdsToGetData}`,
          {
            headers: {
              Authorization: `Bearer ${JSON.parse(items).replace(
                "Bearer ",
                ""
              )}`,
            },
            cancelToken: source.token,
          }
        )
        .then((res) => {
          const data = res.data;
          //  console.log("data1");
          //  console.log(data);

          setGetMonthlyTrafficSourceRevenueData(data);
          for (let i = 0; i < data.responseData.length; i++) {
            if (data.responseData[i].trafficSourceType === "ME") {
              setMonthlyME(
                data.responseData[i].revenue
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              );
            }
            if (data.responseData[i].trafficSourceType === "MS") {
              setMonthlyMS(
                data.responseData[i].revenue
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              );
            }
            if (data.responseData[i].trafficSourceType === "MT") {
              setMonthlyMT(
                data.responseData[i].revenue
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              );
            }
            if (data.responseData[i].trafficSourceType === "MT") {
              setMonthlyMT(
                data.responseData[i].revenue
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              );
            }
            if (data.responseData[i].trafficSourceType === "") {
              setMonthlyEmpty(
                data.responseData[i].revenue
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              );
            }
          }
          //console.log(data);
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading4(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const DunotRevenueBySource = () => {
    SendDataToDunots1 = [];
    var ObjSendDataToGlobalDonuts = [];
    const items = sessionStorage.getItem("Token");
    var parsed = JSON.parse(items);
    setIsLoading5(true);
    try {
      let base64 = require("base-64"); // install it before use from npm i base-64
      axios
        .get(
          `${MyUrl}/api/TrafficSource/getTrafficSourceRevenueBetweenDates?startDate=${startDate}&endDate=${endDate}&${IdsToGetData}`,
          {
            headers: {
              Authorization: `Bearer ${JSON.parse(items).replace(
                "Bearer ",
                ""
              )}`,
            },
            cancelToken: source.token,
          }
        )
        .then((res) => {
          const data = res.data;
          //  console.log("data2");
          //console.log(data);
          for (let i = 0; i < data.responseData.length; i++) {
            ObjSendDataToGlobalDonuts = {
              name: data.responseData[i].trafficSourceType,
              value: Number(data.responseData[i].revenue.toFixed(2)),
            };
            SendDataToDunots1.push(ObjSendDataToGlobalDonuts);
          }
          //console.log(data.responseData[i].trafficSourceType);
          //console.log(SendDataToDunots1);
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading5(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const DunotRevenueBySourceCTVandInApp = () => {
    var ObjSendDataToGlobalDonuts1 = [];
    var ObjSendDataToGlobalDonuts2 = [];

    SendDataToDunots2 = [];
    SendDataToDunots3 = [];
    const items = sessionStorage.getItem("Token");
    var parsed = JSON.parse(items);
    setIsLoading6(true);
    try {
      let base64 = require("base-64"); // install it before use from npm i base-64

      axios
        .get(
          `${MyUrl}/api/TrafficSource/getTrafficSourceRevenueByEnvironment?startDate=${startDate}&endDate=${endDate}&${IdsToGetData}`,
          {
            headers: {
              Authorization: `Bearer ${JSON.parse(items).replace(
                "Bearer ",
                ""
              )}`,
            },
            cancelToken: source.token,
          }
        )
        .then((res) => {
          const data = res.data;
          //   console.log("CTVandInApp");
          // console.log(data);
          for (let i = 0; i < data.responseData.length; i++) {
            if (data.responseData[i].environment === "CTV") {
              //  console.log("SendDataToDunots2" + i);
              ObjSendDataToGlobalDonuts1 = {
                name: data.responseData[i].trafficSourceType,
                value: Number(data.responseData[i].revenue.toFixed(2)),
              };
              SendDataToDunots2.push(ObjSendDataToGlobalDonuts1);
            }
            if (data.responseData[i].environment === "Mobile App") {
              // console.log("SendDataToDunots3" + i);
              ObjSendDataToGlobalDonuts2 = {
                name: data.responseData[i].trafficSourceType,
                value: Number(data.responseData[i].revenue.toFixed(2)),
              };
              SendDataToDunots3.push(ObjSendDataToGlobalDonuts2);
            }
          }
          // console.log("SendDataToDunots2");
          // console.log(SendDataToDunots2);
          // console.log("SendDataToDunots3");
          // console.log(SendDataToDunots3);
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading6(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const DunotRevenueBySourceIntegrationType = () => {
    var ObjSendDataToGlobalDonuts1 = [];
    var ObjSendDataToGlobalDonuts2 = [];

    SendDataToDunots4 = [];
    SendDataToDunots5 = [];
    const items = sessionStorage.getItem("Token");
    var parsed = JSON.parse(items);
    setIsLoading7(true);
    try {
      let base64 = require("base-64"); // install it before use from npm i base-64

      axios
        .get(
          `${MyUrl}/api/TrafficSource/getTrafficSourceRevenueByIntegrationType?startDate=${startDate}&endDate=${endDate}&${IdsToGetData}`,
          {
            headers: {
              Authorization: `Bearer ${JSON.parse(items).replace(
                "Bearer ",
                ""
              )}`,
            },
            cancelToken: source.token,
          }
        )
        .then((res) => {
          const data = res.data;
          //console.log("IntegrationType");
          //console.log(data);
          for (let i = 0; i < data.length; i++) {
            ObjSendDataToGlobalDonuts1 = {
              name: data[i].integrationType,
              value: Number(data[i].revenue),
            };
            SendDataToDunots4.push(ObjSendDataToGlobalDonuts1);
          }
          //     console.log(SendDataToDunots4);
          // for (let i = 0; i < data.responseData.length; i++) {
          //   if (data.responseData[i].environment === "CTV") {
          //     console.log("SendDataToDunots2" + i);
          //     ObjSendDataToGlobalDonuts1 = {
          //       name: data.responseData[i].trafficSourceType,
          //       value: Number(data.responseData[i].revenue.toFixed(2)),
          //     };
          //     SendDataToDunots2.push(ObjSendDataToGlobalDonuts1);
          //   }
          //   if (data.responseData[i].environment === "Mobile App") {
          //     console.log("SendDataToDunots3" + i);
          //     ObjSendDataToGlobalDonuts2 = {
          //       name: data.responseData[i].trafficSourceType,
          //       value: Number(data.responseData[i].revenue.toFixed(2)),
          //     };
          //     SendDataToDunots3.push(ObjSendDataToGlobalDonuts2);
          //   }
          // }

          //console.log("SendDataToDunots3");

          //console.log(SendDataToDunots3);
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading7(false);
        });
    } catch (error) {
      console.log(error);
    }
  };
  var LastDayInMonth = moment(LastDayInMonth)
    .endOf("month")
    .format("YYYY-MM-DD");

  var FirstDayInMonth = moment(FirstDayInMonth)
    .startOf("month")
    .format("YYYY-MM-DD");
  const GraphgetTrafficSourceMediaCostImpAndRevByDate = () => {
    var ObjSendDataToGlobalDonuts1 = [];
    var ObjSendDataToGlobalDonuts2 = [];
    var ObjSendDataToGlobalDonuts3 = [];

    SendDataToDunots6 = [];
    SendDataToDunots7 = [];
    SendDataToDunots8 = [];

    const items = sessionStorage.getItem("Token");
    var parsed = JSON.parse(items);
    setIsLoading8(true);
    try {
      let base64 = require("base-64"); // install it before use from npm i base-64

      axios
        .get(
          `${MyUrl}/api/TrafficSource/getTrafficSourceMediaCostImpAndRevByDate?startDate=${FirstDayInMonth}&endDate=${LastDayInMonth}&${IdsToGetData}`,
          {
            headers: {
              Authorization: `Bearer ${JSON.parse(items).replace(
                "Bearer ",
                ""
              )}`,
            },
            cancelToken: source.token,
          }
        )
        .then((res) => {
          const data = res.data;

          //   console.log(data);
          for (
            let i = 0;
            i < data.responseData.trafficSrcImpressionsDTOs.length;
            i++
          ) {
            // console.log(
            //   data.responseData.trafficSrcMediaCostDTOs[i].impressions
            // );
          }
          for (
            let i = 0;
            i < data.responseData.trafficSrcMediaCostDTOs.length;
            i++
          ) {
            ObjSendDataToGlobalDonuts1 = {
              year: data.responseData.trafficSrcMediaCostDTOs[i].date,
              value: Number(
                data.responseData.trafficSrcMediaCostDTOs[i].mediaCost.toFixed(
                  2
                )
              ),
              category:
                data.responseData.trafficSrcMediaCostDTOs[i].trafficSourceType,
            };
            SendDataToDunots6.push(ObjSendDataToGlobalDonuts1);
          }

          for (
            let i = 0;
            i < data.responseData.trafficSrcImpressionsDTOs.length;
            i++
          ) {
            ObjSendDataToGlobalDonuts2 = {
              year: data.responseData.trafficSrcImpressionsDTOs[i].date,
              value: Number(
                data.responseData.trafficSrcImpressionsDTOs[
                  i
                ].impressions.toFixed(2)
              ),
              category:
                data.responseData.trafficSrcImpressionsDTOs[i]
                  .trafficSourceType,
            };
            SendDataToDunots7.push(ObjSendDataToGlobalDonuts2);
          }

          for (
            let i = 0;
            i < data.responseData.trafficSrcRevenueDTOs.length;
            i++
          ) {
            ObjSendDataToGlobalDonuts3 = {
              year: data.responseData.trafficSrcRevenueDTOs[i].date,
              value: Number(
                data.responseData.trafficSrcRevenueDTOs[i].revenue.toFixed(2)
              ),
              category:
                data.responseData.trafficSrcRevenueDTOs[i].trafficSourceType,
            };
            SendDataToDunots8.push(ObjSendDataToGlobalDonuts3);
          }
          //console.log(SendDataToDunots8);
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading8(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const GetOverallPerformanceReport = () => {
    var ObjSendDataToGlobalDonuts1 = [];
    var ObjSendDataToGlobalDonuts2 = [];

    SendDataToDunots9 = [];
    SendDataToDunots10 = [];
    const items = sessionStorage.getItem("Token");
    var parsed = JSON.parse(items);
    setIsLoading9(true);
    try {
      let base64 = require("base-64"); // install it before use from npm i base-64

      axios
        .get(
          `${MyUrl}/api/TrafficSource/getOverallPerformanceReport?&${IdsToGetData}&startDate=${startDate}&endDate=${endDate}`,
          {
            headers: {
              Authorization: `Bearer ${JSON.parse(items).replace(
                "Bearer ",
                ""
              )}`,
            },
            cancelToken: source.token,
          }
        )
        .then((res) => {
          const data = res.data;
          // console.log("getOverallPerformanceReport");
          // console.log(data);
          SendDataToDunots9 = data.responseData;
          //console.log(SendDataToDunots9);

          for (let i = 0; i < data.responseData.length; i++) {
            // console.log(i);
            ObjSendDataToGlobalDonuts1 = {
              date: data.responseData[i].date,
              addReqInMill: data.responseData[i].addReqInMill
                .toFixed(3)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              addRequests: data.responseData[i].addRequests
                .toFixed(0)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              eCpm: data.responseData[i].eCpm.toFixed(3),
              environment: data.responseData[i].environment,
              impressions: data.responseData[i].impressions
                .toFixed(0)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              revenue: data.responseData[i].revenue
                .toFixed(3)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            };
            SendDataToDunots10.push(ObjSendDataToGlobalDonuts1);
          }
          //console.log(SendDataToDunots10);
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading9(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getSourceCompareReport = (compName1) => {
    var ObjSendDataToGlobalDonuts1 = [];
    var ObjSendDataToGlobalDonuts2 = [];
    var ObjSendDataToGlobalDonuts3 = [];
    var ObjSendDataToGlobalDonuts4 = [];

    SendDataToDunots11 = [];
    SendDataToDunots12 = [];
    SendDataToDunots13 = [];
    SendDataToDunots14 = [];
    SendDataToDunots15 = [];

    const items = sessionStorage.getItem("Token");
    var parsed = JSON.parse(items);
    setIsLoading10(true);
    try {
      let base64 = require("base-64"); // install it before use from npm i base-64
      //getSourceCompareReport?&startDate=2023-08-18&endDate=2023-08-19
      axios
        .get(
          `${MyUrl}/api/TrafficSource/getSourceCompareReport?${IdsToGetData}&environment=${compName1}&startDate=${startDate}&endDate=${endDate}`,
          {
            headers: {
              Authorization: `Bearer ${JSON.parse(items).replace(
                "Bearer ",
                ""
              )}`,
            },
            cancelToken: source.token,
          }
        )
        .then((res) => {
          const data = res.data;
          console.log("getSourceCompareReport");
          console.log(data);
          for (
            let i = 0;
            i < data.responseData.trafficSrcCompareDTOs.length;
            i++
          ) {
            // console.log(i);
            if (
              data.responseData.trafficSrcCompareDTOs[i].trafficSourceType ===
              "MT"
            ) {
              ObjSendDataToGlobalDonuts1 = {
                MTaddReqInMill:
                  data.responseData.trafficSrcCompareDTOs[i].addReqInMill,
                MTaddRequests:
                  data.responseData.trafficSrcCompareDTOs[i].addRequests,
                MTdate: data.responseData.trafficSrcCompareDTOs[i].date,

                MTfillRateInProcent:
                  data.responseData.trafficSrcCompareDTOs[i].fillRateInProcent,
                MTimpressions:
                  data.responseData.trafficSrcCompareDTOs[i].impressions,
                MTrevenue: data.responseData.trafficSrcCompareDTOs[i].revenue,
                MTtrafficSourceType:
                  data.responseData.trafficSrcCompareDTOs[i].trafficSourceType,
              };
              //  SendDataToDunots14.push(ObjSendDataToGlobalDonuts1);
              SendDataToDunots14.push(ObjSendDataToGlobalDonuts1);
            }

            if (
              data.responseData.trafficSrcCompareDTOs[i].trafficSourceType ===
              "ME"
            ) {
              ObjSendDataToGlobalDonuts2 = {
                MEaddReqInMill:
                  data.responseData.trafficSrcCompareDTOs[i].addReqInMill,
                MEaddRequests:
                  data.responseData.trafficSrcCompareDTOs[i].addRequests,
                MEdate: data.responseData.trafficSrcCompareDTOs[i].date,
                MEfillRateInProcent:
                  data.responseData.trafficSrcCompareDTOs[i].fillRateInProcent,
                MEimpressions:
                  data.responseData.trafficSrcCompareDTOs[i].impressions,
                MErevenue: data.responseData.trafficSrcCompareDTOs[i].revenue,
                MEtrafficSourceType:
                  data.responseData.trafficSrcCompareDTOs[i].trafficSourceType,
              };
              //  SendDataToDunots14.push(ObjSendDataToGlobalDonuts2);
              SendDataToDunots14.push(ObjSendDataToGlobalDonuts2);
            }
            if (
              data.responseData.trafficSrcCompareDTOs[i].trafficSourceType ===
              "MS"
            ) {
              ObjSendDataToGlobalDonuts3 = {
                MSaddReqInMill:
                  data.responseData.trafficSrcCompareDTOs[i].addReqInMill,
                MSaddRequests:
                  data.responseData.trafficSrcCompareDTOs[i].addRequests,
                MSdate: data.responseData.trafficSrcCompareDTOs[i].date,
                MSfillRateInProcent:
                  data.responseData.trafficSrcCompareDTOs[i].fillRateInProcent,
                MSimpressions:
                  data.responseData.trafficSrcCompareDTOs[i].impressions,
                MSrevenue: data.responseData.trafficSrcCompareDTOs[i].revenue,
                MstrafficSourceType:
                  data.responseData.trafficSrcCompareDTOs[i].trafficSourceType,
              };
              SendDataToDunots14.push(ObjSendDataToGlobalDonuts3);
            }

            // console.log(ObjSendDataToGlobalDonuts1);
            // console.log(ObjSendDataToGlobalDonuts2);
            // console.log(ObjSendDataToGlobalDonuts3);
          }

          // console.log(ObjSendDataToGlobalDonuts1);
          // console.log(ObjSendDataToGlobalDonuts2);
          // console.log(ObjSendDataToGlobalDonuts3);

          // for (let i = 0; i < SendDataToDunots14.length; i++) {
          //   SendDataToDunots15.push(SendDataToDunots14[i]);
          //   console.log(SendDataToDunots15[i]);
          // }

          // // SendDataToDunots14.push(SendDataToDunots11);
          // // SendDataToDunots14.push(SendDataToDunots12);
          // // SendDataToDunots14.push(SendDataToDunots13);
          console.log(SendDataToDunots14);

          combinedArray = [];

          // // Iterate through the dataArray in chunks of 3
          for (let i = 0; i < SendDataToDunots14.length; i += 3) {
            const obj1 = SendDataToDunots14[i];
            const obj2 = SendDataToDunots14[i + 1];
            const obj3 = SendDataToDunots14[i + 2];

            // Check if all three objects are available
            if (obj1 && obj2 && obj3) {
              // const combinedObj = {
              //   date: obj1.date,
              //   combinedProperties: { ...obj1, ...obj2, ...obj3 },
              // };

              combinedArray.push({ ...obj1, ...obj2, ...obj3 });
            }
          }

          console.log(combinedArray);
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading10(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const dataArray = [
    {
      date1: "2023-08-22",
      trafficSourceType1: "ME",
      impressions1: 112576,
      addRequests1: 649885104,
      revenue1: 523.2824965016916,
    },
    {
      date2: "2023-08-22",
      trafficSourceType2: "MS",
      impressions2: 1468592,
      addRequests2: 9624859954,
      revenue2: 5329.358354151715,
    },
    {
      date: "2023-08-22",
      trafficSourceType: "MT",
      impressions: 101254,
      addRequests: 1090508933,
      revenue: 279.4474997697398,
    },

    {
      date1: "2023-08-23",
      trafficSourceType1: "ME1",
      impressions1: 112576,
      addRequests1: 649885104,
      revenue1: 523.2824965016916,
    },
    {
      date2: "2023-08-23",
      trafficSourceType2: "MS1",
      impressions2: 1468592,
      addRequests2: 9624859954,
      revenue2: 5329.358354151715,
    },
    {
      date: "2023-08-23",
      trafficSourceType: "MT1",
      impressions: 101254,
      addRequests: 1090508933,
      revenue: 279.4474997697398,
    },
    // ... more objects ...
  ];
  // const Try = () => {
  //   const combinedArray = [];

  //   // Iterate through the dataArray in chunks of 3
  //   for (let i = 0; i < dataArray.length; i += 3) {
  //     const obj1 = dataArray[i];
  //     const obj2 = dataArray[i + 1];
  //     const obj3 = dataArray[i + 2];

  //     // Check if all three objects are available
  //     if (obj1 && obj2 && obj3) {
  //       const combinedObj = {
  //         date: obj1.date,
  //         combinedProperties: { ...obj1, ...obj2, ...obj3 },
  //       };
  //       combinedArray.push(combinedObj);
  //     }
  //   }

  //   console.log(combinedArray);
  // };
  useEffect(() => {
    GetDailyTrafficSourceRevenue();
    GetMonthlyTrafficSourceRevenue();
    DunotRevenueBySource();
    DunotRevenueBySourceCTVandInApp();
    DunotRevenueBySourceIntegrationType();
    GraphgetTrafficSourceMediaCostImpAndRevByDate();
    GetOverallPerformanceReport();
    getSourceCompareReport(compName);
    //  Try();
  }, []);
  return (
    <div style={{ paddingLeft: "2.5%", paddingRight: "1%", paddingTop: 30 }}>
      <h2>Select Products:</h2>
      <div></div>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            overflowX: width > 766 ? "auto" : "scroll",
          }}
        >
          {" "}
          <Skeleton
            animation="wave"
            width={750}
            height={80}
            borderRadius={100}
          />
        </div>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                overflowX: width > 766 ? "" : "scroll",
              }}
            >
              {Array.isArray(data) ? (
                data &&
                data.map((responseData) => (
                  <div>
                    <div
                      key={responseData.id}
                      style={{
                        display: "flex",
                        background: responseData.color,
                        borderRadius: 100,
                        justifyContent: "center",
                        alignItems: "center",
                        margin: 3,
                        paddingRight: 5,
                        height: 40,
                      }}
                    >
                      <CircleCheckbox
                        //key={responseData.id}
                        //onClick={() => setSelectedObject(responseData)}
                        checked={chooceCompanies.includes(responseData.id)}
                        onChange={(event) =>
                          handleCheckboxChange(event, responseData.id)
                        }
                      />
                      <label>{responseData.name}</label>
                    </div>
                  </div>
                ))
              ) : (
                <>
                  <h5>There is no companies!</h5>
                </>
              )}
              <button
                style={{
                  border: "none",
                  background: "white",
                  cursor: "pointer",
                  minWidth: 60,

                  // // alignItems: "center",
                  // // justifyContent: "center",
                  // background: "white",
                  // color: "white",
                  // border: "none",
                  // borderRadius: 50,
                  // cursor: "pointer",
                  // //scale: 1.5,
                  // //margin: 20,
                }}
                onClick={() => {
                  setIsLoading2(true);
                  setIsLoading1(true);
                  PickDatesToGetData(PickedDate);
                  setIsClicked(true);
                  setTimeout(() => setIsClicked(false), 1000);
                }}
              >
                <Lottie
                  loop={isClicked ? true : false}
                  autoplay={isClicked ? true : false}
                  animationData={reloadpage}
                  rendererSettings={{
                    preserveAspectRatio: "xMidYMid slice",
                    // scale: 1.5,
                    // width: 60,
                    // height: 60,
                    // // margin: 20,
                    display: "flex",
                    background: "white",
                    borderRadius: 100,
                    justifyContent: "center",
                    alignItems: "center",
                    margin: 3,
                    paddingRight: 5,
                    height: 40,
                    width: 60,
                    minWidth: 60,
                  }}
                  isPaused={isClicked}
                />
              </button>
            </div>
            {/* <button
              style={{
                cursor: "pointer",
                background: "#818cf8",
                borderRadius: 10,
                borderWidth: 0,
                color: "white",
              }}
              onClick={() => {
                PickDatesToGetData(PickedDate);
              }}
            >
              Call data
            </button> */}

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingRight: "1%",
              }}
            >
              <Box sx={{ minWidth: 150 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">days</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={days}
                    label="days"
                    onChange={handleChangeDays}
                  >
                    <MenuItem
                      sx={{ width: 200 }}
                      value={0}
                      onClick={() => {
                        PickedDate = 0;
                        PickDatesToGetData(0);
                      }}
                    >
                      Today
                    </MenuItem>
                    <MenuItem
                      value={1}
                      onClick={() => {
                        PickedDate = 1;
                        PickDatesToGetData(1);
                      }}
                    >
                      Yesterday
                    </MenuItem>
                    <MenuItem
                      value={2}
                      onClick={() => {
                        PickedDate = 2;
                        PickDatesToGetData(2);
                      }}
                    >
                      Last 7 Days
                    </MenuItem>
                    <MenuItem
                      value={3}
                      onClick={() => {
                        PickedDate = 3;
                        PickDatesToGetData(3);
                      }}
                    >
                      This Months
                    </MenuItem>
                    <div style={{ height: 1 }}>
                      <Divider />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                          label="From Date"
                          inputFormat="DD-MM-YYYY"
                          value={value}
                          onChange={handleChange3}
                          renderInput={(params) => <TextField {...params} />}
                        />
                        &nbsp;&nbsp;
                        <DesktopDatePicker
                          label="To Date"
                          inputFormat="DD-MM-YYYY"
                          value={value1}
                          onChange={handleChange4}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </div>

                    <br></br>

                    <MenuItem
                      value={4}
                      onClick={() => {
                        PickedDate = 4;
                        SetOpenRange(true);
                      }}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          variant="contained"
                          style={{
                            backgroundColor: "black",
                            textTransform: "none",
                            fontSize: "100%",
                            borderRadius: "12px",
                          }}
                          onClick={() => {
                            if (value === "" || value1 === "") {
                            } else {
                              SetOpenRange(false);
                              PickDatesToGetData(4);
                            }
                          }}
                        >
                          Custom Range
                        </Button>
                      </div>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
          </div>
        </>
      )}
      {/* <div style={{ paddingTop: "15px" }}>
        <button
          style={{
            //fontWeight: "bold",
            color: ColorOfButtonsMSTE === 0 ? "#5746af" : "black",
            border: "1px solid red",
            width: 80,
            height: 40,
            borderColor: ColorOfButtonsMSTE === 0 ? "#e4defc" : "#e5e5e5",
            borderWidth: 1,
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,
            background: ColorOfButtonsMSTE === 0 ? "#e4defc" : "#f5f5f5",
            cursor: "pointer",
          }}
          onClick={() => {
            setColorOfButtonsMSTE(0);
            //  console.log(ColorOfButtonsMSTE);
          }}
        >
          General
        </button>
        <button
          style={{
            color: ColorOfButtonsMSTE === 1 ? "#5746af" : "black",
            border: "1px solid red",
            width: 80,
            height: 40,
            borderColor: ColorOfButtonsMSTE === 1 ? "#e4defc" : "#e5e5e5",
            borderWidth: 1,
            background: ColorOfButtonsMSTE === 1 ? "#e4defc" : "#f5f5f5",
            cursor: "pointer",
          }}
          onClick={() => {
            setColorOfButtonsMSTE(1);
            //   console.log(ColorOfButtonsMSTE);
          }}
        >
          MS
        </button>
        <button
          style={{
            color: ColorOfButtonsMSTE === 2 ? "#5746af" : "black",
            border: "1px solid red",
            width: 80,
            height: 40,
            borderColor: ColorOfButtonsMSTE === 2 ? "#e4defc" : "#e5e5e5",
            borderWidth: 1,
            background: ColorOfButtonsMSTE === 2 ? "#e4defc" : "#f5f5f5",
            cursor: "pointer",
          }}
          onClick={() => {
            setColorOfButtonsMSTE(2);
            //    console.log(ColorOfButtonsMSTE);
          }}
        >
          MT
        </button>
        <button
          style={{
            color: ColorOfButtonsMSTE === 3 ? "#5746af" : "black",
            border: "1px solid red",
            width: 80,
            height: 40,
            borderColor: ColorOfButtonsMSTE === 3 ? "#e4defc" : "#e5e5e5",
            borderWidth: 1,
            background: ColorOfButtonsMSTE === 3 ? "#e4defc" : "#f5f5f5",
            cursor: "pointer",
            borderTopRightRadius: 10,
            borderBottomRightRadius: 10,
          }}
          onClick={() => {
            setColorOfButtonsMSTE(3);
            //      console.log(ColorOfButtonsMSTE);
          }}
        >
          ME
        </button>
      </div> */}
      {IsLoading3 && IsLoading4 === true ? (
        <>
          <h1>Loading...</h1>
        </>
      ) : (
        <>
          <MediaSourceTopSix
            DailyME={DailyME}
            DailyMS={DailyMS}
            DailyMT={DailyMT}
            DailyEmpty={DailyEmpty}
            MonthlyME={MonthlyME}
            MonthlyMS={MonthlyMS}
            MonthlyMT={MonthlyMT}
            MonthlyEmpty={MonthlyEmpty}
          />
        </>
      )}
      {IsLoading5 && IsLoading6 && IsLoading7 === true ? (
        <>
          <h1>Loading...</h1>
        </>
      ) : (
        <>
          <MediaSourceDunots
            SendDataToDunots1={SendDataToDunots1}
            SendDataToDunots2={SendDataToDunots2}
            SendDataToDunots3={SendDataToDunots3}
            SendDataToDunots4={SendDataToDunots4}
          />
        </>
      )}
      {IsLoading8 === true ? (
        <>
          <h1>Loading...</h1>
        </>
      ) : (
        <>
          <MediaSourceGraphs
            SendDataToDunots6={SendDataToDunots6}
            SendDataToDunots7={SendDataToDunots7}
            SendDataToDunots8={SendDataToDunots8}
          />
        </>
      )}

      {IsLoading9 === true ? (
        <>
          <h1>Loading...</h1>
        </>
      ) : (
        <>
          <div
            style={{
              flex: 1,
              alignItems: "flex-end",
              justifyContent: "center",
            }}
          >
            <span
              style={{
                color: "#1a1523",
                fontSize: 18,
                justifyContent: "center",
                alignItems: "flex-end",
                fontWeight: "bold",
              }}
            >
              Overall Performance Report
              {/* <span style={{ color: "#e4e2e4" }}> • </span>{" "}
                <span style={{ color: "#6f6e77" }}>{startDate}</span> */}
            </span>
          </div>
          <MediaSourceTablePerformanceReport
            SendDataToDunots10={SendDataToDunots10}
          />
        </>
      )}

      {IsLoading10 === true ? (
        <>
          <h1>Loading...</h1>
        </>
      ) : (
        <>
          <div
            style={{
              paddingTop: 5,
              paddingBottom: 25,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <div
              style={{
                flex: 1,
                alignItems: "flex-end",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  color: "#1a1523",
                  fontSize: 18,
                  justifyContent: "center",
                  alignItems: "flex-end",
                  fontWeight: "bold",
                }}
              >
                Source Compare
                {/* <span style={{ color: "#e4e2e4" }}> • </span>{" "}
                <span style={{ color: "#6f6e77" }}>{startDate}</span> */}
              </span>
            </div>
            <div
              style={{
                flex: 1,
                justifyContent: "flex-end",
                display: "flex",
                alignItems: "center",
              }}
            >
              {Array.isArray(data7) ? (
                data7 &&
                data7.map((responseData, i) => (
                  <div
                    style={{
                      background: "#f4f2f4",
                      borderTopLeftRadius: i === 0 ? 50 : 0,
                      borderBottomLeftRadius: i === 0 ? 50 : 0,
                      borderTopRightRadius: i === data7.length - 1 ? 50 : 0,
                      borderBottomRightRadius: i === data7.length - 1 ? 50 : 0,
                      height: 40,
                      alignItems: "center",
                      paddingLeft: i === 0 ? 3 : 0,
                      paddingRight: i === data7.length - 1 ? 3 : 0,
                      //justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <button
                      //value={responseData.component.id}
                      style={{
                        borderWidth: 0,
                        borderRadius: 50,
                        background: CompColor === i ? "white" : "#f4f2f4",
                        height: 35,
                        fontWeight: CompColor === i ? "bold" : "",
                        fontSize: 14,
                      }}
                      onClick={() => {
                        setCompColor(i);
                        compName = responseData;
                        //setCompName(responseData);
                        // setComponentName(responseData.component.name);
                        console.log(responseData);
                        PickDatesToGetData1(PickedDate);
                        //   getSourceCompareReport(compName);
                        //setComponentId(responseData.component.id);
                      }}
                    >
                      {responseData}
                    </button>
                    {i !== data7.length - 1 ? (
                      <div
                        style={{
                          width: 1,
                          height: 16,
                          flexGrow: 0,
                          background: "#c8c7cb",
                        }}
                      ></div>
                    ) : (
                      <></>
                    )}
                  </div>
                ))
              ) : (
                <h6> You dont have components</h6>
              )}
            </div>
          </div>
          <MediaSourceTableSourceCompare combinedArray={combinedArray} />
        </>
      )}
    </div>
  );
}
//SendDataToDunots14

import React, { useState, useEffect, useCallback, useRef } from "react";
import Box from "@mui/material/Box";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import { withRouter, useHistory, useLocation } from "react-router-dom";
import { Input, Space, Table, Typography, Button, Checkbox } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import "antd/dist/antd.css";
import "../../Pages/styles.css";

var newArray6 = [];

export default function GlobalTable1({
  newArray5,
  ISum,
  SISum,
  ARSum,
  GRSum,
  MFSum,
  SFSum,
  revSum,
  MCSum,
  OFSum,
  GPSum,
  NISum,
  PFSum,
  NPSum,
  PMSum,
}) {
  const { pathname } = useLocation();
  const [nbRows, setNbRows] = React.useState(3);
  const removeRow = () => setNbRows((x) => Math.max(0, x - 1));
  const addRow = () => setNbRows((x) => Math.min(100, x + 1));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  function useWindowSize() {
    const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
    }, []);
    return size;
  }
  const [height, width] = useWindowSize();

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    // { field: "id", headerName: "ID", width: 90 },
    {
      dataIndex: "seat",
      title: "Seat",
      flex: 1,
      minWidth: 100,
      editable: true,

      ...getColumnSearchProps("seat"),
      sorter: (a, b) => a.seat.length - b.seat.length,
      sortDirections: ["descend", "ascend"],
    },

    {
      title: "Impressions",
      dataIndex: "impressions",
      flex: 1,
      type: "number",

      minWidth: 100,
      editable: true,
      ...getColumnSearchProps("impressions"),
      sorter: (a, b) => a.impressions - b.impressions,
      render: (value) => {
        return value.toLocaleString("en");
      },
    },
    {
      dataIndex: "revenue",
      title: "Revenue",
      flex: 1,
      minWidth: 100,
      editable: true,
      ...getColumnSearchProps("revenue"),
      sorter: (a, b) => a.revenue - b.revenue,
      render: (value) => {
        return "$ " + Number(value * 1).toLocaleString("en");
      },
    },
    {
      dataIndex: "mediacost",
      title: "Media Cost",
      type: "number",
      flex: 1,
      minWidth: 100,
      editable: true,
      ...getColumnSearchProps("mediacost"),
      sorter: (a, b) => a.mediacost - b.mediacost,
      render: (value) => {
        return "$ " + Number(value * 1).toLocaleString("en");
      },
    },

    {
      dataIndex: "operationfee",
      title: "Operation Fee",
      flex: 1,
      minWidth: 100,
      editable: true,
      ...getColumnSearchProps("operationfee"),
      sorter: (a, b) => a.operationfee - b.operationfee,
      render: (value) => {
        return "$ " + Number(value * 1).toLocaleString("en");
      },
    },
    {
      dataIndex: "grossprofit",
      title: "Gross Profit",
      flex: 1,
      minWidth: 100,
      editable: true,
      ...getColumnSearchProps("grossprofit"),
      sorter: (a, b) => a.grossprofit - b.grossprofit,
      render: (value) => {
        return "$ " + Number(value * 1).toLocaleString("en");
      },
    },

    {
      dataIndex: "partnerfee",
      title: "partnerfee",
      flex: 1,
      minWidth: 100,
      editable: true,
      ...getColumnSearchProps("partnerfee"),
      sorter: (a, b) => a.partnerfee - b.partnerfee,
      render: (value) => {
        return Number(value * 1).toLocaleString("en");
      },
    },
    {
      dataIndex: "netProfit",
      title: "netProfit",
      flex: 1,
      minWidth: 100,
      editable: true,
      ...getColumnSearchProps("netProfit"),
      sorter: (a, b) => a.netProfit - b.netProfit,
      render: (value) => {
        return "$ " + Number(value * 1).toLocaleString("en");
      },
    },
    {
      dataIndex: "profitmargin",
      title: "profitmargin",
      flex: 1,
      minWidth: 100,
      editable: true,
      ...getColumnSearchProps("profitmargin"),
      sorter: (a, b) => a.profitmargin - b.profitmargin,
      render: (value) => {
        return Number(value * 1).toLocaleString("en") + " %";
      },
    },
  ];
  let [columns1, setColumns] = useState(
    columns.filter((item) => item.dataIndex !== "cars")
  );

  let onChange = (checkedValues) => {
    console.log(checkedValues);
    setColumns(
      columns.filter((item) => !checkedValues.includes(item.dataIndex))
    );
  };

  return (
    <div>
      <div className="App" style={{ backgroundColor: "white" }}>
        <h5 style={{ margin: 10 }}>Revenue Overview</h5>

        {/* <button onClick={handleClick}>Export</button> */}
        {/* <Checkbox.Group onChange={onChange} defaultValue={["cars"]}>
          <Checkbox value="cars">Cars</Checkbox>

          <Checkbox value="impressions">impressions</Checkbox>

          <Checkbox value="plane">Plane</Checkbox>
        </Checkbox.Group> */}
        <Table
          dataSource={newArray5}
          columns={columns1}
          pagination={{
            position: ["none", "none"],
          }}
          size="small"
          scroll={{ x: "max-content" }}
          bordered
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row
                style={{
                  backgroundColor: "#b9d5ff91",
                }}
              >
                {" "}
                <Table.Summary.Cell index={0}> Total</Table.Summary.Cell>{" "}
                <Table.Summary.Cell index={1}>
                  {Number(ISum * 1).toLocaleString("en")}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                  {"$ " + revSum.toLocaleString("en")}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3}>
                  {" "}
                  {"$ " + Number(MCSum * 1).toLocaleString("en")}
                </Table.Summary.Cell>{" "}
                <Table.Summary.Cell index={4}>
                  {" "}
                  {"$ " + Number(OFSum * 1).toLocaleString("en")}
                </Table.Summary.Cell>{" "}
                <Table.Summary.Cell index={5}>
                  {" "}
                  {"$ " + Number(GPSum * 1).toLocaleString("en")}
                </Table.Summary.Cell>{" "}
                <Table.Summary.Cell index={6}>
                  {" "}
                  {"$ " + Number(PFSum * 1).toLocaleString("en")}
                </Table.Summary.Cell>{" "}
                <Table.Summary.Cell index={7}>
                  {" "}
                  {"$ " + Number(NPSum * 1).toLocaleString("en")}
                </Table.Summary.Cell>{" "}
                <Table.Summary.Cell index={8}>
                  {" "}
                  {Number(PMSum * 1).toLocaleString("en") + " %"}
                </Table.Summary.Cell>{" "}
              </Table.Summary.Row>
            </Table.Summary>
          )}
        ></Table>
      </div>{" "}
    </div>
  );
}

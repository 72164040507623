import React, { useState, useEffect, useCallback, useRef } from "react";
import { withRouter, useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { Pie } from "@ant-design/plots";
import { MyUrl } from "../App";
import { Input, Space, Table, Typography, Checkbox } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import SupplyTeamReportTop3 from "../components/SupplyTeamReportComponents/SupplyTeamReportTop3";
import SupplyTeamReport2Tables from "../components/SupplyTeamReportComponents/SupplyTeamReport2Tables";
import SupplyTeamReport3Donuts from "../components/SupplyTeamReportComponents/SupplyTeamReport3Donuts";
import SupplyTeamReportGraph from "../components/SupplyTeamReportComponents/SupplyTeamReportGraph";
import Header from "../components/Header";
import SupplyTeamReportLast1Table from "../components/SupplyTeamReportComponents/SupplyTeamReportLast1Table";
import SupplyTeamReportLast2Table from "../components/SupplyTeamReportComponents/SupplyTeamReportLast2Table";
import dayjs from "dayjs";
import moment from "moment";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Button from "@mui/material/Button";

var Array1 = [];
var Array3 = [];
var Array4 = [];
var Array5 = [];
var Array6 = [];
var Array7 = [];
var Array8 = [];
var Array9 = [];
var Array10 = [];
var Array11 = [];
var Array12 = [];
var Array13 = [];

var newArray9 = [];
var newArray = [];
var Obj3 = {};
var Obj4 = {};
var Obj5 = {};
var Obj6 = {};
var Obj7 = {};
var Obj8 = {};
var Obj9 = {};

export default function SupplyTeamReport() {
  const current11 = new Date();
  const CurrnetDate = `${current11.getFullYear()}-${
    current11.getMonth() + 1
  }-${current11.getDate()}`;

  //For yesterday Date
  const [Color, setColor] = React.useState(1);
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }
  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }
  //EndFor yesterday Date
  const [OpenRange, SetOpenRange] = useState(false);
  //For Date Pick
  const [value, setValue] = React.useState(dayjs(new Date()));
  const [value1, setValue1] = React.useState(dayjs(new Date()));
  const [value2, setValue2] = React.useState(true);
  const [CustomValue, setCustomValue] = React.useState("");

  const handleChange = (newValue) => {
    setValue(newValue);
    setdateFrom(`&startDate=${newValue.$y}-${newValue.$M + 1}-${newValue.$D}`);
    //  console.log(`${newValue.$y}-${newValue.$M + 1}-${newValue.$D}`);
  };

  const handleChange1 = (newValue1) => {
    setValue1(newValue1);
    setdateTo(`&endDate=${newValue1.$y}-${newValue1.$M + 1}-${newValue1.$D}`);
    //console.log(`${newValue1.$y}-${newValue1.$M + 1}-${newValue1.$D}`);
  };
  const handleChange2 = (newValue2) => {
    setValue1(newValue2);
  };
  //End For Date Pick
  const current = new Date();
  const dateNow = `${current.getFullYear()}-${
    current.getMonth() + 1
  }-${current.getDate()}`;

  const Week1 = moment();
  const Month1 = moment();
  const Week = Week1.subtract(7, "d").format("YYYY-MM-DD");
  const Month = `${current.getFullYear()}-${current.getMonth() + 1}-01`;
  const [dateFrom, setdateFrom] = React.useState(`&startDate=${dateNow}`);
  const [dateTo, setdateTo] = React.useState(`&endDate=${dateNow}`);

  useEffect(() => {}, [OpenRange, dateFrom, dateTo]);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoading3, setIsLoading3] = useState(true);
  const [isLoading4, setIsLoading4] = useState(true);
  const [IsLoading5, setIsLoading5] = useState(true);
  const [IsLoading6, setIsLoading6] = useState(true);
  const [IsLoading7, setIsLoading7] = useState(true);
  const [IsLoading8, setIsLoading8] = useState(true);
  const [IsLoading9, setIsLoading9] = useState(true);
  const [IsLoading10, setIsLoading10] = useState(true);
  const MyToken = JSON.parse(localStorage.getItem("Token"));
  console.log("MyToken");
  console.log(MyToken);
  const [MyArray1, setMyArray1] = useState([]);
  const [data2, setData2] = useState("");

  const CallData1 = () => {
    setIsLoading10(true);
    MyArray1.length = 0;
    try {
      axios
        .get(
          `${MyUrl}/api/Dashboard/getDailyIncome?$${dateFrom}${dateTo}
              `,
          {
            headers: {
              Authorization: `${MyToken}`,
            },
          }
        )
        .then((res) => {
          const data2 = res.data;
          setData2(data2);
          for (let i = 0; i < data2.responseData.length; i++) {
            MyArray1.push(data2.responseData[i]);
          }
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading10(false);
        });
    } catch (error) {}
  };
  const CallDataCampOptimization = (a) => {
    setIsLoading7(true);
    Array11 = [];
    try {
      axios
        .get(
          `${MyUrl}/api/MediaSource/sourceOptimization?${a}${dateFrom}${dateTo}
              `,
          {
            headers: {
              Authorization: `${MyToken}`,
            },
          }
        )
        .then((res) => {
          const data77 = res.data;
          for (let i = 0; i < data77.responseData.data.length; i++) {
            Obj7 = {
              //  Id: data77.responseData.data[i].campaign.id,
              source: data77.responseData.data[i].source.name,
              SEAT: data77.responseData.data[i].seatName,
              ENV: data77.responseData.data[i].environment.name,
              Req: data77.responseData.data[i].ad_requests,
              Imp: data77.responseData.data[i].impressions_good,
              Fill: data77.responseData.data[i].fill,
              Revenue: data77.responseData.data[i].revenue_total,
              QS: Number.parseFloat(
                data77.responseData.data[i].qs.toString().replace("e-", "")
              ).toFixed(2),
            };
            Array11.push(Obj7);
          }
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading7(false);
        });
    } catch (error) {}
  };
  const [start, setStart] = useState(`&startDate=${formatDate(yesterday)}`);
  const [end, setEnd] = useState(`&endDate=${formatDate(yesterday)}`);

  useEffect(() => {
    if (
      dateFrom === `&startDate=${CurrnetDate}` &&
      dateTo === `&endDate=${CurrnetDate}`
    ) {
      console.log("it equal ");

      setStart(`&startDate=${formatDate(yesterday)}`);
      setEnd(`&endDate=${formatDate(yesterday)}`);
      console.log(start);
      console.log(end);

      // start = `&startDate=${formatDate(yesterday)}`;
      // end = `&endDate=${formatDate(yesterday)}`;
    } else {
      setStart(dateFrom);
      setEnd(dateTo);
    }
  });
  const CallDataTableAppQuality = (a) => {
    console.log(dateFrom);
    console.log(CurrnetDate);

    if (
      dateFrom === `&startDate=${CurrnetDate}` &&
      dateTo === `&endDate=${CurrnetDate}`
    ) {
      console.log("it equal ");

      setStart(`&startDate=${formatDate(yesterday)}`);
      setEnd(`&endDate=${formatDate(yesterday)}`);
      console.log(start);
      console.log(end);

      // start = `&startDate=${formatDate(yesterday)}`;
      // end = `&endDate=${formatDate(yesterday)}`;
    } else {
      setStart(dateFrom);
      setEnd(dateTo);
    }
    setIsLoading8(true);
    // change DateFrom to &startDate=2023-01-08 of any date to work !
    try {
      axios
        .get(
          `${MyUrl}/api/MediaSource/getAppQualityData?${a}${start}${end}
              `,
          {
            headers: {
              Authorization: `${MyToken}`,
            },
          }
        )
        .then((res) => {
          Array12 = [];
          const data = res.data;
          console.log(
            `${MyUrl}/api/MediaSource/getAppQualityData?${a}${start}${end}`
          );
          console.log(data);
          console.log(start);
          console.log(end);
          console.log("CurrnetDate");
          console.log(CurrnetDate);

          //     console.log(a);
          for (let i = 0; i < data.responseData.data.length; i++) {
            Obj8 = {
              app_bundle: data.responseData.data[i].app_bundle.name,

              sivt:
                data.responseData.data[i].sivt /
                data.responseData.data[i].scannedImpressions,
              givt:
                data.responseData.data[i].givt /
                data.responseData.data[i].scannedImpressions,
              scannedImpressions: data.responseData.data[i].scannedImpressions,
            };
            // Obj8 = {
            //   AppName: data.responseData.data[i].app_name.name,
            //   Bundle: data.responseData.data[i].app_bundle.name,
            //   OS: data.responseData.data[i].os.name,
            //   ScannedImpressions: data.responseData.data[i].scannedImpressions,
            //   SivtImpressionsRate:
            //     data.responseData.data[i].sivt /
            //     data.responseData.data[i].scannedImpressions,
            //   GivtImpressionsRate:
            //     data.responseData.data[i].givt /
            //     data.responseData.data[i].scannedImpressions,
            // };
            Array12.push(Obj8);
          }
          console.log("Array12");
          console.log(Array12);
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading8(false);
        });
    } catch (error) {}
  };
  const CallDataForTop3 = (a) => {
    setIsLoading(true);
    try {
      axios
        .get(
          `${MyUrl}/api/MediaSource/getSupplyTeamData
              `,
          {
            headers: {
              Authorization: `${MyToken}`,
            },
          }
        )
        .then((res) => {
          Array1 = [];
          const data1 = res.data;
          Array1.push(data1.responseData);
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading(false);
        });
    } catch (error) {}
  };

  const CallDataTopFillingApps = (a) => {
    setIsLoading3(true);
    Array3 = [];

    try {
      axios
        .get(
          `${MyUrl}/api/MediaSource/getFillAppsData?${a}${dateFrom}${dateTo}
              `,
          {
            headers: {
              Authorization: `${MyToken}`,
            },
          }
        )
        .then((res) => {
          const data3 = res.data;
          for (let i = 0; i < data3.responseData.data.length; i++) {
            Obj3 = {
              AppName: data3.responseData.data[i].app_name.name,
              AppBundle: data3.responseData.data[i].app_bundle.name,
              OS: data3.responseData.data[i].os.name,
              Fill: data3.responseData.data[i].fill,
              Revenue: data3.responseData.data[i].revenue_total,
            };
            Array3.push(Obj3);
          }
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading3(false);
        });
    } catch (error) {}
  };
  const CallDataTrafficSourceDonut = (a) => {
    setIsLoading9(true);
    Array13 = [];

    try {
      axios
        .get(
          `${MyUrl}/api/MediaSource/getTrafficSource?${a}${dateFrom}${dateTo}
              `,
          {
            headers: {
              Authorization: `${MyToken}`,
            },
          }
        )
        .then((res) => {
          const data = res.data;
          for (let i = 0; i < data.responseData.data.length; i++) {
            Obj9 = {
              type: data.responseData.data[i].trafficSrc.name,
              value: data.responseData.data[i].impressions_good,
            };
            Array13.push(Obj9);
          }
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading9(false);
        });
    } catch (error) {}
  };
  const CallData3Donut = (a) => {
    setIsLoading4(true);
    Array4 = [];
    Array5 = [];

    try {
      axios
        .get(
          `${MyUrl}/api/MediaSource/getImpressionData?${a}${dateFrom}${dateTo}
              `,
          {
            headers: {
              Authorization: `${MyToken}`,
            },
          }
        )
        .then((res) => {
          const data4 = res.data;
          for (let i = 0; i < data4.responseData.envResponse.data.length; i++) {
            Obj4 = {
              type: data4.responseData.envResponse.data[i].envName,
              value: data4.responseData.envResponse.data[i].impressions,
            };
            Array4.push(Obj4);
          }
          for (let i = 0; i < data4.responseData.osResponse.data.length; i++) {
            Obj5 = {
              type: data4.responseData.osResponse.data[i].osName,
              value: data4.responseData.osResponse.data[i].impressions,
            };
            Array5.push(Obj5);
          }
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading4(false);
        });
    } catch (error) {}
  };
  const CallDataGraph = (a) => {
    setIsLoading5(true);
    Array6 = [];
    Array7 = [];
    Array8 = [];
    Array9 = [];

    try {
      axios
        .get(
          `${MyUrl}/api/MediaSource/monthlySupplyTeamData?${a}${dateFrom}${dateTo}

              `,
          {
            headers: {
              Authorization: `${MyToken}`,
            },
          }
        )
        .then((res) => {
          const data3 = res.data;
          for (let i = 0; i < data3.responseData.data.length; i++) {
            Array6.push(data3.responseData.data[i].averageFillRate.toFixed(3));
            Array7.push(data3.responseData.data[i].ad_requests.toFixed(2));
            Array8.push(data3.responseData.data[i].impressions_good.toFixed(5));
            Array9.push(data3.responseData.data[i].date.name);
          }
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading5(false);
        });
    } catch (error) {}
  };

  const CallDataGetOpportunityData = (a) => {
    setIsLoading6(true);
    Array10 = [];
    try {
      axios
        .get(
          //${a}${MyDateFrom}${MyDateTo}

          `${MyUrl}/api/MediaSource/getOpportunityData?${a}${dateFrom}${dateTo}
                `,
          {
            headers: {
              Authorization: `${MyToken}`,
            },
          }
        )
        .then((res) => {
          const data100 = res.data;

          for (let i = 0; i < data100.responseData.data.length; i++) {
            // if (
            //   data100.responseData.data[i].impressions_good > 500 &&
            //   data100.responseData.data[i].fill > 0.05 &&
            //   data100.responseData.data[i].qs > 2
            // ) {
            Obj6 = {
              BillingName: data100.responseData.data[i].advertiser.billingName,
              SEAT: data100.responseData.data[i].seatName,
              source: data100.responseData.data[i].source.name,
              ENV: data100.responseData.data[i].environment.name,
              OS: data100.responseData.data[i].os.name,
              Bundle: data100.responseData.data[i].app_bundle.name,
              Region: data100.responseData.data[i].region.name,
              Req: data100.responseData.data[i].ad_requests,
              Imp: data100.responseData.data[i].impressions_good,
              Fill: data100.responseData.data[i].fill,
              Revenue: data100.responseData.data[i].revenue_total,
              QS: Number.parseFloat(
                data100.responseData.data[i].qs.toString().replace("e-", "")
              ).toFixed(2),
            };
            Array10.push(Obj6);
            //}
          }
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading6(false);
        });
    } catch (error) {}
  };
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      dataIndex: "AppName",
      title: "AppName",
    },
    {
      dataIndex: "AppBundle",
      title: "AppBundle",
    },
    {
      dataIndex: "OS",
      title: "OS",
    },
    {
      dataIndex: "Fill",
      title: "Fill",
      ...getColumnSearchProps("Fill"),
      sorter: (a, b) => a.Fill - b.Fill,

      render: (value) => {
        return Number(value * 1).toLocaleString("en");
      },
    },
    {
      dataIndex: "Revenue",
      title: "Revenue",
      ...getColumnSearchProps("Revenue"),
      sorter: (a, b) => a.Revenue - b.Revenue,

      render: (value) => {
        return Number(value * 1).toLocaleString("en");
      },
    },
  ];

  useEffect(() => {
    CallData1();
    CallDataTopFillingApps();
    CallDataTableAppQuality();
    CallDataForTop3();
    CallData3Donut();
    CallDataGraph();
    CallDataGetOpportunityData();
    CallDataCampOptimization();
    CallDataTrafficSourceDonut();
  }, []);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  function useWindowSize() {
    const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
    }, []);
    return size;
  }
  const [height, width] = useWindowSize();

  const config1 = {
    legend: {
      position: "left",
    },

    appendPadding: 10,
    data: Array4,
    angleField: "value",
    colorField: "type",
    radius: 0.6,
    label: {
      type: "outer",
      offset: "35%",
      content: ({ percent }) => `${(percent * 100).toFixed(2)}%`,
      style: {
        fontSize: 14,
        textAlign: "center",
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
  };

  const config = {
    legend: {
      position: "left",
    },

    appendPadding: 10,
    data: Array5,
    angleField: "value",
    colorField: "type",
    radius: 0.6,
    label: {
      type: "outer",
      offset: "35%",
      content: ({ percent }) => `${(percent * 100).toFixed(2)}%`,
      style: {
        fontSize: 14,
        textAlign: "center",
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
  };
  //
  return (
    <div>
      <Header />
      <div className="SetRow">
        <div className="inFirst">
          <h6 style={{ color: "#4c1d95" }}>Generic report date scope:</h6>
        </div>
        <div className="inLast">
          <Button
            style={{
              margin: 5,
              backgroundColor: Color === 1 ? "#5b21b6" : "#8b5cf6",
            }}
            variant="contained"
            onClick={() => {
              setColor(1);
              setdateFrom(`&startDate=${dateNow}`);
              setdateTo(`&endDate=${dateNow}`);
              SetOpenRange(false);
            }}
          >
            Today
          </Button>
          <Button
            style={{
              margin: 5,
              backgroundColor: Color === 2 ? "#5b21b6" : "#8b5cf6",
            }}
            variant="contained"
            onClick={() => {
              setColor(2);
              setdateFrom(`&startDate=${formatDate(yesterday)}`);
              setdateTo(`&endDate=${formatDate(yesterday)}`);
              SetOpenRange(false);
            }}
          >
            Yesterday
          </Button>
          <Button
            style={{
              margin: 5,
              backgroundColor: Color === 3 ? "#5b21b6" : "#8b5cf6",
            }}
            variant="contained"
            onClick={() => {
              setColor(3);
              setdateFrom(`&startDate=${Week}`);
              setdateTo(`&endDate=${formatDate(yesterday)}`);
              SetOpenRange(false);
            }}
          >
            Last 7 Days
          </Button>
          <Button
            style={{
              margin: 5,
              backgroundColor: Color === 4 ? "#5b21b6" : "#8b5cf6",
            }}
            variant="contained"
            onClick={() => {
              setColor(4);
              SetOpenRange(false);
              setdateFrom(`&startDate=${Month}`);
              setdateTo(`&endDate=${dateNow}`);
            }}
          >
            This Month
          </Button>
          <Button
            style={{
              margin: 5,
              backgroundColor: Color === 5 ? "#5b21b6" : "#8b5cf6",
            }}
            onClick={() => {
              setColor(5);
              setdateFrom(`&startDate=${value.$y}-${value.$M + 1}-${value.$D}`);
              setdateTo(`&endDate=${value1.$y}-${value1.$M + 1}-${value1.$D}`);
              SetOpenRange(!OpenRange);
            }}
            variant="contained"
            endIcon={<ArrowDropDownIcon />}
          >
            Custom Range
          </Button>
        </div>
        <div>
          {IsLoading10 ? (
            <>Loading...</>
          ) : (
            <>
              {" "}
              {MyArray1.map(function (item, i) {
                return (
                  <>
                    <Checkbox
                      onClick={() => {
                        const index1 = newArray9.indexOf(
                          data2.responseData[i].id
                        );

                        if (index1 > -1) {
                          newArray9.splice(index1, 1);
                        } else {
                          newArray9.push(data2.responseData[i].id);
                        }

                        const index = newArray.indexOf(
                          `&ids=${data2.responseData[i].id}`
                        );

                        if (index > -1) {
                          newArray.splice(index, 1);
                        } else {
                          newArray.push(`&ids=${data2.responseData[i].id}`);
                        }
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <a style={{ color: "#7e22ce", fontSize: 25 }}>
                      {MyArray1[i].name}
                    </a>
                  </>
                );
              })}
              <div
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <br></br>
                <Button
                  style={{ margin: 5, backgroundColor: "#8b5cf6" }}
                  variant="contained"
                  onClick={() => {
                    // // callIt(newArray.toString().replaceAll(",", ""));
                    // CallData2(newArray.toString().replaceAll(",", ""));
                    // CallData3(newArray.toString().replaceAll(",", ""));
                    // CallDataTopDemand(newArray.toString().replaceAll(",", ""));
                    // CallDataTopTenApps(newArray.toString().replaceAll(",", ""));
                    CallDataTopFillingApps(
                      newArray.toString().replaceAll(",", "")
                    );
                    CallDataForTop3(newArray.toString().replaceAll(",", ""));
                    CallData3Donut(newArray.toString().replaceAll(",", ""));
                    CallDataGraph(newArray.toString().replaceAll(",", ""));
                    CallDataGetOpportunityData(
                      newArray.toString().replaceAll(",", "")
                    );
                    CallDataCampOptimization(
                      newArray.toString().replaceAll(",", "")
                    );
                    CallDataTableAppQuality(
                      newArray.toString().replaceAll(",", "")
                    );
                    CallDataTrafficSourceDonut(
                      newArray.toString().replaceAll(",", "")
                    );
                  }}
                >
                  Filter
                </Button>
                <br></br>
              </div>
            </>
          )}
        </div>
      </div>
      {OpenRange ? (
        <div>
          <div className="centerIt">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="From Date"
                inputFormat="YYYY-MM-DD"
                value={value}
                onChange={handleChange}
                renderInput={(params) => <TextField {...params} />}
              />
              &nbsp;&nbsp;
              <h6>-</h6>
              &nbsp;&nbsp;
              <DesktopDatePicker
                label="To Date"
                inputFormat="YYYY-MM-DD"
                value={value1}
                onChange={handleChange1}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>{" "}
            <h6></h6>
          </div>
          <div className="centerIt">
            <br></br>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div style={{ flex: 1, padding: 20 }}>
        {isLoading ? (
          <>Loading...</>
        ) : (
          <SupplyTeamReportTop3 Array1={Array1} isLoading={isLoading} />
        )}

        <div style={{ flex: 1 }}>
          {IsLoading5 ? (
            <>loading...</>
          ) : (
            <div style={{ background: "white", margin: 10 }}>
              <SupplyTeamReportGraph
                Array6={Array6}
                Array7={Array7}
                Array8={Array8}
                Array9={Array9}
              />
            </div>
          )}
        </div>

        {IsLoading8 || isLoading3 ? (
          <>Loading...</>
        ) : (
          <SupplyTeamReport2Tables Array3={Array3} Array12={Array12} />
        )}

        <div style={{ flex: 1 }}>
          {isLoading4 ? (
            <>Loading...</>
          ) : (
            <SupplyTeamReport3Donuts
              Array5={Array5}
              Array4={Array4}
              Array13={Array13}
            />
          )}
        </div>
        <div style={{ flex: 1 }}>
          {IsLoading6 ? (
            <>Loading...</>
          ) : (
            <>
              <SupplyTeamReportLast1Table Array10={Array10} />{" "}
            </>
          )}
        </div>
        <div style={{ flex: 1 }}>
          {IsLoading7 ? (
            <>Loading...</>
          ) : (
            <>
              <SupplyTeamReportLast2Table Array11={Array11} />{" "}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect, useCallback, useRef } from "react";
import { withRouter, useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { Input, Space, Table, Typography, Button, Checkbox } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
export default function SupplyTeamReport2Tables({ Array3, Array12 }) {
  const { pathname } = useLocation();
  // console.log("SupplyTeamReport2Tables");
  // console.log(Array12);
  // console.log(Array3);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  function useWindowSize() {
    const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
    }, []);
    return size;
  }
  const [height, width] = useWindowSize();

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      dataIndex: "AppName",
      title: "App Name",
      ...getColumnSearchProps("AppName"),
      sorter: (a, b) => a.AppName.length - b.AppName.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      dataIndex: "AppBundle",
      title: "App Bundle",
      ...getColumnSearchProps("AppBundle"),
      sorter: (a, b) => a.AppBundle.length - b.AppBundle.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      dataIndex: "OS",
      title: "OS",
      ...getColumnSearchProps("OS"),
      sorter: (a, b) => a.OS.length - b.OS.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      dataIndex: "Fill",
      title: "Fill",
      ...getColumnSearchProps("Fill"),
      sorter: (a, b) => a.Fill - b.Fill,

      render: (value) => {
        return "$ " + Number(value * 1).toLocaleString();
      },
    },
    {
      dataIndex: "Revenue",
      title: "Revenue",
      ...getColumnSearchProps("Revenue"),
      sorter: (a, b) => a.Revenue - b.Revenue,

      render: (value) => {
        return "$ " + Number(value * 1).toLocaleString();
      },
    },
  ];

  const columns1 = [
    {
      dataIndex: "app_bundle",
      title: "Bundle",
      ...getColumnSearchProps("app_bundle"),
      sorter: (a, b) => a.app_bundle.length - b.app_bundle.length,
      sortDirections: ["descend", "ascend"],
    },
    // {
    //   dataIndex: "Bundle",
    //   title: "Bundle",
    //   ...getColumnSearchProps("Bundle"),
    //   sorter: (a, b) => a.Bundle.length - b.Bundle.length,
    //   sortDirections: ["descend", "ascend"],
    // },

    {
      dataIndex: "scannedImpressions",
      title: "Scanned Impressions",
      ...getColumnSearchProps("scannedImpressions"),
      sorter: (a, b) => a.scannedImpressions - b.scannedImpressions,

      render: (value) => {
        return Number(value * 1).toLocaleString();
      },
    },
    {
      dataIndex: "sivt",
      title: "sivt",
      ...getColumnSearchProps("sivt"),
      sorter: (a, b) => a.sivt - b.sivt,

      render: (value) => {
        return Number(value * 1).toLocaleString() + "%";
      },
    },
    {
      dataIndex: "givt",
      title: "givt",
      ...getColumnSearchProps("givt"),
      sorter: (a, b) => a.givt - b.givt,

      render: (value) => {
        return Number(value * 1).toLocaleString() + "%";
      },
    },
  ];
  return (
    <div
      style={{
        flex: 1,
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          width: width < 1040 ? "100%" : "49%",
          backgroundColor: "white",
          marginBottom: 5,
        }}
      >
        <div style={{ justifyContent: "center", display: "flex" }}>
          <h5>Top Filling Apps </h5>
        </div>
        <Table
          dataSource={Array3}
          columns={columns}
          // pagination={{
          //   position: ["none", "none"],
          // }}
          size="small"
          scroll={{ x: width > 766 ? "100%" : "90vh", y: 300 }}
          bordered
        ></Table>
      </div>
      <div
        style={{
          width: width < 1040 ? "100%" : "49%",
          backgroundColor: "white",
          marginBottom: 5,
        }}
      >
        <div style={{ justifyContent: "center", display: "flex" }}>
          <h5>App Quality</h5>
        </div>
        <Table
          dataSource={Array12}
          columns={columns1}
          // pagination={{
          //   position: ["none", "none"],
          // }}
          size="small"
          scroll={{ x: width > 766 ? "100%" : "90vh", y: 300 }}
          bordered
        ></Table>
      </div>
    </div>
  );
}

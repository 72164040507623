import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";

import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import { MyUrl } from "../../../App";
import axios from "axios";

var MyArray = [];
var compName = "General";

export default function MediaSourceTablePerformanceReport(DataFromFather) {
  //const [compName, setCompName] = useState("General");

  console.log(DataFromFather.SendDataToDunots10);
  useEffect(() => {
    compName = "General";
    //setCompName("General");
  }, []);

  const [data7, setData7] = useState(["General", "CTV", "InApp"]);
  const [CompColor, setCompColor] = useState(0);

  const [Value, setValue] = React.useState("");
  //console.log(rowData);

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const gridRef = useRef(); // Optional - for accessing Grid's API
  //const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row
  function useWindowSize() {
    const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
    }, []);
    return size;
  }
  const [height, width] = useWindowSize();
  const [rowData] = useState(
    DataFromFather.SendDataToDunots10
    //   [
    //   { date: "Toyota" },
    //   { date: "Ford" },
    //   { date: "Porsche" },
    // ]
  );

  const [columnDefs] = useState([
    {
      headerName: "date",
      flex: width > 766 ? 1 : null,
      field: "date",
      filter: true,
      minWidth: 150,
      headerClass: "my-header-class",

      //   headerCheckboxSelection: true,
      //   headerCheckboxSelectionFilteredOnly: true,
      //   checkboxSelection: true,
    },
    {
      headerName: "environment",
      flex: width > 766 ? 1 : null,
      field: "environment",
      filter: true,
      minWidth: 150,
      headerClass: "my-header-class",

      //   headerCheckboxSelection: true,
      //   headerCheckboxSelectionFilteredOnly: true,
      //   checkboxSelection: true,
    },
    {
      headerName: "addRequests",
      flex: width > 766 ? 1 : null,
      field: "addRequests",
      filter: true,
      minWidth: 150,
      headerClass: "my-header-class",

      //   headerCheckboxSelection: true,
      //   headerCheckboxSelectionFilteredOnly: true,
      //   checkboxSelection: true,
    },
    {
      headerName: "impressions",
      flex: width > 766 ? 1 : null,
      field: "impressions",
      filter: true,
      minWidth: 150,
      headerClass: "my-header-class",

      //   headerCheckboxSelection: true,
      //   headerCheckboxSelectionFilteredOnly: true,
      //   checkboxSelection: true,
    },
    {
      headerName: "Total revenue",
      flex: width > 766 ? 1 : null,
      field: "revenue",
      filter: true,
      minWidth: 150,
      headerClass: "my-header-class",
      valueFormatter: (params) => `$ ${params.value}`,

      //   headerCheckboxSelection: true,
      //   headerCheckboxSelectionFilteredOnly: true,
      //   checkboxSelection: true,
    },

    {
      headerName: "eCpm",
      flex: width > 766 ? 1 : null,
      field: "eCpm",
      filter: true,
      minWidth: 150,
      headerClass: "my-header-class",

      //   headerCheckboxSelection: true,
      //   headerCheckboxSelectionFilteredOnly: true,
      //   checkboxSelection: true,
    },
    {
      headerName: "$/AD Req in Mil.",
      flex: width > 766 ? 1 : null,
      field: "addReqInMill",
      filter: true,
      minWidth: 150,
      headerClass: "my-header-class",
      valueFormatter: (params) => `$ ${params.value}`,

      //   headerCheckboxSelection: true,
      //   headerCheckboxSelectionFilteredOnly: true,
      //   checkboxSelection: true,
    },
  ]);

  // Each Column Definition results in one Column.
  // const [columnDefs, setColumnDefs] = useState([
  //   {
  //     headerName: "Seat Name",
  //     flex: width > 766 ? 1 : null,
  //     field: "name",
  //     filter: true,
  //     minWidth: 150,
  //     headerClass: "my-header-class",

  //     //   headerCheckboxSelection: true,
  //     //   headerCheckboxSelectionFilteredOnly: true,
  //     //   checkboxSelection: true,
  //   },
  //   {
  //     headerName: "Total Revenue",
  //     minWidth: 150,
  //     flex: width > 766 ? 1 : null,
  //     field: "revenueTotal",
  //     filter: "agNumberColumnFilter",
  //     headerClass: "my-header-class",

  //     valueFormatter: (params) =>
  //       `$ ${params.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
  //   },
  //   {
  //     minWidth: 150,
  //     flex: width > 766 ? 1 : null,
  //     field: "mediaCost",
  //     filter: "agNumberColumnFilter",
  //     headerClass: "my-header-class",

  //     valueFormatter: (params) =>
  //       `$ ${params.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
  //   },
  //   {
  //     field: "servingFee",
  //     filter: true,
  //     minWidth: 150,
  //     flex: width > 766 ? 1 : null,
  //     headerClass: "my-header-class",

  //     valueFormatter: (params) =>
  //       `$ ${params.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
  //   },
  //   {
  //     headerName: "Scanning Fee",
  //     minWidth: 150,
  //     flex: width > 766 ? 1 : null,
  //     field: "pixalateFee",
  //     filter: "agNumberColumnFilter",
  //     headerClass: "my-header-class",
  //     valueFormatter: (params) =>
  //       `$ ${params.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
  //   },
  //   {
  //     headerName: "Gross Profit",
  //     field: "grossProfit", // it was netProfit
  //     filter: true,
  //     minWidth: 150,
  //     flex: width > 766 ? 1 : null,
  //     headerClass: "my-header-class",

  //     valueFormatter: (params) =>
  //       `$ ${params.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
  //   },
  //   {
  //     field: "grossProfitMargin",
  //     filter: true,
  //     minWidth: 150,
  //     flex: width > 766 ? 1 : null,
  //     headerClass: "my-header-class",

  //     valueFormatter: (params) =>
  //       `% ${params.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
  //   },
  //   {
  //     headerName: CheckTypeOfUser === "Partner" ? "O2R Fee" : "partner Fee",
  //     field: "partnerFee", //should be netprofit
  //     filter: true,
  //     minWidth: 150,
  //     flex: width > 766 ? 1 : null,
  //     headerClass: "my-header-class",

  //     valueFormatter: (params) =>
  //       `$ ${params.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
  //   },
  //   {
  //     headerName: "Net Income",
  //     field: "netIncome", //should be netprofit
  //     filter: true,
  //     minWidth: 150,
  //     flex: width > 766 ? 1 : null,
  //     headerClass: "my-header-class",

  //     valueFormatter: (params) =>
  //       `$ ${params.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
  //   },
  //   // {
  //   //   hide: CheckTypeOfUser === "Partner",
  //   //   field: "netIncome",
  //   //   filter: true,
  //   //   minWidth: 150,
  //   //   flex: width > 766 ? 1 : null,
  //   //   headerClass: "my-header-class",

  //   //   valueFormatter: (params) =>
  //   //     `$ ${params.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
  //   // },

  //   {
  //     hide: CheckTypeOfUser === "Partner",
  //     headerName: "Net Income Margin",
  //     field: "procentNetIncome",
  //     filter: true,
  //     minWidth: 150,
  //     flex: width > 766 ? 1 : null,
  //     headerClass: "my-header-class",

  //     valueFormatter: (params) =>
  //       `% ${params.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
  //   },
  // ]);
  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(() => ({
    sortable: true,
  }));
  // Example of consuming Grid Event
  const cellClickedListener = useCallback((event) => {
    console.log("cellClicked", event);
  }, []);

  // Example using Grid's API
  const buttonListener = useCallback((e) => {
    gridRef.current.api.deselectAll();
  }, []);
  var ragCellClassRules = {
    "rag-green-outer": function (params) {
      return params.value === 2008;
    },
    "rag-amber-outer": function (params) {
      return params.value === 2004;
    },
    "rag-red-outer": function (params) {
      return params.value === 2000;
    },
  };
  const [selectedRows, setSelectedRows] = useState([]);
  const [gridApi, setGridApi] = useState(null);

  const onGridReady = (params) => {
    // console.log(rowData);
    setGridApi(params.api);
  };

  const getRowHeight = (params) => (params.node.group ? 40 : 40);
  const sumRow = {
    name: "Total",
    revenueTotal: rowData.reduce((acc, curr) => acc + curr.revenueTotal, 0),
    mediaCost: rowData.reduce((acc, curr) => acc + curr.mediaCost, 0),
    servingFee: rowData.reduce((acc, curr) => acc + curr.servingFee, 0),
    pixalateFee: rowData.reduce((acc, curr) => acc + curr.pixalateFee, 0),
    partnerFee: rowData.reduce((acc, curr) => acc + curr.partnerFee, 0),
    grossProfit: rowData.reduce((acc, curr) => acc + curr.grossProfit, 0),
    grossProfitMargin:
      ((rowData.reduce((acc, curr) => acc + curr.revenueTotal, 0) -
        rowData.reduce((acc, curr) => acc + curr.mediaCost, 0) -
        rowData.reduce((acc, curr) => acc + curr.servingFee, 0)) /
        rowData.reduce((acc, curr) => acc + curr.revenueTotal, 0)) *
      100,

    netIncome: rowData.reduce((acc, curr) => acc + curr.netIncome, 0),
    procentNetIncome:
      (rowData.reduce((acc, curr) => acc + curr.netIncome, 0) /
        rowData.reduce((acc, curr) => acc + curr.revenueTotal, 0)) *
      100,
  };

  const gridOptions = {
    getRowStyle: (params) => {
      if (params.node.rowPinned === "bottom") {
        return { backgroundColor: "#f9f8f9" };
      }
    },
    rowClass: (params) => {
      return params.node.rowIndex === rowData.length ? "bottom-row" : "";
    },
    // other grid options...
  };

  //   const columnDefs = [
  //     { headerName: "Name", field: "name" },
  //     { headerName: "Value", field: "value" },
  //   ];
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  //   useEffect(() => {
  //     // This code will run only when myVariable changes
  //     console.log(`startDate changed to ${startDate} ---- ${endDate}`);
  //   }, [startDate, endDate]);
  return (
    <div style={{ paddingTop: 30 }}>
      <div
        style={{
          display: "flex",
        }}
      ></div>
      {/* {compName === "General" ? (
        <>
          <h3>table data 1</h3>
          {isLoading7 ? (
            <Skeleton
              animation="wave"
              //sx={{ bgcolor: "grey.900" }}
              // variant="rectangular"
              //width={750}
              height={500}
              borderRadius={100}
            />
          ) : (
            <>
              <CampaignManagementCampaignOptimizationOverview
                SendDataToCampOptimization={SendDataToCampOptimization}
                startDate={startDate}
                endDate={endDate}
              />
            </>
          )}
        </>
      ) : compName === "CTV" ? (
        <>
          <h3>table data 2</h3>
          {isLoading5 ? (
            <>
              {" "}
              <Skeleton
                animation="wave"
                //sx={{ bgcolor: "grey.900" }}
                // variant="rectangular"
                //  width={750}
                height={500}
                borderRadius={100}
              />
            </>
          ) : (
            <>
              <CampaignManagementSourceOptimizationReport
                startDate={startDate}
                endDate={endDate}
                SendDataToCampOptimization1={SendDataToCampOptimization1}
              />
            </>
          )}
        </>
      ) : compName === "InApp" ? (
        <>
          <h3>table data 3</h3>
        </>
      ) : (
        <></>
      )} */}
      <div>
        <div style={containerStyle}>
          <div style={gridStyle} className="ag-theme-alpine">
            {/* <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div style={{ flex: 1 }}>
                <span style={{ color: "#1a1523", fontSize: 18 }}>
                  Overall Performance Report
              
                </span>
              </div>
              <div
                style={{ flex: 1, justifyContent: "flex-end", display: "flex" }}
              >
                {Array.isArray(data7) ? (
                  data7 &&
                  data7.map((responseData, i) => (
                    <div
                      style={{
                        background: "#f4f2f4",
                        borderTopLeftRadius: i === 0 ? 50 : 0,
                        borderBottomLeftRadius: i === 0 ? 50 : 0,
                        borderTopRightRadius: i === data7.length - 1 ? 50 : 0,
                        borderBottomRightRadius:
                          i === data7.length - 1 ? 50 : 0,
                        height: 40,
                        alignItems: "center",
                        paddingLeft: i === 0 ? 3 : 0,
                        paddingRight: i === data7.length - 1 ? 3 : 0,
                        //justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      <button
                        //value={responseData.component.id}
                        style={{
                          borderWidth: 0,
                          borderRadius: 50,
                          background: CompColor === i ? "white" : "#f4f2f4",
                          height: 35,
                          fontWeight: CompColor === i ? "bold" : "",
                          fontSize: 14,
                        }}
                        onClick={() => {
                          setCompColor(i);
                          compName = responseData;
                          //setCompName(responseData);
                          // setComponentName(responseData.component.name);
                          console.log(responseData);
                          //setComponentId(responseData.component.id);
                        }}
                      >
                        {responseData}
                      </button>
                      {i !== data7.length - 1 ? (
                        <div
                          style={{
                            width: 1,
                            height: 16,
                            flexGrow: 0,
                            background: "#c8c7cb",
                          }}
                        ></div>
                      ) : (
                        <></>
                      )}
                    </div>
                  ))
                ) : (
                  <h6> You dont have components</h6>
                )}
              </div>
            </div> */}
            <AgGridReact
              cellClassRules={ragCellClassRules}
              ref={gridRef} // Ref for accessing Grid's API
              rowData={rowData} // Row Data for Rows //{[...rowData, sumRow]} add another column had a total values
              columnDefs={columnDefs} // Column Defs for Columns
              defaultColDef={defaultColDef} // Default Column Properties
              animateRows={true} // Optional - set to 'true' to have rows animate when sorted
              rowSelection="multiple" // Options - allows click selection of rows
              onCellClicked={cellClickedListener} // Optional - registering for Grid Event
              onGridReady={onGridReady}
              gridOptions={gridOptions}
              //pinnedBottomRowData={[sumRow]}
              suppressScrollOnNewData={true}
              getRowHeight={getRowHeight}
              domLayout="autoHeight"
            />
          </div>{" "}
        </div>{" "}
        <style>
          {`
            .ag-theme-alpine .ag-root-wrapper {
              border: none;
            }
          `}
          {`
            .my-header-class {
              background-color: white;
            }
          `}
        </style>
      </div>
    </div>
  );
}

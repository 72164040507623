import ReactApexChart from "react-apexcharts";
import React from "react";

export default function SupplyTeamReportGraph({
  Array6,
  Array7,
  Array8,
  Array9,
}) {
  const series = [
    {
      name: "Ad Requests",
      type: "line",
      data: Array7,
    },
    {
      name: "Impressions",
      type: "line", //line
      data: Array8,
    },
    {
      name: "Fill Rate",
      type: "column",
      data: Array6,
    },
  ];
  const options = {
    bar: {
      horizontal: false,
      borderRadius: 0,
      borderRadiusApplication: "around",
      borderRadiusWhenStacked: "last",
      columnWidth: "20%",
      barHeight: "20%",
      distributed: false,
      rangeBarOverlap: true,
      rangeBarGroupRows: false,
      colors: {
        ranges: [
          {
            from: 0,
            to: 0,
            color: undefined,
          },
        ],
        backgroundBarColors: [],
        backgroundBarOpacity: 1,
        backgroundBarRadius: 0,
      },
    },
    chart: {
      height: 350,
      type: "line",
      stacked: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [4, 4],
    },
    title: {
      text: "Imp ,Req & fill rate Graph",
      align: "left",
      offsetX: 110,
    },
    xaxis: {
      categories: Array9, //[2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016],
    },
    yaxis: [
      {
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#00E396",
        },
        labels: {
          style: {
            colors: "#00E396",
          },
        },
        title: {
          text: "Impressions By Millions",
          style: {
            color: "#00E396",
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      {
        seriesName: "Income",
        //opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#008FFB",
        },
        labels: {
          style: {
            colors: "#008FFB",
          },
        },
        title: {
          text: "Ad Requests By Billions",
          style: {
            color: "#008FFB",
          },
        },
      },
      {
        seriesName: "Revenue",
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#FEB019",
        },
        labels: {
          style: {
            colors: "#FEB019",
          },
        },
        title: {
          text: "Fill Rate By Percent",
          style: {
            color: "#FEB019",
          },
        },
      },
    ],
    tooltip: {
      fixed: {
        enabled: false,
        position: "topRight", // topRight, topLeft, bottomRight, bottomLeft
        offsetY: 30,
        offsetX: 60,
      },
    },
    legend: {
      horizontalAlign: "left",
      offsetX: 40,
    },
  };

  // const series = [
  //   {
  //     name: "Fill Rate",
  //     type: "column",
  //     data: Array6,
  //   },
  //   {
  //     name: "Ad Requests",
  //     data: Array8,
  //   },
  //   {
  //     name: "Impressions",
  //     data: Array7, //[10, 19, 17, 36, 44, 45, 20, 38]
  //   },
  // ];
  // const options = {
  //   bar: {
  //     horizontal: false,
  //     borderRadius: 0,
  //     borderRadiusApplication: "around",
  //     borderRadiusWhenStacked: "last",
  //     columnWidth: "70%",
  //     barHeight: "70%",
  //     distributed: false,
  //     rangeBarOverlap: true,
  //     rangeBarGroupRows: false,
  //     colors: {
  //       ranges: [
  //         {
  //           from: 0,
  //           to: 0,
  //           color: undefined,
  //         },
  //       ],
  //       backgroundBarColors: [],
  //       backgroundBarOpacity: 1,
  //       backgroundBarRadius: 0,
  //     },
  //   },
  //   chart: {
  //     height: 600,
  //     type: "line",
  //   },
  //   stroke: {
  //     width: [3, 3, 3],
  //   },
  //   title: {
  //     text: "Traffic Sources",
  //   },
  //   dataLabels: {
  //     enabled: true,
  //     enabledOnSeries: [0, 1, 2],
  //   },
  //   labels: Array9,
  //   xaxis: {
  //     type: "time",
  //   },
  //   plotOptions: {
  //     bar: {
  //       columnWidth: "20%",
  //     },
  //   },
  //   yaxis: [
  //     {
  //       seriesName: "Fill Rate",
  //       opposite: true,
  //       showAlways: true,
  //       title: {
  //         text: "Fill Rate By Percent",
  //       },
  //       tooltip: {
  //         enabled: true,
  //       },
  //     },
  //     {
  //       seriesName: "Ad Requests",
  //       showAlways: true,
  //       //  opposite: true,
  //       title: {
  //         text: "Imp & Req By Billions",
  //       },
  //       tooltip: {
  //         enabled: true,
  //       },
  //     },
  //     {
  //       seriesName: "Ad Requests",
  //       show: false,
  //       title: {
  //         text: "Imp & Req By Billions",
  //       },
  //       tooltip: {
  //         enabled: false,
  //       },
  //     },
  //   ],
  // };

  return (
    <div>
      <div style={{ background: "white" }}>
        <ReactApexChart
          options={options}
          series={series}
          type="line"
          height={600}
        />
      </div>
      {/* <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={600}
      /> */}
    </div>
  );
}

import logo from "./logo.svg";
import "./App.css";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Body from "./components/Body";
import Dashboard from "./Pages/Dashboard";
import Router from "./components/Router";
import ForCheck from "./Pages/ForCheck";
import "antd/dist/antd.css";
import React, { useEffect } from "react";
import O2RLogo from "./Images/O2RLogo.png";

//my change for check 6-1
//For Shrat
export const MyUrl = "https://apinewbi.o2rintelligence.com"; //https://localhost:7184 <-- //https://apinewbi.o2rintelligence.com
export const Redirect = `https://app.o2rintelligence.com`; //http://apinewbi.o2rintelligence.com //https://localhost:3000
//here we call data from o2r project
export const CallFromO2R = "https://app.o2rintelligence.com"; //https://localhost:7006 <--
export const ForLogOut = "https://app.o2rintelligence.com/";

//End For Shrat

//For Loacal
// export const MyUrl = "https://localhost:7184"; //https://localhost:7184
// export const Redirect = `https://app.o2rintelligence.com`; //http://apinewbi.o2rintelligence.com //https://localhost:3000
// //here we call data from o2r project
// export const CallFromO2R = "https://localhost:7006";
// export const ForLogOut = "http://localhost:3000/";
//End For Loacal

//For Axios
//https://localhost:7184
//http://103.54.56.73:90
//http://apinewbi.o2rintelligence.com

//For Redirect
//http://app.o2rintelligence.com/ --> if we want to Deploy it
//`http://localhost:3000`  --> Send you to o2r project to login page
function App() {
  // useEffect(() => {
  //   document.title = "O2R Intelligence"; // Change this to your desired name
  // }, []);
  // useEffect(() => {
  //   const icon = document.head.querySelector('link[rel="icon"]');
  //   icon.href = O2RLogo; // Change this to the URL of your custom icon file
  // }, []);

  //className="App-header"
  return (
    <div>
      <Router />
    </div>
  );
}

export default App;

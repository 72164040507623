import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { withRouter, useHistory, useLocation } from "react-router-dom";
import { Redirect } from "../App";
const pages = [""];
const settings = ["Profile", "Account", "Dashboard"];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
    console.log(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  //reports DropDown
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  //End reports DropDown

  //Admin DropDown
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const open1 = Boolean(anchorEl1);
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  //End Admin DropDown

  let history = useHistory();

  const GoToIncomePage = () => {
    console.log("Go To Income Page");
    history.push("/Income");
  };

  const GoToGlobalPage = () => {
    console.log("Go To Global Page");
    history.push("/Global");
  };

  const GoToDshboardPage = () => {
    console.log("Go To Income Page");
    history.push("/Dashboard");
  };

  const GoToCustomReportingPage = () => {
    console.log("Go To Custom Reporting Page");
    history.push("/CustomReporting");
  };

  const GoToDemandPage = () => {
    console.log("Go To Demand Page");
    history.push("/Demand");
  };
  const GoToSupplyTeamReportPage = () => {
    console.log("Go To SupplyTeamReport Page");
    history.push("/SupplyTeamReport");
  };

  const LogoutFunc = () => {
    console.log("Logout");
    localStorage.removeItem("Token");
    const redirectUrl = "http://app.o2rintelligence.com";
    window.location = "http://app.o2rintelligence.com";
  };
  return (
    <div className="header">
      <AppBar position="static" style={{ background: "#a21caf" }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              O2R
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={() => {}}>
                    {/* <Typography textAlign="center">aa</Typography> */}
                    <Button
                      id="basic-button"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                      style={{ color: "black" }}
                    >
                      Reports
                      <ArrowDropDownIcon />
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem onClick={handleClose}>Income</MenuItem>
                      <MenuItem onClick={handleClose}>Global</MenuItem>
                      <MenuItem onClick={handleClose}>Media Sources</MenuItem>
                      <MenuItem onClick={handleClose}>
                        Custom Reporting
                      </MenuItem>
                    </Menu>
                    <Button
                      id="basic-button"
                      aria-controls={open1 ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open1 ? "true" : undefined}
                      onClick={handleClick1}
                      style={{ color: "black" }}
                    >
                      Admin
                      <ArrowDropDownIcon />
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl1}
                      open={open1}
                      onClose={handleClose1}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem onClick={handleClose1}>Users</MenuItem>
                      <MenuItem onClick={handleClose1}>Roles</MenuItem>
                      <MenuItem onClick={handleClose1}>Permission</MenuItem>
                      <MenuItem onClick={handleClose1}>Menu</MenuItem>
                      <MenuItem onClick={handleClose1}>Operation log</MenuItem>
                      <MenuItem onClick={handleClose1}>Demand</MenuItem>
                      <MenuItem onClick={handleClose1}>
                        SupplyTeamReport
                      </MenuItem>
                    </Menu>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="Dashboard"
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              O2R
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {pages.map((page) => (
                <>
                  <Button
                    onClick={() => {
                      GoToDshboardPage();
                      handleCloseNavMenu();
                    }}
                    sx={{ my: 2, color: "white", display: "block" }}
                  >
                    dashboard
                  </Button>
                  <Button
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    style={{ color: "white" }}
                  >
                    Reports
                    <ArrowDropDownIcon />
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        GoToIncomePage();
                        handleClose();
                      }}
                    >
                      Income
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        GoToGlobalPage();
                        handleClose();
                      }}
                    >
                      Global
                    </MenuItem>
                    {/* <MenuItem
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Media Sources
                    </MenuItem> */}
                    {/* <MenuItem
                      onClick={() => {
                        GoToCustomReportingPage();
                        handleClose();
                      }}
                    >
                      Custom Reporting
                    </MenuItem> */}

                    <MenuItem
                      onClick={() => {
                        GoToDemandPage();
                        handleClose();
                      }}
                    >
                      Demand
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        GoToSupplyTeamReportPage();
                        handleClose();
                      }}
                    >
                      Supply Team Report
                    </MenuItem>
                  </Menu>
                  {/* <Button
                    id="basic-button"
                    aria-controls={open1 ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open1 ? "true" : undefined}
                    onClick={handleClick1}
                    style={{ color: "white" }}
                  >
                    Admin
                    <ArrowDropDownIcon />
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl1}
                    open={open1}
                    onClose={handleClose1}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem onClick={handleClose1}>Users</MenuItem>
                    <MenuItem onClick={handleClose1}>Roles</MenuItem>
                    <MenuItem onClick={handleClose1}>Permission</MenuItem>
                    <MenuItem onClick={handleClose1}>Menu</MenuItem>
                    <MenuItem onClick={handleClose1}>Operation log</MenuItem>
                  </Menu>
                  <Button
                    key={page}
                    onClick={handleCloseNavMenu}
                    sx={{ my: 2, color: "white", display: "block" }}
                  >
                  </Button> */}
                </>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
                <MenuItem
                  onClick={() => {
                    LogoutFunc();
                    handleCloseUserMenu();
                  }}
                >
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}
export default ResponsiveAppBar;

import React, { useState, useEffect, useCallback } from "react";
import { withRouter, useHistory, useLocation } from "react-router-dom";
var ss = 0;
export default function MediaSourceTopSix(DataFromFather) {
  // console.log("fromTopSix");
  // console.log(DataFromFather);

  // console.log(DataFromFather.GetDailyTrafficSourceRevenueData.responseData);

  // console.log(
  //   DataFromFather.GetDailyTrafficSourceRevenueData.responseData[1].revenue
  // );

  useEffect(() => {}, []);

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  function useWindowSize() {
    const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
    }, []);
    return size;
  }
  const [height, width] = useWindowSize();

  return (
    <div style={{ paddingTop: 30 }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          overflowX: width > 766 ? "auto" : "scroll",
          justifyContent: width > 766 ? "space-evenly" : "",
        }}
      >
        <div
          style={{
            flex: 1,
            maxWidth: "31%",
            //background: "red",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            borderWidth: 1,
            border: "1px solid #e4defc",
            borderRadius: 10,
            minWidth: 200,
            marginRight: width > 766 ? 0 : 20,
          }}
        >
          <div
            style={{
              flex: 1,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              minHeight: 150,
            }}
          >
            <span style={{ fontSize: 20, color: "#20134b", paddingBottom: 10 }}>
              Unknown Daily Run Rate
            </span>
            {/* <h1 style={{ fontSize: 20 }}>MT Daily Run Rate</h1> */}
            <h1 style={{ width: "90%", border: "1px solid #e4defc" }}></h1>
            {/* <h1>1642.466 $</h1> */}
            <span style={{ fontSize: 30, color: "#20134b" }}>
              {DataFromFather.DailyEmpty}{" "}
              {/* {DataFromFather.DailyME.toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "} */}
              $
            </span>
          </div>
        </div>
        <div
          style={{
            flex: 1,
            maxWidth: "31%",
            //background: "red",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            borderWidth: 1,
            border: "1px solid #e4defc",
            borderRadius: 10,
            minWidth: 200,
            marginRight: width > 766 ? 0 : 20,
          }}
        >
          <div
            style={{
              flex: 1,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              minHeight: 150,
            }}
          >
            <span style={{ fontSize: 20, color: "#20134b", paddingBottom: 10 }}>
              MT Daily Run Rate
            </span>
            {/* <h1 style={{ fontSize: 20 }}>MT Daily Run Rate</h1> */}
            <h1 style={{ width: "90%", border: "1px solid #e4defc" }}></h1>
            {/* <h1>1642.466 $</h1> */}
            <span style={{ fontSize: 30, color: "#20134b" }}>
              {DataFromFather.DailyMT}{" "}
              {/* {DataFromFather.DailyMS.toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "} */}
              $
            </span>
          </div>
        </div>
        <div
          style={{
            flex: 1,
            maxWidth: "31%",
            //background: "red",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            borderWidth: 1,
            border: "1px solid #e4defc",
            borderRadius: 10,
            minWidth: 200,
            marginRight: width > 766 ? 0 : 20,
          }}
        >
          <div
            style={{
              flex: 1,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              minHeight: 150,
            }}
          >
            <span style={{ fontSize: 20, color: "#20134b", paddingBottom: 10 }}>
              MS Daily Run Rate
            </span>
            {/* <h1 style={{ fontSize: 20 }}>MT Daily Run Rate</h1> */}
            <h1 style={{ width: "90%", border: "1px solid #e4defc" }}></h1>
            {/* <h1>1642.466 $</h1> */}
            <span style={{ fontSize: 30, color: "#20134b" }}>
              {DataFromFather.DailyMS}{" "}
              {/* {DataFromFather.DailyMS.toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "} */}
              $
            </span>
          </div>
        </div>
        <div
          style={{
            flex: 1,
            maxWidth: "31%",
            //background: "red",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            borderWidth: 1,
            border: "1px solid #e4defc",
            borderRadius: 10,
            minWidth: 200,
            marginRight: width > 766 ? 0 : 20,
          }}
        >
          <div
            style={{
              flex: 1,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              minHeight: 150,
            }}
          >
            <span style={{ fontSize: 20, color: "#20134b", paddingBottom: 10 }}>
              ME Daily Run Rate
            </span>
            {/* <h1 style={{ fontSize: 20 }}>MT Daily Run Rate</h1> */}
            <h1 style={{ width: "90%", border: "1px solid #e4defc" }}></h1>
            {/* <h1>1642.466 $</h1> */}
            <span style={{ fontSize: 30, color: "#20134b" }}>
              {DataFromFather.DailyME}{" "}
              {/* {DataFromFather.DailyME.toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "} */}
              $
            </span>
          </div>
        </div>
      </div>
      <h1></h1>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          overflowX: width > 766 ? "auto" : "scroll",
          justifyContent: width > 766 ? "space-evenly" : "",
        }}
      >
        <div
          style={{
            flex: 1,
            maxWidth: "31%",
            //background: "red",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            borderWidth: 1,
            border: "1px solid #e4defc",
            borderRadius: 10,
            minWidth: 200,
            marginRight: width > 766 ? 0 : 20,
          }}
        >
          <div
            style={{
              flex: 1,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              minHeight: 150,
            }}
          >
            <span style={{ fontSize: 20, color: "#20134b", paddingBottom: 10 }}>
              Unknown Monthly Run Rate
            </span>
            {/* <h1 style={{ fontSize: 20 }}>MT Daily Run Rate</h1> */}
            <h1 style={{ width: "90%", border: "1px solid #e4defc" }}></h1>
            {/* <h1>1642.466 $</h1> */}
            <span style={{ fontSize: 30, color: "#20134b" }}>
              {DataFromFather.MonthlyEmpty} $
            </span>
          </div>
        </div>
        <div
          style={{
            flex: 1,
            maxWidth: "31%",
            //background: "red",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            borderWidth: 1,
            border: "1px solid #e4defc",
            borderRadius: 10,
            minWidth: 200,
            marginRight: width > 766 ? 0 : 20,
          }}
        >
          <div
            style={{
              flex: 1,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              minHeight: 150,
            }}
          >
            <span style={{ fontSize: 20, color: "#20134b", paddingBottom: 10 }}>
              MT Monthly Run Rate
            </span>
            {/* <h1 style={{ fontSize: 20 }}>MT Daily Run Rate</h1> */}
            <h1 style={{ width: "90%", border: "1px solid #e4defc" }}></h1>
            {/* <h1>1642.466 $</h1> */}
            <span style={{ fontSize: 30, color: "#20134b" }}>
              {DataFromFather.MonthlyMT} $
            </span>
          </div>
        </div>
        <div
          style={{
            flex: 1,
            maxWidth: "31%",
            //background: "red",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            borderWidth: 1,
            border: "1px solid #e4defc",
            borderRadius: 10,
            minWidth: 200,
            marginRight: width > 766 ? 0 : 20,
          }}
        >
          <div
            style={{
              flex: 1,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              minHeight: 150,
            }}
          >
            <span style={{ fontSize: 20, color: "#20134b", paddingBottom: 10 }}>
              MS Monthly Run Rate
            </span>
            {/* <h1 style={{ fontSize: 20 }}>MT Daily Run Rate</h1> */}
            <h1 style={{ width: "90%", border: "1px solid #e4defc" }}></h1>
            {/* <h1>1642.466 $</h1> */}
            <span style={{ fontSize: 30, color: "#20134b" }}>
              {DataFromFather.MonthlyMS} $
            </span>
          </div>
        </div>
        <div
          style={{
            flex: 1,
            maxWidth: "31%",
            //background: "red",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            borderWidth: 1,
            border: "1px solid #e4defc",
            borderRadius: 10,
            minWidth: 200,
            marginRight: width > 766 ? 0 : 20,
          }}
        >
          <div
            style={{
              flex: 1,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              minHeight: 150,
            }}
          >
            <span style={{ fontSize: 20, color: "#20134b", paddingBottom: 10 }}>
              ME Monthly Run Rate
            </span>
            {/* <h1 style={{ fontSize: 20 }}>MT Daily Run Rate</h1> */}
            <h1 style={{ width: "90%", border: "1px solid #e4defc" }}></h1>
            {/* <h1>1642.466 $</h1> */}
            <span style={{ fontSize: 30, color: "#20134b" }}>
              {DataFromFather.MonthlyME} $
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

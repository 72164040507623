import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Column } from "@ant-design/plots";

export default function IncomeGraph({ SendDataToIncomeGraph, startDate }) {
  const customTooltipFormatter = (datum) => {
    return { name: datum.name, value: `$ ${datum.value}` };
  };

  const config = {
    data: SendDataToIncomeGraph,
    isGroup: true,
    xField: "company",
    yField: "value",
    seriesField: "name",
    yAxis: {
      label: {
        // 数值格式化为千分位
        formatter: (v) =>
          `$ ${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
    tooltip: { formatter: customTooltipFormatter },
    /** 设置颜色 */
    //color: ['#1ca9e6', '#f88c24'],

    /** 设置间距 */
    // marginRatio: 0.1,
    // label: {
    //   visible: false, // set to false to hide value label
    //   style: {
    //     fill: "red",
    //     opacity: 0.6,
    //   },
    //   // 可手动配置 label 数据标签位置
    //   position: "middle",
    //   // 'top', 'middle', 'bottom'
    //   // 可配置附加的布局方法
    //   layout: [
    //     // 柱形图数据标签位置自动调整
    //     {
    //       type: "interval-adjust-position",
    //     }, // 数据标签防遮挡
    //     {
    //       type: "interval-hide-overlap",
    //     }, // 数据标签文颜色自动调整
    //     {
    //       type: "adjust-color",
    //     },
    //   ],
    // },

    label: {
      // 可手动配置 label 数据标签位置
      position: "middle",
      // 'top', 'bottom', 'middle',
      // 配置样式
      style: {
        fill: "",
        opacity: 0.6,
      },
    },
    domain: ["2023-03-01", "2023-03-31"],
  };

  return (
    <div style={{ paddingBottom: 30, paddingTop: 30 }}>
      <span style={{ color: "#1a1523", fontSize: 18 }}>
        Revenue & Net Income
      </span>
      <Column {...config} />
    </div>
  );
}

import React, { useEffect, useState } from "react";
import axios from "axios";
import Checkbox from "@mui/material/Checkbox";
import { Avatar, List, Skeleton, Switch } from "antd";
import CustomReportingButtons from "./CustomReportingButtons";
import { MyUrl } from "../../App";
var newArray9 = [];
var newArray = [];

export default function CustomReportingChooseCompanies({
  MyDateFrom,
  MyDateTo,
}) {
  const [MyArray1, setMyArray1] = useState([]);
  const [isLoading1, setIsLoading1] = useState(true);
  const [data2, setData2] = useState("");

  useEffect(() => {
    //  callIt();
    CallData1();
  }, []);

  const CallData1 = (a) => {
    setIsLoading1(true);
    MyArray1.length = 0;
    try {
      axios
        .get(
          `${MyUrl}/api/Dashboard/getDailyIncome?${a}${MyDateFrom}${MyDateTo}
              `
        )
        .then((res) => {
          const data2 = res.data;
          setData2(data2);
          for (let i = 0; i < data2.responseData.length; i++) {
            MyArray1.push(data2.responseData[i]);
          }
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading1(false);
        });
    } catch (error) {}
  };

  return (
    <div>
      {isLoading1 ? (
        <>
          <Skeleton active avatar>
            <List.Item.Meta
              avatar={<Avatar src={""} />}
              title={<a href={""}>{""}</a>}
              description={""}
            />
            {""}
          </Skeleton>{" "}
        </>
      ) : (
        <>
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {MyArray1.map(function (item, i) {
              return (
                <>
                  <Checkbox
                    onClick={() => {
                      const index = newArray.indexOf(
                        `&ids=${data2.responseData[i].id}`
                      );
                      const index1 = newArray9.indexOf(
                        data2.responseData[i].id
                      );

                      if (index1 > -1) {
                        newArray9.splice(index1, 1);
                      } else {
                        newArray9.push(data2.responseData[i].id);
                      }

                      if (index > -1) {
                        newArray.splice(index, 1);
                      } else {
                        newArray.push(`&ids=${data2.responseData[i].id}`);
                      }
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <a style={{ color: "#7e22ce", fontSize: 25 }}>
                    {MyArray1[i].name}
                  </a>
                </>
              );
            })}
          </div>
        </>
      )}
      <hr
        style={{
          background: "#5b21b6",
          color: "#5b21b6",
          borderColor: "#5b21b6",
          height: "3px",
        }}
      />
      <CustomReportingButtons />
    </div>
  );
}

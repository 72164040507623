import React, { useState, useEffect } from "react";
import { Line } from "@ant-design/plots";
import { Button, Modal } from "antd";
import { withRouter, useHistory, useLocation } from "react-router-dom";
import FullScreenArrows from "../../../Images/FullScreenArrows.png";

export default function MediaSourceGraphs(DataFromFather) {
  // console.log(DataFromFather.SendDataToDunots7);

  // for (let i = 0; i < DataFromFather.SendDataToDunots7.length; i++) {
  //   console.log(DataFromFather.SendDataToDunots7[i].value);
  // }
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  function useWindowSize() {
    const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
    }, []);
    return size;
  }
  const [height, width] = useWindowSize();

  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);

  const customTooltipFormatter = (datum) => {
    return {
      name: datum.category,
      value: `$ ${datum.value
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
    };
  };
  const customTooltipFormatter1 = (datum) => {
    return {
      name: datum.category,
      value: `${datum.value
        .toFixed(0)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
    };
  };

  useEffect(() => {
    asyncFetch();
  }, []);

  const asyncFetch = () => {
    fetch(
      "https://gw.alipayobjects.com/os/bmw-prod/55424a73-7cb8-4f79-b60d-3ab627ac5698.json"
    )
      .then((response) => response.json())
      .then((json) => {
        setData(json);
      })
      .catch((error) => {
        console.log("fetch data failed", error);
      });
  };
  const config = {
    data: DataFromFather.SendDataToDunots6,
    xField: "year",
    yField: "value",
    seriesField: "category",
    xAxis: {
      type: "time",
    },
    yAxis: {
      label: {
        // 数值格式化为千分位
        formatter: (v) =>
          `$ ${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
    tooltip: { formatter: customTooltipFormatter },
  };

  const config1 = {
    data: DataFromFather.SendDataToDunots7,
    xField: "year",
    yField: "value",
    seriesField: "category",
    xAxis: {
      type: "time",
    },
    yAxis: {
      label: {
        // 数值格式化为千分位
        formatter: (v) =>
          `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
    tooltip: { formatter: customTooltipFormatter1 },
  };

  const config2 = {
    data: DataFromFather.SendDataToDunots8,
    xField: "year",
    yField: "value",
    seriesField: "category",
    xAxis: {
      type: "time",
    },
    yAxis: {
      label: {
        // 数值格式化为千分位
        formatter: (v) =>
          `$ ${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
    tooltip: { formatter: customTooltipFormatter },
  };
  return (
    <div style={{ paddingTop: 30, paddingBottom: 30 }}>
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          justifyContent: width > 766 ? "space-evenly" : "",
          overflowX: width > 766 ? "auto" : "scroll",
        }}
      >
        <div
          style={{
            flex: 1,
            borderWidth: 1,
            border: "1px solid #e4defc",
            borderRadius: 10,
            minWidth: 200,
            maxWidth: "31%",
            marginRight: width > 766 ? 0 : 20,
            padding: 10,

            //background: "#f9f8f9",
          }}
        >
          <>
            {/* <Button type="primary" onClick={() => setOpen(true)}>
              Traffic Id
            </Button> */}
            <div
              style={{
                padding: "1%",
                //backgroundColor: "red",
                justifyContent: "space-between",
                display: "flex",
              }}
            >
              <span style={{ fontSize: 20, color: "#20134b" }}>Media Cost</span>
              <img
                onClick={() => setOpen(true)}
                style={{
                  width: 25,
                  height: 25,
                  cursor: "pointer",
                }}
                src={FullScreenArrows}
              ></img>
            </div>
            <Modal
              title="Media Cost"
              centered
              open={open}
              onOk={() => setOpen(false)}
              onCancel={() => setOpen(false)}
              width="80%"
            >
              <Line {...config} />
            </Modal>
          </>
          <Line {...config} />
        </div>
        <div
          style={{
            flex: 1,
            borderWidth: 1,
            border: "1px solid #e4defc",
            borderRadius: 10,
            minWidth: 200,
            maxWidth: "31%",
            marginRight: width > 766 ? 0 : 20,
            padding: 10,
            //background: "#f9f8f9",
          }}
        >
          <>
            {/* <Button type="primary" onClick={() => setOpen(true)}>
              Traffic Id
            </Button> */}
            <div
              style={{
                padding: "1%",
                //backgroundColor: "red",
                justifyContent: "space-between",
                display: "flex",
              }}
            >
              <span style={{ fontSize: 20, color: "#20134b" }}>
                Impressions
              </span>
              <img
                onClick={() => setOpen1(true)}
                style={{
                  width: 25,
                  height: 25,
                  cursor: "pointer",
                }}
                src={FullScreenArrows}
              ></img>
            </div>
            <Modal
              title="Impressions"
              centered
              open={open1}
              onOk={() => setOpen1(false)}
              onCancel={() => setOpen1(false)}
              width="80%"
            >
              <Line {...config1} />
            </Modal>
          </>
          <Line {...config1} />
        </div>
        <div
          style={{
            flex: 1,
            borderWidth: 1,
            border: "1px solid #e4defc",
            borderRadius: 10,
            minWidth: 200,
            maxWidth: "31%",
            marginRight: width > 766 ? 0 : 20,
            //background: "#f9f8f9",
            padding: 10,
          }}
        >
          <>
            {/* <Button type="primary" onClick={() => setOpen(true)}>
              Traffic Id
            </Button> */}
            <div
              style={{
                padding: "1%",
                //backgroundColor: "red",
                justifyContent: "space-between",
                display: "flex",
              }}
            >
              <span style={{ fontSize: 20, color: "#20134b" }}>Revenue</span>
              <img
                onClick={() => setOpen2(true)}
                style={{
                  width: 25,
                  height: 25,
                  cursor: "pointer",
                }}
                src={FullScreenArrows}
              ></img>
            </div>
            <Modal
              title="Revenue"
              centered
              open={open2}
              onOk={() => setOpen2(false)}
              onCancel={() => setOpen2(false)}
              width="80%"
            >
              <Line {...config2} />
            </Modal>
          </>
          <Line {...config2} />
        </div>
      </div>
    </div>
  );
}

import axios from "axios";
import React, { useState, useEffect, useCallback, useRef } from "react";
import Checkbox from "@mui/material/Checkbox";
import { azAZ } from "@mui/material/locale";
import { Empty } from "antd";
import moment from "moment";
import Button from "@mui/material/Button";
import GeneralReportChannelDateScope from "./GeneralReportChannelDateScope";
import MasterIncomeOverViewTable from "./MasterIncomeOverViewTable";
import Box from "@mui/material/Box";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import IncomeGraph from "./IncomeGraph";
import { style } from "@mui/system";
import { Key } from "@mui/icons-material";
import { Avatar, List, Skeleton, Switch } from "antd";
import { Input, Space, Table, Typography } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import "antd/dist/antd.css";
import "../../Pages/styles.css";
import { MyUrl } from "../../App";
var PFSum1 = 0;
var PMSum1 = 0;
var NSum1 = 0;
var revSum1 = 0;
var MCSum1 = 0;
var OFSum1 = 0;
var GPSum1 = 0;
var NISum1 = 0;

var ISum = 0;
var SISum = 0;
var ARSum = 0;
var GRSum = 0;
var MFSum = 0;
var SFSum = 0;
var revSum = 0;
var MCSum = 0;
var OFSum = 0;
var GPSum = 0;
var PFSum = 0;
var PMSum = 0;
var NISum = 0;
var NSum = 0;
//IncomeGraph
var newArray = [];
var newArray1 = [];
var newArray2 = [];
var newArray3 = [];
var newArray4 = [];
var newArray5 = [];
var newArray6 = [];
var objRevenue = {};
var objMediaCost = {};
var newArray7 = [];
var newArray8 = [];
var newArray9 = [];
var newArray10 = [4, 6, 7, 8, 9, 10];
var newArray11 = [];
var newArray12 = [];
var newArray13 = [];
var newArray14 = [];
var newArray15 = [];
var newArray16 = [];
var newArray17 = [];
var newArray18 = [];
var newArray19 = [];
var newArray20 = [];
var newArray21 = [];
var newArray22 = [];

var revSum = 0;
var MCSum = 0;
var BigArray = [];
var obj10 = {};
var revenueSum = 0;
var mediacostSum = 0;
var operationfeeSum = 0;
var grossprofitSum = 0;
var partnerfeeSum = 0;
var profitmarginSum = 0;
var netincomeSum = 0;
var netincSum = 0;
var impressoinsSum = 0;
var scannedImpressionsSum = 0;
var addRequestsSum = 0;

var grossProfitMargin = 0;
var procentNetIncome = 0;

var LastSumRevenue = 0;
var LastSumRevenueNet = 0;
var LastMediaCost = 0;
var LastOperationFee = 0;
var Lastgrossprofit = 0;
var LastImpressions = 0;
var LastAddRequests = 0;
var LastNetIncome = 0;
var LastScannedImpressions = 0;
var LastUpAndDow = 0;
var upanddown1 = 0;
var LastDates = "";

var TheLastArray = [];
var TheLastArray1 = [];
var uniqArray = [];

export default function IncomeChannelOverViewTable({ MyDateFrom, MyDateTo }) {
  const [filterModel, setFilterModel] = React.useState({
    items: [
      {
        id: 1,
        columnField: "quantity",
        value: [5000, 15000],
        operatorValue: "between",
      },
    ],
  });
  const MyToken = JSON.parse(localStorage.getItem("Token"));
  console.log("MyToken");
  console.log(MyToken);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading1, setIsLoading1] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);

  const [data1, setData1] = useState("");
  const [data2, setData2] = useState("");
  const [data3, setData3] = useState("");

  const [LastArray, setLastArray] = useState([]);
  const [LastArray1, setLastArray1] = useState([]);

  const [MyArray1, setMyArray1] = useState([]);

  const [array, setArray] = useState([]);
  const [MyTrue, setMyTrue] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    //{ field: "id", headerName: "ID", width: 90 },
    {
      title: "Seat",
      dataIndex: "seat",
      flex: 1,
      minWidth: 150,
      editable: true,
      ...getColumnSearchProps("seat"),
      sorter: (a, b) => a.seat.length - b.seat.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Revenue",
      dataIndex: "revenue",
      flex: 1,
      minWidth: 150,
      editable: true,
      ...getColumnSearchProps("revenue"),
      sorter: (a, b) => a.revenue - b.revenue,
      render: (value) => {
        return "$ " + value.toLocaleString("en");
      },
    },

    {
      dataIndex: "mediacost",
      title: "Media Cost",
      type: "number",
      flex: 1,
      minWidth: 150,
      editable: true,
      ...getColumnSearchProps("mediacost"),
      sorter: (a, b) => a.mediacost - b.mediacost,
      render: (value) => {
        return "$ " + value.toLocaleString("en");
      },
    },
    {
      dataIndex: "operationfee",
      title: "Operation Fee",
      flex: 1,
      minWidth: 150,
      editable: true,
      ...getColumnSearchProps("operationfee"),
      sorter: (a, b) => a.operationfee - b.operationfee,
      render: (value) => {
        return "$ " + value.toLocaleString("en");
      },
    },
    {
      dataIndex: "grossprofit",
      title: "Gross Profit",
      flex: 1,
      minWidth: 150,
      editable: true,
      ...getColumnSearchProps("grossprofit"),
      sorter: (a, b) => a.grossprofit - b.grossprofit,
      render: (value) => {
        return "$ " + value.toLocaleString("en");
      },
    },
    {
      dataIndex: "partnerfee",
      title: "Partner Fee",
      flex: 1,
      minWidth: 150,
      editable: true,
      ...getColumnSearchProps("partnerfee"),
      sorter: (a, b) => a.partnerfee - b.partnerfee,
      render: (value) => {
        return "$ " + value.toLocaleString("en");
      },
    },
    {
      dataIndex: "profitmargin",
      title: "Profit Margin (%)",
      flex: 1,
      minWidth: 150,
      editable: true,
      ...getColumnSearchProps("partnerfee"),
      sorter: (a, b) => a.partnerfee - b.partnerfee,
      render: (value) => {
        return "% " + value.toLocaleString("en");
      },
    },
    {
      dataIndex: "netincome",
      title: "Net Income",
      flex: 1,
      minWidth: 150,
      editable: true,
      ...getColumnSearchProps("netincome"),
      sorter: (a, b) => a.netincome - b.netincome,
      render: (value) => {
        return "$ " + value.toLocaleString("en");
      },
    },
    {
      dataIndex: "netinc",
      title: "Netinc (%)",
      flex: 1,
      minWidth: 150,
      editable: true,
      ...getColumnSearchProps("netinc"),
      sorter: (a, b) => a.netinc - b.netinc,
      render: (value) => {
        return "% " + value.toLocaleString("en");
      },
    },
  ];
  function getRow() {
    return {
      id: "Total",
      seat: "Sum of all compaies",
      revenue: revenueSum,
      mediacost: mediacostSum,
      operationfee: operationfeeSum,
      grossprofit: grossprofitSum,
      partnerfee: partnerfeeSum,
      profitmargin: profitmarginSum,
      netincome: netincomeSum,
      netinc: netincSum,
    };
  }

  const pinnedRows = {
    bottom: [getRow()],
  };

  useEffect(() => {
    //newArray9 = [4, 6, 7, 8, 9, 10];
    newArray = [];
    newArray9 = [];
    callIt();
    callIt3();
    callIt4();
  }, []); //newArray9, newArray10

  const callIt4 = (a) => {
    setIsLoading2(false);
    try {
      axios
        .get(
          `${MyUrl}/api/Dashboard/getMonthlyIncome?${a}${MyDateFrom}${MyDateTo}
        `,
          {
            headers: {
              Authorization: `${MyToken}`,
            },
          }
        )
        .then((res) => {
          setData3([]);
          newArray7 = [];
          const data3 = res.data;
          setData3(data3);
          for (let i = 0; i < data3.responseData.length; i++) {
            newArray7.push(data3.responseData[i]);
          }
          console.log(newArray7);
        })
        .catch((error) => {})
        .finally(() => {
          ForTable4();
          setIsLoading2(true);
        });
    } catch (error) {}
  };

  function ForTable4() {
    newArray11 = [];
    newArray12 = [];
    newArray13 = [];
    newArray14 = [];
    newArray15 = [];
    newArray16 = [];
    newArray17 = [];
    newArray18 = [];
    newArray19 = [];
    newArray20 = [];
    newArray21 = [];

    BigArray = [];
    LastSumRevenue = 0;
    LastSumRevenueNet = 0;
    LastMediaCost = 0;
    LastOperationFee = 0;
    Lastgrossprofit = 0;
    LastImpressions = 0;
    LastAddRequests = 0;
    LastNetIncome = 0;
    LastScannedImpressions = 0;
    LastUpAndDow = 0;
    LastDates = "";

    for (let i = 0; i < newArray7.length; i++) {
      for (let j = 0; j < newArray7.length; j++) {
        if (newArray9.length !== 0) {
          if (newArray7[i].id === newArray9[j]) {
            for (let h = 0; h < newArray7[i].dataNetto.data.length; h++) {
              for (let s = 0; s < newArray9.length; s++) {
                LastSumRevenue += newArray7[s].dataNetto.data[h].revenueTotal;
                LastSumRevenueNet +=
                  newArray7[s].dataNetto.data[h].revenueTotal;
                LastMediaCost += newArray7[s].dataNetto.data[h].mediaCost;
                LastOperationFee +=
                  newArray7[s].dataNetto.data[h].operationCost;
                LastDates = newArray7[s].dataNetto.data[h].date.name;
                Lastgrossprofit += newArray7[s].dataNetto.data[h].grossProfit;
                LastImpressions +=
                  newArray7[s].dataNetto.data[h].impressionsGood;
                LastAddRequests += newArray7[s].dataNetto.data[h].addRequests;
                LastNetIncome += newArray7[s].dataNetto.data[h].netIncome;
                LastScannedImpressions +=
                  newArray7[s].dataNetto.data[h].scannedImpressions;
                LastUpAndDow += newArray7[s].dataNetto.data[h].netIncome;
              }

              newArray12.push(LastSumRevenue);
              newArray13.push(LastMediaCost);
              newArray14.push(LastDates);
              newArray15.push(LastOperationFee);
              newArray16.push(Lastgrossprofit);
              newArray17.push(LastImpressions);
              newArray18.push(LastAddRequests);
              newArray19.push(LastSumRevenueNet);
              newArray20.push(LastNetIncome);
              newArray21.push(LastScannedImpressions);

              var uniqLastSumRevenue = [...new Set(newArray12)];
              var uniqLastMediaCost = [...new Set(newArray13)];
              var uniqLastDates = [...new Set(newArray14)];
              var uniqOperationFee = [...new Set(newArray15)];
              var uniqGrossProfit = [...new Set(newArray16)];
              var uniqImpressions = [...new Set(newArray17)];
              var uniqAddRequests = [...new Set(newArray18)];
              var uniqLastSumRevenueNet = [...new Set(newArray19)];
              var uniqLastNetIncome = [...new Set(newArray20)];
              var uniqScannedImpressions = [...new Set(newArray21)];

              LastSumRevenue = 0;
              LastMediaCost = 0;
              LastOperationFee = 0;
              Lastgrossprofit = 0;
              LastImpressions = 0;
              LastAddRequests = 0;
              LastSumRevenueNet = 0;
              LastNetIncome = 0;
              LastScannedImpressions = 0;
            }
          }
        } else {
          if (newArray7[i].id === newArray10[j]) {
            for (let h = 0; h < newArray7[i].dataNetto.data.length; h++) {
              for (let s = 0; s < newArray10.length; s++) {
                LastSumRevenue += newArray7[s].dataNetto.data[h].revenueTotal;
                LastSumRevenueNet +=
                  newArray7[s].dataNetto.data[h].revenueTotal;
                LastMediaCost += newArray7[s].dataNetto.data[h].mediaCost;
                LastOperationFee +=
                  newArray7[s].dataNetto.data[h].operationCost;
                LastDates = newArray7[s].dataNetto.data[h].date.name;
                Lastgrossprofit += newArray7[s].dataNetto.data[h].grossProfit;
                LastImpressions +=
                  newArray7[s].dataNetto.data[h].impressionsGood;
                LastAddRequests += newArray7[s].dataNetto.data[h].addRequests;
                LastNetIncome += newArray7[s].dataNetto.data[h].netIncome;
                LastScannedImpressions +=
                  newArray7[s].dataNetto.data[h].scannedImpressions;
              }
              newArray12.push(LastSumRevenue);
              newArray13.push(LastMediaCost);
              newArray14.push(LastDates);
              newArray15.push(LastOperationFee);
              newArray16.push(Lastgrossprofit);
              newArray17.push(LastImpressions);
              newArray18.push(LastAddRequests);
              newArray19.push(LastSumRevenueNet);
              newArray20.push(LastNetIncome);
              newArray21.push(LastScannedImpressions);

              var uniqLastSumRevenue = [...new Set(newArray12)];
              var uniqLastMediaCost = [...new Set(newArray13)];
              var uniqLastDates = [...new Set(newArray14)];
              var uniqOperationFee = [...new Set(newArray15)];
              var uniqGrossProfit = [...new Set(newArray16)];
              var uniqImpressions = [...new Set(newArray17)];
              var uniqAddRequests = [...new Set(newArray18)];
              var uniqLastSumRevenueNet = [...new Set(newArray19)];
              var uniqLastNetIncome = [...new Set(newArray20)];
              var uniqScannedImpressions = [...new Set(newArray21)];

              LastSumRevenue = 0;
              LastMediaCost = 0;
              LastOperationFee = 0;
              Lastgrossprofit = 0;
              LastImpressions = 0;
              LastAddRequests = 0;
              LastSumRevenueNet = 0;
              LastNetIncome = 0;
              LastScannedImpressions = 0;
            }
          }
        }
      }
    }
    objRevenue = {
      lastRSum: uniqLastSumRevenue,
      lastMCSum: uniqLastMediaCost,
      lastDates: uniqLastDates,
      lastOFSum: uniqOperationFee,
      lastGPSum: uniqGrossProfit,
      lastISum: uniqImpressions,
      lastARSum: uniqAddRequests,
      lastRNSum: uniqLastSumRevenueNet,
      lastNISum: uniqLastNetIncome,
      lastSISum: uniqScannedImpressions,
    };
    BigArray.push(objRevenue);
    objRevenue = {};

    TheLastArray = [];

    //if (newArray9.length !== 0) {
    for (let i = 0; i < BigArray[0].lastDates.length; i++) {
      if (i != 0) {
        upanddown1 = BigArray[0].lastNISum[i] - BigArray[0].lastNISum[i - 1];
      } else {
        upanddown1 = BigArray[0].lastNISum[i];
      }
      obj10 = {
        id: i,
        seat: BigArray[0].lastDates[i],
        revenue: BigArray[0].lastRSum[i],
        mediacost: BigArray[0].lastMCSum[i],
        operationfee: BigArray[0].lastOFSum[i],
        grossprofit: BigArray[0].lastGPSum[i],
        impressions: BigArray[0].lastISum[i],
        addrequests: BigArray[0].lastARSum[i],
        revenueNet: BigArray[0].lastRNSum[i],
        marketplacefee: 0,
        scanningfee: 0,
        netincome: BigArray[0].lastNISum[i],
        scannedimpressions: BigArray[0].lastSISum[i],
        upanddown: upanddown1,
      };
      TheLastArray.push(obj10);
      uniqArray = [...new Set(TheLastArray)];
    }

    // }
  }

  const callIt = (a) => {
    setIsLoading(true);
    try {
      axios
        .get(
          `${MyUrl}/api/Dashboard/getDailyIncome?${a}${MyDateFrom}${MyDateTo}
        `,
          {
            headers: {
              Authorization: `${MyToken}`,
            },
          }
        )
        .then((res) => {
          setData1([]);
          newArray1 = [];
          const data1 = res.data;
          setData1(data1);
          for (let i = 0; i < data1.responseData.length; i++) {
            newArray1.push(data1.responseData[i]);
          }
          //  console.log(newArray1);
        })
        .catch((error) => {})
        .finally(() => {
          ForTable1();
          setIsLoading(false);
        });
    } catch (error) {}
  };

  const callIt3 = (a) => {
    setIsLoading1(true);
    MyArray1.length = 0;
    try {
      axios
        .get(
          `${MyUrl}/api/Dashboard/getDailyIncome?${a}${MyDateFrom}${MyDateTo}
          `,
          {
            headers: {
              Authorization: `${MyToken}`,
            },
          }
        )
        .then((res) => {
          const data2 = res.data;
          setData2(data2);
          for (let i = 0; i < data2.responseData.length; i++) {
            MyArray1.push(data2.responseData[i]);
          }
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading1(false);
        });
    } catch (error) {}
  };

  function ForTable1() {
    newArray5 = [];
    newArray6 = [];
    revenueSum = 0;
    mediacostSum = 0;
    operationfeeSum = 0;
    grossprofitSum = 0;
    partnerfeeSum = 0;
    profitmarginSum = 0;
    netincomeSum = 0;
    netincSum = 0;
    impressoinsSum = 0;
    addRequestsSum = 0;
    scannedImpressionsSum = 0;

    for (let i = 0; i < newArray1.length; i++) {
      const obj1 = {
        id: newArray1[i].id,
        seat: newArray1[i].name,
        revenue: newArray1[i].dataNetto.sumResp.revenueTotal,
        mediacost: newArray1[i].dataNetto.sumResp.mediaCost,
        operationfee: newArray1[i].dataNetto.sumResp.operationCost,
        grossprofit: newArray1[i].dataNetto.sumResp.grossProfit,
        partnerfee: newArray1[i].dataNetto.sumResp.partnerFee,
        profitmargin: newArray1[i].dataNetto.sumResp.grossProfitMargin,
        netincome: newArray1[i].dataNetto.sumResp.netIncome,
        netinc: newArray1[i].dataNetto.sumResp.procentNetIncome,
      };
      //      console.log(newArray1[i].dataNetto.sumResp.revenueTotal)
      revenueSum += newArray1[i].dataNetto.sumResp.revenueTotal;
      mediacostSum += newArray1[i].dataNetto.sumResp.mediaCost;
      operationfeeSum += newArray1[i].dataNetto.sumResp.operationCost;
      grossprofitSum += newArray1[i].dataNetto.sumResp.grossProfit;
      partnerfeeSum += newArray1[i].dataNetto.sumResp.partnerFee;
      profitmarginSum +=
        newArray1[i].dataNetto.sumResp.grossProfitMargin / newArray1.length;
      netincomeSum += newArray1[i].dataNetto.sumResp.netIncome;
      netincSum +=
        newArray1[i].dataNetto.sumResp.procentNetIncome / newArray1.length;
      impressoinsSum += newArray1[i].dataNetto.sumResp.impressionsGood;
      addRequestsSum += newArray1[i].dataNetto.sumResp.addRequests;

      scannedImpressionsSum +=
        newArray1[i].dataNetto.sumResp.scannedImpressions;
      newArray5.push(obj1);
    }
    const obj2 = {
      id: "22",
      seat: "aa",
      revenue: revenueSum.toFixed(2),
      mediacost: mediacostSum.toFixed(2),
      operationfee: operationfeeSum.toFixed(2),
      grossprofit: grossprofitSum.toFixed(2),
      partnerfee: partnerfeeSum.toFixed(2),
      profitmargin: profitmarginSum.toFixed(2),
      netincome: netincomeSum.toFixed(2),
      netinc: netincSum.toFixed(2),
    };
    newArray6.push(obj2);
    console.log(newArray5.length);
  }
  revSum1 = newArray5.reduce((acc, cur) => acc + cur.revenue, 0);
  MCSum1 = newArray5.reduce((acc, cur) => acc + cur.mediacost, 0);
  OFSum1 = newArray5.reduce((acc, cur) => acc + cur.operationfee, 0);
  GPSum1 = newArray5.reduce((acc, cur) => acc + cur.grossprofit, 0);
  PFSum1 = newArray5.reduce((acc, cur) => acc + cur.partnerfee, 0);
  PMSum1 = newArray5.reduce((acc, cur) => acc + cur.profitmargin, 0);
  NISum1 = newArray5.reduce((acc, cur) => acc + cur.netincome, 0);
  NSum1 = newArray5.reduce((acc, cur) => acc + cur.netinc, 0);

  ISum = uniqArray.reduce((acc, cur) => acc + cur.impressions, 0);
  SISum = uniqArray.reduce((acc, cur) => acc + cur.scannedimpressions, 0);
  ARSum = uniqArray.reduce((acc, cur) => acc + cur.addrequests, 0);
  GRSum = uniqArray.reduce((acc, cur) => acc + cur.revenue, 0);
  MFSum = uniqArray.reduce((acc, cur) => acc + cur.marketplacefee, 0);
  SFSum = uniqArray.reduce((acc, cur) => acc + cur.scanningfee, 0);
  revSum = uniqArray.reduce((acc, cur) => acc + cur.revenue, 0);
  MCSum = uniqArray.reduce((acc, cur) => acc + cur.mediacost, 0);
  OFSum = uniqArray.reduce((acc, cur) => acc + cur.operationfee, 0);
  GPSum = uniqArray.reduce((acc, cur) => acc + cur.grossprofit, 0);
  NISum = uniqArray.reduce((acc, cur) => acc + cur.netincome, 0);

  return (
    <div>
      {isLoading1 ? (
        <>
          <Skeleton active avatar>
            <List.Item.Meta
              avatar={<Avatar src={""} />}
              title={<a href={""}>{""}</a>}
              description={""}
            />
            {""}
          </Skeleton>{" "}
        </>
      ) : (
        <>
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <div>
              {MyArray1.map(function (item, i) {
                return (
                  <>
                    <Checkbox
                      onClick={() => {
                        const index1 = newArray9.indexOf(
                          data2.responseData[i].id
                        );
                        if (index1 > -1) {
                          newArray9.splice(index1, 1);
                        } else {
                          newArray9.push(data2.responseData[i].id);
                        }

                        const index = newArray.indexOf(
                          `&ids=${data2.responseData[i].id}`
                        );
                        if (index > -1) {
                          newArray.splice(index, 1);
                        } else {
                          newArray.push(`&ids=${data2.responseData[i].id}`);
                        }
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <a style={{ color: "#7e22ce", fontSize: 25 }}>
                      {MyArray1[i].name}
                    </a>
                  </>
                );
              })}
            </div>
            <br></br>
          </div>
        </>
      )}

      {isLoading ? (
        <>
          <Skeleton active avatar>
            <List.Item.Meta
              avatar={<Avatar src={""} />}
              title={<a href={""}>{""}</a>}
              description={""}
            />
            {""}
          </Skeleton>{" "}
        </>
      ) : (
        <div>
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <br></br>
            <Button
              style={{ margin: 5, backgroundColor: "#8b5cf6" }}
              variant="contained"
              onClick={() => {
                callIt(newArray.toString().replaceAll(",", ""));
                callIt4(newArray.toString().replaceAll(",", ""));
              }}
            >
              Filter
            </Button>
          </div>
          <br></br>
          <div style={{ backgroundColor: "white" }}>
            <div style={{ margin: 10 }}>
              <h5>Income Channel Overview</h5>
            </div>
            <div className="App" style={{ backgroundColor: "white" }}>
              {/* <button onClick={handleClick}>Export</button> */}

              <Table
                dataSource={newArray5}
                columns={columns}
                pagination={{
                  position: ["none", "none"],
                }}
                size="small"
                scroll={{ x: "max-content" }}
                bordered
                summary={() => (
                  <Table.Summary fixed>
                    <Table.Summary.Row
                      style={{
                        backgroundColor: "#b9d5ff91",
                      }}
                    >
                      {" "}
                      <Table.Summary.Cell index={0}>
                        {" "}
                        Total
                      </Table.Summary.Cell>{" "}
                      <Table.Summary.Cell index={1}>
                        {"$ " + revSum1.toLocaleString("en")}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2}>
                        {" "}
                        {"$ " + MCSum1.toLocaleString("en")}
                      </Table.Summary.Cell>{" "}
                      <Table.Summary.Cell index={3}>
                        {" "}
                        {"$ " + OFSum1.toLocaleString("en")}
                      </Table.Summary.Cell>{" "}
                      <Table.Summary.Cell index={4}>
                        {" "}
                        {"$ " + GPSum1.toLocaleString("en")}
                      </Table.Summary.Cell>{" "}
                      <Table.Summary.Cell index={5}>
                        {" "}
                        {"$ " + PFSum1.toLocaleString("en")}
                      </Table.Summary.Cell>{" "}
                      <Table.Summary.Cell index={6}>
                        {" "}
                        {"% " +
                          (
                            PMSum1.toLocaleString("en") / newArray5.length
                          ).toFixed(2)}{" "}
                        {/*should split on nuumber of compaines ! */}
                      </Table.Summary.Cell>{" "}
                      <Table.Summary.Cell index={7}>
                        {" "}
                        {"$ " + NISum1.toLocaleString("en")}
                      </Table.Summary.Cell>{" "}
                      <Table.Summary.Cell index={8}>
                        {" "}
                        {"% " +
                          (
                            NSum1.toLocaleString("en") / newArray5.length
                          ).toFixed(2)}{" "}
                        {/*should split on nuumber of compaines ! */}
                      </Table.Summary.Cell>{" "}
                    </Table.Summary.Row>
                  </Table.Summary>
                )}
              ></Table>
            </div>{" "}
            {/* <Box
              sx={{
                height: "100%",
                width: "100%",
                "& .cold": {
                  backgroundColor: "#ff943975",
                  color: "#1a3e72",
                },
                "& .hot": {
                  backgroundColor: "#b9d5ff91 ",
                  color: "#1a3e72",
                },
              }}
            >
              <DataGridPro
                autoHeight
                components={{
                  Toolbar: GridToolbar,
                }}
                rows={newArray5}
                columns={columns}
                pageSize={6}
                rowsPerPageOptions={[6]}
                // checkboxSelection
                // disableSelectionOnClick
                pinnedRows={pinnedRows}
                experimentalFeatures={{ rowPinning: true }}
                getCellClassName={(params) => {
                  if (params.id !== "Total" || params.value == null) {
                    return "";
                  }
                  return params.id === "Total" ? "hot" : "cold";
                }}
              />
            </Box> */}
          </div>{" "}
          <br></br>
          <IncomeGraph
            myArr={newArray4}
            myArr1={newArray}
            myArr2={newArray5}
            MyTrue={MyTrue}
          />
        </div>
      )}

      <br></br>
      {!isLoading2 ? (
        <>
          <Skeleton active avatar>
            <List.Item.Meta
              avatar={<Avatar src={""} />}
              title={<a href={""}>{""}</a>}
              description={""}
            />
            {""}
          </Skeleton>{" "}
        </>
      ) : (
        <MasterIncomeOverViewTable
          uniqArray1={uniqArray}
          ISum={ISum}
          SISum={SISum}
          ARSum={ARSum}
          GRSum={GRSum}
          MFSum={MFSum}
          SFSum={SFSum}
          revSum={revSum}
          MCSum={MCSum}
          OFSum={OFSum}
          GPSum={GPSum}
          PFSum={PFSum}
          PMSum={PMSum}
          NISum={NISum}
          NSum={NSum}
        />
      )}
    </div>
  );
}

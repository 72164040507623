import React from "react";
import DashboardGetDataAndFilter from "./DashboardComponents/DashboardGetDataAndFilter";

export default function Dashboard() {
  return (
    <div>
      <DashboardGetDataAndFilter />
    </div>
  );
}

import React from "react";
import DemandGraph from "./DemandComponents/DemandGraph";
import DemandTable1 from "./DemandComponents/DemandTable1";
import DemandTable2 from "./DemandComponents/DemandTable2";
import PickDate from "./DemandComponents/PickDate";

export default function DemandBody() {
  return (
    <div className="container">
      <PickDate />
    </div>
  );
}

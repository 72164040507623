import React from "react";
import IncomeGetDataAndFilter from "./IncomeComponents/IncomeGetDataAndFilter";

export default function Income() {
  return (
    <div>
      <IncomeGetDataAndFilter />
    </div>
  );
}

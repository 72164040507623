import { parse } from "date-and-time";
import React, { useEffect, useState } from "react";
import MainPageLeftDrawer from "./MainPageLeftDrawer";
import NewHeader from "./NewHeader";
import axios from "axios";
import { MyUrl } from "../App";
import { withRouter, useHistory, useLocation } from "react-router-dom";

var jwtToken = "";
export default function MainPage() {
  //console.log(myPassword);
  //useEffect(() => {
  const items1 = localStorage.getItem("User");
  const [CheckTypeOfUser, setCheckTypeOfUser] = useState("");
  //useEffect(() => {

  const urlParams1 = new URLSearchParams(window.location.search);
  console.log(urlParams1);
  const tokenFromUrl = urlParams1.get("Authorization");
  console.log(tokenFromUrl);
  if (tokenFromUrl != null) {
    sessionStorage.setItem("Token", JSON.stringify(tokenFromUrl));
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("Token", JSON.stringify(tokenFromUrl));
    });
  }
  //localStorage.setItem("Token", JSON.stringify(tokenFromUrl));
  //const items2 = localStorage.getItem("Token");
  //console.log(items2);
  console.log(tokenFromUrl);

  axios
    .post(
      `${MyUrl}/api/Account/loginWithJwt`,
      {},
      { headers: { Authorization: `${tokenFromUrl}` }, withCredentials: true }
    )
    .then((response) => {
      console.log(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
  const timer = setTimeout(() => {
    const currentUrl = window.location.href;
    const newUrl = currentUrl.replace(
      /(\?|&)myObject=[^&]*|(\?|&)Authorization=[^&]*/gi,
      ""
    );
    window.history.replaceState({}, "", newUrl);
    console.log("This will run after 1 second!");
  }, 0);
  //return () => clearTimeout(timer);
  useEffect(() => {}, []);

  // if (urlParams1.has("myObject") && urlParams1.has("Authorization")) {
  //   const encodedObject = urlParams1.get("myObject");
  //   const decodedString = atob(encodedObject);
  //   console.log(decodedString);

  //   const decodedObject = JSON.parse(decodedString);
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const tokenFromUrl = urlParams.get("Authorization");
  //   console.log(tokenFromUrl);

  //   // if (tokenFromUrl !== null) {
  //   //   var token = tokenFromUrl;
  //   //   localStorage.setItem("Token", JSON.stringify(token));
  //   //   const MyToken = JSON.parse(localStorage.getItem("Token"));
  //   // }
  //   const MyToken = JSON.parse(localStorage.getItem("Token"));
  //   const decodedToken = atob(MyToken.split(" ")[1]);
  //   const [username, password] = decodedToken.split(":");
  //   //console.log(decodedToken);

  //   sessionStorage.setItem("User", JSON.stringify(decodedObject));
  //   localStorage.setItem("User", JSON.stringify(decodedObject));

  //   sessionStorage.setItem("Password", JSON.stringify(password));
  //   localStorage.setItem("Password", JSON.stringify(password));

  //   // const items1 = localStorage.getItem("User");
  //   // if (items1 === null) {
  //   //   const items = sessionStorage.getItem("User");
  //   //   const pass = sessionStorage.getItem("Password");
  //   //   var parsed = JSON.parse(items1);
  //   //   console.log(parsed.role);

  //   //   //  var myPassword = JSON.parse(pass);
  //   // } else {
  //   //   const items = sessionStorage.setItem("User", JSON.stringify(items1));
  //   //   const pass = sessionStorage.setItem("Password", JSON.stringify(password));
  //   //   console.log(items1);

  //   //   var parsed = JSON.parse(items1);
  //   //   console.log(parsed.role);

  //   //   // var myPassword = JSON.parse(password);
  //   // }
  // }

  // useEffect(() => {
  //   if (items1 === null) {
  //     const items = sessionStorage.getItem("User");
  //     var parsed = JSON.parse(items);
  //     setCheckTypeOfUser(parsed.role);
  //     jwtToken = parsed.token;
  //     const base64Url = jwtToken.split(".")[1];
  //     const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  //     const payload = JSON.parse(atob(base64));
  //     const expirationDate = new Date(payload.exp * 1000);
  //     const currentTime = new Date().getTime(); // Get the current time in milliseconds
  //     console.log(jwtToken);

  //     if (expirationDate < currentTime) {
  //       try {
  //         axios
  //           .post(`${MyUrl}/api/RefreshToken/refresh`, {
  //             headers: {
  //               Authorization: `Bearer ${jwtToken}`,
  //             },
  //           })
  //           .then((response) => {
  //             parsed.token = response.data.accessToken;
  //             const updatedObjString = JSON.stringify(parsed);
  //             sessionStorage.setItem("User", updatedObjString);
  //           })
  //           .catch((error) => {
  //             console.log(error);
  //           });
  //       } catch (error) {}
  //     } else {
  //       console.log("Token is still valid");
  //     }
  //   } else {
  //     const items = sessionStorage.setItem("User", JSON.stringify(items1));
  //     var parsed = JSON.parse(items1);
  //     setCheckTypeOfUser(parsed.role);
  //     jwtToken = parsed.token;
  //   }
  // }, []);

  // const config = {
  //   headers: { Authorization: `${tokenFromUrl}` },
  // };

  // useEffect(async () => {
  //   // var userObj = {
  //   //   userName: name,
  //   //   password: password,
  //   // };
  //   console.log("yes");
  //   console.log(MyUrl);
  //   try {
  //     axios
  //       .post(
  //         `${MyUrl}/api/Account/loginWithJwt
  //         `,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${jwtToken}`,
  //           },
  //         }
  //         // userObj,
  //         // {
  //         //   withCredentials: true,
  //         //   Auth: {
  //         //     Username: name,
  //         //     Password: password,
  //         //   },
  //         // }
  //       )
  //       .then((res) => {
  //         const Client = res.data;
  //       })
  //       .catch((error) => {});
  //   } catch (error) {}

  // }, []);
  // const timer = setTimeout(() => {
  //   const currentUrl = window.location.href;
  //   const newUrl = currentUrl.replace(
  //     /(\?|&)myObject=[^&]*|(\?|&)Authorization=[^&]*/gi,
  //     ""
  //   );
  //   window.history.replaceState({}, "", newUrl);
  //   console.log("This will run after 1 second!");
  // }, 0);
  //return () => clearTimeout(timer);
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  function useWindowSize() {
    const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
    }, []);
    return size;
  }
  const [height, width] = useWindowSize();
  console.log(width);
  return (
    <div>
      <MainPageLeftDrawer WidthFromMainPage={width} />
    </div>
  );
}

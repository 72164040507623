import React from "react";
import GlobalGetDataAndFilter from "./GlobalComponents/GlobalGetDataAndFilter";

export default function Global() {
  return (
    <div>
      <GlobalGetDataAndFilter />
    </div>
  );
}

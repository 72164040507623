import React, { useState, useRef } from "react";
import Box from "@mui/material/Box";
import clsx from "clsx";
import { Input, Space, Table, Typography, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import "antd/dist/antd.css";
import "../../Pages/styles.css";

import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";

export default function MasterIncomeOverViewTable({
  uniqArray1,
  ISum,
  SISum,
  ARSum,
  GRSum,
  MFSum,
  SFSum,
  revSum,
  MCSum,
  OFSum,
  GPSum,
  PFSum,
  PMSum,
  NISum,
  NSum,
}) {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    // { field: "id", headerName: "ID", width: 90 },
    {
      dataIndex: "seat",
      title: "Dates",
      flex: 1,
      minWidth: 100,
      editable: true,

      ...getColumnSearchProps("seat"),
      sorter: (a, b) => a.seat.length - b.seat.length,
      sortDirections: ["descend", "ascend"],
    },

    {
      title: "Impressions",
      dataIndex: "impressions",
      flex: 1,
      type: "number",

      minWidth: 100,
      editable: true,
      ...getColumnSearchProps("impressions"),
      sorter: (a, b) => a.impressions - b.impressions,
      render: (value) => {
        return value.toLocaleString("en");
      },
    },
    {
      dataIndex: "scannedimpressions",
      title: "Scanned Impressions",
      flex: 1,
      minWidth: 100,
      editable: true,
      ...getColumnSearchProps("scannedimpressions"),
      sorter: (a, b) => a.scannedimpressions - b.scannedimpressions,
      render: (value) => {
        return Number(value * 1).toLocaleString("en");
      },
    },

    {
      dataIndex: "addrequests",
      title: "Add Request",
      flex: 1,
      minWidth: 100,
      editable: true,
      ...getColumnSearchProps("addrequests"),
      sorter: (a, b) => a.addrequests - b.addrequests,
      render: (value) => {
        return Number(value * 1).toLocaleString("en");
      },
    },
    {
      dataIndex: "revenue",
      title: "Gross Revenue",
      flex: 1,
      minWidth: 100,
      editable: true,
      ...getColumnSearchProps("revenue"),
      sorter: (a, b) => a.revenue - b.revenue,
      render: (value) => {
        return Number(value * 1).toLocaleString("en");
      },
    },
    {
      dataIndex: "revenueNet",
      title: "Net Revenue",
      flex: 1,
      minWidth: 100,
      editable: true,
      ...getColumnSearchProps("revenue"),
      sorter: (a, b) => a.revenue - b.revenue,
      render: (value) => {
        return "$ " + Number(value * 1).toLocaleString("en");
      },
    },

    {
      dataIndex: "mediacost",
      title: "Media Cost",
      type: "number",
      flex: 1,
      minWidth: 100,
      editable: true,
      ...getColumnSearchProps("mediacost"),
      sorter: (a, b) => a.mediacost - b.mediacost,
      render: (value) => {
        return "$ " + Number(value * 1).toLocaleString("en");
      },
    },
    {
      dataIndex: "marketplacefee",
      title: "Marketplace Fee",
      type: "number",
      flex: 1,
      minWidth: 100,
      editable: true,
      ...getColumnSearchProps("marketplacefee"),
      sorter: (a, b) => a.marketplacefee - b.marketplacefee,
      render: (value) => {
        return "$ " + Number(value * 1).toLocaleString("en");
      },
    },
    {
      dataIndex: "operationfee",
      title: "Ad Serving Fee",
      flex: 1,
      minWidth: 100,
      editable: true,
      ...getColumnSearchProps("operationfee"),
      sorter: (a, b) => a.operationfee - b.operationfee,
      render: (value) => {
        return "$ " + Number(value * 1).toLocaleString("en");
      },
    },
    {
      dataIndex: "scanningfee",
      title: "Scanning Fee",
      flex: 1,
      minWidth: 100,
      editable: true,
      ...getColumnSearchProps("scanningfee"),
      sorter: (a, b) => a.scanningfee - b.scanningfee,
      render: (value) => {
        return "$ " + Number(value * 1).toLocaleString("en");
      },
    },

    {
      dataIndex: "grossprofit",
      title: "Gross Profit",
      flex: 1,
      minWidth: 100,
      editable: true,
      ...getColumnSearchProps("grossprofit"),
      sorter: (a, b) => a.grossprofit - b.grossprofit,
      render: (value) => {
        return "$ " + Number(value * 1).toLocaleString("en");
      },
    },
    {
      dataIndex: "netincome",
      title: "Net Income",
      flex: 1,
      minWidth: 100,
      editable: true,
      ...getColumnSearchProps("netincome"),
      sorter: (a, b) => a.netincome - b.netincome,
      render: (value) => {
        return "$ " + Number(value * 1).toLocaleString("en");
      },
    },
    {
      dataIndex: "upanddown",
      title: "",
      flex: 1,
      minWidth: 100,
      editable: true,
      ...getColumnSearchProps("upanddown"),
      sorter: (a, b) => a.upanddown - b.upanddown,
      render(text, record) {
        if (text == null) {
          return "";
        }
        return {
          props: {
            style: { background: parseInt(text) > 0 ? "#10b981" : "#f43f5e" },
          },
          children: <div>$ {text}</div>,
        };
      },
    },
  ];

  console.log(ISum);
  return (
    <div>
      <div className="App" style={{ backgroundColor: "white" }}>
        {/* <button onClick={handleClick}>Export</button> */}

        <Table
          dataSource={uniqArray1}
          columns={columns}
          pagination={{
            position: ["none", "none"],
          }}
          size="small"
          scroll={{ x: "max-content" }}
          bordered
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row
                style={{
                  backgroundColor: "#b9d5ff91",
                }}
              >
                {" "}
                <Table.Summary.Cell index={0}> Total</Table.Summary.Cell>{" "}
                <Table.Summary.Cell index={1}>
                  {Number(ISum * 1).toLocaleString("en")}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  {Number(SISum * 1).toLocaleString("en")}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  {Number(ARSum * 1).toLocaleString("en")}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  {Number(GRSum * 1).toLocaleString("en")}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  {"$ " + revSum.toLocaleString("en")}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                  {" "}
                  {"$ " + Number(MCSum * 1).toLocaleString("en")}
                </Table.Summary.Cell>{" "}
                <Table.Summary.Cell index={2}>
                  {" "}
                  {"$ " + Number(MFSum * 1).toLocaleString("en")}
                </Table.Summary.Cell>{" "}
                <Table.Summary.Cell index={3}>
                  {" "}
                  {"$ " + Number(OFSum * 1).toLocaleString("en")}
                </Table.Summary.Cell>{" "}
                <Table.Summary.Cell index={3}>
                  {" "}
                  {"$ " + Number(SFSum * 1).toLocaleString("en")}
                </Table.Summary.Cell>{" "}
                <Table.Summary.Cell index={4}>
                  {" "}
                  {"$ " + Number(GPSum * 1).toLocaleString("en")}
                </Table.Summary.Cell>{" "}
                <Table.Summary.Cell index={5}>
                  {" "}
                  {"$ " + Number(NISum * 1).toLocaleString("en")}
                </Table.Summary.Cell>{" "}
                <Table.Summary.Cell index={7}>
                  {" "}
                  {"$ " + Number(NISum * 1).toLocaleString("en")}
                </Table.Summary.Cell>{" "}
              </Table.Summary.Row>
            </Table.Summary>
          )}
        ></Table>
      </div>{" "}
    </div>
  );
}

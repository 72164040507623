import React, { useEffect } from "react";
import Body from "../components/Body";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ForCheck from "./ForCheck";
import { Outlet, Link, withRouter, useHistory } from "react-router-dom";

function Dashboard(props) {
  let history = useHistory();

  const GoToUsersPanel = async () => {
    //   history.push("/Dashboard");
  };
  function refreshPage() {
    window.location.reload();
  }

  useEffect(() => {
    GoToUsersPanel();
    //  refreshPage();
  }, []);

  return (
    <div>
      <Header />
      <Body />
      <Footer />
    </div>
  );
}
export default withRouter(Dashboard);

import React, { useState, useEffect } from "react";
import DashboardTopOfPage from "./DashboardTopOfPage";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DashboardBySeat from "./DashboardBySeat";
import DashboardTotal from "./DashboardTotal";
import moment from "moment";
import axios from "axios";
import { MyUrl } from "../../../App";
import Skeleton from "@mui/material/Skeleton";
import jwt_decode from "jwt-decode";
var MyChooce = "By Seat";
var MyChooce2 = "Daily";

var MySum = [];
var ObjDailyNetIncome = {};
var DailyNetIncomeToSend = [];

var MySum1 = [];
var ObjMonthlyNetIncome = {};
var MonthlyNetIncomeToSend = [];

var MySum2 = [];
var ObjDailyNetIncomeTotal = {};
var DailyNetIncomeToSendTotal = [];

var MySum3 = [];
var ObjMonthlyNetIncomeTotal = {};
var MonthlyNetIncomeToSendTotal = [];

var SumDailyForTopOfPage = 0;
var SumMonthlyForTopOfPage = 0;

var SumDailyForTopOfPageToSend = 0;
var SumMonthlyForTopOfPageToSend = 0;
var jwtToken = "";
export default function DashboardGetDataAndFilter() {
  // const items1 = localStorage.getItem("Token");
  // const MyToken = JSON.parse(localStorage.getItem("Token"));
  // const base64Url = MyToken.split(".")[1];
  // const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  // const payload = JSON.parse(atob(base64));
  // const expirationDate = new Date(payload.exp * 1000);
  // const currentTime = new Date().getTime(); // Get the current time in milliseconds
  //const now = new Date(Date.UTC());
  //console.log(currentTime);
  //console.log(now);
  const [CheckTypeOfUser, setCheckTypeOfUser] = useState("");
  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();

  const RefreshFunc = () => {
    const token = sessionStorage.getItem("Token");
    var parsed = JSON.parse(token);
    console.log(parsed);
    const decodedToken = jwt_decode(token);
    const role =
      decodedToken[
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
      ];
    setCheckTypeOfUser(role);
    jwtToken = JSON.parse(token); //parsed
    const base64Url = jwtToken.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const payload = JSON.parse(atob(base64));
    const expirationDate = new Date(payload.exp * 1000);
    const currentTime = new Date().getTime(); // Get the current time in milliseconds
    //  const now =new Date(Date.UTC())
    if (expirationDate < currentTime) {
      try {
        axios
          .post(
            `${MyUrl}/api/RefreshToken/refresh`,
            { accessToken: JSON.parse(token).replace("Bearer ", "") },
            { withCredentials: true }
          )
          .then((response) => {
            parsed = response.data.accessToken;
            const updatedObjString = JSON.stringify(response.data.accessToken);
            sessionStorage.setItem("Token", updatedObjString);
            GetDailyIncomeFunc(response.data.accessToken);
            GetMonthlyIncomeFunc(response.data.accessToken);
            return () => {
              source.cancel("Component unmounted");
            };
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("Token is still valid");
      const items = sessionStorage.getItem("Token");
      var parsed = JSON.parse(items);
      jwtToken = JSON.parse(items); //parsed
      GetDailyIncomeFunc(jwtToken);
      GetMonthlyIncomeFunc(jwtToken);
      return () => {
        source.cancel("Component unmounted");
      };
    }
  };

  useEffect(() => {
    RefreshFunc();
  }, []);

  const today = moment(); // get current date using Moment.js
  const firstDayOfMonth = moment().startOf("month"); // get first day of the current month using Moment.js
  const diffInDays = today.diff(firstDayOfMonth, "days"); // calculate difference in days
  const [age, setAge] = React.useState("Daily");
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const [CompColor, setCompColor] = useState(0);
  const Choose = ["By Seat", "Total"];

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState("");

  const [isLoading1, setIsLoading1] = useState(true);
  const [data1, setData1] = useState("");

  const Today = moment().add("days").format("YYYY-MM-DD");
  const yesterday = moment().subtract(1, "days").format("YYYY-MM-DD");

  //hay bnkes shhar
  //.subtract(1, "months")
  var LastDayInMonth = moment(LastDayInMonth)
    .endOf("month")
    .format("YYYY-MM-DD");

  var FirstDayInMonth = moment(FirstDayInMonth)
    .startOf("month")
    .format("YYYY-MM-DD");
  const currentDate = moment();
  const daysInCurrentMonth = currentDate.daysInMonth();

  const GetDailyIncomeFunc = (a) => {
    const items = sessionStorage.getItem("Token");
    var parsed = JSON.parse(items);
    const token = sessionStorage.getItem("Token");
    const decodedToken = jwt_decode(token);
    const role =
      decodedToken[
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
      ];

    setIsLoading(true);
    try {
      let base64 = require("base-64"); // install it before use from npm i base-64
      axios
        .get(
          `${MyUrl}/api/Dashboard/getDailyIncome?&startDate=${Today}&endDate=${Today}`,
          {
            headers: {
              Authorization: `Bearer ${a.replace("Bearer ", "")}`,
            },
            cancelToken: source.token,
          }
        )
        .then((res) => {
          MySum = [];
          DailyNetIncomeToSend = [];
          const data = res.data;
          setData(data);

          //For daily by seat
          for (let i = 0; i < data.responseData.length; i++) {
            for (
              let j = 0;
              j < data.responseData[i].dataNetto.data.length;
              j++
            ) {
              MySum.push(data.responseData[i].dataNetto.data[j].netIncome);
              ObjDailyNetIncome = {
                name: data.responseData[i].name,
                netIncome:
                  role === "Partner"
                    ? Number(
                        data.responseData[i].dataNetto.data[
                          j
                        ].netProfit.toFixed(2)
                      )
                    : Number(
                        data.responseData[i].dataNetto.data[
                          j
                        ].netIncome.toFixed(2)
                      ),
                hour: data.responseData[i].dataNetto.data[j].hour.name,
              };
              DailyNetIncomeToSend.push(ObjDailyNetIncome);
              ObjDailyNetIncome = {};
            }
            console.log(DailyNetIncomeToSend);
          }
          //End for daily by seat
          //For Total
          MySum2 = [];
          ObjDailyNetIncomeTotal = {};
          DailyNetIncomeToSendTotal = [];
          const netIncomeByHour = data.responseData.reduce((acc, obj) => {
            const data = obj.dataNetto.data;
            data.forEach((hourData) => {
              const hour = hourData.hour.id;
              if (!acc[hour]) {
                acc[hour] = 0;
              }
              acc[hour] +=
                role === "Partner" ? hourData.netProfit : hourData.netIncome;
            });
            return acc;
          }, {});
          //For TopOfPage
          SumDailyForTopOfPage = 0;
          SumDailyForTopOfPageToSend = 0;
          //console.log("data.responseData[i].dataNetto.data");

          for (let i = 0; i < 1; i++) {
            //console.log(data.responseData[i].dataNetto.data);

            for (
              let j = 0;
              j < data.responseData[i].dataNetto.data.length;
              j++
            ) {
              SumDailyForTopOfPage += netIncomeByHour[j];
            }
            SumDailyForTopOfPageToSend =
              SumDailyForTopOfPage / data.responseData[i].dataNetto.data.length;
            SumDailyForTopOfPageToSend *= 24;
            //console.log("data.responseData[i].dataNetto.data.length");

            //console.log(data.responseData[i].dataNetto.data.length);
          }
          //End for TopOfPage
          for (let i = 0; i < 1; i++) {
            for (
              let j = 0;
              j < data.responseData[i].dataNetto.data.length;
              j++
            ) {
              ObjDailyNetIncomeTotal = {
                hour: j,
                netIncome: Number(netIncomeByHour[j].toFixed(2)),
                name: "Seats Overview",
              };
              DailyNetIncomeToSendTotal.push(ObjDailyNetIncomeTotal);
            }
            ObjDailyNetIncomeTotal = {};
          }
          //End for Total
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading(false);
        });
    } catch (error) {}
  };

  var FirstDayInMonth = moment(FirstDayInMonth)
    .startOf("month")
    .format("YYYY-MM-DD");
  const DaysOfMonth = moment().daysInMonth();
  const TheDay = moment().add(1, "days").format("YYYY-MM-DD");
  const GetMonthlyIncomeFunc = (a) => {
    const token = sessionStorage.getItem("Token");
    const decodedToken = jwt_decode(token);
    const role =
      decodedToken[
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
      ];
    const items = sessionStorage.getItem("Token");
    //console.log(items);
    var parsed = JSON.parse(items);
    //console.log(parsed);

    MySum1 = [];
    ObjMonthlyNetIncome = {};
    MonthlyNetIncomeToSend = [];
    setIsLoading1(true);
    try {
      axios
        .get(
          `${MyUrl}/api/Dashboard/getMonthlyIncome?startDate=${FirstDayInMonth}&endDate=${LastDayInMonth}`, //${yesterday}
          {
            headers: {
              Authorization: `Bearer ${a.replace("Bearer ", "")}`,
            },
            cancelToken: source.token,
          }
        )
        .then((res) => {
          const data1 = res.data;
          setData1(data1);
          //For monthly by seat
          for (let i = 0; i < data1.responseData.length; i++) {
            for (
              let j = 0;
              j < data1.responseData[i].dataNetto.data.length;
              j++
            ) {
              MySum1.push(data1.responseData[i].dataNetto.data[j].netIncome);

              ObjMonthlyNetIncome = {
                name: data1.responseData[i].name,
                netIncome:
                  role === "Partner"
                    ? Number(
                        data1.responseData[i].dataNetto.data[
                          j
                        ].netProfit.toFixed(2)
                      )
                    : Number(
                        data1.responseData[i].dataNetto.data[
                          j
                        ].netIncome.toFixed(2)
                      ),
                date: data1.responseData[i].dataNetto.data[j].date.id,
              };
              MonthlyNetIncomeToSend.push(ObjMonthlyNetIncome);
              ObjMonthlyNetIncome = {};
            }
          }

          for (
            let i = 1;
            i < DaysOfMonth - data1.responseData[0].dataNetto.data.length;
            i++
          ) {
            ObjMonthlyNetIncome = {
              name: "",
              netIncome: "",
              date: moment().add(i, "days").format("YYYY-MM-DD"),
            };
            MonthlyNetIncomeToSend.push(ObjMonthlyNetIncome);
            ObjMonthlyNetIncome = {};
          }

          //End for monthly by seat

          //For Total
          MySum3 = [];
          ObjMonthlyNetIncomeTotal = {};
          MonthlyNetIncomeToSendTotal = [];
          console.log(CheckTypeOfUser);
          console.log(data1.responseData);

          const netIncomeByDate = data1.responseData.reduce((acc, obj) => {
            const data1 = obj.dataNetto.data;
            data1.forEach((dateData, i) => {
              const date1 = i; //hourData.date.id
              if (!acc[date1]) {
                acc[date1] = 0;
              }
              acc[date1] +=
                role === "Partner" ? dateData.netProfit : dateData.netIncome;
            });
            return acc;
          }, {});
          for (let i = 0; i < 1; i++) {
            for (
              let j = 0;
              j < data1.responseData[i].dataNetto.data.length;
              j++
            ) {
              ObjMonthlyNetIncomeTotal = {
                date: data1.responseData[i].dataNetto.data[j].date.id,
                netIncome: Number(netIncomeByDate[j].toFixed(2)),
                name: "Seats Overview",
              };
              MonthlyNetIncomeToSendTotal.push(ObjMonthlyNetIncomeTotal);
            }
            ObjMonthlyNetIncomeTotal = {};
          }
          for (
            let i = 1;
            i < DaysOfMonth - data1.responseData[0].dataNetto.data.length;
            i++
          ) {
            //   DaysOfMonth - data1.responseData[0].dataNetto.data.length + i
            // );
            ObjMonthlyNetIncomeTotal = {
              name: "",
              netIncome: "",
              date: moment().add(i, "days").format("YYYY-MM-DD"),
            };
            MonthlyNetIncomeToSendTotal.push(ObjMonthlyNetIncomeTotal);
            ObjMonthlyNetIncomeTotal = {};
          }
          //End for Total

          //For TopOfPage
          SumMonthlyForTopOfPage = 0;
          SumMonthlyForTopOfPageToSend = 0;
          for (let i = 0; i < 1; i++) {
            for (
              let j = 0;
              j < diffInDays; //data1.responseData[i].dataNetto.data.length
              j++
            ) {
              SumMonthlyForTopOfPage += netIncomeByDate[j];
            }

            // SumMonthlyForTopOfPageToSend =
            //   SumMonthlyForTopOfPage /
            //     data1.responseData[i].dataNetto.data.length -
            //   1;
            SumMonthlyForTopOfPageToSend = SumMonthlyForTopOfPage / diffInDays;
            SumMonthlyForTopOfPageToSend *= daysInCurrentMonth;
          }
          //End for TopOfPage
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading1(false);
        });
    } catch (error) {}
  };

  return (
    <div>
      {/* <button
        onClick={() => {
          source.cancel("Request canceled");
          GetDailyIncomeFunc();
          GetMonthlyIncomeFunc();
        }}
      >
        aa
      </button> */}
      <>
        <DashboardTopOfPage
          isLoading={isLoading}
          isLoading1={isLoading1}
          SumMonthlyForTopOfPageToSend={SumMonthlyForTopOfPageToSend}
          SumDailyForTopOfPageToSend={SumDailyForTopOfPageToSend}
        />
      </>

      <div style={{ paddingLeft: "2.5%", paddingRight: "1%", paddingTop: 30 }}>
        <div style={{ display: "flex" }}>
          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
            }}
          >
            {" "}
            <div
              style={{
                display: "flex",
              }}
            >
              {Choose.map((responseData, i) => (
                <div
                  style={{
                    background: "#f4f2f4",
                    borderTopLeftRadius: i === 0 ? 50 : 0,
                    borderBottomLeftRadius: i === 0 ? 50 : 0,
                    borderTopRightRadius: i === Choose.length - 1 ? 50 : 0,
                    borderBottomRightRadius: i === Choose.length - 1 ? 50 : 0,
                    height: 40,
                    alignItems: "center",
                    paddingLeft: i === 0 ? 3 : 0,
                    paddingRight: i === Choose.length - 1 ? 3 : 0,
                    display: "flex",
                  }}
                >
                  <button
                    style={{
                      minWidth: 80,
                      borderWidth: 0,
                      borderRadius: 50,
                      background: CompColor === i ? "white" : "#f4f2f4",
                      height: 35,
                      fontWeight: CompColor === i ? "bold" : "",
                      fontSize: 14,
                    }}
                    onClick={() => {
                      setCompColor(i);

                      MyChooce = responseData;
                    }}
                  >
                    {responseData}
                  </button>
                  {i !== Choose.length - 1 ? (
                    <div
                      style={{
                        width: 1,
                        height: 16,
                        flexGrow: 0,
                        background: "#c8c7cb",
                      }}
                    ></div>
                  ) : (
                    <></>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              //   backgroundColor: "blue",
              justifyContent: "flex-end",
            }}
          >
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <Select value={age} onChange={handleChange}>
                  <MenuItem
                    value={"Daily"}
                    onClick={() => {
                      MyChooce2 = "Daily";
                    }}
                  >
                    Daily
                  </MenuItem>
                  <MenuItem
                    value={"Monthly"}
                    onClick={() => {
                      MyChooce2 = "Monthly";
                    }}
                  >
                    Monthly
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>
        </div>
      </div>
      {MyChooce === "By Seat" ? (
        <>
          {MyChooce2 === "Daily" ? (
            <>
              {isLoading ? (
                <div
                  style={{
                    paddingRight: "1%",
                    paddingLeft: "2.5%",
                  }}
                >
                  <Skeleton
                    animation="wave"
                    //sx={{ bgcolor: "grey.900" }}
                    // variant="rectangular"
                    //width={210}
                    height={500}
                    borderRadius={100}
                  />
                </div>
              ) : (
                <>
                  <DashboardBySeat
                    MyChooce2={MyChooce2}
                    DailyNetIncomeToSend={DailyNetIncomeToSend}
                    MonthlyNetIncomeToSend={MonthlyNetIncomeToSend}
                  />
                </>
              )}
            </>
          ) : (
            <>
              {" "}
              {isLoading1 ? (
                <div
                  style={{
                    paddingRight: "1%",
                    paddingLeft: "2.5%",
                  }}
                >
                  <Skeleton
                    animation="wave"
                    //sx={{ bgcolor: "grey.900" }}
                    // variant="rectangular"
                    //width={210}
                    height={500}
                    borderRadius={100}
                  />
                </div>
              ) : (
                <>
                  <DashboardBySeat
                    MyChooce2={MyChooce2}
                    DailyNetIncomeToSend={DailyNetIncomeToSend}
                    MonthlyNetIncomeToSend={MonthlyNetIncomeToSend}
                  />
                </>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <>
            {MyChooce2 === "Daily" ? (
              <>
                {isLoading ? (
                  <div
                    style={{
                      paddingRight: "1%",
                      paddingLeft: "2.5%",
                    }}
                  >
                    <Skeleton
                      animation="wave"
                      //sx={{ bgcolor: "grey.900" }}
                      // variant="rectangular"
                      //width={210}
                      height={500}
                      borderRadius={100}
                    />
                  </div>
                ) : (
                  <>
                    {isLoading ? (
                      <div
                        style={{
                          paddingRight: "1%",
                          paddingLeft: "2.5%",
                        }}
                      >
                        <Skeleton
                          animation="wave"
                          //sx={{ bgcolor: "grey.900" }}
                          // variant="rectangular"
                          //width={210}
                          height={500}
                          borderRadius={100}
                        />
                      </div>
                    ) : (
                      <>
                        <DashboardTotal
                          MyChooce2={MyChooce2}
                          DailyNetIncomeToSendTotal={DailyNetIncomeToSendTotal}
                          MonthlyNetIncomeToSendTotal={
                            MonthlyNetIncomeToSendTotal
                          }
                        />
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {" "}
                {isLoading1 ? (
                  <div
                    style={{
                      paddingRight: "1%",
                      paddingLeft: "2.5%",
                    }}
                  >
                    <Skeleton
                      animation="wave"
                      //sx={{ bgcolor: "grey.900" }}
                      // variant="rectangular"
                      //width={210}
                      height={500}
                      borderRadius={100}
                    />
                  </div>
                ) : (
                  <>
                    {isLoading1 ? (
                      <div
                        style={{
                          paddingRight: "1%",
                          paddingLeft: "2.5%",
                        }}
                      >
                        <Skeleton
                          animation="wave"
                          //sx={{ bgcolor: "grey.900" }}
                          // variant="rectangular"
                          //width={210}
                          height={500}
                          borderRadius={100}
                        />
                      </div>
                    ) : (
                      <>
                        <DashboardTotal
                          MyChooce2={MyChooce2}
                          DailyNetIncomeToSendTotal={DailyNetIncomeToSendTotal}
                          MonthlyNetIncomeToSendTotal={
                            MonthlyNetIncomeToSendTotal
                          }
                        />
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        </>
      )}
    </div>
  );
}

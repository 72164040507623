import React from "react";
import CustomReportingFilters from "./CustomReportingComponents/CustomReportingFilters";
import CustomReportingResults from "./CustomReportingComponents/CustomReportingResults";

export default function CustomReportingBody() {
  return (
    <div className="container">
      <CustomReportingFilters />
      <CustomReportingResults />
    </div>
  );
}

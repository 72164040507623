import axios from "axios";
import React, { useState, useEffect, useCallback } from "react";
import { withRouter, useHistory, useLocation } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import { azAZ } from "@mui/material/locale";
import { Empty } from "antd";
import moment from "moment";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import { style } from "@mui/system";
import { Key } from "@mui/icons-material";
import { Avatar, List, Skeleton, Switch } from "antd";
import GlobalFirstGraph from "./GlobalFirstGraph";
import GlobalDonut from "./GlobalDonut";
import GlobalTable1 from "./GlobalTable1";
import GlobalTable2 from "./GlobalTable2";
import GlobalTable3 from "./GlobalTable3";
import GlobalTable4 from "./GlobalTable4";
import { MyUrl } from "../../App";
var newArray = [];
var TheSum = [];
var MyArray = [];
var newArray1 = [];
var newArray2 = [];
var newArray3 = [];
var newArray4 = [];
var newArray5 = [];
var newArray6 = [];
var newArray7 = [];
var newArray9 = [];
var newArray10 = [];

var newArray13 = [];
var newArray14 = [];

var BigArray = [];
var obj10 = {};
var impressionsSum = 0;
var revenueSum = 0;
var mediacostSum = 0;
var operationfeeSum = 0;
var grossprofitSum = 0;
var partnerfeeSum = 0;
var profitmarginSum = 0;
var netincomeSum = 0;
var netincSum = 0;
var impressoinsSum = 0;
var netProfitSum = 0;
var scannedImpressionsSum = 0;
var addRequestsSum = 0;

var obj = {};
var obj1 = {};
var obj2 = {};
var obj3 = {};
var obj4 = {};
var obj5 = {};
var obj6 = {};
var obj7 = {};
var obj8 = {};
var obj9 = {};

var newArray11 = [];
var newArray12 = [];

var AllObj = [];
var AllObj1 = [];

var mobileSum = 0;
var ctvSum = 0;
var tag_based = 0;
var rtb = 0;
var direct_demand = 0;

var pushArray = [];
var pushArray1 = [];
var pushArray2 = [];

var ISum = 0;
var SISum = 0;
var ARSum = 0;
var GRSum = 0;
var MFSum = 0;
var SFSum = 0;
var revSum = 0;
var MCSum = 0;
var OFSum = 0;
var GPSum = 0;
var NISum = 0;
var PFSum = 0;
var NPSum = 0;
var PMSum = 0;
export default function GenericReportDateScope({ MyDateFrom, MyDateTo }) {
  const [isLoading1, setIsLoading1] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);
  const [isLoading3, setIsLoading3] = useState(true);
  const [isLoading4, setIsLoading4] = useState(true);
  const [isLoading5, setIsLoading5] = useState(true);
  const [isLoading6, setIsLoading6] = useState(true);

  const { pathname } = useLocation();
  const [Obj, setObj] = useState([]);
  const [data1, setData1] = useState("");
  const [data2, setData2] = useState("");
  const [data3, setData3] = useState("");
  const [data4, setData4] = useState("");
  const [data5, setData5] = useState("");
  const [data6, setData6] = useState("");

  const [MyArray1, setMyArray1] = useState([]);
  const MyToken = JSON.parse(localStorage.getItem("Token"));
  console.log("MyToken");
  console.log(MyToken);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  function useWindowSize() {
    const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
    }, []);
    return size;
  }
  const [height, width] = useWindowSize();

  useEffect(() => {
    CallDataTopDemand();
    CallDataTopTenApps();
    //  callIt();
    CallData1();
    //  callIt4();
    CallData2();
    CallData3();
  }, [newArray9]); // shoud add newArray10

  const CallData1 = (a) => {
    setIsLoading1(true);
    MyArray1.length = 0;
    try {
      axios
        .get(
          `${MyUrl}/api/Dashboard/getDailyIncome?${a}${MyDateFrom}${MyDateTo}
              `,
          {
            headers: {
              Authorization: `${MyToken}`,
            },
          }
        )
        .then((res) => {
          const data2 = res.data;
          setData2(data2);
          for (let i = 0; i < data2.responseData.length; i++) {
            MyArray1.push(data2.responseData[i]);
          }
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading1(false);
        });
    } catch (error) {}
  };

  const CallData2 = (a) => {
    setIsLoading3(true);
    newArray3.length = 0;
    AllObj = [];
    try {
      axios
        .get(
          `${MyUrl}/api/Dashboard/getDailyIncome?${a}${MyDateFrom}${MyDateTo}
              `,
          {
            headers: {
              Authorization: `${MyToken}`,
            },
          }
        )
        .then((res) => {
          setData3([]);
          newArray1 = [];
          const data3 = res.data;
          setData3(data3);
          for (let i = 0; i < data3.responseData.length; i++) {
            newArray3.push(data3.responseData[i]);
            for (let j = 0; j < newArray3[i].dataNetto.data.length; j++) {
              newArray11.push(newArray3[i].dataNetto.data[j].impressionsGood);
            }
            obj = {
              name: newArray3[i].name,
              type: "line",
              fill: "solid",
              data: newArray11,
            };
            newArray11 = [];
            AllObj.push(obj);
          }

          for (let i = 0; i < data3.responseData.length; i++) {
            newArray1.push(data3.responseData[i]);
          }
        })
        .catch((error) => {})
        .finally(() => {
          ForTable1();

          setIsLoading3(false);
        });
    } catch (error) {}
  };

  function ForTable1() {
    newArray5 = [];
    newArray6 = [];
    impressionsSum = 0;
    revenueSum = 0;
    mediacostSum = 0;
    operationfeeSum = 0;
    grossprofitSum = 0;
    partnerfeeSum = 0;
    profitmarginSum = 0;
    netincomeSum = 0;
    netincSum = 0;
    impressoinsSum = 0;
    addRequestsSum = 0;
    scannedImpressionsSum = 0;
    netProfitSum = 0;
    for (let i = 0; i < newArray1.length; i++) {
      const obj1 = {
        id: newArray1[i].id,
        seat: newArray1[i].name,
        revenue: newArray1[i].dataNetto.sumResp.revenueTotal,
        mediacost: newArray1[i].dataNetto.sumResp.mediaCost,
        operationfee: newArray1[i].dataNetto.sumResp.operationCost,
        grossprofit: newArray1[i].dataNetto.sumResp.grossProfit,
        partnerfee: newArray1[i].dataNetto.sumResp.partnerFee,
        profitmargin: newArray1[i].dataNetto.sumResp.grossProfitMargin,
        netincome: newArray1[i].dataNetto.sumResp.netIncome,
        netinc: newArray1[i].dataNetto.sumResp.procentNetIncome,
        impressions: newArray1[i].dataNetto.sumResp.impressionsGood,
        netProfit: newArray1[i].dataNetto.sumResp.netProfit,
      };

      impressionsSum += newArray1[i].dataNetto.sumResp.impressionsGood;
      revenueSum += newArray1[i].dataNetto.sumResp.revenueTotal;
      mediacostSum += newArray1[i].dataNetto.sumResp.mediaCost;
      operationfeeSum += newArray1[i].dataNetto.sumResp.operationCost;
      grossprofitSum += newArray1[i].dataNetto.sumResp.grossProfit;
      partnerfeeSum += newArray1[i].dataNetto.sumResp.partnerFee;
      profitmarginSum +=
        newArray1[i].dataNetto.sumResp.grossProfitMargin / newArray1.length;
      netincomeSum += newArray1[i].dataNetto.sumResp.netIncome;
      netincSum +=
        newArray1[i].dataNetto.sumResp.procentNetIncome / newArray1.length;
      impressoinsSum += newArray1[i].dataNetto.sumResp.impressionsGood;
      addRequestsSum += newArray1[i].dataNetto.sumResp.addRequests;

      scannedImpressionsSum +=
        newArray1[i].dataNetto.sumResp.scannedImpressions;
      netProfitSum += newArray1[i].dataNetto.sumResp.netProfit;
      newArray5.push(obj1);
    }
    const obj2 = {
      id: "22",
      seat: "aa",
      impressions: impressionsSum.toFixed(2),
      revenue: revenueSum.toFixed(2),
      mediacost: mediacostSum.toFixed(2),
      operationfee: operationfeeSum.toFixed(2),
      grossprofit: grossprofitSum.toFixed(2),
      partnerfee: partnerfeeSum.toFixed(2),
      profitmargin: profitmarginSum.toFixed(2),
      netincome: netincomeSum.toFixed(2),
      netinc: netincSum.toFixed(2),
      impressions: impressoinsSum.toFixed(2),
      netProfit: netProfitSum.toFixed(2),
    };
    newArray6.push(obj2);
  }

  useEffect(() => {}, []);

  function DoSum() {
    TheSum = [];
    MyArray = [];
    for (let i = 0; i < newArray7.length; i++) {
      TheSum.push(
        parseInt(newArray7[i].value.toFixed(2)) +
          parseInt(newArray4[i].value.toFixed(2))
      );
      obj = {
        id: i,
        seat: newArray7[i].type,
        revenue: parseInt(newArray7[i].value),
        precent: ((parseInt(newArray7[i].value) / TheSum[i]) * 100).toFixed(3),
        revenue1: parseInt(newArray4[i].value),
        precent1: ((parseInt(newArray4[i].value) / TheSum[i]) * 100).toFixed(3),
      };
      MyArray.push(obj);
    }
  }

  const CallData3 = (a) => {
    setIsLoading4(true);
    newArray4 = [];
    newArray7 = [];
    pushArray = [];
    pushArray1 = [];
    pushArray2 = [];

    ctvSum = 0;
    mobileSum = 0;
    try {
      axios
        //ids=4&startDate=2022-12-04&endDate=2022-12-04
        .get(
          `${MyUrl}/api/Dashboard/getRevenueData?${a}${MyDateFrom}${MyDateTo}
              `,
          {
            headers: {
              Authorization: `${MyToken}`,
            },
          }
        )
        .then((res) => {
          const data4 = res.data;
          setData4(data4);

          for (let i = 0; i < data4.responseData.length; i++) {
            for (
              let j = 0;
              j <
              data4.responseData[i].dataNetto.data[0].envResponse.data.length;
              j++
            ) {
              obj1 = {
                type: data4.responseData[i].name,
                value:
                  data4.responseData[i].dataNetto.data[0].envResponse.data[0]
                    .revenue,
              };
              mobileSum +=
                data4.responseData[i].dataNetto.data[0].envResponse.data[0]
                  .revenue;
            }
            obj4 = {
              type: data4.responseData[i].name,
              value:
                data4.responseData[i].dataNetto.data[0].envResponse
                  .globalRevenue,
            };
            pushArray1.push(obj4);
            newArray4.push(obj1);
          }
          obj2 = {
            type: "MobileApp",
            value: mobileSum,
          };

          for (let i = 0; i < data4.responseData.length; i++) {
            for (
              let j = 0;
              j <
              data4.responseData[i].dataNetto.data[0].envResponse.data.length;
              j++
            ) {
              obj1 = {
                type: data4.responseData[i].name,
                value:
                  data4.responseData[i].dataNetto.data[0].envResponse.data[1]
                    .revenue,
              };
            }
            ctvSum +=
              data4.responseData[i].dataNetto.data[0].envResponse.data[1]
                .revenue;
            newArray7.push(obj1);
          }
          obj3 = {
            type: "CTV",
            value: ctvSum,
          };
          pushArray.push(obj2);
          pushArray.push(obj3);

          // For Donut Number 3

          for (let i = 0; i < data4.responseData.length; i++) {
            tag_based +=
              data4.responseData[i].dataNetto.data[0].integrationResponse
                .data[0].revenue;
            rtb +=
              data4.responseData[i].dataNetto.data[0].integrationResponse
                .data[1].revenue;
            direct_demand +=
              data4.responseData[i].dataNetto.data[0].integrationResponse
                .data[2].revenue;
          }
          obj5 = {
            type: "Tag based",
            value: tag_based,
          };
          obj6 = {
            type: "Open RTB",
            value: rtb,
          };
          obj7 = {
            type: "Direct demand",
            value: direct_demand,
          };

          pushArray2.push(obj5);
          pushArray2.push(obj6);
          pushArray2.push(obj7);

          //End  For Donut Number 3
        })
        .catch((error) => {})
        .finally(() => {
          DoSum();
          setIsLoading4(false);
        });
    } catch (error) {}
  };

  const CallDataTopDemand = (a) => {
    setIsLoading5(true);
    newArray10 = [];
    newArray12 = [];
    try {
      axios
        .get(
          `${MyUrl}/api/DemandReport/getDemandData?${a}${MyDateFrom}${MyDateTo}&daily=false
              `,
          {
            headers: {
              Authorization: `${MyToken}`,
            },
          }
        )
        .then((res) => {
          const data55 = res.data;
          setData5(data55);
          //  console.log(data55);
          //  console.log(data55.responseData.data[0].qs);
          for (let i = 0; i < data55.responseData.data.length; i++) {
            obj8 = {
              id: i,
              BillingName: data55.responseData.data[i].advertiser.billingName,
              Impressions: data55.responseData.data[i].impressions_good,
              Revenue: data55.responseData.data[i].revenue_total,
              QS: Number.parseFloat(
                data55.responseData.data[i].qs.toString().replace("e-", "")
              ).toFixed(2),
            };

            newArray10.push(obj8);
          }
          //  console.log(newArray10);
          // for (let i = 0; i < newArray10.slice(0, 15).length; i++) {
          //   //console.log(Array10[i]);
          //   newArray12.push(newArray10[i]);
          // }
          // console.log("newArray12");

          // console.log(newArray12);
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading5(false);
        });
    } catch (error) {}
  };

  const CallDataTopTenApps = (a) => {
    setIsLoading6(true);
    try {
      newArray13 = [];
      console.log("Forcheck");
      console.log(MyDateFrom);
      console.log(MyDateTo);
      axios
        .get(
          `${MyUrl}/api/DemandReport/getAppsData?${a}${MyDateFrom}${MyDateTo}&daily=false
              `,
          {
            headers: {
              Authorization: `${MyToken}`,
            },
          }
        )
        .then((res) => {
          const data66 = res.data;
          setData6(data66);
          for (let i = 0; i < data66.responseData.data.length; i++) {
            obj9 = {
              id: i,
              AppBundle: data66.responseData.data[i].app_bundle.name,
              AppName: data66.responseData.data[i].app_name.name,
              OS: data66.responseData.data[i].os.name,
              Impressions: data66.responseData.data[i].impressions_good,
              Revenue: data66.responseData.data[i].revenue_total,
              Fill: data66.responseData.data[i].fill,
              QS: data66.responseData.data[i].qs,
              // Impressions: data55.responseData.data[i].impressions_good,
              // Revenue: data55.responseData.data[i].revenue_total,
              // QS: Number.parseFloat(
              //   data55.responseData.data[i].qs.toString().replace("e-", "")
              // ).toFixed(2),
            };

            newArray13.push(obj9);
          }
          console.log("ALL DATA");
          console.log(data66);

          console.log(newArray13);
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading6(false);
        });
    } catch (error) {}
  };

  ISum = newArray5.reduce((acc, cur) => acc + cur.impressions, 0);
  SISum = newArray5.reduce((acc, cur) => acc + cur.scannedimpressions, 0);
  ARSum = newArray5.reduce((acc, cur) => acc + cur.addrequests, 0);
  GRSum = newArray5.reduce((acc, cur) => acc + cur.revenue, 0);
  MFSum = newArray5.reduce((acc, cur) => acc + cur.marketplacefee, 0);
  SFSum = newArray5.reduce((acc, cur) => acc + cur.scanningfee, 0);
  revSum = newArray5.reduce((acc, cur) => acc + cur.revenue, 0);
  MCSum = newArray5.reduce((acc, cur) => acc + cur.mediacost, 0);
  OFSum = newArray5.reduce((acc, cur) => acc + cur.operationfee, 0);
  GPSum = newArray5.reduce((acc, cur) => acc + cur.grossprofit, 0);
  NISum = newArray5.reduce((acc, cur) => acc + cur.netincome, 0);
  PFSum = newArray5.reduce((acc, cur) => acc + cur.partnerfee, 0);
  NPSum = newArray5.reduce((acc, cur) => acc + cur.netProfit, 0);
  PMSum = newArray5.reduce((acc, cur) => acc + cur.profitmargin, 0);

  return (
    <div>
      {isLoading1 ? (
        <>
          <Skeleton active avatar>
            <List.Item.Meta
              avatar={<Avatar src={""} />}
              title={<a href={""}>{""}</a>}
              description={""}
            />
            {""}
          </Skeleton>{" "}
        </>
      ) : (
        <>
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <div>
              {MyArray1.map(function (item, i) {
                return (
                  <>
                    <Checkbox
                      onClick={() => {
                        const index1 = newArray9.indexOf(
                          data2.responseData[i].id
                        );

                        if (index1 > -1) {
                          newArray9.splice(index1, 1);
                        } else {
                          newArray9.push(data2.responseData[i].id);
                        }

                        const index = newArray.indexOf(
                          `&ids=${data2.responseData[i].id}`
                        );

                        if (index > -1) {
                          newArray.splice(index, 1);
                        } else {
                          newArray.push(`&ids=${data2.responseData[i].id}`);
                        }
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <a style={{ color: "#7e22ce", fontSize: 25 }}>
                      {MyArray1[i].name}
                    </a>
                  </>
                );
              })}
              <div
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <br></br>
                <Button
                  style={{ margin: 5, backgroundColor: "#8b5cf6" }}
                  variant="contained"
                  onClick={() => {
                    // callIt(newArray.toString().replaceAll(",", ""));
                    CallData2(newArray.toString().replaceAll(",", ""));
                    CallData3(newArray.toString().replaceAll(",", ""));
                    CallDataTopDemand(newArray.toString().replaceAll(",", ""));
                    CallDataTopTenApps(newArray.toString().replaceAll(",", ""));
                  }}
                >
                  Filter
                </Button>
                <br></br>
              </div>
            </div>
            &nbsp;
          </div>
        </>
      )}

      {isLoading3 ? (
        <>
          <Skeleton active avatar>
            <List.Item.Meta
              avatar={<Avatar src={""} />}
              title={<a href={""}>{""}</a>}
              description={""}
            />
            {""}
          </Skeleton>{" "}
          <br></br>
        </>
      ) : (
        <>
          <GlobalFirstGraph MyAllObj={AllObj} />
        </>
      )}

      {isLoading4 ? (
        <>
          <Skeleton active avatar>
            <List.Item.Meta
              avatar={<Avatar src={""} />}
              title={<a href={""}>{""}</a>}
              description={""}
            />
            {""}
          </Skeleton>{" "}
          <br></br>
        </>
      ) : (
        <>
          <GlobalDonut
            pushArray={pushArray}
            pushArray1={pushArray1}
            pushArray2={pushArray2}
          />
        </>
      )}

      {isLoading3 ? (
        <>
          <Skeleton active avatar>
            <List.Item.Meta
              avatar={<Avatar src={""} />}
              title={<a href={""}>{""}</a>}
              description={""}
            />
            {""}
          </Skeleton>{" "}
          <br></br>
        </>
      ) : (
        <>
          <GlobalTable1
            newArray5={newArray5}
            ISum={ISum}
            SISum={SISum}
            ARSum={ARSum}
            GRSum={GRSum}
            MFSum={MFSum}
            SFSum={SFSum}
            revSum={revSum}
            MCSum={MCSum}
            OFSum={OFSum}
            GPSum={GPSum}
            NISum={NISum}
            PFSum={PFSum}
            NPSum={NPSum}
            PMSum={PMSum}
          />
        </>
      )}

      <div
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            flex:
              width > 769 ? (width > 1023 ? "0 1 33%" : "0 1 50%") : "0 1 100%",
            width: "100%",
          }}
        >
          {isLoading4 ? (
            <>
              <Skeleton active avatar>
                <List.Item.Meta
                  avatar={<Avatar src={""} />}
                  title={<a href={""}>{""}</a>}
                  description={""}
                />
                {""}
              </Skeleton>{" "}
              <br></br>
            </>
          ) : (
            <>
              <GlobalTable2
                pushArray={pushArray}
                newArray7={newArray7}
                newArray4={newArray4}
                newArray5={newArray5}
                MyArray={MyArray}
              />
            </>
          )}
        </div>
        <div
          style={{
            flex:
              width > 769 ? (width > 1023 ? "0 1 33%" : "0 1 50%") : "0 1 100%",
            width: "100%",
          }}
        >
          {isLoading5 ? (
            <>
              <Skeleton active avatar>
                <List.Item.Meta
                  avatar={<Avatar src={""} />}
                  title={<a href={""}>{""}</a>}
                  description={""}
                />
                {""}
              </Skeleton>{" "}
              <br></br>
            </>
          ) : (
            <>
              <GlobalTable4 Array10={newArray10} />
            </>
          )}
        </div>

        <div
          style={{
            flex:
              width > 769 ? (width > 1023 ? "0 1 33%" : "0 1 50%") : "0 1 100%",
            width: "100%",
          }}
        >
          {isLoading6 ? (
            <>
              <Skeleton active avatar>
                <List.Item.Meta
                  avatar={<Avatar src={""} />}
                  title={<a href={""}>{""}</a>}
                  description={""}
                />
                {""}
              </Skeleton>{" "}
              <br></br>
            </>
          ) : (
            <>
              <GlobalTable3 newArray13={newArray13} />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import { display } from "@mui/system";
//import "./styles.css";
import "antd/dist/antd.css";
import { Excel } from "antd-table-saveas-excel";
import "../../Pages/styles.css";
import Highlighter from "react-highlight-words";
import { Button, Input, Space, Table, Typography } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { withRouter, useHistory, useLocation } from "react-router-dom";

const { Text } = Typography;

export default function DemandTable4({ newArray5 }) {
  function useWindowSize() {
    const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
    }, []);
    return size;
  }
  const [height, width] = useWindowSize();

  // console.log(newArray5);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    { dataIndex: "Id", title: "ID", width: 90 },
    {
      title: "Campaign Name",
      dataIndex: "CampaignName",
      flex: 1,
      width: 200,
      minWidth: 100,

      editable: true,
      ...getColumnSearchProps("CampaignName"),
      sorter: (a, b) => a.CampaignName.length - b.CampaignName.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "SEAT",
      dataIndex: "SEAT",
      flex: 1,
      // width: 800,
      minWidth: 100,

      editable: true,
      ...getColumnSearchProps("SEAT"),
      sorter: (a, b) => a.SEAT.length - b.SEAT.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "ENV",
      dataIndex: "ENV",
      flex: 1,
      minWidth: 100,
      editable: true,
      ...getColumnSearchProps("ENV"),
      sorter: (a, b) => a.ENV - b.ENV,
      render: (value) => {
        return value.toLocaleString("en");
      },
    },

    {
      title: "Req",
      dataIndex: "Req",
      flex: 1,
      minWidth: 100,
      editable: true,
      ...getColumnSearchProps("Req"),
      sorter: (a, b) => a.Req - b.Req,
      render: (value) => {
        return value.toLocaleString("en");
      },
    },
    {
      title: "Imp",
      dataIndex: "Imp",
      flex: 1,
      minWidth: 100,
      editable: true,
      ...getColumnSearchProps("Imp"),
      sorter: (a, b) => a.Imp - b.Imp,
      render: (value) => {
        return value.toLocaleString("en");
      },
    },
    {
      title: "Fill",
      dataIndex: "Fill",
      flex: 1,
      minWidth: 100,
      editable: true,
      ...getColumnSearchProps("Fill"),
      sorter: (a, b) => a.Fill - b.Fill,
      render: (value) => {
        return value.toLocaleString("en");
      },
    },
    {
      title: "Revenue",
      dataIndex: "Revenue",
      flex: 1,
      minWidth: 100,
      editable: true,

      ...getColumnSearchProps("Revenue"),
      sorter: (a, b) => a.Revenue - b.Revenue,
      render: (value) => {
        return "$" + value.toLocaleString("en");
      },
    },
    {
      title: "QS",
      dataIndex: "QS",
      flex: 1,
      minWidth: 100,
      editable: true,
      ...getColumnSearchProps("QS"),
      sorter: (a, b) => a.QS - b.QS,
      render: (value) => {
        return value.toLocaleString("en");
      },
      render(text, record) {
        if (text == null) {
          return "";
        }
        return {
          props: {
            style: {
              background:
                parseFloat(text) >= 5
                  ? "#10b981"
                  : parseFloat(text) > 2 && parseFloat(text) < 5
                  ? "#fdba74"
                  : "#f43f5e",
            },
          },
          children: <div>$ {text}</div>,
        };
      },
    },
  ];
  const [pageSize, setPageSize] = React.useState(15);
  const handleClick = () => {
    const excel = new Excel();
    excel
      .addSheet("test")
      .addColumns(columns)
      .addDataSource(newArray5, {
        str2Percent: true,
      })
      .saveAs("Excel.xlsx");
  };
  const sum = newArray5.reduce((acc, cur) => acc + cur.REQ, 0);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  function useWindowSize() {
    const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
    }, []);
    return size;
  }
  return (
    <div>
      <br></br>
      <div style={{ backgroundColor: "white" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h6>Campaign Optimization Overview</h6>
        </div>
        <div
          style={{
            backgroundColor: "white",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <div style={{ width: "100%" }}>
            <div style={{ width: "100%" }}>
              <Table
                dataSource={newArray5}
                columns={columns}
                pagination={
                  {
                    //   position: ["none", "none"],
                  }
                }
                size="small"
                scroll={{ x: width > 1025 ? "100%" : "200vh", y: 300 }}
              ></Table>
            </div>
          </div>
        </div>
      </div>{" "}
    </div>
  );
}

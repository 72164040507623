import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Line } from "@ant-design/plots";
import moment from "moment";

import { uniq, findIndex } from "@antv/util";

export default function DashboardTotal({
  DailyNetIncomeToSendTotal,
  MonthlyNetIncomeToSendTotal,
  MyChooce2,
}) {
  const [data, setData] = useState([]);
  console.log(MonthlyNetIncomeToSendTotal);
  const currentDate = moment().format("MMMM DD, YYYY");

  const COLOR_PLATE_10 = [
    "#5B8FF9",
    "#5AD8A6",
    "#5D7092",
    "#F6BD16",
    "#E8684A",
    "#6DC8EC",
    "#9270CA",
    "#FF9D4D",
    "#269A99",
    "#FF99C3",
  ];
  const container = document.getElementById("container");
  const customTooltipFormatter = (datum) => {
    return {
      name: datum.name,
      value: `$ ${datum.netIncome
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
    };
  };

  const config = {
    data: DailyNetIncomeToSendTotal,
    xField: "hour",
    yField: "netIncome",
    seriesField: "name",
    yAxis: {
      label: {
        // 数值格式化为千分位
        formatter: (v) =>
          `$ ${v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
        // formatter: (v) =>
        //   `$ ${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
    color: COLOR_PLATE_10,
    point: {
      shape: ({ name }) => {
        return name === "Gas fuel" ? "square" : "circle";
      },
      style: ({ hour }) => {
        return {
          r: hour, // 4 个数据示一个点标记
        };
      },
    },
    tooltip: { formatter: customTooltipFormatter },
  };

  const config1 = {
    data: MonthlyNetIncomeToSendTotal,
    xField: "date",
    yField: "netIncome",
    seriesField: "name",
    yAxis: {
      label: {
        // 数值格式化为千分位
        // formatter: (v) => `$ ${v.toLocaleString().replace(/,/g, ".")}`,
        // `$ ${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
        formatter: (v) =>
          `$ ${v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
      },
    },
    color: COLOR_PLATE_10,
    point: {
      shape: ({ name }) => {
        return "circle";
      },
      style: ({ date }) => {
        return {
          r: date, // 4 个数据示一个点标记
        };
      },
    },
    tooltip: { formatter: customTooltipFormatter },
  };
  return (
    <div
      style={{
        paddingRight: "1%",
        paddingLeft: "2.5%",
      }}
    >
      {MyChooce2 === "Daily" ? (
        <>
          <div style={{ paddingTop: "0.5%", paddingBottom: "0.5%" }}>
            <span style={{ color: "#1a1523", fontSize: 18 }}>
              Daily Net Income Overview{" "}
              {/* <span style={{ color: "#e4e2e4" }}> • </span>{" "}
              <span style={{ color: "#6f6e77" }}>{currentDate}</span> */}
            </span>
          </div>
          <Line {...config} />
        </>
      ) : (
        <>
          <div style={{ paddingTop: "0.5%", paddingBottom: "0.5%" }}>
            <span style={{ color: "#1a1523", fontSize: 18 }}>
              Monthly Net Income Overview{" "}
              {/* <span style={{ color: "#e4e2e4" }}> • </span>{" "}
              <span style={{ color: "#6f6e77" }}>{currentDate}</span> */}
            </span>
          </div>
          <Line {...config1} />
        </>
      )}
    </div>
  );
}

import React, { useState, useEffect, useCallback } from "react";
import { withRouter, useHistory, useLocation } from "react-router-dom";
import upArrow from "../../Images/upArrow.png";
import downArrow from "../../Images/downArrow.png";

export default function SupplyTeamReportTop3(Array1) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  function useWindowSize() {
    const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
    }, []);
    return size;
  }
  const [height, width] = useWindowSize();

  return (
    <div
      style={{
        flex: 1,
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        flexWrap: "wrap",
      }}
    >
      <div
        style={{
          flex:
            width < 759
              ? "0 1 100%"
              : width >= 760 && width < 1024
              ? "0 1 45%"
              : "0 1 30%",
          borderRadius: 20,
          height: "40%",
          backgroundColor: "#c084fc",
          marginBottom: 10,
          padding: 10,
        }}
      >
        <div style={{ flex: 1, justifyContent: "center", display: "flex" }}>
          <h4 style={{ color: "white" }}>Ad Requests</h4>
        </div>

        <div
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h6 style={{ color: "white" }}>MTD</h6>
          <h6 style={{ color: "white" }}>Yesterday</h6>
          <h6 style={{ color: "white" }}>
            Today
            {Array1.Array1[0].addRequests.ratio > 0 ? (
              <img
                style={{
                  width: 20,
                  height: 20,
                }}
                src={upArrow}
              ></img>
            ) : (
              <img
                style={{
                  width: 20,
                  height: 20,
                }}
                src={downArrow}
              ></img>
            )}
            {Array1.Array1[0].addRequests.ratio.toFixed(2)}%
          </h6>
        </div>

        <div
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h6 style={{ color: "white" }}>
            Bil. {Array1.Array1[0].addRequests.monthlyData.toFixed(2)}
          </h6>
          <h6 style={{ color: "white" }}>
            Bil. {Array1.Array1[0].addRequests.yesterdayData.toFixed(2)}
          </h6>
          <h6 style={{ color: "white" }}>
            Bil. {Array1.Array1[0].addRequests.todaysData.toFixed(2)}
          </h6>
        </div>
      </div>
      <div
        style={{
          flex:
            width < 759
              ? "0 1 100%"
              : width >= 760 && width < 1024
              ? "0 1 45%"
              : "0 1 30%",

          borderRadius: 20,
          height: "40%",
          backgroundColor: "#c084fc",
          marginBottom: 10,
          padding: 10,
        }}
      >
        <div style={{ flex: 1, justifyContent: "center", display: "flex" }}>
          <h4 style={{ color: "white" }}>Fill Rate</h4>
        </div>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h6 style={{ color: "white" }}>MTD</h6>
          <h6 style={{ color: "white" }}>Yesterday</h6>
          <h6 style={{ color: "white" }}>
            Today
            {Array1.Array1[0].fillRate.ratio > 0 ? (
              <img
                style={{
                  width: 20,
                  height: 20,
                }}
                src={upArrow}
              ></img>
            ) : (
              <img
                style={{
                  width: 20,
                  height: 20,
                }}
                src={downArrow}
              ></img>
            )}
            {Array1.Array1[0].fillRate.ratio.toFixed(2)}%
          </h6>
        </div>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h6 style={{ color: "white" }}>
            % {Array1.Array1[0].fillRate.monthlyData.toFixed(2)}
          </h6>
          <h6 style={{ color: "white" }}>
            % {Array1.Array1[0].fillRate.yesterdayData.toFixed(2)}
          </h6>
          <h6 style={{ color: "white" }}>
            % {Array1.Array1[0].fillRate.todaysData.toFixed(2)}
          </h6>
        </div>
      </div>
      <div
        style={{
          flex:
            width < 759
              ? "0 1 100%"
              : width >= 760 && width < 1024
              ? "0 1 45%"
              : "0 1 30%",

          borderRadius: 20,
          height: "40%",
          backgroundColor: "#c084fc",
          marginBottom: 10,
          padding: 10,
        }}
      >
        <div style={{ flex: 1, justifyContent: "center", display: "flex" }}>
          <h4 style={{ color: "white" }}>Impression</h4>
        </div>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h6 style={{ color: "white" }}>MTD</h6>
          <h6 style={{ color: "white" }}>Yesterday</h6>
          <h6 style={{ color: "white" }}>
            Today
            {Array1.Array1[0].impression.ratio > 0 ? (
              <img
                style={{
                  width: 20,
                  height: 20,
                }}
                src={upArrow}
              ></img>
            ) : (
              <img
                style={{
                  width: 20,
                  height: 20,
                }}
                src={downArrow}
              ></img>
            )}
            {Array1.Array1[0].impression.ratio.toFixed(2)}%
          </h6>
        </div>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h6 style={{ color: "white" }}>
            Mil. {Array1.Array1[0].impression.monthlyData.toFixed(2)}
          </h6>
          <h6 style={{ color: "white" }}>
            Mil. {Array1.Array1[0].impression.yesterdayData.toFixed(2)}
          </h6>
          <h6 style={{ color: "white" }}>
            Mil. {Array1.Array1[0].impression.todaysData.toFixed(2)}
          </h6>
        </div>
      </div>
    </div>
  );
}

import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";

import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import { MyUrl } from "../../../App";
import axios from "axios";

var MyArray = [];
var compName = "General";

export default function MediaSourceTableSourceCompare(DataFromFather) {
  //const [compName, setCompName] = useState("General");
  //console.log(DataFromFather.combinedArray.length);
  useEffect(() => {
    compName = "General";
    //setCompName("General");
  }, []);

  const [data7, setData7] = useState(["General", "CTV", "InApp"]);
  const [CompColor, setCompColor] = useState(0);

  const [Value, setValue] = React.useState("");
  //console.log(rowData);

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const gridRef = useRef(); // Optional - for accessing Grid's API
  //const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row
  function useWindowSize() {
    const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
    }, []);
    return size;
  }
  const [height, width] = useWindowSize();
  const [rowData] = useState(DataFromFather.combinedArray);

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(() => ({
    sortable: true,
  }));
  // Example of consuming Grid Event
  const cellClickedListener = useCallback((event) => {
    console.log("cellClicked", event);
  }, []);

  // Example using Grid's API
  const buttonListener = useCallback((e) => {
    gridRef.current.api.deselectAll();
  }, []);
  var ragCellClassRules = {
    "rag-green-outer": function (params) {
      return params.value === 2008;
    },
    "rag-amber-outer": function (params) {
      return params.value === 2004;
    },
    "rag-red-outer": function (params) {
      return params.value === 2000;
    },
  };
  const [selectedRows, setSelectedRows] = useState([]);
  const [gridApi, setGridApi] = useState(null);

  const onGridReady = (params) => {
    // console.log(rowData);
    setGridApi(params.api);
  };

  const [columnDefs] = useState([
    {
      headerName: "",
      headerClass: "my-header-class",

      children: [
        {
          headerName: "date",
          flex: width > 766 ? 1 : null,
          field: "MSdate",
          filter: true,
          minWidth: 150,
          headerClass: "my-header-class",
        },
      ],
    },
    {
      headerName: "MS",
      headerClass: "my-header-class",

      children: [
        {
          headerName: "Ad Requests",
          flex: width > 766 ? 1 : null,
          field: "MSaddRequests",
          filter: true,
          minWidth: 150,
          headerClass: "my-header-class",
          valueFormatter: (params) => {
            if (params.node.rowPinned === "bottom" && params.data === sumRow1) {
              return params.value; // Return the value without formatting for pinnedBottomRowData
            } else {
              return `${params.value
                .toFixed(0)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
            }
          },
          // valueFormatter: (params) =>
          //   `${params.value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
        },
        {
          headerName: "Impressions",
          flex: width > 766 ? 1 : null,
          field: "MSimpressions",
          filter: true,
          minWidth: 150,
          headerClass: "my-header-class",
          valueFormatter: (params) => {
            if (params.node.rowPinned === "bottom" && params.data === sumRow1) {
              return `${params.value
                .toFixed(0)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`; // Return the value without formatting for pinnedBottomRowData
            } else {
              return `${params.value
                .toFixed(0)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
            }
          },
        },
        {
          headerName: "Total Revenue",
          minWidth: 150,
          flex: width > 766 ? 1 : null,
          field: "MSrevenue",
          filter: "agNumberColumnFilter",
          headerClass: "my-header-class",
          valueFormatter: (params) => {
            if (params.node.rowPinned === "bottom" && params.data === sumRow1) {
              return params.value; // Return the value without formatting for pinnedBottomRowData
            } else {
              return `$ ${params.value
                .toFixed(0)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
            }
          },
        },
        {
          headerName: "$/AD Req in Mil.",
          field: "MSaddReqInMill",
          filter: true,
          minWidth: 150,
          flex: width > 766 ? 1 : null,
          headerClass: "my-header-class",

          valueFormatter: (params) => {
            if (params.node.rowPinned === "bottom" && params.data === sumRow1) {
              return `${params.value
                .toFixed(0)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`; // Return the value without formatting for pinnedBottomRowData
            } else {
              return `$ ${params.value
                .toFixed(3)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
            }
          },
        },
        {
          headerName: "	Fill Rate(%)",
          field: "MSfillRateInProcent",
          filter: true,
          minWidth: 150,
          flex: width > 766 ? 1 : null,
          headerClass: "my-header-class",
          valueFormatter: (params) => {
            if (params.node.rowPinned === "bottom" && params.data === sumRow1) {
              return params.value; // Return the value without formatting for pinnedBottomRowData
            } else {
              return `${params.value
                .toFixed(3)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} %`;
            }
          },
        },
      ],
    },
    {
      headerName: "MT",
      headerClass: "my-header-class",

      children: [
        {
          headerName: "Ad Requests",
          flex: width > 766 ? 1 : null,
          field: "MTaddRequests",
          filter: true,
          minWidth: 150,
          headerClass: "my-header-class",
          valueFormatter: (params) =>
            `${params.value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,

          valueFormatter: (params) => {
            if (params.node.rowPinned === "bottom" && params.data === sumRow1) {
              return `$ ${params.value
                .toFixed(0)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`; // Return the value without formatting for pinnedBottomRowData
            } else {
              return `${params.value
                .toFixed(0)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
            }
          },
        },
        {
          headerName: "Impressions",
          flex: width > 766 ? 1 : null,
          field: "MTimpressions",
          filter: true,
          minWidth: 150,
          headerClass: "my-header-class",
          valueFormatter: (params) => {
            if (params.node.rowPinned === "bottom" && params.data === sumRow1) {
              return params.value; // Return the value without formatting for pinnedBottomRowData
            } else {
              return `${params.value
                .toFixed(0)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
            }
          },
        },
        {
          headerName: "Total Revenue",

          minWidth: 150,
          flex: width > 766 ? 1 : null,
          field: "MTrevenue",
          filter: "agNumberColumnFilter",
          headerClass: "my-header-class",
          valueFormatter: (params) => {
            if (params.node.rowPinned === "bottom" && params.data === sumRow1) {
              return `${params.value
                .toFixed(3)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`; // Return the value without formatting for pinnedBottomRowData
            } else {
              return `$ ${params.value
                .toFixed(0)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
            }
          },
        },
        {
          headerName: "$/AD Req in Mil.",
          field: "MTaddReqInMill",
          filter: true,
          minWidth: 150,
          flex: width > 766 ? 1 : null,
          headerClass: "my-header-class",
          valueFormatter: (params) => {
            if (params.node.rowPinned === "bottom" && params.data === sumRow1) {
              // Return the value without formatting for pinnedBottomRowData
            } else {
              return `${params.value
                .toFixed(3)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
            }
          },
        },
        {
          headerName: "	Fill Rate(%)",
          field: "MTfillRateInProcent",
          filter: true,
          minWidth: 150,
          flex: width > 766 ? 1 : null,
          headerClass: "my-header-class",
          valueFormatter: (params) => {
            if (params.node.rowPinned === "bottom" && params.data === sumRow1) {
              return `${params.value
                .toFixed(3)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} %`;
            } else {
              return `${params.value
                .toFixed(3)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} %`;
            }
          },
        },
      ],
    },
    {
      headerName: "ME",
      headerClass: "my-header-class",

      children: [
        {
          headerName: "Ad Requests",
          flex: width > 766 ? 1 : null,
          field: "MEaddRequests",
          filter: true,
          minWidth: 150,
          headerClass: "my-header-class",
          // valueFormatter: (params) =>
          //   `${params.value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,

          valueFormatter: (params) => {
            if (params.node.rowPinned === "bottom" && params.data === sumRow1) {
              return params.value; // Return the value without formatting for pinnedBottomRowData
            } else {
              return `${params.value
                .toFixed(0)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
            }
          },
        },
        {
          headerName: "Impressions",
          flex: width > 766 ? 1 : null,
          field: "MEimpressions",
          filter: true,
          minWidth: 150,
          headerClass: "my-header-class",
          // valueFormatter: (params) =>
          //   `${params.value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,

          valueFormatter: (params) => {
            if (params.node.rowPinned === "bottom" && params.data === sumRow1) {
              return params.value; // Return the value without formatting for pinnedBottomRowData
            } else {
              return `${params.value
                .toFixed(0)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
            }
          },
        },
        {
          headerName: "Total Revenue",
          minWidth: 150,
          flex: width > 766 ? 1 : null,
          field: "MErevenue",
          filter: "agNumberColumnFilter",
          headerClass: "my-header-class",
          // valueFormatter: (params) =>
          //   `$ ${params.value
          //     .toFixed(3)
          //     .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
          valueFormatter: (params) => {
            if (params.node.rowPinned === "bottom" && params.data === sumRow1) {
              return params.value; // Return the value without formatting for pinnedBottomRowData
            } else {
              return `$ ${params.value
                .toFixed(0)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
            }
          },
        },
        {
          headerName: "$/AD Req in Mil.",
          field: "MEaddReqInMill",
          filter: true,
          minWidth: 150,
          flex: width > 766 ? 1 : null,
          headerClass: "my-header-class",
          // valueFormatter: (params) =>
          //   `${params.value.toFixed(3).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
          valueFormatter: (params) => {
            if (params.node.rowPinned === "bottom" && params.data === sumRow1) {
              return params.value; // Return the value without formatting for pinnedBottomRowData
            } else {
              return `$ ${params.value
                .toFixed(3)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
            }
          },
        },
        {
          headerName: "	Fill Rate(%)",
          field: "MEfillRateInProcent",
          filter: true,
          minWidth: 150,
          flex: width > 766 ? 1 : null,
          headerClass: "my-header-class",
          // valueFormatter: (params) =>
          //   `${params.value
          //     .toFixed(3)
          //     .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} %`,
          valueFormatter: (params) => {
            if (params.node.rowPinned === "bottom" && params.data === sumRow1) {
              return params.value; // Return the value without formatting for pinnedBottomRowData
            } else {
              return `${params.value
                .toFixed(3)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} %`;
            }
          },
        },
      ],
    },
  ]);

  const getRowHeight = (params) => (params.node.group ? 40 : 40);

  const sumRow = {
    MSdate: "Total",
    MSaddRequests: rowData.reduce((acc, curr) => acc + curr.MSaddRequests, 0),
    MSimpressions: rowData.reduce((acc, curr) => acc + curr.MSimpressions, 0),
    MSrevenue: rowData.reduce((acc, curr) => acc + curr.MSrevenue, 0),
    MSaddReqInMill:
      rowData.reduce((acc, curr) => acc + curr.MSaddReqInMill, 0) /
      DataFromFather.combinedArray.length,
    MSfillRateInProcent:
      rowData.reduce((acc, curr) => acc + curr.MSfillRateInProcent, 0) /
      DataFromFather.combinedArray.length,

    MTaddRequests: rowData.reduce((acc, curr) => acc + curr.MTaddRequests, 0),
    MTimpressions: rowData.reduce((acc, curr) => acc + curr.MTimpressions, 0),
    MTrevenue: rowData.reduce((acc, curr) => acc + curr.MTrevenue, 0),
    MTaddReqInMill:
      rowData.reduce((acc, curr) => acc + curr.MTaddReqInMill, 0) /
      DataFromFather.combinedArray.length,
    MTfillRateInProcent:
      rowData.reduce((acc, curr) => acc + curr.MTfillRateInProcent, 0) /
      DataFromFather.combinedArray.length,

    MEaddRequests: rowData.reduce((acc, curr) => acc + curr.MEaddRequests, 0),
    MEimpressions: rowData.reduce((acc, curr) => acc + curr.MEimpressions, 0),
    MErevenue: rowData.reduce((acc, curr) => acc + curr.MErevenue, 0),
    MEaddReqInMill:
      rowData.reduce((acc, curr) => acc + curr.MEaddReqInMill, 0) /
      DataFromFather.combinedArray.length,
    MEfillRateInProcent:
      rowData.reduce((acc, curr) => acc + curr.MEfillRateInProcent, 0) /
      DataFromFather.combinedArray.length,

    // name: "date",
    // revenueTotal: rowData.reduce((acc, curr) => acc + curr.revenueTotal, 0),
    // mediaCost: rowData.reduce((acc, curr) => acc + curr.mediaCost, 0),
    // servingFee: rowData.reduce((acc, curr) => acc + curr.servingFee, 0),
    // pixalateFee: rowData.reduce((acc, curr) => acc + curr.pixalateFee, 0),
    // partnerFee: rowData.reduce((acc, curr) => acc + curr.partnerFee, 0),
    // grossProfit: rowData.reduce((acc, curr) => acc + curr.grossProfit, 0),
    // grossProfitMargin:
    //   ((rowData.reduce((acc, curr) => acc + curr.revenueTotal, 0) -
    //     rowData.reduce((acc, curr) => acc + curr.mediaCost, 0) -
    //     rowData.reduce((acc, curr) => acc + curr.servingFee, 0)) /
    //     rowData.reduce((acc, curr) => acc + curr.revenueTotal, 0)) *
    //   100,
    // netIncome: rowData.reduce((acc, curr) => acc + curr.netIncome, 0),
    // procentNetIncome:
    //   (rowData.reduce((acc, curr) => acc + curr.netIncome, 0) /
    //     rowData.reduce((acc, curr) => acc + curr.revenueTotal, 0)) *
    //   100,
  };

  const sumRow1 = {
    MSdate: "Combined Total",
    MSaddRequests: "Total Ad Requests",
    MSimpressions:
      rowData.reduce((acc, curr) => acc + curr.MSaddRequests, 0) +
      rowData.reduce((acc, curr) => acc + curr.MEaddRequests, 0) +
      rowData.reduce((acc, curr) => acc + curr.MTaddRequests, 0),

    MSrevenue: "	Total Impressions",
    MSaddReqInMill:
      rowData.reduce((acc, curr) => acc + curr.MSimpressions, 0) +
      rowData.reduce((acc, curr) => acc + curr.MEimpressions, 0) +
      rowData.reduce((acc, curr) => acc + curr.MTimpressions, 0),
    MSfillRateInProcent: "Total Revenue",

    MTaddRequests:
      rowData.reduce((acc, curr) => acc + curr.MSrevenue, 0) +
      rowData.reduce((acc, curr) => acc + curr.MErevenue, 0) +
      rowData.reduce((acc, curr) => acc + curr.MTrevenue, 0),

    MTimpressions: "Total $/AD Req in Mil",
    MTrevenue:
      rowData.reduce((acc, curr) => acc + curr.MSaddReqInMill, 0) /
        DataFromFather.combinedArray.length +
      rowData.reduce((acc, curr) => acc + curr.MEaddReqInMill, 0) /
        DataFromFather.combinedArray.length +
      rowData.reduce((acc, curr) => acc + curr.MTaddReqInMill, 0) /
        DataFromFather.combinedArray.length,

    MTaddReqInMill: "Total Fill Rate(%)",
    MTfillRateInProcent:
      rowData.reduce((acc, curr) => acc + curr.MSfillRateInProcent, 0) /
        DataFromFather.combinedArray.length +
      rowData.reduce((acc, curr) => acc + curr.MEfillRateInProcent, 0) /
        DataFromFather.combinedArray.length +
      rowData.reduce((acc, curr) => acc + curr.MTfillRateInProcent, 0) /
        DataFromFather.combinedArray.length,

    MEaddRequests: "",
    // rowData.reduce((acc, curr) => acc + curr.MSfillRateInProcent, 0) +
    // rowData.reduce((acc, curr) => acc + curr.MTfillRateInProcent, 0) +
    // rowData.reduce((acc, curr) => acc + curr.MEfillRateInProcent, 0),

    MEimpressions: "", // rowData.reduce((acc, curr) => acc + curr.MEimpressions, 0),
    MErevenue: "", //rowData.reduce((acc, curr) => acc + curr.MErevenue, 0),
    MEaddReqInMill: "", // rowData.reduce((acc, curr) => acc + curr.MEaddReqInMill, 0),
    MEfillRateInProcent: "",
    //  rowData.reduce(
    //   (acc, curr) => acc + curr.MEfillRateInProcent,
    //   0
    // ),
  };

  // const gridOptions = {
  //   getRowStyle: (params) => {
  //     if (params.node.rowPinned === "bottom") {
  //       return { backgroundColor: "#f9f8f9" };
  //     }
  //   },
  //   rowClass: (params) => {
  //     return params.node.rowIndex === rowData.length ? "bottom-row" : "";
  //   },
  //   // other grid options...
  // };

  // Custom style function for pinned bottom rows
  const getRowStyle = (params) => {
    if (params.node.rowPinned === "bottom") {
      if (params.data === sumRow) {
        return { backgroundColor: "#f9f8f9" }; // Custom background color for totalRow1
      } else if (params.data === sumRow1) {
        return {
          fontWeight: "bold",
          backgroundColor: "#fef2f2",
          border: "2px solid", // Border style with 2px width
          borderColor: "red", // Border color
          //   padding: "10px", // Adding some padding for spacing
        }; // Custom background color for totalRow2
      }
    }
    return null;
  };

  // Custom class function for the last row
  const rowClass = (params) => {
    return params.node.rowIndex === rowData.length ? "bottom-row" : "";
  };

  const gridOptions = {
    getRowStyle: getRowStyle,
    rowClass: rowClass,
    // other grid options...
  };

  //   const columnDefs = [
  //     { headerName: "Name", field: "name" },
  //     { headerName: "Value", field: "value" },
  //   ];
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  //   useEffect(() => {
  //     // This code will run only when myVariable changes
  //     console.log(`startDate changed to ${startDate} ---- ${endDate}`);
  //   }, [startDate, endDate]);
  return (
    <div style={{ paddingTop: 30, paddingBottom: 30 }}>
      <div
        style={{
          display: "flex",
        }}
      ></div>

      <div>
        <div style={containerStyle}>
          <div style={gridStyle} className="ag-theme-alpine">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              {/* <div
                style={{ flex: 1, justifyContent: "flex-end", display: "flex" }}
              >
                {Array.isArray(data7) ? (
                  data7 &&
                  data7.map((responseData, i) => (
                    <div
                      style={{
                        background: "#f4f2f4",
                        borderTopLeftRadius: i === 0 ? 50 : 0,
                        borderBottomLeftRadius: i === 0 ? 50 : 0,
                        borderTopRightRadius: i === data7.length - 1 ? 50 : 0,
                        borderBottomRightRadius:
                          i === data7.length - 1 ? 50 : 0,
                        height: 40,
                        alignItems: "center",
                        paddingLeft: i === 0 ? 3 : 0,
                        paddingRight: i === data7.length - 1 ? 3 : 0,
                        //justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      <button
                        //value={responseData.component.id}
                        style={{
                          borderWidth: 0,
                          borderRadius: 50,
                          background: CompColor === i ? "white" : "#f4f2f4",
                          height: 35,
                          fontWeight: CompColor === i ? "bold" : "",
                          fontSize: 14,
                        }}
                        onClick={() => {
                          setCompColor(i);
                          compName = responseData;
                          //setCompName(responseData);
                          // setComponentName(responseData.component.name);
                          console.log(responseData);
                          //setComponentId(responseData.component.id);
                        }}
                      >
                        {responseData}
                      </button>
                      {i !== data7.length - 1 ? (
                        <div
                          style={{
                            width: 1,
                            height: 16,
                            flexGrow: 0,
                            background: "#c8c7cb",
                          }}
                        ></div>
                      ) : (
                        <></>
                      )}
                    </div>
                  ))
                ) : (
                  <h6> You dont have components</h6>
                )}
              </div> */}
            </div>
            <AgGridReact
              cellClassRules={ragCellClassRules}
              ref={gridRef} // Ref for accessing Grid's API
              rowData={rowData} // Row Data for Rows //{[...rowData, sumRow]} add another column had a total values
              columnDefs={columnDefs} // Column Defs for Columns
              defaultColDef={defaultColDef} // Default Column Properties
              animateRows={true} // Optional - set to 'true' to have rows animate when sorted
              rowSelection="multiple" // Options - allows click selection of rows
              onCellClicked={cellClickedListener} // Optional - registering for Grid Event
              onGridReady={onGridReady}
              gridOptions={gridOptions}
              pinnedBottomRowData={[sumRow, sumRow1]}
              suppressScrollOnNewData={true}
              getRowHeight={getRowHeight}
              domLayout="autoHeight"
            />
          </div>{" "}
        </div>{" "}
        <style>
          {`
              .ag-theme-alpine .ag-root-wrapper {
                border: none;
              }
            `}
          {`
              .my-header-class {
                background-color: white;
              }
            `}
        </style>
      </div>
    </div>
  );
}

import React from "react";
import { Outlet, Link, withRouter, useHistory } from "react-router-dom";
import GeneralReportChannelDateScope from "./IncomeBodyComponents/GeneralReportChannelDateScope";
import IncomeChannelOverViewTable from "./IncomeBodyComponents/IncomeChannelOverViewTable";
import IncomeGraph from "./IncomeBodyComponents/IncomeGraph";
import MasterIncomeOverViewTable from "./IncomeBodyComponents/MasterIncomeOverViewTable";
import { token1 } from "./Body";
function IncomBody() {
  const MyToken = JSON.parse(localStorage.getItem("Token"));
  console.log("MyToken");
  console.log(MyToken);
  return (
    <div className="container">
      <h5 style={{ color: "#4c1d95" }}>Income Reports</h5>
      <GeneralReportChannelDateScope />
      <br></br>
      {/* <IncomeChannelOverViewTable /> */}
      <br></br>
      {/* <IncomeGraph/> */}
      {/* <MasterIncomeOverViewTable /> */}
    </div>
  );
}
export default IncomBody;

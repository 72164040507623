import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import { display } from "@mui/system";
//import "./styles.css";
import "antd/dist/antd.css";
import { Excel } from "antd-table-saveas-excel";
import "../../Pages/styles.css";
import Highlighter from "react-highlight-words";
import { Button, Input, Space, Table, Typography } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { withRouter, useHistory, useLocation } from "react-router-dom";

const { Text } = Typography;

export default function SupplyTeamReportLast1Table({ Array10 }) {
  // console.log(Array10);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  function useWindowSize() {
    const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
    }, []);
    return size;
  }
  const [height, width] = useWindowSize();

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    //  { field: "id", headerName: "ID", width: 90 },
    {
      title: "BillingName",
      dataIndex: "BillingName",
      flex: 1,
      width: 150,
      editable: true,
      ...getColumnSearchProps("BillingName"),
      sorter: (a, b) => a.BillingName.length - b.BillingName.length,
      sortDirections: ["descend", "ascend"],
    },

    //it look mko3ar Beacuse it is Empty
    {
      title: "SEAT",
      dataIndex: "SEAT",
      editable: true,
      ...getColumnSearchProps("SEAT"),
      sorter: (a, b) => a.SEAT.length - b.SEAT.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "source",
      dataIndex: "source",
      editable: true,
      ...getColumnSearchProps("source"),
      sorter: (a, b) => a.source.length - b.source.length,
      sortDirections: ["descend", "ascend"],
    },

    {
      title: "ENV",
      dataIndex: "ENV",
      editable: true,
      ...getColumnSearchProps("ENV"),
      sorter: (a, b) => a.ENV - b.ENV,
      //   render: (value) => {
      //     return value.toLocaleString("en");
      //   },
    },
    {
      title: "OS",
      dataIndex: "OS",
      editable: true,
      ...getColumnSearchProps("OS"),
      sorter: (a, b) => a.OS.length - b.OS.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Bundle",
      dataIndex: "Bundle",
      editable: true,
      ...getColumnSearchProps("Bundle"),
      sorter: (a, b) => a.Bundle.length - b.Bundle.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Region",
      dataIndex: "Region",
      editable: true,
      ...getColumnSearchProps("Region"),
      sorter: (a, b) => a.Region.length - b.Region.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Req",
      dataIndex: "Req",
      editable: true,
      ...getColumnSearchProps("Req"),
      sorter: (a, b) => a.Req - b.Req,
      render: (value) => {
        return value.toLocaleString("en");
      },
    },
    {
      title: "Imp",
      dataIndex: "Imp",
      editable: true,
      ...getColumnSearchProps("Imp"),
      sorter: (a, b) => a.Imp - b.Imp,
      render: (value) => {
        return value.toLocaleString("en");
      },
    },
    {
      title: "Fill",
      dataIndex: "Fill",
      editable: true,
      ...getColumnSearchProps("Fill"),
      sorter: (a, b) => a.Fill - b.Fill,
      render: (value) => {
        return value.toLocaleString("en");
      },
    },

    {
      title: "Revenue",
      dataIndex: "Revenue",
      editable: true,
      ...getColumnSearchProps("Revenue"),
      sorter: (a, b) => a.Revenue - b.Revenue,
      render: (value) => {
        return "$" + value.toLocaleString("en");
      },
    },

    {
      title: "QS",
      dataIndex: "QS",
      editable: true,
      ...getColumnSearchProps("QS"),
      sorter: (a, b) => a.QS - b.QS,
      //   render: (value) => {
      //     return value.toLocaleString("en");
      //   },
    },
  ];
  const [pageSize, setPageSize] = React.useState(15);
  const handleClick = () => {
    const excel = new Excel();
    excel
      .addSheet("test")
      .addColumns(columns)
      .addDataSource(Array10, {
        str2Percent: true,
      })
      .saveAs("Excel.xlsx");
  };
  const sum = Array10.reduce((acc, cur) => acc + cur.REQ, 0);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  function useWindowSize() {
    const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
    }, []);
    return size;
  }
  return (
    <div style={{ backgroundColor: "white" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h6>Opportunity Radar</h6>
      </div>
      <div
        style={{
          flex: 1,
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "white",
            marginBottom: 5,
          }}
        >
          <Table
            dataSource={Array10}
            columns={columns}
            pagination={{
              position: ["none", "none"],
            }}
            size="small"
            scroll={{ x: width > 1025 ? "100%" : "200vh", y: 300 }}
          ></Table>
        </div>{" "}
      </div>{" "}
    </div>
  );
}

{
  /* <div
style={{
  flex: 1,
  flexDirection: "row",
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  justifyContent: "space-between",
}}
>
<div
  style={{
    width: width < 1040 ? "100%" : "49%",
    backgroundColor: "white",
    marginBottom: 5,
  }}
> */
}
